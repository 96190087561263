import React from 'react';

function Streamflow() {
    return (
      <div>
        <div className="card bg-dark text-white">
          <img src="/media/streamflow/River_big_pic_edited.jpg" className="card-img" alt="Spokane Falls" />
          <div className="h-40 header-image-portion-overlay header-title d-none d-lg-flex">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text">Chapter 4: Streamflow Impact Study</h1>
              <h4 className="card-text header-title">
                <i>
                  <p>
                    “From Lake Coeur d'Alene to its confluence with the Columbia, the Spokane River travels 111 miles of 
                    varied and often spectacular terrain-rural, urban, in places wild. The river has been a trading and 
                    gathering place for Indigenous peoples for thousands of years. With bountiful trout, accessible swimming holes, 
                    and challenging rapids, it is a recreational magnet for residents and tourists alike.”
                  </p>
                  <p>
                    – Paul Lindholdt, “The Spokane River”
                  </p>
                </i>
              </h4>
            </div>
          </div>
          <div className="h-80 header-image-portion-overlay header-title d-none d-md-flex d-lg-none">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text">Chapter 4: Streamflow Impact Study</h1>
              <i>
                <p>
                  “From Lake Coeur d'Alene to its confluence with the Columbia, the Spokane River travels 111 miles of 
                  varied and often spectacular terrain-rural, urban, in places wild. The river has been a trading and 
                  gathering place for Indigenous peoples for thousands of years. With bountiful trout, accessible swimming holes, 
                  and challenging rapids, it is a recreational magnet for residents and tourists alike.”
                </p>
                <p>
                  – Paul Lindholdt, “The Spokane River”
                </p>
              </i>
            </div>
          </div>
          <div className="h-80 header-image-portion-overlay header-title d-none d-sm-flex d-md-none">
            <div className="my-auto">
              <h3 className="card-title image-overlay-text">Chapter 4: Streamflow Impact Study</h3>
              <i>
                <p>
                  “From Lake Coeur d'Alene to its confluence with the Columbia, the Spokane River travels 111 miles of 
                  varied and often spectacular terrain-rural, urban, in places wild. The river has been a trading and 
                  gathering place for Indigenous peoples for thousands of years. With bountiful trout, accessible swimming holes, 
                  and challenging rapids, it is a recreational magnet for residents and tourists alike.”
                </p>
                <p>
                  – Paul Lindholdt, “The Spokane River”
                </p>
              </i>
            </div>
          </div>
          <div className="h-80 header-image-portion-overlay header-title d-sm-none">
            <div className="my-auto">
              <h3 className="card-title image-overlay-text center-vertical">Chapter 4: Streamflow Impact Study</h3>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="row">
            <div className="col-md-10 content">
              <ul id="chapter-summary" className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Chapter Summary: </b>Examines the impact that projected hydrological changes to the Spokane River will have on river
                  recreation, Redband trout habitat, and the Spokane Valley-Rathdrum Prairie Aquifer.</li>
                <li className="list-group-item">
                  <b>Lead Authors: </b>John Porcello (GSI Water Solutions, Inc.), Kara Odegard (Measure Meant), Karl Rains (Washington
                  Department of Ecology), Jule Schultz (Spokane Riverkeeper), and Brad Morin (Spokane Riverkeeper)</li>
                <li className="list-group-item">
                  <b>Review Editors: </b>Nathan Gilles (CIRC/Oregon State University), Katherine Hegewisch (CIRC/University of Idaho), John
                  Abatzoglou (CIRC/UI), Ann Mooney (CIRC/OSU), and Meghan Dalton (CIRC/OSU).</li>
              </ul>
              <div className="row m-1">
                <div className="col p-0">
                  <div className="card border-dark m-3">
                    <div className="card-header p-2 card-header-display">
                      <img className="col-1 icon-image p-0" src="/media/OSU_icon_key_01.png" alt="key"></img>
                      <h4 className="col-11 card-header-title p-0">Key Findings</h4>
                    </div>
                    <div className="card-body p-0">
                      <ol className="list-group" >
                        <li className="list-group-item">
                          In the coming decades, the shift of precipitation from snow to rain coupled with earlier snow melts is expected to
                          alter the timing of streamflow on the Spokane River even while the total annual volume of streamflow in the river is
                          expected to remain similar to historical levels.
                        </li>
                        <li className="list-group-item">
                          Impacts from low summer flows are likely to intensify over time, particularly if greenhouse gas emissions are
                          allowed to continue increasing at their current rate.
                        </li>
                        <li className="list-group-item">
                          By the end of the century, flows conducive to optimum whitewater rafting and kayaking on the Spokane River may
                          cease as early as June.
                        </li>
                        <li className="list-group-item">
                          The expected changes to the unregulated flow of the Spokane River is expected to have several detrimental impacts,
                          including for native Redband trout, summer recreational opportunities, and the general aesthetic value that the
                          Spokane River provides to the community.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                {/* End of Key Findings */}
                <div className="col p-0">
                  <div className="card border-dark m-3">
                    <div className="card-header text-white bg-success p-2 card-header-display">
                      <img className="col-2 icon-image" src="/media/OSU_icon_shield_01.png" alt="shield"></img>
                      <h4 className="col-10 card-header-title p-0">Resiliency Actions</h4>
                    </div>
                    <div className="card-body p-0">
                      <ol className="list-group">
                        <li className="list-group-item list-group-item-success">
                          <b>Reduce Emissions:</b> Take all possible actions to reduce greenhouse gas emissions and avoid the high emissions
                          scenario (RCP 8.5).
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Prioritize Trout Habitat:</b> Reconsider regulations at Post Falls to help prioritize Redband trout habitat.
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Future Research:</b> Conduct more research to fully understand the long-term impacts of climate change on the
                          Spokane Valley-Rathdrum Prairie Aquifer.
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Future Research:</b> Investigate well depth and pump technology.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div id="climate-data-story-recreation" className="card border-dark mb-3">
                <div className="card-header p-2 card-header-display">
                  <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data-story"></img>
                  <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Recreation on the Spokane River</i></h4>
                </div>
                <div className="card-body">
                  <p>
                    The Spokane River is arguably Spokane’s top natural attraction, especially during the spring and summer months when
                    outdoor enthusiasts and visitors spend more time along or on the river. As the Spokane area’s primary source of visitor
                    information, the organization Visit Spokane encourages visitors to float down the river, paddleboard, fish, or simply take in
                    the scenery from along the shoreline (<b>Visit Spokane 2019</b>). It is no surprise that summertime recreation activities associated
                    with the river have a significant economic impact for our region. According to a 2015 report from Washington’s Recreation
                    & Conservation Office, the total annual economic contribution of all types of outdoor recreation in Spokane County
                    amounted to nearly $1.2 billion and 12,500 jobs annually (<b>Washington State Recreation & Conservation Office 2015</b>). 
                    Although not broken down at a county level, annual state expenditures on non-motorized boating and rafting activities
                    have been estimated to exceed $640 million (<b>Briceno and Schundler 2015</b>).
                  </p>
                  <p>
                    Several individuals representing local paddling organizations and commercial rafting enterprises were interviewed in an
                    effort to determine potential ramifications to recreational users and businesses from an earlier high streamflow and extended
                    summer low-flow on the Spokane River. Although qualitative information from the individuals was mixed, a few common
                    themes came out of the discussions. The first is that the lower portion of the river between Spokane Falls and Nine Mile
                    Reservoir—which includes the Spokane River Gorge, the Bowl & Pitcher rock formation, and the Devil’s Toenail whitewater
                    rapids—relies on a minimum flow of approximately 2,500 cubic feet per second (cfs) for the commercial rafting companies
                    and most kayakers to safely navigate the section.
                  </p>
                  <p>
                    Once flow drops below 2,000 cfs, all but the most extreme kayakers are limited to milder “float” trips that include longer
                    sections of calmer water. Historical trends in streamflow typically allowed rafting companies to market whitewater trips
                    through this section of the Spokane River during the month of June, when school is out and many Americans begin their
                    summer vacations. With earlier spring high flows and an earlier and extended summer low-flow period, future whitewater
                    conditions may cease as early as June, according to our analysis. This means that optimum whitewater conditions could shift
                    from June to May, resulting in a reduced consumer base. (<b>Rains 2019</b>).This would limit local rafting companies from
                    capitalizing on summer tourism.
                  </p>
                  <p>
                    Although representatives of the commercial rafting companies contacted for this report recognized existing and potential
                    financial impacts from an earlier whitewater season on the Spokane River, they also reported that they already mitigate for
                    seasonal variations through a variety of methods, including operating on multiple regional rivers, highlighting scenic float
                    trips on the Spokane River versus whitewater trips, and/or by offering other types of river activities, such as tubing.
                    Representatives also indicated that while the instream flow rule (<b>See Analysis—Assumptions & Limitations</b>) is not as
                    favorable to recreational use as they would prefer, the rule does mitigate against potential extreme low flow conditions
                    occurring in mid to late summer. Rafting company representatives also noted that during the last several years, smoke from
                    seasonal wildfires has had an even greater detrimental effect on river use than streamflow.
                  </p>
                </div>
              </div>
              <div id="larger-context" className="card border-dark mb-3 bg-light">
                <div className="card-header p-2 card-header-display">
                  <h4 className="col-11 card-header-title p-0">Larger Context—Streamflows are Changing Across the Pacific Northwest United States</h4>
                </div>
                <div className="card-body">
                  <p>
                    As the <i><b>Precipitation</b></i> chapter noted, projected future precipitation for Spokane under both the lower emissions scenario (RCP
                    4.5) and the high emissions scenario (RCP 8.5) is not expected to deviate significantly from historical levels in terms of both
                    total annual volume and seasonal timing. Spokane and the Pacific Northwest United States generally are projected to see a
                    slight increase in precipitation during the fall, winter, and spring months, and a slight decrease in precipitation over the
                    summer months. However, as the <i><b>Precipitation</b></i> chapter notes, precipitation projections should not be considered alone. As
                    noted in the <i><b>Temperature</b></i> chapter, throughout this century Spokane is projected to see an increase in annual and monthly
                    temperatures under both RCP 4.5 and RCP 8.5. As temperatures rise throughout this century, the Pacific Northwest is
                    expected to see more rain and less snow in many of its watersheds during the cool months. This shift in the form of
                    precipitation is anticipated to greatly alter the flow of water in the Pacific Northwest’s rivers and streams even while
                    precipitation generally is projected to remain similar to (or become slightly wetter than) current conditions during the fall,
                    winter, and spring months. In general across the Pacific Northwest, projected future warming is expected to lead to higher
                    river and stream flows during the winter months and lower river and stream flows during the summer months (<b>Chegwidden et al., 2019</b>).
                  </p>
                  <p>
                    When precipitation falls as snow it can contribute to snowpack, a critical natural water storage system for our region.
                    Snowpack, especially mountain snowpack, delays the timing of the water entering a stream system. Conversely, when winter
                    precipitation falls as rain, that water enters the stream system immediately. This leads to a decrease in the amount of water
                    stored in the mountains as snow that would otherwise enter the stream system later during the spring and summer months. As
                    a result, more water flows out of the watershed earlier in the year, leading to earlier and longer summer-low flows in rivers
                    and streams.
                  </p>
                </div>
              </div>
              <div id="local-context" className="card border-dark bg-light mb-3">
                <div className="card-header"><h4>Local Context—Lower Summer Flows on the Spokane River</h4></div>
                <div className="card-body">
                  <p>
                    <i>
                      “From Lake Coeur d'Alene to its confluence with the Columbia, the Spokane River travels 111 miles of varied and often
                      spectacular terrain-rural, urban, in places wild. The river has been a trading and gathering place for Indigenous peoples for
                      thousands of years. With bountiful trout, accessible swimming holes, and challenging rapids, it is a recreational magnet for
                      residents and tourists alike. The Spokane also bears the legacy of industrial growth and remains caught amid interests
                      competing over natural resources.”
                      – Paul Lindholdt, “The Spokane River”
                    </i>
                  </p>
                  <p>
                    It is with Paul Lindholdt’s eloquent words that we investigated projected future trends to the flow of the Spokane River
                    resulting from a warming climate. Setting aside the influence of regulated flows from Post Falls Dam at Coeur d’Alene Lake,
                    publicly available climate and hydrologic models together suggest that human-caused climate change will have a dramatic
                    effect on the seasonal flow of the Spokane River compared to historic levels.
                  </p>
                  <p>
                    The impacts on streamflow of the transition from rain to snow has already been seen in the Spokane River watershed in
                    individual years (<b>Abatzoglou 2016</b>) and multi-year/decadal time frames (<b>Porcello et al., 2017</b>). Our analysis indicates that
                    due to decreased snowpack and increased rainfall, the river’s summertime flow rates will continue to decrease throughout the
                    21st century. The timing and severity of changes to the flow of the Spokane River are likely to have a detrimental impact on
                    habitat for native Redband trout, summer recreational opportunities for boaters and anglers, and the general aesthetic value
                    that the Spokane River provides to our community and its visitors. These climate impacts, our analysis suggests, are likely to
                    have financial impacts to Spokane’s regional economy. Our analysis further estimates that impacts to the Spokane
                    Valley–Rathdrum Prairie Aquifer will be less significant than impacts to Redband trout and impacts to river-based recreation.
                    However, a more in-depth analysis is needed to fully understand the potential impacts that future climate scenarios might
                    have on the aquifer.
                  </p>
                  <p>
                    It is clear that impacts to the Spokane River are likely to intensify over time, particularly if greenhouse gas emissions are
                    allowed to continue increasing at their current rate. Impacts are projected to be more intense under the high emissions
                    scenario (RCP 8.5), which closely resembles humanity’s current emissions rates, when compared to the lower emissions
                    scenario (RCP 4.5).
                  </p>
                </div>
              </div>
              <div id="methods" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Methods—Geography, Data Tools, Assumptions & Limitations, Multi-model Means, Emissions Scenarios, Variables, and Climate Data Stories</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Geography:</b> Historical and projected future streamflow rates and volumes for the Spokane River were simulated for the U.S.
                    Geological Survey stream gauge below Post Falls Dam, which is located in Idaho at the upstream end of the Spokane River,
                    just below Coeur d’Alene Lake. The Post Falls gauge (USGS stream gauge station number 12419000) was used to evaluate
                    how climate change may affect the Spokane River in terms of habitat, recreation, and aquifer recharge. Projected mountain
                    snowpack (as snow water equivalent) was evaluated for the two highest elevation Snow Telemetry (SNOTEL) stations in the
                    local watershed: the Sunset and Lost Lake stations.
                  </p>
                  <p>
                    <b>Data Tools:</b> Data and visualizations were reviewed from the climate web tools available online in The Climate Toolbox
                    (<a href="https://climatetoolbox.org/">https://climatetoolbox.org/</a>), a product of The Pacific Northwest Climate Impacts Research Consortium (CIRC), A NOAA
                    Regional Integrated Sciences and Assessments Team (<b>“Climate Tools” CIRC 2019</b>). The Toolbox provides projections
                    from multiple global climate models (GCMs) and two emission scenarios (RCP 4.5 and RCP 8.5) downscaled to a 2.5-mile
                    resolution. This analysis primarily used 10 GCMs downscaled using Multivariate Adaptive Constructed Analogs (MACA)
                    statistical downscaling method. Downscaled GCM data were then run through the Variable Infiltration Capacity (VIC)
                    Macroscale Hydrologic Model (<b>Climate Toolbox 2019</b>). Downscaling data from multiple GCM runs adds localized data to
                    make the models more useful at a local scale. For this analysis, our team downloaded data and figures from the following
                    Toolbox tools:
                  </p>
                  <ul>
                    <li>
                      Future Streamflows Tool (<a href="https://climatetoolbox.org/tool/Future-Streamflows">https://climatetoolbox.org/tool/Future-Streamflows</a>)
                    </li>
                    <li>
                      Climate Mapper Tool (<a href="https://climatetoolbox.org/tool/Climate-Mapper">https://climatetoolbox.org/tool/Climate-Mapper</a>)
                    </li>
                    <li>
                      The Future Boxplots Tool (<a href='https://climatetoolbox.org/tool/Future-Boxplots'>https://climatetoolbox.org/tool/Future-Boxplots</a>)
                    </li>
                  </ul>
                  <p>
                    <b>Assumptions &; Limitations:</b> Our analysis of projected impacts to the Spokane River began with an analysis of projected
                    declines in mountain snowpack and the increased likelihood of rain during the fall, winter, and spring months. We then
                    analyzed how more rain and less snow is likely to manifest as changes to flows in the Spokane River. From there we
                    analyzed how projected low flows might impact water-based recreation on the Spokane River (<b>Climate Data Story—Recreation on the Spokane River</b>),
                    Redband Trout (<u><b>Climate Data Story—Redband Trout Habitat</b></u>), and the local
                    aquifer (<b>Climate Data Story—Spokane Valley-Rathdrum Prairie Aquifer</b>). The primary limitations and uncertainties in this
                    initial exploration of future trends to the flow of the Spokane River resulting from climate change arises from the
                    uncertainties inherent in the following:
                  </p>
                  <ol>
                    <li>
                      The many different global climate models that are available.
                    </li>
                    <li>
                      The processes of downscaling rainfall, snowmelt, and temperature information from those models to local
                      watersheds.
                    </li>
                    <li>
                      Additional uncertainties that arise when translating the downscaled climate information into estimates of flow rates
                      in the Spokane River using a hydrologic modeling process.
                    </li>
                    <li>
                      Uncertainty regarding future rates of greenhouse gas emissions expressed as the two emissions scenarios used in the
                      Toolbox, the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5).
                    </li>
                  </ol>
                  <p>
                    Another limitation our team confronted concerned the two highest elevation Snow Telemetry (SNOTEL) stations in the local
                    watershed: the Sunset and Lost Lake stations. Our team hoped to determine how snowpack at the two stations might change
                    under projected future climate change. To determine this, our team used the variable snow water equivalent (SWE) as a proxy
                    for projected future snow on the ground at the two stations. SWE is a measure of how much liquid water is available in a
                    given amount of snow on the ground. We were limited in our analysis by the resolution of downscaled data available from
                    the Toolbox. As noted above, the Toolbox uses GCM data that has been downscaled to a 2.5-mile grid cell resolution. This
                    meant that while we were interested in projected future SWE at the exact point locations for the Sunset and Lost Lake
                    stations, what we were able to determine using Toolbox data was projected future SWE for the two 2.5-mile grid cells
                    containing the stations. Because the grid cells assume a set average elevation for the entire cell, this meant that the elevations
                    of the two stations in reality might have been either above or below the average elevation for the two cells containing the two
                    stations.
                  </p>
                  <p>
                    Additionally, there are two other assumptions and limitations that warrant consideration in discussions of flow rates in the
                    Spokane River, but which are not possible to directly evaluate in this analysis:
                  </p>
                  <ol>
                    <li>
                      The streamflow data from the Toolbox are projections of unregulated flow and do not account for the regulated
                      flow on the Spokane River that is largely controlled by the Post Falls Dam in Idaho. The Post Falls Dam is operated
                      by the Avista power utility. The 50-year license from the Federal Energy Regulatory Commission (FERC) issued to
                      Avista in 2009 includes regulatory thresholds of minimum flows to address aesthetic, recreation, and fish habitat
                      concerns. At the Post Falls Dam, consideration is also given to property owners and recreation opportunities on
                      Coeur d’Alene Lake from June 5th until Labor Day. Further downstream, the Upriver, Monroe Street, and Nine Mile
                      dams are operated as “run-of-the-river” facilities that unlike Post Falls Dam are not used to regulate the river’s flow.
                    </li>
                    <li>
                      The State of Washington’s instream flow rule for the Spokane River (<b>Chapter 173-557 Washington Administrative Code</b>) 
                      took effect on February 27th, 2015 and was developed primarily to identify and establish
                      instream flow levels necessary to protect wildlife, fish, scenic, aesthetic, recreation, water quality, and other
                      environmental values, navigational values, stock watering requirements, and existing water rights (<b>Washington State Legislature 2019</b>).
                      Because these instream flow levels are target flow rates for the river, like other regulated
                      flows on the river they are not accounted for in the Toolbox’s streamflow data sets. Specific details regarding the
                      flow rates established under the instream flow rule for the Spokane River are as follows:
                      <ol>
                        <li>
                          Seasonally variable flow rates are identified throughout the year in this instream flow rule at the location on
                          the river of the Monroe Street stream gauge (the U.S. Geological Survey gauge formally known as the
                          “Spokane River at Spokane” gauge, which is USGS stream gauge station number 12422500) <b>(Washington State Legislature, “Stream management units” 2019</b>). 
                          The minimum instream flow standards at this
                          gauge are 850 cubic feet per second (cfs) for the period of June 16 through September 30; 1,700 cfs from
                          October 1 through March 31; and 6,500 cfs from April 1 through June 15.
                        </li>
                        <li>
                          The rule also identifies a set of streamflow standards at the Greenacres gauge number 12420500 at Barker
                          Road, but only for the period of June 16 through September 30, when the standard is set at 500 cfs.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <p>
                    <b>Multi-model Means:</b> Many of the data and figures that make up this analysis employ the mean resulting from multiple
                    global climate models (GCMs). In general, the Toolbox uses 20 global climate models (GCMs) to create its climate
                    projections (temperature, precipitation, etc.) and 10 GCMs to create its hydrology projections (snow water equivalent,
                    streamflow, etc.). As noted above, this analysis primarily used data from 10 GCMs downscaled using the MACA method and
                    that were then run through the VIC hydrologic model. Using a multi-model mean, as opposed to the results of a single model,
                    is accepted as best practice by the climate science community. However, the multi-model mean does not show the full spread
                    of results from all the GCMs used to create a future projection, but rather the average of that multi-model spread. In other
                    words, actual future climate conditions—when we get to them in the decades ahead—might lie either above or below the
                    multi-model mean.
                  </p>
                  <p>
                    <b>Emissions Scenarios:</b> For this analysis, we utilized both the lower emissions scenario (RCP 4.5) and the high emissions
                    scenario (RCP 8.5) from phase 5 of the Coupled Model Intercomparison Project (<b>Climate Toolbox 2019</b>). The RCP 8.5
                    scenario simulates what is likely to happen if greenhouse gases continue to be released into the atmosphere at their current
                    rate, and, as result, warming is allowed to continue at its current upward trajectory throughout this century and beyond. RCP
                    4.5 simulates a dramatic reduction in greenhouse gas emissions, so that while warming continues throughout this century,
                    warming starts to level off after 2100. In general, the two emissions scenarios start to diverge around the middle decades of
                    this century (2040–2069). At mid-century, warming under RCP 4.5 slows while warming under RCP 8.5 continues at its
                    current rate (CIRC 2019). The two scenarios were used side by side in this analysis to evaluate whether the climate impacts
                    to the Spokane River differed significantly from RCP 4.5 to RCP 8.5. <b>In general, while we found impacts to flows on the river under both scenarios, RCP 8.5 produced far greater impacts when compared to RCP 4.5.</b>
                  </p>
                  <p>
                    (Note: RCP 4.5 isn’t the lowest emissions scenario used by climate researchers. RCP 2.6 is the lowest emission
                    scenario considered in climate models. However, because the collective global emissions pathway has very likely
                    veered off course from that modeled under RCP 2.6, RCP 2.6 is no longer used as the low emission scenario
                    pathway. Since RCP 4.5 is lower than RCP 8.5, this report has adopted the standard used by many in the climate
                    research community: lower to describe RCP 4.5 and high to describe RCP 8.5, rather than lower and higher to
                    describe the two scenarios.)
                  </p>
                  <p>
                    <b>Variables:</b> Future projections of snow accumulation and melt were calculated as snow water equivalent (SWE), the amount
                    of liquid water contained in a given amount of snow. Projected SWE was evaluated at the Sunset and Lost Lake SNOTEL
                    stations using the variable <i>April 1st snow water equivalent</i> (SWE). (As noted above and below, this analysis entailed a large
                    limitation.) The date April 1st is a commonly used date to measure SWE because this date historically has often marked the
                    point of peak snow accumulation across much of the Pacific Northwest United States. Historical and projected future
                    streamflow rates and volumes for the Spokane River were calculated in cubic feet per second (cfs). We used the following
                    variables and Climate Toolbox tools to guide our analysis:
                  </p>
                  <ul>
                    <li>
                      <i>Streamflow as measured in cubic feet per second</i> (Future Streamflows Tool)
                    </li>
                    <li>
                      <i>April 1st Snow Water Equivalent</i> (Future Climate Boxplots Tool)
                    </li>
                  </ul>
                  <p>
                    <b>Climate Data Stories:</b> For our analysis we chose to investigate three climate data stories covering the potential effects of
                    changing streamflows on Redband Trout habitat (<b>Climate Data Story— Redband Trout Habitat</b>), the Spokane
                    Valley–Rathdrum Prairie Aquifer (<b>Climate Data Story—Spokane Valley-Rathdrum Prairie Aquifer</b>), and recreation on the
                    Spokane River (<b>Climate Data Story—Recreation on the Spokane River</b>). A climate data story is defined by CIRC as “a
                    narrative outlining climate facts and impacts specific to your community” (<b>Mooney et al., 2019</b>).
                  </p>
                </div>
              </div>
              <div id="projected-future-climate" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Projected Future Climate—Decrease in Snow Water Equivalent for Grid Cells Containing Sunset
                  and Lost Lake</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Variable:</b> <i>April 1st Snow Water Equivalent</i>
                  </p>
                  <p>
                    <b>Finding:</b> The grid cells containing Sunset and Lost Lake SNOTEL Stations are projected to see declines in April 1st snow
                    water equivalent (SWE) under both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5).
                  </p>
                  <p>
                    <b>Finding:</b> Declines in SWE at both stations will be greater under RCP 8.5 when compared to RCP 4.5.
                  </p>
                  <p>
                    <b>Finding:</b> Of the two stations, Sunset station, which is 570 feet lower in elevation than Lost Lake, is projected to see the
                    largest declines in SWE.
                  </p>
                  <p>
                    <b>Justification:</b> For this analysis we examined future snow projections at two snow monitoring sites: the Sunset SNOTEL
                    Station (Elevation: 5,540 feet) (47.55545 o N, 115.82422 o W) and the Lost Lake SNOTEL Station (Elevation 6,110) (47.0809
                    o N, 115.9604 o W). As noted above, this analysis entailed a large limitation. To determine how snowpack at the two stations
                    might change under projected future climate change, our team used the variable snow water equivalent (SWE) as a proxy for
                    projected future snow on the ground at the two stations. (SWE is a measure of how much liquid water is available in a given
                    amount of snow on the ground.) The date April 1st was chosen to examination SWE at the two stations because the date
                    marks the point of peak snow accumulation across much of the Pacific Northwest. However, our analysis was limited due to
                    downscaled data available through the Toolbox. The Toolbox downscales data to a 2.5-mile grid cell resolution. This meant
                    that while we were interested in projected future SWE at the exact point locations of the Sunset and Lost Lake stations, what
                    we were able to determine using the Toolbox was projected future SWE for the entire 2.5-mile grid cells that contained the
                    two stations. Because the grid cells each used a set average elevation across the entire cells, this meant that the elevations of
                    the two stations in reality might have been either above or below the average elevation for the two cells containing the two
                    stations. This made our results more general than we would have liked. That said, our analysis was able to give us a general
                    picture of how SWE at the two grid cells containing the two station is projected to decline under future climate change.
                  </p>
                  <p>
                    Sunset and Lost Lake are the two highest elevation upstream SNOTEL sites in our local watershed. Snow measured at the
                    two stations ultimately feeds the Spokane River and Coeur d’Alene Lake (<b>Figure 1</b>). SNOTEL—short for “Snow
                    Telemetry”—are fixed stations in the mountains of the Western United States that measure snow depth and SWE. SNOTEL
                    stations are operated by the Natural Resources Conservation Service (<b>NRCS 2019</b>). <b>Table 1</b> and <b>Figure 2</b> summarize the
                    SWE that is projected to be lost at each grid cell containing each site under both RCP 4.5 and RCP 8.5. SWE is measured in
                    inches. The grid cells containing both the Sunset and Lost Lake stations are projected to see significant decreases in snow
                    water equivalent (SWE) under both RCP 4.5 and RCP 8.5. The grid cell containing the Sunset station is projected to see a
                    greater degree of SWE loss compared to the grid cell containing the Lost Lake station. This is likely due to the difference in
                    elevation between these two stations. The Sunset station is 570 feet lower in elevation than the Lost Lake station. Being at a
                    lower elevation makes the Sunset more likely to experience less snowpack under projected warming as that warming will be
                    more intense at the lower elevations.
                  </p>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="streamflow-snotel-data" src="/media/streamflow/Fig-6-Streamflow.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 1:</b> Map of Spokane-area SNOTEL sites, including the Sunset and Lost Lake stations. Source: John Porcello and Jake Gorski, GSI
                            Water Solutions, 2016 (http://gsiwatersolutions.com/).
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p>
                    <i>
                      <b>Table 1</b>: April 1 Snow Water Equivalent (SWE) (in inches) for the grid cells containing the Sunset SNOTEL and Lost Lake SNOTEL
                      monitoring stations for the simulated historical period 1971–2000 and the projected future period 2010–2039, 2040–2069, and 2070–2099
                      under both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5). The numbers used in this table represent
                      the multi-model mean from 10 downscaled GCMs. Source: Future Climate Boxplots Tool (https://climatetoolbox.org/tool/Future-
                      Boxplots), The Climate Toolbox.
                    </i>
                  </p>
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">SNOTEL Station</th>
                        <th scope="col">Historical (1971–2000) April 1st SWE in inches</th>
                        <th scope="col">RCP 4.5 Mid-Century (2040–2069) April 1st SWE in inches</th>
                        <th scope="col">RCP 8.5 Mid-Century (2040–2069) April 1st SWE in inches</th>
                        <th scope="col">RCP 4.5 Late-Century (2070–2099) April 1st SWE in inches</th>
                        <th scope="col">RCP 8.5 Late-Century (2070-2099) April 1st SWE in inches</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Sunset (Elevation: 5,540 feet)</th>
                        <td>32.72 ″</td>
                        <td>25.62 ″</td>
                        <td>21.69 ″</td>
                        <td>20.88 ″</td>
                        <td>10.77 ″</td>
                      </tr>
                      <tr>
                        <th scope="row">Lost Lake (Elevation: 6,110)</th>
                        <td>47.54 ″</td>
                        <td>46.32 ″</td>
                        <td>44.03 ″</td>
                        <td>44.13 ″</td>
                        <td>33.71″</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="projected-future-climate-seasonal" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Projected Future Climate—Change in Timing and Intensity of High and Low Flow Rates on the Spokane River</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Variable:</b> <i>Streamflow</i>
                  </p>
                  <p>
                    <b>Finding:</b> In the coming decades, the shift of precipitation to more rain and less snow during the fall, winter, and spring
                    months coupled with earlier snow melts is expected to alter the timing of streamflow on the Spokane River.
                  </p>
                  <p>
                    <b>Finding:</b> From 1971–2000, flows peaked on the Spokane River during the month of May. By the mid-century (2040–2069)
                    under both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5), the Spokane River will see
                    two peaks: first in February and then again in April. These represent high flow events that result from more precipitation
                    falling as rain in the cool season and immediately running off as well as earlier snow melt.
                  </p>
                  <p>
                    <b>Finding:</b> Under both the lower emissions scenario (RCP 4.5) and high emissions scenario (RCP 8.5), summertime low flow
                    rates (historically seen in August and September) are projected to begin one month earlier (July) by mid-century (2040–2069)
                    and as much as two months earlier (June) by late century (2070–2099).
                  </p>
                  <p>
                    <b>Justification:</b> The projected declines in snow water equivalent (SWE) in the local watershed (discussed above) are expected
                    to lead to higher flows in the Spokane River during the winter months and lower flows during the summer months. By
                    examining the non-regulated streamflow projections for the Spokane River at Post Falls Dam, a changing pattern in spring
                    flows was identified. Historically, the timing of peak river flow has been in May, as depicted in the hydrographs in <b>Figures 4</b>
                    and 5. Historical flows for the years 1971–2000 are depicted in black. Data are displayed across the water year, which runs
                    from October 1st of a given calendar year through September 30th of the following calendar year. By the mid-century
                    (2040–2069) under both the lower emissions scenario (RCP 4.5) (shown in blue) and the high emissions scenario (RCP 8.5)
                    (shown in orange), models project two smaller peaks in river flow: first in February and then again in April (<b>Figure 4</b>).
                  </p>
                  <p>
                    Similarly, by late century (2070–2099) under both RCP 4.5 (blue) and RCP 8.5 (orange) two peaks representing high river
                    flow events once again occur in February and April (<b>Figure 5</b>). It is also important to note that under both RCP 4.5 and RCP
                    8.5, summertime low flow rates (historically seen in August and September) are projected to begin one month earlier (July)
                    by mid-21st century (<b>Figure 4</b>) and as much as two months earlier (June) by the end of the 21st century (<b>Figure 5</b>).
                  </p>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="precipitation-low" src="/media/streamflow/Fig-4-Streamflow.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 4:</b> Non-regulated streamflow on the Spokane River at Post Falls, Washington, for the historical period 1971–2000 (black) and
                            projected future period 2040–2069 (mid-century) for both the lower emissions scenario (RCP 4.5) (blue) and the high emissions scenario
                            (RCP 8.5) (orange). Data is displayed for the water year, which runs from October 1st of a calendar year through September 30 of the
                            following calendar year. The data presented here represents the multi-model mean of 10 downscaled global climate models run through
                            the Variable Infiltration Capacity (VIC) Hydrologic Model. Source: Future Streamflows Tool (https://climatetoolbox.org/tool/Future-
                            Streamflows), The Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="precipitation-high" src="/media/streamflow/Fig-5-Streamflow.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 5:</b> Non-regulated streamflow on the Spokane River at Post Falls, Washington, for the historical period 1971–2000 (black) and
                            projected future period 2070–2099 (late century) for both the lower emissions scenario (RCP 4.5) (blue) and the high emissions scenario
                            (RCP 8.5) (orange). Data is displayed for the water year, which runs from October 1st of a calendar year through September 30 of the
                            following calendar year. The data presented here represents the multi-model mean of 10 downscaled global climate models run through
                            the Variable Infiltration Capacity (VIC) Hydrologic Model. Source: Future Streamflows Tool (https://climatetoolbox.org/tool/Future-
                            Streamflows), The Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="climate-data-story-redband" className="card border-dark mb-3">
                <div className="card-header p-2 card-header-display">
                  <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data-story"></img>
                  <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Redband Trout Habitat</i></h4>
                </div>
                <div className="card-body">
                  <h6 className="card-subtitle mb-2 text-muted">
                    <p>
                      <i>
                        “Interior Redband trout are considered a species of special concern by the American Fisheries Society and the U.S. Fish and
                        Wildlife Service (FWS) in most states where the subspecies historically existed and are classified as a sensitive species by the
                        U.S. Forest Service and Bureau of Land Management.”
                      – Western Native Trout Status Report
                    </i>
                    </p>
                    <p>
                      <i>
                        “The Redband population has been severely reduced due to habitat destruction, warm water temperatures and the negative
                        effects of sediment loading on riverbeds.”
                      - <u>Spokane Riverkeeper</u>
                      </i>
                    </p>
                  </h6>
                  <p>
                    The Redband trout is a subspecies of Rainbow trout and is the primary native Salmonid species of the Spokane River. The
                    Washington Department of Fish and Wildlife considers Redband trout a sentinel species, meaning the health and abundance
                    of the species are indicating factors of the overall health of a river ecosystem (<b>Gerber 2017</b>). Over the past century, many
                    factors have contributed to a decline in the population of Redband trout. Projected changes in-stream flow rates would
                    exacerbate the problem, according to our analysis. It is estimated that Redband trout once occupied 37,465 miles of streams
                    and 152 natural (un-impounded) lakes throughout Washington, Oregon, Idaho, Nevada, Montana, and northern California.
                    Currently Redband trout occupy approximately 42% of their historical stream habitat (<b>Western Native Trout 2018; Muhlfeld et al., 2014</b>).
                  </p>
                  <p>
                    Locally, substantial impacts to the species will likely occur in the upper Spokane River, where populations are already
                    depressed (<b>Lee 2019</b>). Projected reduced flows below Post Falls Dam could result in warmer water temperatures that benefit
                    smallmouth bass (a non-native species) and approach the upper lethal temperature range for Redband trout. Redband trout
                    generally prefer stream temperatures less than 70 degrees Fahrenheit (<b>Wydoski and Whitney 2003</b>) and experience stress at
                    71.6 °F and above (<b>Behnke 1992</b>). 
                  </p> 
                  <p> 
                    The effects of climate change on rising temperatures and their effects on salmonids has
                    already been overserved (<b>Isaak et al., 2012</b>). Earlier peak-flow and reduced summer flows projected under climate change
                    could decrease viable rearing habitat for Redband trout. While reduced flows in late May/early June could dewater and
                    desiccate trout eggs, reducing trout populations
                  </p>
                  <p>
                    During a survey conducted by Michael Taylor McCroskey in the summer of 2015, very few Redband trout were present in
                    the upper reaches near the spawning areas. 
                  </p>
                  <p>  
                    The study was conducted during an extreme drought year (in historical context),
                    during which river flows were lower than usual and water temperatures were warmer than normal, which likely influenced
                    species distribution, but may have also impacted spawning success and survival. Additionally, a large population of
                    smallmouth bass was documented, which likely impacted the survival of juvenile Redband trout from predation; to what
                    extent is unknown (<b>McCroskey 2015</b>).
                  </p>
                  <p>
                    The Spokane River (below Sullivan Road) is heavily influenced by groundwater recharge, which moderates summer stream
                    temperatures with an influx of cooler water. However, projections of reduced surface flows would result in reduced carrying
                    capacity for Redband trout and an anticipated increase in predation from species that thrive in warmer water temperatures. Of
                    particular concern to the viability of Redband trout is the projected earlier low-flow period beginning as early as May, rather
                    than later in the summer. According to the Parametrix 2003 spawning report, spawning generally commences at the
                    beginning of April when water temperature reaches 45 ° F (<b>Parametrix 2003</b>). Emergence occurs near the end of May and
                    into the beginning of June. Future projections indicate that a decrease in streamflow will occur during the Redband trout
                    incubation period that could result in water levels falling below the level of fish nests, a process called <i>redd dewatering</i>.
                  </p>
                </div>
              </div>
              <div id="climate-data-story-rathdrum" className="card border-dark mb-3">
                <div className="card-header p-2 card-header-display">
                  <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data-story"></img>
                  <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Spokane Valley - Rathdrum Prairie Aquifer</i></h4>
                </div>
                <div className="card-body">
                  <p>
                    The Spokane Valley-Rathdrum Prairie (SVRP) Aquifer is the sole-source drinking water for over 500,000 people (<b>MacInnis et al., 2009</b>). 
                    According to our analysis, the aquifer appears to be less sensitive to climate change impacts than aquifers in
                    other regions. Aquifer levels are primarily affected by recharge from the Spokane River and from several lakes in the region
                    that bound and recharge the aquifer (<b>Hsieh et al., 2007; Kahle et al., 2007</b>). 
                  </p>
                  <p>  
                    Seasonal changes in streamflow under future
                    climate conditions are anticipated, according to our analysis. Specifically, peak streamflows may occur earlier in the winter
                    and/or spring months, while the summer-season low streamflows could begin earlier in the summer. However, future climate
                    projections indicate that only small percentage changes in annual total precipitation and streamflows are likely to occur,
                    which suggests—assuming no net change in annual water extraction due to human factors—that there might be just a limited
                    overall change in annual volumes of aquifer recharge on a long-term multi-decadal basis. However, within these long multi-
                    decadal time periods, aquifer recharge periodically could be below historically observed conditions if multi-year droughts
                    were to occur more frequently than in the past or be more intense than in the past.
                  </p>
                  <p>
                    The primary mid-term vulnerability of the Spokane region’s sole source of potable water may have less to do with climate
                    change and more to do with the fact that historically some of the region’s water supply wells have been drilled only into the
                    very uppermost portion of the aquifer. The oldest wells in the region were excavated and/or hand-dug within the city of
                    Spokane during the early 1900s. Accordingly, these wells were constructed no further than necessary into the water table,
                    which means they obtain water by essentially “skimming” off of the top of this thick aquifer (<b>CH2M HILL, 1998; GSI, 2012</b>). 
                    The operational efficiencies of some of these shallow wells may be sensitive to small climate-driven changes in
                    summer-season water levels in the aquifer (<b>GSI et al., 2019</b>). Newer wells in the region were constructed using more
                    conventional drilling methods, achieving greater penetration depths into the water table. However, even these wells were not
                    typically drilled any deeper than necessary, in order to minimize drilling and pumping costs.
                  </p>
                  <p>
                    Due to the particularly low water levels observed in the aquifer during the past few summers, the City of Spokane is now
                    actively working to understand the resiliency of several of its water supply wells and to evaluate what types of modifications
                    (if any) to certain wells and/or pumping systems might be warranted for future implementation (<b>GSI et al., 2019</b>). The City is
                    conducting this work as part of its planning for capital improvement projects. Although other municipal water providers own
                    conventional drilled wells that penetrate deeper into this aquifer, it is possible that some of those providers could eventually
                    identify that one or more of their wells would warrant resilience evaluations in the future, and potentially adjustments to their
                    construction and/or their pumping systems to optimize or improve well operations.
                  </p>
                  <p>
                    More research is needed before we can fully understand the longer-term impacts of climate change on the Spokane Valley-
                    Rathdrum Prairie Aquifer. In addition to further climate analysis, it is also necessary for local policy makers to understand
                    the non-climate related impacts, such as increased demand on the aquifer due to (1) population growth and (2) increased
                    evapotranspiration as temperatures rise in our region. Individual choices, business practices, and government policy are all
                    necessary components of an effective strategy to prevent adverse climate-induced impacts on water availability.
                  </p>
                </div>
              </div>
              <div id="conclusion" className="card border-dark bg-light mb-3">
                <div className="card-header"><h4>Conclusion </h4></div>
                <div className="card-body">
                  <p>
                    Future climate projections suggest that as temperatures rise in the Inland Pacific Northwest, snow from the mountains will
                    thaw faster and earlier in the season. This coupled with the increased likelihood that precipitation will fall as rain rather than
                    as snow will create earlier seasonal high flows during the spring months and more noticeable low flow rates in the river
                    during the summer months.
                  </p>
                  <p>
                    Changes in timing and intensity of peak streamflow will likely create some challenges in our region in terms of Redband
                    trout habitat, recreation on the river, and possibly (though maybe not) refresh rates for the Spokane Valley-Rathdrum Prairie
                    Aquifer. (As noted above, the impact of climate change on the aquifer needs more research.) In recent years, researchers have
                    already witnessed the effects of low summer flows on the Redband trout spawning and rearing grounds. As peak flows on the
                    Spokane River occur earlier in the spring, local rafting companies will likely experience some loss in revenue as many
                    families tend to wait for mid-June before taking their vacations. Our analysis clearly shows that impacts to flows in the
                    Spokane River will be greater under the “business as usual,” high emissions scenario (RCP 8.5) when compared to the lower
                    emissions scenario (RCP 4.5), which assumes a reduction in greenhouse gas emissions. This leads us to the conclusion that
                    absent an immediate and significant reduction in greenhouse gas emissions, climate-related impacts to the Spokane River are
                    likely to continue throughout the 21st century. We therefore recommend the following resilience actions.
                  </p>
                  <div className="card border-dark m-3">
                    <div className="card-header text-white bg-success p-2 card-header-display">
                      <h4 className="col-10 card-header-title ">Recommended Resiliency Actions</h4>
                    </div>
                    <div className="card-body p-0">
                      <ol className="list-group">
                        <li className="list-group-item list-group-item-success">
                          <b>Reduce Emissions:</b> Take all possible actions to reduce greenhouse gas emissions and avoid the high emissions
                        scenario (RCP 8.5).
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Prioritize Trout Habitat:</b> Reconsider regulations at Post Falls to help prioritize Redband trout habitat.
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Future Research:</b> Conduct more research to fully understand the longer-term impacts of climate change on the
                          Spokane Valley-Rathdrum Prairie Aquifer.
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Future Research:</b> Investigate well depth and pump technology.
                        </li>
                      </ol>
                    </div>
                  </div>
                  <p>
                    <b>Future Work: </b>The findings of this report indicate the need for more research to be conducted on the future vulnerability of the Spokane
                    Valley-Rathdrum Prairie Aquifer, which is the Spokane metropolitan region’s sole source of potable water to its residents. In
                    addition to further climate analysis, it is also necessary for local policy makers to understand the non-climate related impacts,
                    such as increased demand on the aquifer due to (1) population growth and (2) increased evapotranspiration as temperatures
                    rise in our region. Individual choices, business practices, and government policy are all necessary components of an effective
                    strategy to prevent adverse climate-induced impacts on water availability.
                  </p>
                </div>
              </div>
              <div id="citations" className="card border-dark bg-light mb-3">
                <div className="card-header"><h4>Citations For this Chapter</h4></div>
                <div className="card-body">
                  <p className="card-text">
                    John Porcello, Kara Odegard, Karl Rains, Jule Schultz, and Brad Morin.
                    “Streamflow Impact Study for Spokane, Washington.” In <i>The Spokane Climate Vulnerability and Resilience Assessment</i>,
                    edited by Nathan Gilles, Katherine Hegewisch, John Abatzoglou, Ann Mooney, and Meghan Dalton. Corvallis, Oregon: 
                    <i>The Pacific Northwest Climate Impacts Research Consortium (CIRC), College of Earth, Ocean, and Atmospheric Sciences, Oregon State University</i>, 2020.
                </p>
                  <h5>CIRC-Related Data Sources:</h5>
                  <ol className="list-group">
                    <li className="list-group-item">
                      Abatzoglou, John T. &quot;Development of gridded surface meteorological data for ecological applications and
                      modelling.&quot; <i>International Journal of Climatology</i> 33, no. 1 (2013): 121-131.  
                      <a href="https://doi.org/10.1002/joc.3413">https://doi.org/10.1002/joc.3413</a>.
                    </li>
                    <li className="list-group-item">
                      Abatzoglou, John T., and Timothy J. Brown. &quot;A comparison of statistical downscaling methods suited for wildfire
                      applications.&quot; <i>International Journal of Climatology</i> 32, no. 5 (2012): 772-780.  
                      <a href="https://doi.org/10.1002/joc.2312">https://doi.org/10.1002/joc.2312</a>.
                    </li>
                    <li className="list-group-item">
                      Hegewisch, Katherine C., and John T. Abatzoglou. “Future Boxplots Tool.” The Climate Toolbox. . Accessed March 31st,
                      2019. <a href="https://climatetoolbox.org/tool/future-climate-boxplots">https://climatetoolbox.org/tool/future-climate-boxplots</a>.
                    </li>
                    <li className="list-group-item">
                      Hegewisch, Katherine C., John T. Abatzoglou, Oriana Chegwidden, and Bart Nijssen. “The Climate Mapper Tool.” The
                      Climate Toolbox. Accessed March 29, 2019. <a href="https://climatetoolbox.org/tool/Climate-Mapper">https://climatetoolbox.org/tool/Climate-Mapper</a>.
                    </li>
                    <li className="list-group-item">
                      Hegewisch, Katherine C., John T. Abatzoglou, and Oriana Chegwidden. “Future Streamflows Tool.” The Climate Toolbox.
                      Accessed March 29, 2019. <a href="https://climatetoolbox.org/tool/Future-Streamflows">https://climatetoolbox.org/tool/Future-Streamflows</a>.
                    </li>
                    <li className="list-group-item">
                      Lohmann, Dag, Ralph Nolte-Holube, and Ehrhard Raschke. &quot;A large‐scale horizontal routing model to be coupled to land
                      surface parametrization schemes.&quot; <i>Tellus A</i> 48, no. 5 (1996): 708-721. <a href="https://doi.org/10.1034/j.1600-0870.1996.t01-3-00009.x">https://doi.org/10.1034/j.1600-0870.1996.t01-3-00009.x</a>.
                    </li>
                    <li className="list-group-item">
                      Mote, Philip W., John T. Abatzoglou, Dennis P. Lettenmaier, Dave P. Turner, David E. Rupp, Dominique Bachelet, and
                      David R. Conklin. “Integrated Scenarios of Climate, Hydrology, and Vegetation for the Northwest, Final Report.” Corvallis,
                      Oregon: Pacific Northwest Climate Impacts Research Consortium, College of Earth, Ocean, and Atmospheric Sciences,
                      Oregon State University, 2014.
                      <a href="https://climate.northwestknowledge.net/IntegratedScenarios/pages/publicationsreports/IntegratedScenariosFinalReport2014-10-07.pdf">https://climate.northwestknowledge.net/IntegratedScenarios/pages/publicationsreports/IntegratedScenariosFinalReport2014-10-07.pdf</a>.
                    </li>
                  </ol>
                  <h5 className="m-2">Additional References:</h5>
                  <ol className="list-group">
                    <li className="list-group-item">
                      Abatzoglou, John T. "Water year 2015: A glimpse of our future?" Coeur d’Alene, Idaho: Plenary presentation prepared for
                      the Spokane River Forum Conference. March 23, 2016.
                    </li>
                    <li className="list-group-item">
                      Briceno, Tania, and Greg Schundler. &quot;Economic analysis of outdoor recreation in Washington State.&quot; <i>Earth Economics</i>,
                      Tacoma, WA (2015). <a href="https://www.rco.wa.gov/documents/ORTF/EconomicAnalysisOutdoorRec.pdf">https://www.rco.wa.gov/documents/ORTF/EconomicAnalysisOutdoorRec.pdf</a>.
                   </li>
                    <li className="list-group-item">
                      Behnke, Robert J. "Native trout of western North America." <i>American Fisheries Society monograph (USA)</i>. no. 6. (1992).
                    </li>
                    <li className="list-group-item">
                      Chegwidden, Oriana S., Bart Nijssen, David E. Rupp, Jeffrey R. Arnold, Martyn P. Clark, Joseph J. Hamman, Shih‐Chieh
                      Kao, Yixin Mao, Naoki Mizukami, Philip W. Mote, Ming Pan, Erik Pytlak, and Mu Xiao. “How Do Modeling Decisions
                      Affect the Spread Among Hydrologic Climate Change Projections? Exploring a Large Ensemble of Simulations Across a
                      Diversity of Hydroclimates.” <i>Earth’s Future</i>, 7 (2019). <a href="https://doi.org/10.1029/2018EF001047">https://doi.org/10.1029/2018EF001047</a>.
                    </li>
                    <li className="list-group-item">
                      CH2M HILL, 1998. “City of Spokane Wellhead Protection Program Phase 1 – Technical Assessment Report.” Prepared for
                      the City of Spokane. Prepared in association with Dally Environmental, Fujitani, Hilts, and Associates, and SeisPulse
                      Development Corporation. February 1998.
                    </li>
                    <li className="list-group-item">
                      Gerber, Drew. 2017. &quot;Spokane&#39;s signature fish continues to struggle as new campaign raises awareness.&quot; <i>Spokesman Review</i>,
                      June 28, 2017. <a href="https://www.spokesman.com/stories/2017/jun/28/spokanes-signature-fish-continues-to-struggle-as-n/">https://www.spokesman.com/stories/2017/jun/28/spokanes-signature-fish-continues-to-struggle-as-n/</a>.
                    </li>
                    <li className="list-group-item">
                      Grubb, Peter, Jon Wilmot, Paul Delaney, Steve Bailey, and Josh Flannagan. Interviewed by Karl Rains March 2019, April
                      2019.
                    </li>
                    <li className="list-group-item">
                      GSI Water Solutions (GSI), Landau Associates, Murraysmith, LSB Consulting Engineers, and PQ Products. 2019. “Final
                      Report: Ray Street Well Station Assessment.” Prepared for the City of Spokane, Washington Department of Integrated
                      Capital Management. July 2019.
                    </li>
                    <li className="list-group-item">
                      GSI Water Solutions (GSI). 2012. “Groundwater Model Update and Expansion into Idaho (Task 2: City of Spokane and
                      SAJB Water Purveyors Groundwater Model Improvement Project).” Technical Memorandum to Lloyd Brewer and Doug
                      Greenlund, City of Spokane, from John Porcello, LHG. GSI Water Solutions, Inc. (GSI). September 24, 2012.
                    </li>
                    <li className="list-group-item">
                      Hsieh, P.A., M.E. Barber, B.A. Contor, Md. A. Hossain, G.S. Johnson, J.L. Jones, and A.H. Wylie. 2007. “Ground-Water
                      Flow Model for the Spokane Valley-Rathdrum Prairie Aquifer, Spokane County, Washington, and Bonner and Kootenai
                      Counties, Idaho.” <i>U.S. Geological Survey Scientific Investigations Report</i> 2007-5044, 78 p.
                    </li>
                    <li className="list-group-item">
                      Isaak, D. J., S. Wollrab, D. Horan, and G. Chandler. "Climate change effects on stream and river temperatures across the
                      northwest US from 1980–2009 and implications for salmonid fishes." <i>Climatic change</i> 113, no. 2 (2012): 499-524.
                      <a href="https://doi.org/ 10.1007/s10584-011-0326-z">https://doi.org/ 10.1007/s10584-011-0326-z</a>.
                    </li>
                    <li className="list-group-item">
                      Kahle, S.C., and J. R. Bartolino. 2007. Hydrogeologic Framework and Ground-Water Budget for the Spokane Valley-
                      Rathdrum Prairie Aquifer, Spokane County, Washington, and Bonner and Kootenai Counties, Idaho. <i>U.S. Geological Survey Scientific Investigations Report</i> 2007-5041, 48 p, 2 plates.
                    </li>
                    <li className="list-group-item">
                      Lee, Charles. 2019. Email to Charles Lee, March 8, 2019.
                    </li>
                    <li className="list-group-item">
                      Lindholdt, Paul. The Spokane River. Seattle: <i>University of Washington Press</i>, 2018.
                    </li>
                    <li className="list-group-item">
                      McCroskey, Michael Taylor. “Smallmouth bass abundance and diet composition in the upper Spokane River.” Masters
                      Thesis, Eastern Washington University, 2015. <a href="https://dc.ewu.edu/theses/326/">https://dc.ewu.edu/theses/326/</a>.
                    </li>
                    <li className="list-group-item">
                      Mooney, Ann, Nathan Gilles, Katherine Hegewisch, John Abatzoglou, and Meghan Dalton. “The Northwest Climate
                      Toolbox Workbook: Discovering and applying your climate data story for climate adaptation planning,” Corvallis, Oregon:
                      The Pacific Northwest Climate Impacts Research Consortium (CIRC), College of Earth, Ocean, and Atmospheric Sciences,
                      Oregon State University, 2019. <a href="https://pnwcirc.org/sites/pnwcirc.org/files/nwct.pdf">https://pnwcirc.org/sites/pnwcirc.org/files/nwct.pdf</a>.
                    </li>
                    <li className="list-group-item">
                      Muhlfeld, Clint C., Ryan P. Kovach, Leslie A. Jones, Robert Al-Chokhachy, Matthew C. Boyer, Robb F. Leary, Winsor H.
                      Lowe, Gordon Luikart, and Fred W. Allendorf. "Invasive hybridization in a threatened species is accelerated by climate
                      change." <i>Nature Climate Change</i> 4, no. 7 (2014): 620. <a href="https://www.nature.com/articles/nclimate2252">https://www.nature.com/articles/nclimate2252</a>.
                    </li>
                    <li className="list-group-item">
                      National Resources Conservation Service (NRC). “Snow Telemetry (SNOTEL) and Snow Course Data and Products.”
                      Accessed November 13, 2019. <a href="https://www.wcc.nrcs.usda.gov/snow/">https://www.wcc.nrcs.usda.gov/snow/</a>.
                    </li>
                    <li className="list-group-item">
                      Pacific Northwest Climate Impacts Research Consortium (CIRC). “Climate Tools.” Accessed March 31, 2019.
                      <a href='https://pnwcirc.org/climatetools'>https://pnwcirc.org/climatetools</a>.
                    </li>
                    <li className="list-group-item">
                      Pacific Northwest Climate Impacts Research Consortium (CIRC). “Snowpack, Hydrology, &amp; Drought.” Accessed November
                      12, 2019. <a href="https://pnwcirc.org/science/hydrology">https://pnwcirc.org/science/hydrology</a>.
                    </li>
                    <li className="list-group-item">
                      Parametrix, Inc. “Rainbow Trout Spawning Survey.” Prepared for the Fisheries Work Group, Kirkland, WA: Parametrix.
                      2003.
                    </li>
                    <li className="list-group-item">
                      Porcello, John, Walter Burt, Jacob Gorski, and Ty Wick. 2017. “Climate Change and Summer Streamflows: Climate Change
                      Influence on Summer Streamflows: Unanticipated Discovery While Studying Other Influences.” In The Water Report: Water
                      Rights, Water Quality & Water Solutions in the West. EnviroTech Publications, edited by David Light and David Moon,
                      Eugene, Oregon. Issue #166. December 2017.
                    </li>
                    <li className="list-group-item">
                      Porcello, John J., and Jake Gorski. 2016. "Spokane River Low-Flow Trends: The Role of Changing Water Demands, Water
                      Sources, and Watershed Conditions." Presentation prepared for the Spokane River Forum Conference. Coeur d’Alene, Idaho.
                      GSI Water Solutions, March 24, 2016.
                    </li>
                    <li className="list-group-item">
                      Spokane County Washington. “Spokane Valley–Rathdrum Prairie Aquifer Atlas, 2015 Edition.” Accessed March 31, 2019.
                      <a href="https://www.spokanecounty.org/DocumentCenter/View/3178/2015-Edition-SVRP-Aquifer-Atlas-PDF">https://www.spokanecounty.org/DocumentCenter/View/3178/2015-Edition-SVRP-Aquifer-Atlas-PDF</a>.
                    </li>
                    <li className="list-group-item">
                      Spokane County Washington. “Spokane Valley–Rathdrum Prairie Aquifer Atlas, 2009 Update.” Accessed March 31, 2019.
                      <a href="https://www.spokanecounty.org/DocumentCenter/View/3180/2009-SVRP-Aquifer-Atlas-PDF">https://www.spokanecounty.org/DocumentCenter/View/3180/2009-SVRP-Aquifer-Atlas-PDF</a>.
                    </li>
                    <li className="list-group-item">
                      Visit Spokane. “Things to Do, Rafting, Kayaking, and Canoeing.”Accessed May 2019. <a href='https://www.visitspokane.com/things-to-do/recreation/rafting-kayaking-canoeing/'>https://www.visitspokane.com/things-to-do/recreation/rafting-kayaking-canoeing/</a>.
                    </li>
                    <li className="list-group-item">
                      Washington State Recreation and Conservation Office. "Economic Benefits of Outdoor Recreation in Washington." Accessed
                      March 31, 2019. <a href="https://www.rco.wa.gov/documents/ORTF/OutdoorEconomicsFactSheet.pdf">https://www.rco.wa.gov/documents/ORTF/OutdoorEconomicsFactSheet.pdf</a>.
                    </li>
                    <li className="list-group-item">
                      Washington State Legislature. “Instream flow, Washington Administrative Code (WAC) 173-557-050.” Accessed December
                      13, 2019. <a href="https://app.leg.wa.gov/wac/default.aspx?cite=173-557&full=true#173-557-050">https://app.leg.wa.gov/wac/default.aspx?cite=173-557&full=true#173-557-050</a>.
                    </li>
                    <li className="list-group-item">
                      Washington State Legislature. “Stream management units.” Accessed December 13, 2019.
                      <a href="https://app.leg.wa.gov/wac/default.aspx?cite=173-557&full=true#173-557-040">https://app.leg.wa.gov/wac/default.aspx?cite=173-557&full=true#173-557-040</a>.
                    </li>
                    <li className="list-group-item">
                      Western Native Trout. 2018. "Redband Trout." Accessed March 31, 2019. <a href="https://westernnativetrout.org/wp-content/uploads/2018/08/InteriorRedband_WesternNativeTroutStatusReport_UpdatedAugust2018.pdf">https://westernnativetrout.org/wp-content/uploads/2018/08/InteriorRedband_WesternNativeTroutStatusReport_UpdatedAugust2018.pdf</a>.
                    </li>
                    <li className="list-group-item">
                      Wydoski, Richard S., and Richard R. Whitney. Inland fishes of Washington. American Fisheries Society, 2003.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* Beginning of Sidebar */}
            <div className="col-2 d-none d-md-block navbar">
              <div className="navbar-sticky-top sticky-top bg-white side-navbar">
                <h5 className="sidebar-title">Menu</h5>
                <ol className="list-group">
                  <li className="list-group-item">
                    <a href="#chapter-summary">Chapter Summary</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#climate-data-story-recreation">CDS - Recreation</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#larger-context">Larger Context</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#local-context">Local Context</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#methods">Methods</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#projected-future-climate">Projected Future Climate</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#climate-data-story-redband">CDS - Redband Trout</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#climate-data-story-rathdrum">CDS - Rathdrum Prairie</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#conclusion">Conclusion</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#citations">Citations</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Streamflow;