import React from 'react';
import YoutubeEmbed from "./YoutubeEmbed";

function Summary() {
    return (
      <div>
        <div className="card bg-dark text-white">
          <img src="/media/summary-page-header-edited.jpg" className="card-img" alt="Spokane Falls"/>
          <div className="h-40 header-image-portion-overlay header-title d-none d-lg-flex">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text display-3">Spokane Climate Project</h1>
              <h1 className="card-text">Summary</h1>
            </div>
          </div>
          <div className="h-25 header-image-portion-overlay header-buttons header-title d-none d-lg-flex">
            <h3>The Spokane Climate Project assesses the science of climate change and its impact on Spokane now and throughout this century.</h3>
          </div>
          <div className="h-40 header-image-portion-overlay header-title d-none d-md-flex d-lg-none">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text display-4">Spokane Climate Project</h1>
              <h2 className="card-text display-5">Summary</h2>
            </div>
          </div>
          <div className="h-25 header-image-portion-overlay header-buttons header-title d-none d-md-flex d-lg-none">
            <h4>The Spokane Climate Project assesses the science of climate change and its impact on Spokane now and throughout this century.</h4>
          </div>
          <div className="h-40 header-image-portion-overlay header-title d-none d-sm-flex d-md-none">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text">Spokane Climate Project</h1>
              <h3 className="card-text">Summary</h3>
            </div>
          </div>
          <div className="h-25 header-image-portion-overlay header-buttons header-title d-none d-sm-flex d-md-none">
            <h5>The Spokane Climate Project assesses the science of climate change and its impact on Spokane now and throughout this century.</h5>
          </div>
          <div className="h-40 header-image-portion-overlay header-title d-sm-none">
            <div className="my-auto">
              <h3 className="card-title image-overlay-text">Spokane Climate Project</h3>
              <h5 className="card-text">Summary</h5>
            </div>
          </div>
          <div className="h-25 header-image-portion-overlay header-buttons header-title d-sm-none">
            The Spokane Climate Project assesses the science of climate change and its impact on Spokane now and throughout this century.
          </div>
        </div>
        <div className="p-4">
          <div className="row">
            <div className="col-md-10 content">
              <div id="scp-documentary" className="card bg-light mb-3 border-dark">
                <div className="card-header"><h4>View the Spokane Climate Project Documentary</h4></div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-8">
                      <YoutubeEmbed embedId="HLtUjMSbWAM" />
                    </div>
                    <div className="col-md-4">
                      <h2>Help to keep Spokane's future bright by participating in <a href="https://sustainablespokane.org/">Sustainable Spokane</a></h2>
                      <a href="https://sustainablespokane.org/"><img className="col-12 img-link align-center" src="/media/sustainabile_spokane.jpg" alt="Sustainable Spokane"></img></a>
                    </div>
                  </div>
                </div>
              </div>
              <div id="about-this-report" className="card bg-light mb-3 border-dark">
                <div className="card-header"><h4>About this Report</h4></div>
                <div className="card-body">
                  <p className="card-text">
                    <p>
                      This executive summary was created as part of the Spokane Climate Project (SCP) and complies the key climate
                      science finding of the project. This summary report is part of a larger climate and weather vulnerability and
                      resilience assessment for Spokane, Washington. The full assessment is scheduled for completion sometime
                      in 2021.
                    </p>
                    <p>
                      Under the guidance of the Pacific Northwest Climate Impacts Research Consortium (CIRC), Spokane Climate
                      Project participants from Spokane used CIRC’s Climate Toolbox, a suite of online climate science tools and
                      related datasets, to investigate how climate and weather-related impacts—including rising temperatures,
                      wildfires, and the loss of snowpack—are projected to affect the community of Spokane, its businesses, working
                      lands, and ecosystems in the decades ahead. The findings presented here also draw upon additional academic
                      resources as well as on interviews with local business owners, emergency responders, and other
                      Spokane residents. 
                    </p>
                  </p>
                </div>
              </div>
              <div className="card bg-light mb-3 border-dark">
                <div className="card-header"><h4>About The Spokane Climate Project (SCP)</h4></div>
                <div className="card-body">
                  <p className="card-text">
                    The Spokane Climate Project is a collaborative effort
                    between the Pacific Northwest Climate Impacts
                    Research Consortium (CIRC) and the community of
                    Spokane, Washington. The project’s goals are to identify
                    climate and weather impacts faced by the Spokane
                    community as well as resiliency actions designed to
                    keep the community, its economy, and natural systems
                    healthy and prosperous. Project participants include
                    representatives from city and county entities, local
                    universities, local nonprofits, and local businesses.
                  </p>
                </div>
              </div>
              <div className="card bg-light mb-3 border-dark">
                <div className="card-header"><h4>About CIRC</h4></div>
                <div className="card-body">
                  <p className="card-text">
                    The Climate Impacts Research Consortium (CIRC) is a team of climate and social science researchers
                    based in the Northwest United States. CIRC helps Northwest communities become more resilient to
                    extreme climate and weather impacts. CIRC is publicly funded through the National Oceanic and
                    Atmospheric Administration’s Regional Integrated Sciences and Assessments (RISA) program. Part of
                    NOAA’s Climate Program Office, the RISA program supports research teams that help expand and build
                    our nation’s capacity to prepare for and adapt to climate variability and change. CIRC members can be
                    found at Oregon State University, the University of Idaho, the University of Washington, and the
                    University of Oregon.
                  </p>
                </div>
              </div>
              <div id="emissions-scenario" className="card mb-3 bg-light border-dark">
                <div className="card-header"><h4>Key Term: <i>Emissions Scenario</i></h4></div>
                <div className="row no-gutters">
                  <div className="col-md-6">
                    <div className="card-body">
                      <p className="card-text">
                        The writers of this report primarily used the
                        greenhouse gas emissions scenario (RCP 8.5)
                        when examining future climate impacts. This
                        scenario simulates in a computer what is likely
                        to happen if greenhouse gases continue to be
                        released into the atmosphere at their current rate.
                      </p>
                      <p className="card-text">
                        RCP 4.5, a lower emissions scenario that
                        assumes dramatic reductions in greenhouse gas
                        emissions, was used by many groups as a useful
                        comparison to RCP 8.5, offering a glimpse into
                        a future in which emissions and climate impacts
                        are reduced.
                      </p>
                      <p className="card-text">
                        <i>
                          Projected annual temperature simulations for both high
                          emissions scenario (RCP 8.5) and lower emissions scenario (RCP 4.5) 
                          for the Northwest United States to the year 2100. (Figure source:
                          David E. Rupp; data source: Rupp et al. 2016, adapted.)
                        </i>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-body">
                      <img className="img-fluid border border-dark" alt="emissions-scenarios" src="/media/emissions-scenario-graph.png"></img>
                    </div>
                  </div>
                </div>
              </div>
              {/* Temperature Card */}
              <div id="temperature-summary" className="card bg-light mb-3 border-dark">
                <div className="card-header p-2 card-header-display">
                  <img className="col-1 icon-image" src="/media/OSU_icon_sun_01.png" alt="temperature"></img>
                  <h3  className="col-11 card-header-title p-0">Temperature</h3>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Lead Authors</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Kara Odegard (Measure Meant), Rebecca MacMullan (350 Spokane), 
                    Jim Simon (Gonzaga University), and David Camp (Northwest Renewables)
                  </h6>
                  <div className="card-text">
                    <div className="row">
                      <div className="card border-dark m-3">
                        <div className="card-header p-2 card-header-display">
                          <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data story"></img>
                          <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Bloomsday</i></h4>
                        </div>
                        <div className="card-body">
                          <p>
                            Held every year in May to coincide with the seasonal
                            blooming of lilacs, Spokane’s Lilac Bloomsday Run
                            (Bloomsday) is a seven-and-a-half mile run that draws
                            roughly 50,000 participants and raises money for
                            charities (<b>Bloomsday Run 2019</b>). From 1979 to 2019,
                            Spokane has seen above average temperatures for
                            May. As a consequence of these rising temperatures,
                            Bloomsday is already experiencing an increase in
                            heat-related health issues.
                          </p>
                          <p>
                            During the May 2018 Bloomsday race, warmer-thannormal temperatures likely led to an increase in
                            heat-related illnesses and an increase in the dropout
                            rate for the race (<b>Lutz 2018</b>). Heat-related illnesses
                            will likely be a factor during future Bloomsdays. A
                            comparison between recorded historical temperatures
                            and projected future temperatures shows how.
                          </p>

                          <p>
                            The first Bloomsday run was held in 1977 (<b>Bloomsday Run 2019</b>). 
                            Historically temperatures during
                            Bloomsday races have varied considerably, ranging
                            from an historical low of 42 degrees Fahrenheit
                            (recorded at 2:00 pm during the 1984 race) to a high
                            of 78 °F (recorded at 2:00 pm during the 1977 and
                            1980 races). The mean maximum daily temperature
                            for the entire month of May for the race’s early
                            years, defined here as 1971–2000, was roughly 68
                            °F (<b>Bloomsday Run 2019</b>). However, under the high
                            emissions scenario (RCP 8.5), mean maximum daily
                            temperatures for May are projected to be 74 °F by the
                            middle decades of this century (2051–2080) and 76
                            °F by the late decades of this century (2081–2099), a
                            projected 8 °F increase. The projected future increase
                            in May temperatures strongly suggests the likelihood
                            of a corresponding increase in heat-related illnesses
                            occurring during future Bloomsdays.
                          </p>
                        </div>
                      </div>
                      <div className="card border-dark m-3">
                        <div className="card-header p-2 card-header-display">
                          <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data story"></img>
                          <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Urban Tree Canopy</i></h4>
                        </div>
                        <div className="card-body">
                          <p>
                            Protecting and expanding urban tree cover
                            nationwide is increasingly understood as important
                            for minimizing the effects of heat and drought.
                            Supporting coverage of trees that are fire-resistant,
                            drought-resistant, and deciduous hardwood species
                            will be especially important. Deciduous, leafy trees cool streets and structures in summer while allowing winter sun to provide heat and light. As summers grow
                            hotter, trees can absorb 90% of incoming solar radiation (<b>Heisler 1986</b>) while evapotranspiration from their
                            leaves cools the air, and their shade cuts the solar heating of buildings and parking lots—effects that can reduce
                            air temperatures by as much as 5.8° Fahrenheit (<b>Akbari and Taha 1992</b>). As side benefits, trees boost property
                            values (<b>Nowak et al., 2002</b>), increase tax revenue (<b>Dwyer et al.,1992</b>), and increase general feelings of wellbeing
                            (<b>Kardan et al., 2015; Donovan et al., 2013</b>).
                          </p>
                          <p>
                            Urban forestry will become more challenging as temperatures increase, both for maintaining trees planted in
                            cooler times and for establishing species better suited to hotter summers. Urban foresters will need to adapt
                            to new conditions and anticipate still warmer conditions to come. Insect pests, including bark beetles, will
                            likely continue to infest conifers as warmer winters expand insect ranges, especially as drought stress makes
                            more trees susceptible to these insects (<b>NCA 4, Chapter 6 2018</b>). At the same time, horticulturists in Spokane,
                            currently in U.S. Department of Agriculture zone 6a or 6b (<b>USDA Plant Hardiness Zone Map 2018</b>), will have a
                            broader palette of plant species to work with as Spokane continues to warm. The Climate Toolbox’s Future Cold
                            Hardiness Zones Tool reports Spokane as being in zone 7a for the years 1971–2010. The tool projects the city
                            will be in zones 7b, 8a, and 8b by 2010–2039, 2040–2069, and 2070–2099, respectively under RCP8.5 (<b>Future Cold Hardiness Zones Tool 2018</b>).
                          </p>
                          <p>
                            For reference, among Intermountain western United States cities, Portland, Oregon, is currently in zone 8b
                            (<b>Future Cold Hardiness Zones Tool 2018</b>) (<b>USDA 2018</b>). Because frequent watering is critical when establishing
                            newly planted trees (<b>University of Minnesota Extension 2019</b>), increased summer and spring irrigation could be
                            more important when establishing trees under a warmer climate.
                          </p>
                          <p>
                            While planting more trees is desirable and wise, planting on the south sides of homes and buildings could
                            interfere with rooftop solar power, which will be a fast-growing requirement in a decarbonizing world.
                            Policymakers might make allowances for this by allowing or even assisting in tree removal where needed to
                            accommodate solar, even as they tighten protections for trees overall. Further analysis is required to determine
                            the net impact on carbon emissions if policymakers are going to prioritize tree removal for solar installation.
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card border-dark mb-3">
                          <div className="card-header p-2 card-header-display">
                            <img className="col-1 icon-image p-0" src="/media/OSU_icon_key_01.png" alt="key"></img>
                            <h4 className="col-11 card-header-title p-0">Key Findings</h4>
                          </div>
                          <div className="card-body p-0">
                            <ol className="list-group" >
                            <li className="list-group-item">
                              Strong evidence suggests that human-caused climate change is leading to rising temperatures in Spokane that will
                              likely correspond to a rise in heat-related illnesses.
                            </li>
                            <li className="list-group-item">
                              By the middle of this century (2040–2069), mean annual temperatures in Spokane are projected to be 4.4 degrees
                              Fahrenheit warmer under the lower emissions scenario (RCP 4.5) and 5.9 °F warmer under the high emissions
                              scenario (RCP 8.5) than they were during the historical years 1971–2000. Temperature increases in Spokane become
                              more extreme by late century (2070–2099), rising by 5.5 °F under RCP 4.5 and by 9.5 °F under RCP 8.5.
                            </li>
                            <li className="list-group-item">
                              Throughout this century, temperatures in the Spokane region are projected to rise across all months of the year.
                            </li>
                            <li className="list-group-item">
                              Under the high emissions scenario (RCP 8.5), Spokane’s hottest summer day is projected to reach 106 °F by mid-
                              century (2040–2069) and 111°F by late-century (2070–2099) compared to the mean of 99 °F for the years 1971–2000.
                            </li>
                            <li className="list-group-item">
                              Under both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5), Spokane is projected
                              to see an increase in the number of days annually where the heat index reaches or exceeds 90 °F, 100 °F, and 105 °F.
                            </li>
                            <li className="list-group-item">
                              Rising temperatures create future challenges in the Spokane region that will require planning and preparation to
                              protect the health and safety of all Spokane residents, as well groups identified as being vulnerable to heat-related
                              illnesses.
                            </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                      {/* End of Key Findings */}
                      <div className="col">
                        <div className="card border-dark mb-3">
                          <div className="card-header text-white bg-success p-2 card-header-display">
                            <img className="col-2 icon-image" src="/media/OSU_icon_shield_01.png" alt="shield"></img>
                            <h4 className="col-10 card-header-title p-0">Resiliency Actions</h4>
                          </div>
                          <div className="card-body p-0">
                            <ol className="list-group">
                            <li className="list-group-item list-group-item-success">
                              <b>Reduce Emissions:</b> The negative outcomes of the high emissions scenario (RCP 8.5) can be mitigated by swift and
                                significant reductions in greenhouse gas emissions.
                            </li>
                            <li className="list-group-item list-group-item-success">
                              <b>Plan for High Temperatures:</b> Over the next 30–60 years, high heat dangers should be considered when scheduling outdoor
                              public events. 
                            </li>
                            <li className="list-group-item list-group-item-success">
                              <b>Safety Protocols:</b> Health and safety officials should create and promote safety protocols for extreme heat events
                              with particular concern for outdoor workers, outdoor recreation/athletes, and vulnerable
                              populations.                         
                            </li>
                            <li className="list-group-item list-group-item-success">
                              <b>Safety Protocols:</b> First responders should be adequately trained and equipped to treat heat-related illnesses.
                            </li>
                            <li className="list-group-item list-group-item-success">
                              <b>Urban Forest:</b>The City should take immediate action to protect and expand the urban tree cover to mitigate the effects of rising temperatures on human health.
                            </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End of Resiliency Actions */}
                    {/* End of Temperature Card Body */}
                  </div>
                </div>
              </div>
              {/* End of Temperature Card */}

              {/* Precipitation Card */}
              <div id="precipitation-summary" className="card bg-light mb-3 border-dark">
                <div className="card-header p-2 card-header-display">
                  <img className="col-1 icon-image" src="/media/OSU_icon_rain_01.png" alt="precipitation"></img>
                  <h3 className="col-11 card-header-title p-0">Precipitation</h3>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Lead Authors</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Joel Breems (Avista) and Kevin Booth (Avista)
                  </h6>
                  <div className="card-text">
                    <div className="row">
                      <div className="card border-dark m-3">
                        <div className="card-header p-1 card-header-display">
                          <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data story"></img>
                          <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Dryland Wheat Farming</i></h4>
                        </div>
                        <div className="card-body">
                          <p>
                            In the Inland Pacific Northwest, dryland farming
                            dominates much of the landscape. In 2018 alone,
                            Washington dryland farmers produced 153 million
                            bushels of wheat, making the state the fourth largest
                            wheat-producing state in the nation, with the second
                            highest average yield per acre, according to the
                            Washington Grain Commission. All told, dryland
                            wheat is Washington’s third largest commodity and
                            accounts for nearly $691 million in production value
                            for the state (<b>Washington Grain Commission 2019</b>).
                            Dryland wheat farming is also dependent solely on
                            precipitation for all of the moisture required for crop
                            growth, maturation, and productivity                          
                          </p>
                          <p>
                            This relationship to precipitation makes dryland
                            wheat ideal for studying how projected future
                            changes to precipitation could impact dryland farming
                            in the Spokane region. Dryland wheat production
                            requires 8–25 inches of precipitation during the fall,
                            winter, and spring, and benefits from relatively dry
                            summer months (<b>Schillinger et al., 2012</b>). Historically,
                            the majority of Spokane’s annual precipitation has
                            fallen during the winter and spring months. This
                            trend is expected to continue under both the lower
                            emissions scenario (RCP 4.5) and the high emissions
                            scenario (RCP 8.5). Under both scenarios, precipitation
                            projections for Spokane show a slight increase in
                            annual precipitation; a slight increase in precipitation
                            during the fall, winter, and spring months; and a
                            slight decrease in precipitation over the summer
                            months.
                          </p>
                          <p>
                            According to future climate projections tracked in the Climate Toolbox, the Spokane region will continue to
                            meet the precipitation timing and volume requirements for dryland wheat production. Independent research
                            has also concluded that dryland winter wheat production in the Pacific Northwest could see increased yields
                            under both the rising temperatures and the rising atmospheric carbon dioxide levels expected under both RCP
                            4.5 and RCP 8.5 (<b>Stöckle et al., 2014; Fourth National Climate Assessment, Chapter 24 2018</b>).
                          </p>
                          <p>
                            Historically, however, dryland wheat farming has not been immune to the effects of drought. To better
                            understand how drought might impact dryland wheat farming in the Spokane area, CIRC examined droughtassociated insurance loss claims for wheat filed from 2001 to 2015 across the 24-county region of the Inland
                            Pacific Northwest, a region that includes Spokane County and nearby Adams, Whitman, and Lincoln Counties
                            (<b>Seamon et al., 2019a</b>).                          
                          </p>
                          <p>
                            In 2015, wheat insurance loss claims filed across the Inland Pacific Northwest totaled $240 million for all
                            damage causes, of which losses attributed to drought accounted for 56%. In Spokane, Adams, Whitman, and
                            Lincoln Counties in 2015, drought loss claims filed for wheat totaled a combined $22 million
                            (<b>Seamon et al., 2019b</b>).
                          </p>
                          <p>
                            The drought conditions during 2015 provide a potential analog for future droughts in terms of both
                            precipitation and temperature. The year 2015 is an ideal analog because the year saw near-normal precipitation
                            levels but with temperatures similar to those projected for the middle decades of this century (<b>Marlier et al., 2017</b>). 
                            While the 2015 drought and its effect on wheat and insurance loss claims is not a clear guide to how
                            dryland wheat might be affected in the future, it does raise the possibility that dryland wheat farming might
                            be more susceptible to certain climate conditions than previously estimated.
                          </p>
                          <p>
                            <b>Discussion — Why Precipitation Should Not Be Considered Alone</b>
                          </p>
                          <p>
                            When precipitation is considered with other climate variables, such as temperature, several other potential
                            impacts to agriculture become apparent. While there is evidence to suggest that dryland wheat farming in
                            Washington State may see higher yields due both to rising temperatures and rising CO2
                            levels at least through
                            the middle of this century (<b>Stöckle et al., 2014</b>) (<b>Fourth National Climate Assessment, “Chapter 24: Northwest” 2018</b>), 
                            other crops might not benefit from rising temperatures. For instance, warmer winter temperatures are
                            expected to lead to precipitation falling more as rain and less as snow, particularly at the lower elevations. This
                            is important for some crops because winter snow cover can provide an insulating effect, protecting dormant
                            crops from freezing temperatures (<b>Aase and Siddoway 1979</b>).
                          </p>
                          <p>
                            Additionally, as the Snowfall and Streamflow chapters of this report describe, the shift in precipitation from
                            snow to rain during the fall, winter, and spring months is expected to alter the timing of streamflow in the
                            region and is likely to impact the amount of stored water available for irrigated agriculture. Without large
                            increases in storage or conservation, the decline in spring snowpack will tax summer irrigation and water
                            resources. However, this is likely to be a larger problem in other parts of the Pacific Northwest rather than
                            Spokane (<b>Fourth National Climate Assessment, “Chapter 24: Northwest” 2018</b>). In the farmlands surrounding
                            Spokane, direct irrigation from the Spokane River is limited. Most agriculture in the region is dryland farming
                            and is not irrigated.
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card border-dark mb-3">
                          <div className="card-header p-2 card-header-display">
                            <img className="col-1 icon-image p-0" src="/media/OSU_icon_key_01.png" alt="key"></img>
                            <h4 className="col-11 card-header-title p-0">Key Findings</h4>
                          </div>
                          <div className="card-body p-0">
                            <ol className="list-group" >
                              <li className="list-group-item">
                                In recent decades, the Spokane region has observed a slight increase in precipitation during the fall, winter,
                                and spring months, and a slight decrease in precipitation during the summer months.
                              </li>
                              <li className="list-group-item">
                                The timing and volume of precipitation in the Spokane region is not projected to dramatically change over
                                this century.
                              </li>
                              <li className="list-group-item">
                                Precipitation projections for Spokane for this century show a slight increase in annual precipitation, with a
                                slight increase in precipitation during the fall, winter, and spring months, and a slight decrease in
                                precipitation over the summer months. However, these projections do not preclude the existence of periodic
                                future droughts due to low precipitation levels.
                              </li>
                              <li className="list-group-item">
                                The Spokane region will continue to meet the precipitation timing and volume requirements for dryland
                                wheat production.
                              </li>
                              <li className="list-group-item">
                                During the 2015 drought, drought loss claims filed for wheat in Spokane, Adams, Whitman, and Lincoln
                                Counties totaled a combined $22 million.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                      {/* End of Key Findings */}
                      <div className="col">
                        <div className="card border-dark mb-3">
                          <div className="card-header p-2 text-white bg-success p-1 card-header-display">
                            <img className="col-2 icon-image" src="/media/OSU_icon_shield_01.png" alt="shield"></img>
                            <h4 className="col-10 card-header-title p-0">Resiliency Actions</h4>
                          </div>
                          <div className="card-body p-0">
                            <ol className="list-group">
                              <li className="list-group-item list-group-item-success">
                                <b>Planting Techniques:</b> There are several steps farmers can take to minimize the compounding effects of
                                climate change to our agricultural community. Current efforts to minimize erosion in our region, including
                                no-till and direct seeding planting techniques as well as re-establishing stream and field buffers, will
                                become even more important in the future.
                              </li>
                              <li className="list-group-item list-group-item-success">
                                <b>Plan for High Temperatures:</b> Over the next 30–60 years, high heat dangers should be considered when scheduling outdoor
                                public events.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End of Resiliency Actions */}
                    {/* End of Precipitation Card Body */}
                  </div>
                </div>
              </div>
              {/* End of Precipitation Card */}

              {/* Snow Card */}
              <div id="snow-summary" className="card bg-light mb-3 border-dark">
                <div className="card-header p-2 card-header-display">
                  <img className="col-1 icon-image" src="/media/OSU_icon_mountain_01.png" alt="snow"></img>
                  <h3 className="col-11 card-header-title p-0">Snowfall</h3>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Lead Authors</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Brian Henning (Gonzaga University), David Camp (Northwest Renewables), and Erik Budsberg (Eastern Washington University)
                  </h6>
                  <div className="card-text">
                    <div className="row">
                      <div className="card border-dark m-3">
                        <div className="card-header p-1 card-header-display">
                          <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data story"></img>
                          <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Projected Future Snow at Mt. Spokane Ski & Snowboard Park</i></h4>
                        </div>
                        <div className="card-body">
                          <p>
                            Over the course of this century, projected
                            temperature increases and the increasing likelihood
                            that precipitation will fall as rain rather than snow
                            during the cold months of the year will likely shrink
                            the length of the ski season and lead to adverse
                            economic impacts for all five Spokane-area ski resorts:
                            Mt. Spokane Ski & Snowboard Park, 49 Degrees North
                            Mountain Resort, Silver Mountain Resort, Schweitzer
                            Mountain Resort, and Lookout Pass Ski Area.
                          </p>
                          <p>
                            For the purposes of this summary climate data story,
                            the authors of this chapter focused on future climate
                            impacts at one of these five resorts: Mt. Spokane Ski &
                            Snowboard Park (Mt. Spokane).
                          </p>
                          <p>
                            By the middle of this century (2040–2069) during the
                            prime ski season (December–February), the average
                            mean temperature at Mt. Spokane is expected to rise
                            from a historical (1971–2000) mean of 25.9 degrees
                            Fahrenheit to 30.6 °F under the lower emissions
                            scenario (RCP 4.5) or 31.8 °F under high emissions
                            scenario (RCP 8.5).
                          </p>
                          <p>
                            The rise in mean temperatures at Mt. Spokane is
                            expected to correspond to a decline in snow at the
                            ski park. Simply put, as temperatures rise, it becomes
                            far more likely that precipitation will fall as rain
                            rather than as snow. To determine how much snow
                            Mt. Spokane might have by mid-century, our team
                            used the variable <i>snow water equivalent</i> (SWE), which
                            is a measure of how much liquid water is available
                            in a given amount of snow on the ground. By midcentury, our analysis found, SWE accumulated on
                            Mt. Spokane by the date January 1st—a key date for
                            the local ski industry—is expected to decline from a
                            historical mean of 10.7” to 8.8” under RCP 4.5 and to
                            7.5” under RCP 8.5. 
                          </p>
                          <p>
                            Moreover, if we examine precipitation projections, we can make the reasonable inference that this loss of snow
                            is due to rising temperatures and not declines in precipitation. By mid-century, winter (December to February)
                            precipitation at Mt. Spokane is projected to move from a historical mean of 14.6” to 15.8” under RCP 4.5 and to
                            16.0” under RCP 8.5. During this same mid-century period, the annual number of days below freezing (32 °F) at Mt. Spokane is
                            projected to drop from a historical average of 169 days to 127 days under RCP 4.5 and to 111 days under RCP 8.5
                            (Table 6). In other words, there is expected to be 42–58 fewer freezing days per year at Mt. Spokane by midcentury compared to what was observed during the last three decades of the 20th century. 
                          </p>
                          <p>
                            If we consider this trend of fewer freezing days, we can make the reasonable interference that by mid-century
                            Mt. Spokane will see fewer days cold enough for snow to form in the atmosphere and remain frozen on the
                            ground. Fewer days below freezing also means fewer days cold enough to make snow with equipment, which
                            generally requires temperatures below freezing (specifically 30 °F or lower).
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card border-dark mb-3">
                          <div className="card-header p-2 card-header-display">
                            <img className="col-1 icon-image p-0" src="/media/OSU_icon_key_01.png" alt="key"></img>
                            <h4 className="col-11 card-header-title p-0">Key Findings</h4>
                          </div>
                          <div className="card-body p-0">
                            <ol className="list-group" >
                              <li className="list-group-item">
                                All five Spokane-area ski resorts (Mt. Spokane Ski & Snowboard Park, 49 Degrees North Mountain Resort,
                                Silver Mountain Resort, Schweitzer Mountain Resort, and Lookout Pass Ski Area) are likely to be impacted
                                by rising temperatures.
                              </li>
                              <li className="list-group-item">
                                Projected climate changes at Spokane’s five ski resorts by the middle of this century include:
                                <ol>
                                  <li >
                                    An increase in mean temperatures
                                  </li>
                                  <li >
                                    An overall decline in snow on the ground (as measured as snow water equivalent)
                                  </li>
                                  <li >
                                    A decrease in the total number of winter days below 32 degrees Fahrenheit, potentially hindering
                                    opportunities to make snow
                                  </li>
                                </ol>
                              </li>
                              <li className="list-group-item">
                                Taken together, these projected climate changes pose a continuing threat to the vitality of winter sports
                                recreation in the Spokane region.
                              </li>
                              <li className="list-group-item">
                                Reducing greenhouse gas emissions—moving from our current path on the high emissions scenario (RCP
                                8.5) to the lower emissions scenario (RCP 4.5)—could mean the difference between a degraded but viable
                                ski industry (RCP 4.5) and a nonviable ski industry (RCP 8.5) for five Spokane-area ski resorts, according
                                to our analysis.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                      {/* End of Key Findings */}
                      <div className="col">
                        <div className="card border-dark mb-3">
                          <div className="card-header p-2 text-white bg-success p-1 card-header-display">
                            <img className="col-2 icon-image" src="/media/OSU_icon_shield_01.png" alt="shield"></img>
                            <h4 className="col-10 card-header-title p-0">Resiliency Actions</h4>
                          </div>
                          <div className="card-body p-0">
                            <ol className="list-group">
                              <li className="list-group-item list-group-item-success">
                                <b>Reduce Emissions:</b> The primary action for lessening the impacts to Spokane’s winter sports industry
                                associated with projected higher temperatures is to reduce greenhouse gas emissions, specifically to move
                                away from the high emissions scenario (RCP 8.5) to the lower emissions scenario (RCP 4.5).
                              </li>
                              <li className="list-group-item list-group-item-success">
                                <b>Prepare for Shorter Seasons:</b> As temperatures in the Spokane region continue to rise, Spokane’s
                                regional snow-dependent recreation industries—including skiing, snowboarding, snowshoeing, and
                                snowmobiling—need to prepare for snow seasons that start later, are shorter, have less snow, and
                                potentially provide fewer days cold enough to make snow.
                              </li>
                              <li className="list-group-item list-group-item-success">
                                <b>Diversify: </b> Winter recreation industries in the Spokane region should consider diversifying their business
                                models, including emphasizing warmer weather recreation activities.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End of Resiliency Actions */}
                    {/* End of Snow Card Body */}
                  </div>
                </div>
              </div>
              {/* End of Snow Card */}

              {/* Fire Card */}
              <div id="fire-summary" className="card bg-light mb-3 border-dark">
                <div className="card-header p-2 card-header-display">
                  <img className="col-1 icon-image" src="/media/OSU_icon_fire_01.png" alt="fire"></img>
                  <h3 className="col-11 card-header-title p-0">Wildfires</h3>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Lead Authors</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Mike Petersen (Lands Council), Jerusha Hampson (Lands Council), Levi Keesecker (E2 Environmental), Wei Li (E2 Environmental), Terry Lawhead (ret.), Katherine Rowden (NOAA), and Joel Breems (Avista)
                  </h6>
                  <div className="card-text">
                    <div className="row">
                      <div className="card border-dark m-3">
                        <div className="card-header p-1 card-header-display">
                          <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data story"></img>
                          <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Local Forest Fires</i></h4>
                        </div>
                        <div className="card-body">
                          <p>
                            Spokane, Washington, is surrounded by forests.
                            While wildfires have long been a natural feature of
                            our landscape—for instance, playing a role in forest
                            succession—wildfires can be incredibly destructive
                            to human life and property as well as wildlife and
                            ecosystem services.
                          </p>
                          <p>
                            The primary means through which climate change is
                            expected to impact wildfire potential in the Spokane
                            region and the United States Pacific Northwest
                            generally is through fuel dryness. Fire and land
                            management agencies use several diagnostics
                            throughout the fire season to track fire potential.
                            Among these diagnostics are a suite of fire danger
                            indices from the US National Fire Danger Rating
                            System. Our analysis employs the 100-hour Fuel
                            Moisture Index, the standard metric used by foresters
                            and wildland firefighters to determine the risk of fire
                            ignition and how fast a fire is likely to spread.
                          </p>
                          <p>
                            For our analysis, we examined the Climate Toolbox
                            metric Extreme Fire Danger Days. Extreme Fire
                            Danger Days is defined in the Toolbox as calendar
                            days that fall into the lowest 3rd percentile of fuel
                            dryness as defined by the 100-hour Fuel Moisture
                            Index. These are days when fuels are very dry. Future
                            projections indicate that the number of Extreme Fire
                            Danger Days in the Spokane region is projected to
                            increase throughout this century, starting from a
                            late 20th century baseline of 11 days, then rising to
                            13.9 days through the early decades of this century
                            (2010–2039), and to 20.4 days by the middle decades
                            (2040–2069) of this century.
                          </p>
                          <p>
                            Extreme Fire Danger Days and 100-hour Fuel
                            Moisture Index do not predict that fires will occur
                            only that they are more likely to occur given the right
                            ignition source (be it human or natural). The rise in
                            the number of days with extremely dry fuels is very
                            likely to produce more days with wildfires each year.
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card border-dark mb-3">
                          <div className="card-header p-2 card-header-display">
                            <img className="col-1 icon-image p-0" src="/media/OSU_icon_key_01.png" alt="key"></img>
                            <h4 className="col-11 card-header-title p-0">Key Findings</h4>
                          </div>
                          <div className="card-body p-0">
                            <ol className="list-group" >
                              <li className="list-group-item">
                                Throughout this century, climate projections indicate that the Spokane region will see drier, warmer
                                summers, conditions that are ideal for larger, more destructive wildfires.
                              </li>
                              <li className="list-group-item">
                                Throughout this century, Spokane is projected to see an increase in the number of days during which fires
                                could ignite.
                              </li>
                              <li className="list-group-item">
                                During the fire seasons of 2017 and 2018, Spokane saw an increase in the number of days during which
                                local air quality was listed as unhealthy and very unhealthy by the Environmental Protection Agency’s Air
                                Quality Index.
                              </li>
                              <li className="list-group-item">
                                Spokane is likely to experience both a longer fire season and a longer smoke season in the decades ahead.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                      {/* End of Key Findings */}
                      <div className="col">
                        <div className="card border-dark mb-3">
                          <div className="card-header p-2 text-white bg-success p-1 card-header-display">
                            <img className="col-2 icon-image" src="/media/OSU_icon_shield_01.png" alt="shield"></img>
                            <h4 className="col-10 card-header-title p-0">Resiliency Actions</h4>
                          </div>
                          <div className="card-body p-0">
                            <ol className="list-group">
                              <li className="list-group-item list-group-item-success">
                                <b>Prepare for Increased Fire, Smoke, and Ash:</b> Spokane emergency planners need to adopt policies and
                                adaptation strategies that help Spokane and the surrounding region prepare for the increased risk of fire,
                                smoke, and ash dangers. This could include strategies for monitoring air quality related to outdoor activities
                                (including school recesses) and, when necessary, issuing particulate masks at large outdoor events.
                              </li>
                              <li className="list-group-item list-group-item-success">
                                <b>Air Quality Shelters:</b> The creation of “air quality shelters” should be considered for sensitive groups, as
                                defined by the Environmental Protection Agency (EPA), as well as the population generally. This could
                                include providing large public spaces (school gyms, community centers, etc.) with high quality air filtration
                                systems.
                              </li>
                              <li className="list-group-item list-group-item-success">
                                <b>Forest Management:</b> Forests in and around Spokane should be managed to reduce the amount of fuel
                                available. This could be done through various management techniques, including forest thinning and
                                prescribed burning.
                              </li>
                              <li className="list-group-item list-group-item-success">
                                <b>Outreach:</b> Educational outreach campaigns should be created that:
                                <ul>
                                  <li>
                                    Ensure the public understands why fire management strategies, such as prescribed burns are being
                                    used;
                                  </li>
                                  <li>
                                    Encourage voluntary compliance with fire-safe housing recommendations (clear space around
                                    homes and structures); and
                                  </li>
                                  <li>
                                    That clearly communicate the health risks associated with smoke and ash.
                                  </li>
                                </ul>
                              </li>
                              <li className="list-group-item list-group-item-success">
                                <b>Regulations:</b> New regulations should be designed to increase defensible areas around structures for
                                business and homeowners in the wildland/urban interface.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End of Resiliency Actions */}
                    {/* End of Fire Card Body */}
                  </div>
                </div>
              </div>
              {/* End of Fire Card */}

              {/* Streamflow Card */}
              <div id="streamflow-summary" className="card bg-light mb-3 border-dark">
                <div className="card-header p-2 card-header-display">
                  <img className="col-1 icon-image" src="/media/OSU_icon_water_01.png" alt="stream"></img>
                  <h3 className="col-11 card-header-title p-0">Streamflow</h3>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Lead Authors</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Karl Rains (WA Department of Ecology), John Porcello (GSI Water Solutions, Inc.), Kara Odegard (Measure Meant), Jule Schultz (Spokane Riverkeeper), and Brad Morin (Spokane Riverkeeper)
                  </h6>
                  <div className="card-text">
                    <div className="row">
                      <div className="card border-dark m-3">
                        <div className="card-header p-1 card-header-display">
                          <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data story"></img>
                          <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Recreation on the Spokane River</i></h4>
                        </div>
                        <div className="card-body">
                          <p>
                            The Spokane River is arguably Spokane’s top natural
                            attraction, especially during the spring and summer
                            months when outdoor enthusiasts and visitors spend
                            more time along or on the river. As the Spokane
                            area’s primary source of visitor information, the
                            organization Visit Spokane encourages visitors to
                            float down the river, paddleboard, fish, or simply
                            take in the scenery from along the shoreline (<b>Visit Spokane 2019</b>). It is no surprise that summertime
                            recreation activities associated with the river have a
                            significant economic impact for our region. According
                            to a 2015 report from Washington’s Recreation &
                            Conservation Office, the total annual economic
                            contribution of all types of outdoor recreation in
                            Spokane County amounted to nearly $1.2 billion and
                            12,500 jobs annually (<b>Washington State Recreation & Conservation Office 2015</b>). 
                            Although not broken down at a county level, annual state expenditures
                            on non-motorized boating and rafting activities have
                            been estimated to exceed $640 million (<b>Briceno and Schundler 2015</b>).
                          </p>
                          <p>
                            Several individuals representing local paddling
                            organizations and commercial rafting enterprises
                            were interviewed in an effort to determine potential
                            ramifications to recreational users and businesses
                            from an earlier high streamflow and extended
                            summer low-flow on the Spokane River. Although
                            qualitative information from the individuals was
                            mixed, a few common themes came out of the
                            discussions. The first is that the lower portion of the
                            river between Spokane Falls and Nine Mile Reservoir—which includes the Spokane River Gorge, the Bowl &
                            Pitcher rock formation, and the Devil’s Toenail whitewater rapids—relies on a minimum flow of approximately
                            2,500 cubic feet per second (cfs) for commercial rafting companies and most kayakers to safely navigate
                            the section
                          </p>
                          <p>
                            Once flow drops below 2,000 cfs, all but the most extreme kayakers are limited to milder “float” trips that
                            include longer sections of calmer water. Historical trends in streamflow typically allowed rafting companies
                            to market whitewater trips through this section of the Spokane River during the month of June, when school
                            is out and many Americans begin their summer vacations. With earlier spring high flows and an earlier and
                            extended summer low-flow period, future whitewater conditions may cease as early as June, according to our
                            analysis. This means that optimum whitewater conditions could shift from June to May, resulting in a reduced
                            consumer base. (<b>Rains 2019</b>). This would limit local rafting companies from capitalizing on summer tourism.
                            Although representatives of the commercial rafting companies contacted for this report recognized existing
                            and potential financial impacts from an earlier whitewater season on the Spokane River, they also reported that
                            they already mitigate for seasonal variations through a variety of methods, including operating on multiple
                            regional rivers, highlighting scenic float trips on the Spokane River versus whitewater trips, and/or by offering
                            other types of river activities, such as tubing. Representatives also indicated that while the instream flow
                            rule (See full report for more details) is not as favorable to recreational use as they would prefer, the rule does
                            mitigate against potential extreme low flow conditions occurring in mid to late summer. Rafting company
                            representatives also noted that during the last several years, smoke from seasonal wildfires has had an even
                            greater detrimental effect on river use than streamflow.
                          </p>
                        </div>
                      </div>
                      <div className="card border-dark m-3">
                        <div className="card-header p-1 card-header-display">
                          <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data story"></img>
                          <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Redband Trout</i></h4>
                        </div>
                        <div className="card-body">
                          <p>
                            “Interior Redband trout are considered a species of special concern by the American Fisheries Society and the
                            U.S. Fish and Wildlife Service (FWS) in most states where the subspecies historically existed and are classified
                            as a sensitive species by the U.S. Forest Service and Bureau of Land Management.”
                             - <i>Western Native Trout Status Report</i>
                          </p>
                          <p>
                            The Redband trout is a subspecies of Rainbow trout and is the primary native Salmonid species of the Spokane
                            River. The Washington Department of Fish and Wildlife considers Redband trout a sentinel species, meaning
                            the health and abundance of the species are indicating factors of the overall health of a river ecosystem (<b>Gerber 2017</b>). 
                            Over the past century, many factors have contributed to a decline in the population of Redband trout.
                            Projected changes in-stream flow rates would exacerbate the problem, according to our analysis. It is estimated
                            that Redband trout once occupied 37,465 miles of streams and 152 natural (un-impounded) lakes throughout
                            Washington, Oregon, Idaho, Nevada, Montana, and northern California. Currently Redband trout occupy
                            approximately 42% of their historical stream habitat (<b>Western Native Trout 2018; Muhlfeld et al., 2014</b>).
                          </p>
                          <p>
                            Locally, substantial impacts to the species will likely occur in the upper Spokane River, where populations
                            are already depressed (<b>Lee 2019</b>). Projected reduced flows below Post Falls Dam could result in warmer water
                            temperatures that benefit smallmouth bass (a non-native species) and approach the upper lethal temperature
                            range for Redband trout. Redband trout generally prefer stream temperatures less than 70 °F (<b>Wydoski and Whitney 2003</b>) 
                            and experience stress at 71.6 degrees Fahrenheit and above (<b>Behnke 1992</b>).
                          </p>
                          <p>
                            The effects of climate change on rising temperatures and their impacts on salmonids has already been
                            observed (<b>Isaak et al., 2012</b>). Earlier peak flow and reduced summer flows projected under climate change could
                            decrease viable rearing habitat for Redband trout, leading to reductions in populations
                          </p>
                          <p>
                            During a survey conducted by Michael Taylor McCroskey in the summer of 2015, very few Redband trout
                            were present in the upper reaches near the spawning areas. 
                          </p>
                          <p>
                            The study was conducted during an extreme drought year (in historical context), during which river flows
                            were lower than usual and water temperatures were warmer than normal. This likely influenced species
                            distribution, and may have also impacted spawning success and survival. Additionally, a large population
                            of smallmouth bass was documented, which likely impacted the survival of juvenile Redband trout from
                            predation; to what extent is unknown (<b>McCroskey 2015</b>).
                          </p>
                          <p>
                            The Spokane River (below Sullivan Road) is heavily influenced by groundwater recharge, which moderates
                            summer stream temperatures with an influx of cooler water. However, projections of reduced surface flows
                            would result in reduced carrying capacity for Redband trout and an anticipated increase in predation from
                            species that thrive in warmer water temperatures. Of particular concern to the viability of Redband trout is
                            the projected earlier low-flow period beginning as early as May, rather than later in the summer. According to
                            the Parametrix 2003 spawning report, spawning generally commences at the beginning of April when water
                            temperature reaches 45 °F (<b>Parametrix 2003</b>). Emergence occurs near the end of May and into the beginning of
                            June. Future projections indicate that a decrease in streamflow will occur during the Redband trout incubation
                            period that could result in water levels falling below the level of fish nests, a process called <i>redd dewatering</i>.
                          </p>
                        </div>
                      </div>
                      <div className="card border-dark m-3">
                        <div className="card-header p-1 card-header-display">
                          <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data story"></img>
                          <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Spokane Valley-Rathdrum Prairie Aquifer</i></h4>
                        </div>
                        <div className="card-body">
                          <p>
                            The Spokane Valley-Rathdrum Prairie (SVRP) Aquifer is the sole-source drinking water for over 500,000
                            people (<b>MacInnis et al., 2009</b>). According to our analysis, the aquifer appears to be less sensitive to climate
                            change impacts than aquifers in other regions. Aquifer levels are primarily affected by recharge from the
                            Spokane River and from several lakes in the region that bound and recharge the aquifer (<b>Hsieh et al., 2007; Kahle et al., 2007</b>). 
                          </p>
                          <p>
                            Seasonal changes in streamflow under future climate conditions are anticipated, according to our analysis.
                            Specifically, peak streamflows may occur earlier in the winter and/or spring months, while the summer-season
                            low streamflows could begin earlier in the summer. However, future climate projections indicate that only small
                            percentage changes in annual total precipitation and streamflows are likely to occur, which suggests—assuming
                            no net change in annual water extraction due to human factors—that there might be just a limited overall
                            change in annual volumes of aquifer recharge on a long-term multi-decadal basis. However, within these long
                            multi-decadal time periods, aquifer recharge periodically could be below historically observed conditions if
                            multi-year droughts were to occur more frequently than in the past or be more intense than in the past.
                          </p>
                          <p>
                            The primary mid-term vulnerability of the Spokane region’s sole source of potable water may have less to do
                            with climate change and more to do with the fact that historically some of the region’s water supply wells have
                            been drilled only into the very uppermost portion of the aquifer. The oldest wells in the region were excavated
                            and/or hand-dug within the Spokane city limits during the early 1900s. Accordingly, these wells were
                            constructed no further than necessary into the water table, which means they obtain water by essentially
                            “skimming” off of the top of this thick aquifer (<b>CH2M HILL 1998; GSI 2012</b>). The operational efficiencies of some
                            of these shallow wells may be sensitive to small climate-driven changes in summer-season water levels in the
                            aquifer (<b>GSI et al., 2019</b>). Newer wells in the region were constructed using more conventional drilling methods,
                            achieving greater penetration depths into the water table. However, even these wells were not typically drilled
                            any deeper than necessary in order to minimize drilling and pumping costs.
                          </p>
                          <p>
                            Due to the particularly low water levels observed in the aquifer during the past few summers, the City of
                            Spokane is now actively working to understand the resiliency of several of its water supply wells and to
                            evaluate what types of modifications (if any) to certain wells and/or pumping systems might be warranted for
                            future implementation (<b>GSI et al., 2019</b>). City officials are conducting this work as part of their planning for
                            capital improvement projects. Although other municipal water providers own conventional drilled wells that
                            penetrate deeper into this aquifer, it is possible that some of those providers could eventually identify that one
                            or more of their wells would warrant resilience evaluations in the future and potential adjustments to their
                            construction and/or their pumping systems to optimize or improve well operations.
                          </p>
                          <p>
                            More research is needed before we can fully understand the longer-term impacts of climate change on the
                            Spokane Valley-Rathdrum Prairie Aquifer. In addition to further climate analysis, it is also necessary for local
                            policymakers to understand the non-climate related impacts, such as increased demand on the aquifer due to (1)
                            population growth and (2) increased evapotranspiration as temperatures rise in our region. Individual choices,
                            business practices, and government policy are all necessary components of an effective strategy to prevent
                            adverse climate-induced impacts on water availability.
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card border-dark mb-3">
                          <div className="card-header p-2 card-header-display">
                            <img className="col-1 icon-image p-0" src="/media/OSU_icon_key_01.png" alt="key"></img>
                            <h4 className="col-11 card-header-title p-0">Key Findings</h4>
                          </div>
                          <div className="card-body p-0">
                            <ol className="list-group" >
                              <li className="list-group-item">
                                In the coming decades, the shift of precipitation from snow to rain coupled with earlier snow melts is expected to
                                alter the timing of streamflow on the Spokane River even while the total annual volume of streamflow in the river is
                                expected to remain similar to historical levels.
                              </li>
                              <li className="list-group-item">
                                Impacts from low summer flows are likely to intensify over time, particularly if greenhouse gas emissions are
                                allowed to continue increasing at their current rate.
                              </li>
                              <li className="list-group-item">
                                By the end of the century, flows conducive to optimum whitewater rafting and kayaking on the Spokane River may
                                cease as early as June.
                              </li>
                              <li className="list-group-item">
                                The expected changes to the unregulated flow of the Spokane River is expected to have several detrimental impacts,
                                including for native Redband trout, summer recreational opportunities, and the general aesthetic value that the
                                Spokane River provides to the community.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                      {/* End of Key Findings */}
                      <div className="col">
                        <div className="card border-dark mb-3">
                          <div className="card-header p-2 text-white bg-success p-1 card-header-display">
                            <img className="col-2 icon-image" src="/media/OSU_icon_shield_01.png" alt="shield"></img>
                            <h4 className="col-10 card-header-title p-0">Resiliency Actions</h4>
                          </div>
                          <div className="card-body p-0">
                            <ol className="list-group">
                              <li className="list-group-item list-group-item-success">
                                <b>Reduce Emissions:</b> Take all possible actions to reduce greenhouse gas emissions and avoid the high emissions
                                scenario (RCP 8.5).
                              </li>
                              <li className="list-group-item list-group-item-success">
                                <b>Prioritize Trout Habitat:</b> Reconsider regulations at Post Falls to help prioritize Redband trout habitat.
                              </li>
                              <li className="list-group-item list-group-item-success">
                                <b>Future Research:</b> Conduct more research to fully understand the long-term impacts of climate change on the
                                Spokane Valley-Rathdrum Prairie Aquifer.
                              </li>
                              <li className="list-group-item list-group-item-success">
                                <b>Future Research:</b> Investigate well depth and pump technology.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End of Resiliency Actions */}
                    {/* End of Streamflow Card Body */}
                  </div>
                </div>
              </div>
              {/* End of Streamflow Card */}
            </div>
            <div className="col-2 d-none d-md-block navbar ">
              <div className="navbar-sticky-top sticky-top bg-white side-navbar">
                <h5 className="sidebar-title">Menu</h5>
                <ol className="list-group">
                  <li className="list-group-item">
                    <a href="#scp-documentary">Documentary</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#about-this-report">About</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#emissions-scenario">Emissions Scenario</a>
                  </li>
                  <li className="list-group-item">
                      <a href="#temperature-summary">Temperature</a>
                  </li>
                  <li className="list-group-item">
                      <a href="#precipitation-summary">Precipitation</a>
                  </li>
                  <li className="list-group-item">
                      <a href="#snow-summary">Snow</a>
                  </li>
                  <li className="list-group-item">
                      <a href="#fire-summary">Fire</a>
                  </li>
                  <li className="list-group-item">
                      <a href="#streamflow-summary">Streamflow</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Summary;