import React from 'react';

function Wildfires() {
    return (
      <div>
        <div className="card bg-dark text-white">
          <img src="/media/fire/forest-fire-photo-edited.jpg" className="card-img" alt="Spokane Falls" />
          <div className="h-40 header-image-portion-overlay header-title d-none d-lg-flex">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text">Chapter 5: Fire and Smoke Impact Study</h1>
              <h4 className="card-text header-title">
                <i>
                  <p>
                    “Megafires are the result of the way we've managed this western landscape for the last 150 years and a steadily warming climate.”
                  </p>
                  <p>
                    —Paul Hessburg, US Forest Service
                  </p>
                </i>
              </h4>
            </div>
          </div>
          <div className="h-80 header-image-portion-overlay header-title d-none d-md-flex d-lg-none">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text">Chapter 5: Fire and Smoke Impact Study</h1>
              <i>
                <p>
                  “Megafires are the result of the way we've managed this western landscape for the last 150 years and a steadily warming climate.”
                </p>
                <p>
                  —Paul Hessburg, US Forest Service
                </p>
              </i>
            </div>
          </div>
          <div className="h-80 header-image-portion-overlay header-title d-none d-sm-flex d-md-none">
            <div className="my-auto">
              <h3 className="card-title image-overlay-text">Chapter 5: Fire and Smoke Impact Study</h3>
              <i>
                <p>
                  “Megafires are the result of the way we've managed this western landscape for the last 150 years and a steadily warming climate.”
                </p>
                <p>
                  —Paul Hessburg, US Forest Service
                </p>
              </i>
            </div>
          </div>
          <div className="h-80 header-image-portion-overlay header-title d-sm-none">
            <div className="my-auto">
              <h3 className="card-title image-overlay-text center-vertical">Chapter 5: Fire and Smoke Impact Study</h3>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="row">
            <div className="col-md-10 content">
              <ul id="chapter-summary" className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Chapter Summary: </b>Examines the rise in wildfire activity projected for Spokane and the Pacific Northwest
                  throughout this century. Focusses on the health impacts that smoke from historical wildfires has had on Spokane’s
                  residents and the health impacts that smoke from future wildfires is projected to have on Spokane residents.</li>
                <li className="list-group-item">
                  <b>Lead Authors: </b>Mike Petersen (Lands Council), Levi Keesecker (E2 Environmental), Wei Li (E2 Environmental),
                  Terry Lawhead (ret.), Katherine Rowden (NOAA), and Joel Breems (Avista), Jerusha Hampson (Lands Council),
                  and Katherine Hegewisch (CIRC/University of Idaho).</li>
                <li className="list-group-item">
                  <b>Review Editors: </b>Katherine Hegewisch (CIRC/University of Idaho), Nathan Gilles (CIRC/Oregon State University),
                  John Abatzolgou (CIRC/University of Idaho), Ann Mooney (CIRC/OSU), and Meghan Dalton (CIRC/OSU).</li>
              </ul>
              <div className="row m-1">
                <div className="col p-0">
                  <div className="card border-dark m-3">
                    <div className="card-header p-2 card-header-display">
                      <img className="col-1 icon-image p-0" src="/media/OSU_icon_key_01.png" alt="key"></img>
                      <h4 className="col-11 card-header-title p-0">Key Findings</h4>
                    </div>
                    <div className="card-body p-0">
                      <ol className="list-group" >
                        <li className="list-group-item">
                          Throughout this century, climate projections indicate that the Spokane region will see drier, warmer
                          summers, conditions that are ideal for larger, more destructive wildfires.
                        </li>
                        <li className="list-group-item">
                          Throughout this century, Spokane is projected to see an increase in the number of days during which fires
                          could ignite.
                        </li>
                        <li className="list-group-item">
                          During the fire seasons of 2017 and 2018, Spokane saw an increase in the number of days during which
                          local air quality was listed as unhealthy and very unhealthy by the Environmental Protection Agency’s Air
                          Quality Index.
                        </li>
                        <li className="list-group-item">
                          Spokane is likely to experience both a longer fire season and a longer smoke season in the decades ahead.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                {/* End of Key Findings */}
                <div className="col p-0">
                  <div className="card border-dark m-3">
                    <div className="card-header text-white bg-success p-2 card-header-display">
                      <img className="col-2 icon-image" src="/media/OSU_icon_shield_01.png" alt="shield"></img>
                      <h4 className="col-10 card-header-title p-0">Resiliency Actions</h4>
                    </div>
                    <div className="card-body p-0">
                      <ol className="list-group">
                        <li className="list-group-item list-group-item-success">
                          <b>Prepare for Increased Fire, Smoke, and Ash:</b> Spokane emergency planners need to adopt policies and
                          adaptation strategies that help Spokane and the surrounding region prepare for the increased risk of fire,
                          smoke, and ash dangers. This could include strategies for monitoring air quality related to outdoor activities
                          (including school recesses) and, when necessary, issuing particulate masks at large outdoor events.
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Air Quality Shelters:</b> The creation of “air quality shelters” should be considered for sensitive groups, as
                          defined by the Environmental Protection Agency (EPA), as well as the population generally. This could
                          include providing large public spaces (school gyms, community centers, etc.) with high quality air filtration
                          systems.
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Foreset Management:</b> Forests in and around Spokane should be managed to reduce the amount of fuel
                          available. This could be done through various management techniques, including forest thinning and
                          prescribed burning.
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Outreach:</b> Educational outreach campaigns should be created that:
                          <ul>
                            <li>
                              Ensure the public understands why fire management strategies, such as prescribed burns are being
                              used;
                            </li>
                            <li>
                              Encourage voluntary compliance with fire-safe housing recommendations (clear space around
                              homes and structures); and
                            </li>
                            <li>
                              That clearly communicate the health risks associated with smoke and ash.
                            </li>
                          </ul>
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Regulations:</b> New regulations should be designed to increase defensible areas around structures for
                          business and homeowners in the wildland/urban interface.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div id="climate-data-story-fires" className="card border-dark mb-3">
                <div className="card-header p-2 card-header-display">
                  <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data-story"></img>
                  <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Wildfires in the Forests Surrounding Spokane</i></h4>
                </div>
                <div className="card-body">
                  <p>
                    Spokane, Washington is surrounded by forests. While wildfires have long been a natural feature of our landscape—
                    for instance, playing a role in forest succession—wildfires can be incredibly destructive to human life and property
                    as well as wildlife and ecosystem services.
                  </p>
                  <p>
                    The primary means through which climate change is expected to impact wildfire potential in the Spokane region and
                    the United States Pacific Northwest generally is through fuel dryness. Fire and land management agencies use
                    several diagnostics throughout the fire season to track fire potential. Among these diagnostics are a suite of fire
                    danger indices from the US National Fire Danger Rating System. Our analysis employs the 100-hour Fuel Moisture
                    Index, the standard metric used by foresters and wildland fire fighters to determine the risk of fire ignition and how
                    fast a fire is likely to spread. (Readers may already be familiar with this metric. The 100-hour Fuel Moisture Index is
                    one metric used to create the color-coded fire danger scale that Smokey Bear stands next to at the entrance to
                    national forests.) 
                  </p>
                  <p>  
                    For our analysis, we examined the Climate Toolbox metric Extreme Fire Danger Days. Extreme
                    Fire Danger Days is defined in the Toolbox as calendar days that fall into the lowest 3rd percentile of fuel dryness as
                    defined by the 100-hour fuel moisture index. These are days when fuels are very dry. Future projections indicate that
                    the number of Extreme Fire Danger Days in the Spokane region is projected to increase throughout this century,
                    starting from a late 20th century baseline of 11 days, then rising to 13.9 days through the early decades of this
                    century (2010–2039), and to 20.4 days by the middle decades (2040–2069) of this century.
                  </p>
                  <p>
                    Extreme Fire Danger Days and 100-hour Fuel Moisture Index do not predict that fires will occur only that they are
                    more likely to occur given the right ignition source (be it human or natural). The rise in the number of days with
                    extremely dry fuels is very likely to produce more days with wildfires each year.
                  </p>
                </div>
              </div>
              <div id="larger-context" className="card border-dark mb-3 bg-light">
                <div className="card-header p-2 card-header-display">
                  <h4 className="col-11 card-header-title p-0">Larger Context — Wildfires and Climate Change in the American West</h4>
                </div>
                <div className="card-body">
                  <p>
                    Wildfires are an essential and natural part of many forest ecosystems in the Western United States, including the
                    Pacific Northwest. However, very large fires can be destructive to both humans and wildlife. Under projected future
                    climate change, wildfires could potentially change many aspects of Pacific Northwest forests as we now know them
                    (<b>Sheehan et al., 2015</b>). Climate affects the risk of wildfire ignitions through precipitation, temperature, humidity,
                    and winds. These climate factors affect the dryness of natural vegetation, which can serve as fuel for wildfires
                    (“<b>Wildfires & Changing Vegetation,” CIRC 2019</b>). In recent decades, the frequency and size of wildfires in the
                    Western US has increased due in large part to climate changes that are making conditions ideal for wildfires
                    (<b>Abatzoglou and Williams 2016</b>). Put simply, climate changes are drying out forest vegetation, leading to high
                    levels of fuel aridity. This drying process is turning vegetation into fuels, which only need the right ignition
                    source—be it a lightning strike or a stray lit cigarette—to go up in flames (<b>Abatzoglou, The Climate CIRCulator 2017</b>). 
                    From 1979 to 2015, roughly half (55%) of the increase in fuel aridity conditions in the Western US can be
                    attributed to warming temperatures resulting from human-caused climate change (<b>Abatzoglou and Williams 2016</b>).
                  </p>
                  <p>
                    Climate changes, especially warming temperatures, are projected to continue to make conditions ideal for wildfires
                    in the Western US throughout this century. Specifically, the number of very large fires—fires that burn more than
                    5,000 hectares, (about 19 square miles)—are expected to increase (<b>Barbero et al., 2015</b>) during this century. Very
                    large fires can contribute to degraded air quality, affecting populations both near to and distant from burning fires.
                  </p>
                  <p>
                    The increased risk of wildfires has also been linked to changes in precipitation. In the summer of 2017, the United
                    States Pacific Northwest, British Columbia, and California experienced an unexpectedly destructive wildfire season.
                    The summer fire season was preceded by especially wet winter and spring months. However, the summer months
                    that followed were especially warm and dry. This dried out vegetation, which when ignited, led to a very active fire
                    season (<b>Abatzoglou, CIRCulator 2017</b>). The climate models used in our analysis suggest this same trend of wetter
                    springs and drier summers will continue in the future. (See <b>Temperature and Precipitation—Climate Drivers of Wildfires </b>
                    below and the <b>Precipitation</b> chapter of this report.) Changing climate conditions are expected to lead to
                    increased wildfire risk in the Pacific Northwest generally as well as in the forests surrounding Spokane, Washington.
                  </p>
                </div>
              </div>
              <div id="local-context" className="card border-dark bg-light mb-3">
                <div className="card-header"><h4>Local Context — Wildfires, Smoke, Air Quality in Spokane</h4></div>
                <div className="card-body">
                  <p>
                    Spokane, Washington is surrounded by forests. National forests that surround Spokane include: the Colville
                    National Forest to the north; the Umatilla and Wallowa-Whitman National Forests to the south; the Okanogan-
                    Wenatchee National Forest to the west; the Mt. Hood, Gifford Pinchot, and Willamette National Forests to the
                    southwest; the Kootenai and Flathead National Forests to the northeast; and the Lolo, Nez-Perce Clearwater, and
                    Payette National Forests to the southeast. Nearby state forests include: Riverside and Mount Spokane State Parks.
                    Nearby forested wildlife refuges and conservation areas include: the Turnbull National Wildlife Refuge; the Antoine
                    Peak Conservation Area; and the Dishman Hills Natural Area.
                  </p>
                  <p>
                    Spokane benefits from the natural beauty of its nearby forests. However, being surrounded by forests also means
                    Spokane is at increased risk of impacts from wildfires. In recent years, Spokane has experienced drier, warmer
                    summers. Drier, warmer summers likely made conditions ideal for wildfires in and around Spokane in recent years.
                    (See <b>Historical Fires—How Climate Conditions Aided Recent Fires</b> below.)
                  </p>
                  <p>
                    A major health impact from Spokane’s recent wildfire activity has been unhealthy air quality in Spokane due to
                    smoke emanating from both nearby and more distant fires. (See the <b>Historical Smoke</b> sections below.) During a
                    single day in 2019, Spokane experienced the worst air quality in the U.S. due to smoke from a wildfire burning on
                    the nearby Colville Indian Reservation (<b>Campbell 2019</b>). During the summer of 2018, Spokane experienced
                    unhealthy air quality due to smoke from more distant fires in Canada, Montana, and northern Idaho (<b>Sokol and Sun 2018</b>). 
                    The wildfires of 2017 also impacted Spokane air quality. In the summer of 2017, smoke from wildfires likely
                    led to periods where Spokane air was categorized using the U. S. Environmental Protection Agency’s Air Quality
                    Index as very unhealthy and hazardous, according to numbers compiled by the Washington State Department of
                    Ecology (<b>Brunt 2017</b>). (See <b>The Air Quality Index (AQI) and Sensitive Groups</b>.)
                  </p>
                  <p>
                    As climate change continues to make conditions ideal for more and larger wildfires, it is reasonable to assume that
                    unhealthy air conditions due to smoke similar to those experienced in 2017, 2018, and 2019 will continue to impact
                    Spokane in the future.
                  </p>
                </div>
              </div>
              <div id="analysis" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Analysis — Data Tools, Inferences & Limitations, Variables, Timeframes, Emissions
                  Scenarios, Multi-model Means, and Climate Data Story</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Data Tools:</b> For this report we performed our data analysis by first examining recent historical climate drivers of wildfire risk,
                    recent historical wildfires, and how this recent wildfire activity likely led to unhealthy air quality in Spokane and its
                    surrounding region. We then preceded to examine projected future climate drivers of wildfire risk and projected
                    future wildfire risk for Spokane and its surrounding region. Using this analysis we made the reasonable inference,
                    based on what was observed historically, that if the wildfire risk is projected to increase in and around Spokane
                    throughout this century, as the data shows, then the effects of smoke on air quality in Spokane and its surrounding
                    region are also likely to persist throughout this century.
                  </p>
                  <p>
                    For this report we employed data visualizations of historical and projected future climate and wildfire risk using the
                    online tools available through The Climate Toolbox (<b>Climate Toolbox 2019</b>) a product of the Pacific Northwest
                    Climate Impacts Research Consortium (CIRC) (<b>“Climate Tools,” CIRC 2019</b>). This included downloading figures
                    and data from the following Toolbox tools:
                  </p>
                  <ul>
                    <li>
                      Historical Climate Tracker Tool (<a href="https://climatetoolbox.org/tool/Historical-Climate-Tracker">https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>)
                    </li>
                    <li>
                      The Future Boxplots Tool (<a href='https://climatetoolbox.org/tool/Future-Boxplots'>https://climatetoolbox.org/tool/Future-Boxplots</a>)
                    </li>
                    <li>
                      The Climate Mapper Tool (<a href="https://climatetoolbox.org/tool/Climate-Mapper">https://climatetoolbox.org/tool/Climate-Mapper</a>)
                    </li>
                  </ul>
                  <p>
                    To understand how smoke from historical fires affected Spokane, we also included data visualizations showing
                    distributions of unhealthy air quality around Spokane that used the United States Environmental Protection Agency
                    (EPA) variable the Air Quality Index (AQI). AQI values were derived for times with historical fires using the
                    following additional online tools:
                  </p>
                  <ul>
                    <li>
                      The EPA’s Air Data: Air Quality Data Collected at Outdoor Monitors Across the US (<a href="https://www.epa.gov/airdata">https://www.epa.gov/airdata</a>; <a href='https://www.epa.gov/outdoor-air-quality-data/air-quality-index-report'>https://www.epa.gov/outdoor-air-quality-data/air-quality-index-report</a>)
                    </li>
                  </ul>
                  <p>
                    <b>Inferences & Limitations:</b> Our analysis did not attempt to track how and whether smoke from specific fires was carried by winds to Spokane.
                    Instead we relied on historical reports of active wildfires and AQI values that occurred at the time of those historical
                    wildfires.
                  </p>
                  <p>
                    While beyond the scope of this inquiry, other impacts from wildfires are worth mentioning here, including: impacts
                    to agricultural production (through reduced crop photosynthesis due to smoke cover); impacts to energy (through
                    reduced effectiveness of ash-covered solar panels); impacts to local ecosystem services (such as, potential changes
                    to local watersheds); impacts to local wildlife; and impacts to outdoor tourism (hiking, boating, etc.), which is likely
                    to be reduced on smoky days due to air quality issues and safety concerns. This analysis also did not attempt to
                    calculate loss of property or loss of human and animal life due to fire. All of these impacts are important and could
                    be explored at length in a larger analysis.
                  </p>
                  <p>
                    <b>Variables:</b> When a geographic area has received inadequate precipitation or is especially warm or has received inadequate
                    precipitation while being especially warm, wildfires become a greater likelihood. The reason is that warm and dry
                    conditions dry out trees, grasses, and other vegetation, referred to as fuels in the scientific literature. There are
                    several approaches for estimating whether fuels are dry enough to burn. These approaches calculate what the
                    scientific literature refers to as fuel aridity.
                  </p>
                  <p>
                    To determine fuel aridity, CIRC’s Climate Toolbox employs the fire indices Energy Release Component (ERC),
                    Burning Index (BI), and 100-hour fuel moisture index from the National Fire Danger Rating System to give
                    information about how hot a fire could burn, how fast a fire would spread, and the risk for fire ignition, respectively
                    (<b>Climate Toolbox 2019; Cohen and Deeming 1985</b>). The probability of large fires has been shown to be well-
                    related to these indices (<b>Riley et al., 2013</b>). The indices are derived primarily from precipitation and temperature
                    measurements, meaning if precipitation levels are especially low and temperatures are especially warm (or are some
                    combination of the two), then this will show up in the values of each of the fire indices.
                  </p>
                  <p>
                    Because of the close connection between precipitation and temperature, all three fire indices used by the Toolbox
                    can be calculated for either past (measured and simulated) historical climate as well as for future projected climate.
                    For this analysis, we focused on the 100-hour Fuel Moisture Index. Specially, we focused on the Toolbox variable
                    derived from the 100-hour Fuel Moisture Index: Extreme Fire Danger Days. Extreme Fire Danger Days are days
                    where the 100-hour Fuel Moisture Index is below the historical 3rd percentile of years determined using the
                    historical baseline years 1971–2000.
                  </p>
                  <p>
                    Our analysis examined the following variables using the following Toolbox tools.
                  </p>
                  <ul>
                    <li>
                      <i>Variations in Spring (March–May) Precipitation for the years 1979–2019</i> (Historical Climate Tracker)
                    </li>
                    <li>
                      <i>Variations in Summer (June–August) Precipitation for the years 1979–2019</i> (Historical Climate Tracker)
                    </li>
                    <li>
                      <i>Variations in Summer (June–August) Mean Maximum Daily Temperature for the years 1979–2019</i> (Historical Climate Tracker)
                    </li>
                    <li>
                      <i>Spring (March–May) Precipitation (simulated historical and projected future)</i> (Future Boxplots Tool)
                    </li>
                    <li>
                      <i>Summer (June–August) Precipitation (simulated historical and projected future)</i> (Future Boxplots Tool)
                    </li>
                    <li>
                      <i>Summer (June–August) Mean of Maximum Daily Temperatures (simulated historical and projected future)</i> (Future Boxplots Tool)
                    </li>
                    <li>
                      <i>Extreme Fire Danger Days (simulated historical and projected future)</i> (Climate Mapper Tool; Future
                      Boxplots Tool)
                    </li>
                    <li>
                      <i>Very High Fire Danger Days (simulated historical and projected future)</i> (Future Boxplots tool)
                    </li>
                  </ul>
                  <p>
                    <b>Timeframes:</b> Our analysis looked at projections of Extreme Fire Danger Days and Very High Fire Danger Days for the periods
                    early century (2010–2039) and mid-century (2040–2069). Data for late century (2070–2099) were not available for
                    the fire metrics used. Non-fire variables for projected future periods were examined for early century, mid-century,
                    and late century. The historical baselines used were 1971–2010 and 1981–2010, depending on the dataset used.
                  </p>
                  <p>
                    <b>Emissions Scenarios:</b> For this analysis, we used both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5)
                    from phase 5 of the Coupled Model Intercomparison Project (<b>Climate Toolbox 2019</b>). The RCP 8.5 scenario
                    simulates in a computer what is likely to happen if greenhouse gases continue to be released into the atmosphere at
                    their current rate, and, as result, warming is allowed to continue at its current upward trajectory throughout this
                    century and beyond. By contrast, RCP 4.5 simulates a dramatic reduction in greenhouse gas emissions, so that while
                    warming continues throughout this century, warming starts to level off after 2100. In general, the two emissions
                    scenarios start to diverge around mid-century (2040–2069). At mid-century, warming under RCP 4.5 slows while
                    warming under RCP 8.5 continues at its current rate (<b>“Human Choice, Warming, & Emissions,” CIRC 2019</b>).
                    The two scenarios were used side by side in this analysis to evaluate how smoke from wildfires is likely to impact
                    Spokane residents and neighboring communities. Because both scenarios project an increase in wildfire activity, and
                    because other chapters of this report employed RCP 8.5 on its own for specific analyses due to the similarity
                    between the high emissions scenario and current emissions rates, our analysis also used RCP 8.5 by itself to
                    illustrate specific cases.
                  </p>
                  <p>
                    (Note: RCP 4.5 isn’t the lowest emissions scenario used by climate researchers. RCP 2.6 is the lowest emissions
                    scenario considered in climate models. However, because the collective global emissions pathway has very likely
                    veered off course from that modeled under RCP 2.6, RCP 2.6 is no longer used as the low emissions scenario
                    pathway. Since RCP 4.5 is lower than RCP 8.5, this report has adopted the standard used by many in the climate
                    research community: lower to describe RCP 4.5 and high to describe RCP 8.5, rather than lower and higher to
                    describe the two scenarios.)
                  </p>
                  <p>
                    <b>Multi-model Means:</b> The Toolbox data and figures that make up this analysis employ the mean, or average, resulting from multiple
                    climate models. In general, the Toolbox uses 18–20 global climate models (GCMs) to create its climate projections
                    (temperature, precipitation, etc.) and 10 GCMs to create its hydrology projections (snow water equivalent,
                    streamflow, etc.). The fire danger variables used in this analysis employed 18 GCMs. Using a multi-model mean, as
                    opposed to the results of a single model, is accepted as best practice by the climate science community. However,
                    the multi-model mean does not show the full spread of results from all the GCMs used to create a future projection,
                    but instead shows the average of that multi-model spread. In other words, actual future climate conditions—when
                    we get to them in the decades ahead—might lie either above or below the multi-model mean.
                  </p>
                  <p>
                    <b>Climate Data Story:</b> The climate data story we constructed from our data analysis focused on how smoke from wildfires in Spokane’s
                    surrounding forests are likely to impact residents of Spokane and neighboring communities. A climate data story is
                    defined by CIRC as “a narrative outlining climate facts and impacts specific to your community” (<b>Mooney et al., 2019</b>)
                  </p>
                </div>
              </div>
              <div id="temperature-precipitation" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Temperature and Precipitation - Climate Drivers of Recent Wildfires</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Variables:</b> <i>Mean Spring (March–May) Precipitation; Mean Summer (June–August) Precipitation; Maximum
                    Summer (June–August) Temperature</i>
                  </p>
                  <p>
                    <b>Finding:</b> In recent years, Spokane has experienced wetter springs followed by drier, hotter summers.
                  </p>
                  <p>
                    <b>Justification:</b> The climate data made available through the Climate Toolbox provides evidence that from 1979 to
                    2019, Spokane has seen a trend of wetter springs (<b>Figure 1</b>), followed by drier (<b>Figure 2</b>), hotter (<b>Figure 3</b>)
                    summers.
                  </p>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="March May Precipitation" src="/media/fire/Fig-1-Fire.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 1:</b> Variations in spring (March–May) precipitation (in inches) with trend line for Spokane, Washington for the years
                            1979–2019. Red bars represent precipitation values below the historical mean for the years 1979–2019. Blue bars represent
                            precipitation values above the historical mean. Source: Historical Climate Tracker Tool
                            (<a href="https://climatetoolbox.org/tool/Historical-Climate-Tracker">https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>), The Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="June August Precipitation" src="/media/fire/Fig-2-Fire.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 2:</b> Variations in summer (June–August) precipitation (in inches) with trend line for Spokane, Washington for the years
                            1979–2019. Blue bars show precipitation values above the historical mean. Red bars represent precipitation values below the
                            historical mean. Source: The Historical Climate Tracker Tool (https://climatetoolbox.org/tool/Historical-Climate-Tracker), The
                            Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="June August Precipitation" src="/media/fire/Fig-3-Fire.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 3:</b> Variations in summer (June–August) maximum temperature (in degrees Fahrenheit) with trend line for Spokane,
                            Washington for the years 1979–2019. Blue bars represent temperatures numbers below the historical mean for the years 1979–
                            2019. Red bars represent temperature numbers above the historical mean. Source: The Historical Climate Tracker Tool
                            (<a href="https://climatetoolbox.org/tool/Historical-Climate-Tracker">https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>), The Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="historical-fires" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Historical Fires — How Climate Conditions Aided Recent Fires</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Variable:</b> <i>Energy Release Component</i>
                  </p>
                  <p>
                    <b>Finding:</b> Large fire seasons occurred in the Spokane region during 2017 and 2018, two years with wetter springs
                    followed by hotter, drier summers.
                  </p>
                  <p>
                    <b>Justification:</b> In recent years, Spokane has seen an increase in spring precipitation followed by a decrease in
                    summer precipitation and increase in summer temperatures (see <b>Figures 1–3</b> above). Drier, warmer summers appear
                    to have set the stage, so to speak, for the two very active fire seasons that occurred during 2017 and 2018. For the
                    purposes of our analysis we will focus on just the 2017 fire season.
                  </p>
                  <p>
                    <b>The 2017 Fire Season:</b> The fire season of 2017 was preceded by a very wet spring. During the months of January to March, Spokane saw
                    11.7 inches of rain, well above the historical average of just under 6 inches (<b>Figure 1</b>). The winter of 2016–2017
                    (not showed here) also saw above-normal precipitation. However, the summer conditions that followed were
                    especially dry (<b>Figure 2</b>) and warm (<b>Figure 3</b>). What was true of Spokane was also true of the US Pacific
                    Northwest generally (<b>Abatzoglou, CIRCulator, 2017</b>).
                  </p>
                  <p>
                    <b>Figure 4</b> displays the variable the Energy Release Component (ERC) for the whole Pacific Northwest for August
                    6th, 2017. ERC calculates how hot a fire could burn given recent climate and weather conditions. The figure
                    compares ERC conditions during the summer of 2017 to ERC conditions to all summers over the historical period
                    1979–2015 and ranks them accordingly by percentile. During August 2017, much of the Pacific Northwest was in
                    the 94th percentile or higher for ERC. This means that much for the region was ready to burn, and much of it did.
                    Active wildfires at the time the figure was created in August 2017 are shown on the map as fire icons (<b>Abatzoglou, CIRCulator, 2017</b>).
                  </p>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="energy release component percentile" src="/media/fire/Fig-4-Fire.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 4:</b> Energy Release Component (ERC) mapped for the Pacific Northwest United States for August 6, 2017 along with
                              active US fire perimeters. Sources: “Fire Season is Here!,”The Climate CIRCulator, 2017
                              (<a href="https://climatecirculatororg.wordpress.com/2017/08/08/a-look-at-this-years-fire-season-using-the-northwest-climate-toolbox/">https://climatecirculatororg.wordpress.com/2017/08/08/a-look-at-this-years-fire-season-using-the-northwest-climate-toolbox/</a>);
                              The Climate Mapper Tool (<a href="https://climatetoolbox.org/tool/Climate-Mapper">https://climatetoolbox.org/tool/Climate-Mapper</a>), The Climate Toolbox; Geospatial Multi-Agency
                              Coordination Wildland Fire Support (GeoMAC) data displaying active fire perimeters (<a href="https://www.geomac.gov/">https://www.geomac.gov/</a>).
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="air-quality-index" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>The Air Quality Index and Sensitive Groups</h4>
                </div>
                <div className="card-body">
                  <p>
                    The United States Environmental Protection Agency (EPA) reports daily air quality values through the Air Quality
                    Index (AQI) at airnow.gov. AQI provides an indication of how healthy or unhealthy air is. The AQI scale also
                    indicates what associated health effects might be of concern and to whom based on AQI values. The AQI ranges
                    from 0 to 500. Higher AQI values indicate greater levels of air pollution as well as the degree of health concern.
                    Health concern is rated from Good (an AQI value to 0–50) to Hazardous (an AQI value of 301–500). AQI values
                    below 100 are generally considered to be satisfactory. AQI values of 100 or more generally correspond to the
                    national air quality standard for a pollutant, which is the level the EPA has set to protect public health. When AQI
                    values are above 100, air quality is considered to be unhealthy. The first group affected by unhealthy AQI values are
                    sensitive groups of people (<b>“Air Quality Index (AQI) Basics” Air Now 2019</b>). Sensitive groups include: children,
                    older adults, people with asthma, people with heart and lung disease, and people sensitive to air pollution, including
                    people sensitive to ozone and particulates (<b>EPA 2006</b>). Air with an AQI value of 101–150 is considered unhealthy
                    for sensitive groups. AQI values that exceed 150 are designated unhealthy for everyone, not just sensitive groups.
                    During an event designated unhealthy, everyone may begin to experience some adverse health effects, and members
                    of sensitive groups may experience more serious effects (<b>“Air Quality Index (AQI) Basics” Air Now 2019</b>). When
                    AQI values are designated very healthy (201–, a health alert is triggered (<b>“Air Quality Index—A Guide to Air Quality and Your Health” Air Now, 2019). Table 1</b>, 
                    taken from airnow.gov, shows the color-coded AQI values
                    used by the EPA to determine air quality.
                  </p>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="air-qualit-index" src="/media/fire/Table-1-Fire.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Table 1:</b> The Air Quality Index (AQI) is broken up into six color-coded categories that illustrate how AQI values
                            relate to health concerns and the individuals who are likely to be affected. Source: AirNow
                            (<a href="https://airnow.gov/index.cfm?action=aqibasics.aqi">https://airnow.gov/index.cfm?action=aqibasics.aqi</a>).
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="historical-smoke-wildfires" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Historical Smoke — How Smoke From Wildfires Affected Spokane’s Air Quality In
                  September 2017</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Variables:</b> <i>Air Quality Index</i>
                  </p>
                  <p>
                    <b>Finding:</b> Fires during September 2017 led to unhealthy air quality in Spokane and its surrounding region.
                  </p>
                  <p>
                    <b>Justification:</b> During September 2017, wildfires were very active in the forests surrounding Spokane (<b>Brunt 2017</b>).
                    It is important to note here that our analysis did not attempt to track how and whether smoke from specific fires were
                    carried by winds to Spokane. However, the number of active wildfires considered alongside air quality data
                    available at the time of those wildfires can allow us to make the reasonable inference that smoke from surrounding
                    wildfires affected Spokane’s air quality in September 2017. <b>Figure 5</b> shows the EPA’s Air Quality Index (AQI) for
                    September 7th, 2017. On September 7th, Spokane was color-coded purple, designating that air in Spokane was very
                    unhealthy (an AQI of 201–300). The AQI designation of very unhealthy means that air in Spokane on September
                    7th, 2017 was unhealthy for all individuals to be outside, not just sensitive groups (<b>“Air Quality Index Basics” Air Now 2019</b>).
                  </p>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Daily Combined AQI" src="/media/fire/Fig-5-Fire.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 5:</b> Air Quality Index (AQI) values for September 7th, 2017 for the United States Pacific Northwest. Note: Spokane,
                            Washington and much of the city’s surrounding areas are color-coded purple, or “very unhealthy,” an AQI value of 201–300.
                            Source: AirNow (airnow.gov).
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="historical-smoke-air-quality" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Historical Smoke — Unhealthy Air Quality in Spokane During 2017 and 2018</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Variables:</b> <i>Air Quality Index</i>
                  </p>
                  <p>
                    <b>Finding:</b> Spokane saw an increase in unhealthy and very unhealthy air quality during the fire seasons of 2017 and 2018.
                  </p>
                  <p>
                    <b>Finding:</b> Air Quality Index (AQI) values during the fire seasons of 2017 and 2018 are clear outliers when compared
                    to AQI values for the years 1999–2018. During the fire season of 2017, Spokane experienced 18 days with AQI
                    values designated unhealthy for sensitive groups or higher. During the fire season of 2018, Spokane experienced 20
                    days with AQI values designated unhealthy for sensitive groups or higher.
                  </p>
                  <p>
                    <b>Justification:</b> So far, our analysis has looked at the air quality during just one day (September 7th, 2017) during just
                    one fire season (the fire season of 2017). In this section, we examine air quality data using the EPA’s Air Quality
                    Index (AQI) over several recent decades. <b>Figure 6</b> shows AQI designations for Spokane, Washington for all months
                    of the year from 1999 to 2019. (Data for the 2019 fire season was available only through March at the time of this
                    report.) Most of <b>Figure 6</b> is designated as green (good, AQI of 0–50 ) to yellow (moderate, AQI of 51–100). The
                    prevalence of moderate AQI ratings (yellow) during the fall and winter months is likely due to wood-burning stoves
                    and persistent inversions. If we focus in on recent summers, a new trend emerges. The summer months from 2014 to
                    2018 show unhealthy (red, AQI 151–200) air quality in Spokane. Air that is designated unhealthy is considered to
                    be unhealthy for all groups, according the AQI rating system. If we parse this data still further, the trend becomes
                    clearer.
                  </p>
                  <p>
                    <b>Figure 7</b> shows annual number of days with AQI designations with a rating of unhealthy for sensitive groups
                    (orange, AQI 101–150) and above for Spokane for the years 1999–2018. As the figure illustrates, from 1999 to
                    2018, Spokane saw not only an increase in days designated as unhealthy for sensitive groups (orange, AQI 101–
                    150), the city also saw an increase in the number unhealthy (red, AQI 151–200), and very unhealthy (purple, AQI
                    201–300) days. Of particular interest are the years 2017 and 2018. AQI ratings for 2017 and 2018 are clear outliers.
                    During the fire season of 2017, Spokane experienced 10 days designated as unhealthy for sensitive groups (orange),
                    6 days designated unhealthy (for all groups) (red), and 3 days designated as and very unhealthy (purple), for a total
                    of 19 days, nearly two-and-a-half weeks. During the fire season of 2018, Spokane experienced 9 days designated at
                    unhealthy for sensitive groups (orange), 10 days designated unhealthy for all groups (red), and 1 day designated as
                    very unhealthy for all groups (purple), for a total of 20 days, or nearly three weeks.
                  </p>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Daily AQI Values" src="/media/fire/fig-6-aqi-fire.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 6:</b> Daily Air Quality Index (AQI) values (color-coded by small PM2.5 particulates in the air) for the Spokane Valley for
                            the years 1999–2019. Note: data for the 2019 fire season as unavailable at the time this report was written. Source: EPA Outdoor
                            Air Quality Data (<a href='https://www.epa.gov/outdoor-air-quality-data/air-data-multiyear-tile-plot'>https://www.epa.gov/outdoor-air-quality-data/air-data-multiyear-tile-plot</a>) .
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Annual days with pm 2.5" src="/media/fire/Fig-7-Fire.png"></img>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 7:</b> The annual number of days from 2000–2018 during which Air Quality Index (AQI) values in the Spokane Valley were
                            designated as unhealthy for sensitive groups or higher. Source: EPA Outdoor Air Quality Data.
                            (<a href="https://www.epa.gov/outdoor-air-quality-data/air-quality-index-report">https://www.epa.gov/outdoor-air-quality-data/air-quality-index-report</a>).
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="future-climate-temperature" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Future Climate — Temperature and Precipitation, Drivers of Future Fire Risk</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Variables:</b> <i>Mean Summer (June–August) Precipitation, Maximum Summer (June–August) Temperature</i>
                  </p>
                  <p>
                    <b>Finding:</b> Climate projections indicate that the Spokane region will see drier, warmer summers throughout this century.
                  </p>
                  <p>
                    <b>Justification:</b> To determine if wildfires pose a continuing threat to Spokane and its surrounding forests, our team
                    first sought to determine if the climate trend that was a major factor in recent forest fires, namely drier, warmer
                    summers, is likely to continue in the future. Using the downscaled climate data provided by the Climate Toolbox,
                    our team examined future projections for Spokane to the year 2099 for summer (June–August) precipitation
                    (<b>Figures 8 and 9</b>) and temperature (<b>Figure 10 and 11</b>). Summer temperature and precipitation were examined under
                    both the lower emissions scenario (RCP 4.5) (<b>Figures 8 and 10</b>) the high emissions scenario (RCP 8.5) (<b>Figures 9 and 11</b>). 
                    The data from the Toolbox clearly shows that under both RCP 4.5 and RCP 8.5 Spokane is projected to see
                    drier, warmer summers compared to the historical baseline 1971–2000.
                  </p>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="June July Aug Precipitation" src="/media/fire/Fig-9-Fire.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 8:</b> Summer (June–August) mean precipitation (in inches) under the lower emissions future (RCP 4.5) for Spokane,
                            Washington. The results of each of the 20 models used in the analysis are represented by individual points. The multi-model
                            mean is indicated by a solid bar. Source: Future Boxplots Tool (https://climatetoolbox.org/tool/Future-Boxplots), The Climate
                            Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Daily AQI Values" src="/media/fire/Fig-10-Fire.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 9:</b> Summer (June–August) mean precipitation (in inches) under the high emissions future (RCP 8.5) for Spokane,
                            Washington. The results of each of the 20 models used in the analysis are represented by individual points. The multi-model
                            mean is indicated by a solid bar. Source: Future Boxplots Tool (https://climatetoolbox.org/tool/Future-Boxplots), The Climate
                            Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Daily AQI Values" src="/media/fire/Fig-11-Fire.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 10:</b> Summer (June–August) maximum temperature (in degrees Fahrenheit) under the lower emissions future (RCP 4.5)
                            for Spokane, Washington. The results of each of the 20 models used in the analysis are represented by individual points. The
                            multi-model mean is indicated by a solid bar. Source: Future Boxplots Tool (https://climatetoolbox.org/tool/Future-Boxplots),
                            The Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Daily AQI Values" src="/media/fire/Fig-12-Fire.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 11:</b> Summer (June–August) maximum temperature (in degrees Fahrenheit) under the high emissions future (RCP 8.5) for
                            Spokane, Washington. The multi-model mean is indicated by a solid bar. The results of each of the 20 models used in the analysis
                            are represented by individual points. The multi-model mean is indicated by a solid bar. Source: Future Boxplots Tool
                            (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="future-climate-more-extreme" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Future Climate — More Extreme Fire Danger Days Projected</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Variables:</b> <i>Extreme Fire Danger Days</i>
                  </p>
                  <p>
                    <b>Finding:</b> During both early century (2010–2039) and mid-century (2040–2069), all 12 national forests surrounding
                    Spokane are projected to see increases in the number of Extreme Fire Danger Days each year under both the lower
                    emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5).
                  </p>
                  <p>
                    <b>Finding:</b> All 12 Spokane-area national forests are projected to see more Extreme Fire Danger Days under the high
                    emissions scenario (RCP 8.5) compared to the lower emissions scenario (RCP 4.5) during both early century and
                    mid-century.
                  </p>
                  <p>
                    <b>Justification:</b> The large number of forests surrounding Spokane means active wildfires in these forests can send
                    smoke Spokane’s way. For this section of our analysis, we considered wildfire activity in the 12 national forests
                    surrounding Spokane as a proxy for burning in Spokane-area forests generally. To do this, we examined projected
                    future fire conditions using the variable Extreme Fire Danger Days. Based on the National Fire Danger Rating
                    System, Extreme Fire Danger Days are classified in the Toolbox as calendar days where the 100-hour Fuel Moisture
                    Index variable has been ranked as below the historical 3rd percentile of years compared to the historical baseline
                    1971–2000. These are days when fuels are very dry—on average, the 11th driest fuels of each year. The 100-hr Fuel
                    Moisture Index, also part of the National Fire Danger Rating System (<b>Scholbohm and Brain 2002</b>), is commonly
                    used to inform wildfire potential
                  </p>
                  <p>
                    <b>Table 2</b> below lists the 12 Spokane-area national forests used in our analysis, the number of forested acres
                    potentially affected by future wildfires, the cardinal direction of each national forest from Spokane, and the number
                    of Extreme Fire Danger Days each forest experienced during the historical period 1981–2010, and the projected
                    future number of Extreme Fire Danger Days each forest is likely to experience each year during early century
                    (2010–2039) and mid-century (2040-2069) under both RCP 4.5 and RCP 8.5. Note: Extreme Fire Danger Days data
                    was not available for late century (2070–2099). The location of the natural forests were identified using a single
                    point location to represent the entire forest. The numbers below represent the multi-model mean from 18
                    downscaled global climate models. During both early century and mid-century all 12 Spokane-area national forests
                    are projected to see an increase in the number of Extreme Fire Danger Days each year under both RCP 4.5 and RCP
                    8.5. All 12 national forests are projected to see more Extreme Fire Danger Days under the RCP 8.5 when compared
                    to RCP 4.5. While this trend is observable at early century, it becomes more pronounced at mid-century. To use just
                    one forest as an example, during early century, the Okanogan-Wenatchee National Forest is projected to experience
                    13.4 Extreme Fire Danger Days under RCP 4.5 and 15.9 Extreme Fire Danger Days under RCP 8.5. At mid-century,
                    the Okanogan-Wenatchee National Forest is projected to experience 19.3 Extreme Fire Danger Days under RCP 4.5
                    and 23.5 Extreme Fire Danger Days under RCP 8.5.
                  </p>
                  <p>
                    <i>
                      Table 2: Extreme Fire Danger Days annually for the 12 national forests surrounding Spokane, Washington calculated for the
                      historical period 1971–2010, and projected future dates 2010–2039 (early century) and 2040–2069 (mid-century) for both the
                      lower emissions scenario (RCP 4.5) and the high emissions scenario RCP 8.5. The table also includes the cardinal direction of
                      the forests from Spokane as well as the number of acres (in millions of acres) that could be affected by projected future wildfires.
                      Data represents the multi-model mean of 18 downscaled global climate models. Source: The Climate Mapper Tool
                      (<a href="https://climatetoolbox.org/tool/Climate-Mapper">https://climatetoolbox.org/tool/Climate-Mapper</a>), The Climate Toolbox.
                    </i>
                  </p>
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Extreme Fire Danger Days Annually</th>
                        <th scope="col">Extreme Fire Danger Days Annually</th>
                        <th scope="col">Extreme Fire Danger Days Annually</th>
                        <th scope="col">Extreme Fire Danger Days Annually</th>
                        <th scope="col">Extreme Fire Danger Days Annually</th>
                      </tr>
                      <tr>
                        <th scope="col">National Forest</th>
                        <th scope="col">Direction from Spokane</th>
                        <th scope="col">Area (million acres)</th>
                        <th scope="col">Historical, 1971 - 2010</th>
                        <th scope="col">RCP 4.5, Early Century, 2010 - 2039</th>
                        <th scope="col">RCP 8.5, Early Century, 2010 - 2039</th>
                        <th scope="col">RCP 4.5, Mid Century, 2040 - 2069</th>
                        <th scope="col">RCP 8.5, Mid Century, 2040 - 2069</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Colville</th>
                        <td>North</td>
                        <td>1.1</td>
                        <td>11 days</td>
                        <td>13.2 days</td>
                        <td>15.1 days</td>
                        <td>18.1 days</td>
                        <td>22.2 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Umatilla</th>
                        <td>South</td>
                        <td>1.4</td>
                        <td>11 days</td>
                        <td>12.8 days</td>
                        <td>14.3 days</td>
                        <td>17.8 days</td>
                        <td>21.4 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Wallowa-Whitman</th>
                        <td>South</td>
                        <td>2.3</td>
                        <td>11 days</td>
                        <td>14.5 days</td>
                        <td>16.9 days</td>
                        <td>19.8 days</td>
                        <td>24.2 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Okanogan-Wenatchee</th>
                        <td>West</td>
                        <td>1.7</td>
                        <td>11 days</td>
                        <td>13.4 days</td>
                        <td>15.9 days</td>
                        <td>19.3 days</td>
                        <td>23.5 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Mt. Hood</th>
                        <td>Southwest</td>
                        <td>1.1</td>
                        <td>11 days</td>
                        <td>11.7 days</td>
                        <td>14 days</td>
                        <td>16.3 days</td>
                        <td>19 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Willamette</th>
                        <td>Southwest</td>
                        <td>1.6</td>
                        <td>11 days</td>
                        <td>11.4 days</td>
                        <td>12.9 days</td>
                        <td>15 days</td>
                        <td>17.4 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Gifford Pinchot</th>
                        <td>Southwest</td>
                        <td>1.3</td>
                        <td>11 days</td>
                        <td>12.8 days</td>
                        <td>15.2 days</td>
                        <td>17.7 days</td>
                        <td>21.4 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Kootenai</th>
                        <td>East</td>
                        <td>2.2</td>
                        <td>11 days</td>
                        <td>13.3 days</td>
                        <td>15.4 days</td>
                        <td>18.2 days</td>
                        <td>22.8 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Flathead</th>
                        <td>East</td>
                        <td>2.4</td>
                        <td>11 days</td>
                        <td>13.2 days</td>
                        <td>14.5 days</td>
                        <td>17.8 days</td>
                        <td>21.3 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Lolo</th>
                        <td>Southeast</td>
                        <td>2.2</td>
                        <td>11 days</td>
                        <td>13.8 days</td>
                        <td>14.7 days</td>
                        <td>18.8 days</td>
                        <td>22.6 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Nez Perce-Clearwater</th>
                        <td>Southeast</td>
                        <td>2.6</td>
                        <td>11 days</td>
                        <td>14 days</td>
                        <td>15.8 days</td>
                        <td>19 days</td>
                        <td>22.6 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Payette</th>
                        <td>Southeast</td>
                        <td>2.3</td>
                        <td>11 days</td>
                        <td>13.9 days</td>
                        <td>15.2 days</td>
                        <td>18.8 days</td>
                        <td>23 days</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="future-climate-lengthening-fire-season" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Future Climate — Lengthening of the Fire Season</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Variables:</b> <i>Very High Fire Danger Days; Extreme Fire Danger Days</i>
                  </p>
                  <p>
                    <b>Finding:</b> The nearby Coeur d’Alene National Forest is projected to see an increase in the length of its fire season,
                    indicating a potentially longer smoke season for Spokane.
                  </p>
                  <p>
                    <b>Justification:</b> Following seasonal changes in precipitation and temperature, Spokane’s fire season generally runs
                    from the middle of the summer months to the start of the fall months. Because the timing of the start and the end of
                    the fire season is important for determining the number of days each year that Spokane could experience both the
                    threat of wildfires in its surrounding forests and smoke from ignited fires, our team choose to look at how the fire
                    season might change under projected future climate changes. To streamline our analysis, we examined a single
                    forest, the Coeur d’Alene National Forest. Our analysis examined annual as well as monthly projections of fire
                    danger under both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5) near Wallace
                    Idaho, which includes the Coeur d’Alene National Forest. To determine the risk of fire danger, we examined
                    projections for both <i>Extreme Fire Danger Days</i> (days when the 100-hour Fuel Moisture Index is below the historical 3
                    rd percentile of years compared to the historical baseline 1971–2000) and <i>Very High Fire Danger Days</i> (days when
                    the 100-hour Fuel Moisture Index is below the historical 10th percentile of years).
                  </p>
                  <p>
                    <b>Table 3</b> below displays the number of <i>Very High Fire Danger Days</i> and <i>Extreme Fire Danger Days</i> projected for
                    Wallace, Idaho, and by extension the Coeur d’Alene National Forest, for both early century (2010–2039) and mid-
                    century (2040–2069) under both RCP 4.5 and RCP 8.5. The table clearly shows that the length of the fire season is
                    projected to grow at both early century and mid-century. Note: the normally wet months of January–February and
                    November–December are not displayed in the table as these months effectively see zero days for either of the two
                    variables used in this analysis. Data for this table displays the multi-model mean from 20 downscaled global climate
                    models and was accessed from the MACAv2-METDATA dataset. If we examine this data as a figure, the trend can
                    be seen as well.
                  </p>
                  <p>
                    <b>Figure 12</b> shows the projected future lengthening of the fire season at Wallace, Idaho, and by extension the Coeur
                    d’Alene National Forest, as measured in the number of Extreme Fire Danger days projected to occur each month
                    under RCP 8.5 during early century (2010–2039) and mid-century (2040–2069) when compared to the historical fire
                    season (1971–2000). Note: this analysis used the high emissions scenario exclusively. The projected early century
                    fire season is shown in orange. The projected mid-century fire season is shown in gray. The historical fires season is
                    shown in blue.
                  </p>
                  <p>
                    If we make the reasonable assumption that the lengthening of the season during which conditions will be ideal for
                    wildfires entails fires will likely be ignited, then taken together <b>Table 3</b> and <b>Figure 12</b> indicate that Spokane is
                    likely to experience a potentially longer smoke season in the decades ahead.
                  </p>
                  <p>
                    <i>
                      Table 3: Future projections of High and Extreme Fire Danger Days for each month historically (1971–2000) and under the
                      lower emissions scenario (RCP 4.5) and high emissions future (RCP 8.5) for the early century (2010–2039) and mid-century
                      (204–2069) for Wallace, Idaho (Coeur d’Alene National Forest). The table displays the multi-model mean resulting from 20
                      downscaled global climate models. Note: the months Jan¬–Feb and Nov¬¬–Dec are not shown as they had essentially 0 days in
                      all categories. Source: Future Boxplots Tool https://climatetoolbox.org/tool/future-boxplots), The Climate Toolbox.
                    </i>
                  </p>
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col" colSpan="2">Historical 1971 - 2000</th>
                        <th scope="col" colSpan="4">Early Century 2010 - 2039</th>
                        <th scope="col" colSpan="4">Mid-Century 2040 - 2069</th>
                      </tr>
                      <tr>
                        <th scope="col">Month</th>
                        <th scope="col" colSpan="2">Avg. Historical Fire Danger Days</th>
                        <th scope="col" colSpan="2">RCP 4.5</th>
                        <th scope="col" colSpan="2">RCP 8.5</th>
                        <th scope="col" colSpan="2">RCP 4.5</th>
                        <th scope="col" colSpan="2">RCP 8.5</th>
                      </tr>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Very high (days)</th>
                        <th scope="col">Extreme (days)</th>
                        <th scope="col">Very high (days)</th>
                        <th scope="col">Extreme (days)</th>
                        <th scope="col">Very high (days)</th>
                        <th scope="col">Extreme (days)</th>
                        <th scope="col">Very high (days)</th>
                        <th scope="col">Extreme (days)</th>
                        <th scope="col">Very high (days)</th>
                        <th scope="col">Extreme (days)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Annual</th>
                        <td className="table-success">73</td>
                        <td className="table-success">11</td>
                        <td className="table-warning">77.67</td>
                        <td className="table-warning">14</td>
                        <td className="table-warning">79.3</td>
                        <td className="table-warning">16</td>
                        <td className="table-danger">84.1</td>
                        <td className="table-danger">19.1</td>
                        <td className="table-danger">87.2</td>
                        <td className="table-danger">23.5</td>
                      </tr>
                      <tr>
                        <th scope="row">March</th>
                        <td className="table-success">0.1</td>
                        <td className="table-success">0</td>
                        <td className="table-warning">0.1</td>
                        <td className="table-warning">0</td>
                        <td className="table-warning">0.1</td>
                        <td className="table-warning">0</td>
                        <td className="table-danger">0.3</td>
                        <td className="table-danger">0</td>
                        <td className="table-danger">0.5</td>
                        <td className="table-danger">0</td>
                      </tr>
                      <tr>
                        <th scope="row">April</th>
                        <td className="table-success">1.8</td>
                        <td className="table-success">0</td>
                        <td className="table-warning">1.8</td>
                        <td className="table-warning">0</td>
                        <td className="table-warning">2.3</td>
                        <td className="table-warning">0</td>
                        <td className="table-danger">2.3</td>
                        <td className="table-danger">0</td>
                        <td className="table-danger">2.0</td>
                        <td className="table-danger">0</td>
                      </tr>
                      <tr>
                        <th scope="row">May</th>
                        <td className="table-success">6.4</td>
                        <td className="table-success">0.1</td>
                        <td className="table-warning">6.5</td>
                        <td className="table-warning">0.1</td>
                        <td className="table-warning">7.0</td>
                        <td className="table-warning">0.2</td>
                        <td className="table-danger">7.6</td>
                        <td className="table-danger">0.3</td>
                        <td className="table-danger">7.4</td>
                        <td className="table-danger">0.4</td>
                      </tr>
                      <tr>
                        <th scope="row">June</th>
                        <td className="table-success">10</td>
                        <td className="table-success">0.4</td>
                        <td className="table-warning">11.1</td>
                        <td className="table-warning">0.7</td>
                        <td className="table-warning">11.5</td>
                        <td className="table-warning">0.8</td>
                        <td className="table-danger">12.4</td>
                        <td className="table-danger">1</td>
                        <td className="table-danger">12.8</td>
                        <td className="table-danger">1.4</td>
                      </tr>
                      <tr>
                        <th scope="row">July</th>
                        <td className="table-success">22.2</td>
                        <td className="table-success">5.4</td>
                        <td className="table-warning">22.6</td>
                        <td className="table-warning">6.3</td>
                        <td className="table-warning">23.2</td>
                        <td className="table-warning">7.1</td>
                        <td className="table-danger">24</td>
                        <td className="table-danger">8.6</td>
                        <td className="table-danger">24.5</td>
                        <td className="table-danger">10.2</td>
                      </tr>
                      <tr>
                        <th scope="row">August</th>
                        <td className="table-success">22.2</td>
                        <td className="table-success">4.8</td>
                        <td className="table-warning">22.6</td>
                        <td className="table-warning">6.4</td>
                        <td className="table-warning">22.5</td>
                        <td className="table-warning">7.2</td>
                        <td className="table-danger">23</td>
                        <td className="table-danger">8.4</td>
                        <td className="table-danger">24.2</td>
                        <td className="table-danger">10.1</td>
                      </tr>
                      <tr>
                        <th scope="row">September</th>
                        <td className="table-success">9.3</td>
                        <td className="table-success">0.4</td>
                        <td className="table-warning">10.3</td>
                        <td className="table-warning">0.5</td>
                        <td className="table-warning">10.8</td>
                        <td className="table-warning">0.8</td>
                        <td className="table-danger">11.1</td>
                        <td className="table-danger">0.8</td>
                        <td className="table-danger">12.8</td>
                        <td className="table-danger">1.3</td>
                      </tr>
                      <tr>
                        <th scope="row">October</th>
                        <td className="table-success">1.7</td>
                        <td className="table-success">0</td>
                        <td className="table-warning">2.2</td>
                        <td className="table-warning">0</td>
                        <td className="table-warning">2.1</td>
                        <td className="table-warning">0</td>
                        <td className="table-danger">2.3</td>
                        <td className="table-danger">0</td>
                        <td className="table-danger">2.7</td>
                        <td className="table-danger">0</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Daily Combined AQI" src="/media/fire/Fig-13-Fire.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 12:</b>Future projections of number of Extreme Fire Danger Days by month under the high emissions future (RCP 8.5) for
                            Wallace, Idaho (Coeur d’Alene National Forest) for early century (2010–2039) and mid-century (2040–2069) compared the
                            historical period (1971–2000). Source: Future Climate Boxplots web tool (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The
                            Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="conclusion" className="card border-dark bg-light mb-3">
                <div className="card-header"><h4>Conclusion - Smoke Risk for Spokane is Projected to Increase Throughout This Century</h4></div>
                <div className="card-body">
                  <p>
                    Our analysis shows that under both the lower emissions scenario (RCP 4.5) and high emissions scenario (RCP 8.5),
                    climate projections indicate that the Spokane region will continue to see wetter springs, and warmer, drier summers
                    throughout this century. Our analysis further shows that these climate conditions will lead to a higher likelihood of
                    wildfires in the forests surrounding Spokane generally as well as a lengthening of the local fire season. It is highly
                    likely that an increase in wildfire risk will lead to an increase in the number of ignited fires. If we use Air Quality
                    Index (AQI) values observed during Spokane’s active fire seasons of 2017 and 2018 as analog for our future under
                    climate change, we can infer that the projected increased risk of wildfires will very likely lead to reduced air quality
                    in Spokane in the decades ahead. If AQI values during the fire seasons of 2017 and 2018 are any guide to the future,
                    both sensitive groups (as defined by the Environmental Protection Agency) and the population generally will
                    experience health impacts due to smoke from wildfires. Because the fire risk under RCP 8.5 is greater when
                    compared to RCP 4.5, we can further infer that Spokane’s air quality will be more harmful under RCP 8.5 when
                    compared to RCP 4.5. This implies an incentive to reduce emissions.
                  </p>
                  <p>
                    There are preventative and adaptive steps that the Spokane region can take to mitigate the impacts of both wildfires
                    and smoke in the region. These steps could be taken through city management, forest management, and civic
                    engagement, as well as aiding sensitive groups (as defined by Environmental Protection Agency).
                      </p>
                  <p>
                    As a community, we need to prepare for increased risk of fire within and near to Spokane and the increased risk of
                    smoke and ash to Spokane from surrounding fires. City planners, school districts, employers, healthcare workers,
                    elected officials, and other community decision-makers will be required to create new policies and adaptation
                    measures to lessen the adverse health effects of smoke to all Spokane residents as well as Spokane’s sensitive
                    groups. We therefore recommend the following resilience actions.
                  </p>
                  <div className="card border-dark m-3">
                    <div className="card-header text-white bg-success p-2 card-header-display">
                      <h4 className="col-10 card-header-title ">Recommended Resiliency Actions</h4>
                    </div>
                    <div className="card-body p-0">
                      <ol className="list-group">
                        <li className="list-group-item list-group-item-success">
                          <b>Prepare for Increased Fire, Smoke, and Ash:</b> Spokane emergency planners need to adopt policies and
                          adaptation strategies that help Spokane and the surrounding region prepare for the increased risk of fire,
                          smoke, and ash dangers. This could include strategies for monitoring air quality related to outdoor activities
                          (including school recesses) and, when necessary, issuing particulate masks at large outdoor events.
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Air Quality Shelters:</b> The creation of “air quality shelters” should be considered for sensitive groups as
                          well as the population generally. This could include providing large public spaces (school gyms,
                          community centers, etc.) with high quality air filtration systems.
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Forest Management:</b> Forests in and around Spokane should be managed to reduce the amount of fuel
                          available. This could be done through various management techniques, including forest thinning and
                          prescribed burning.
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Outreach:</b> Educational outreach campaigns should be created that:
                          <ul>
                            <li>
                              Ensure the public understands why fire management strategies, such as prescribed burns are being
                              used;
                            </li>
                            <li>
                              Encourage voluntary compliance with fire-safe housing recommendations (clear space around
                              homes and structures);
                            </li>
                            <li>
                              And that clearly communicate the health risks associated with smoke and ash.
                            </li>
                          </ul>
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Regulations:</b> New regulations should be designed to increase defensible areas around structures for
                          business and homeowners in the wildland/urban interface.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div id="recommmended-resiliency-actions-more-detail" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Recommended Resiliency Actions in More Detail</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Forest Management</b>: National forests are managed by the US Forest Service, while forests in state parks are
                    managed by state government. Forest management focuses on managing vegetation, restoring ecosystems, reducing
                    hazards, and maintaining forest health. Some techniques for management include thinning of forests to reduce the
                    amount of fuel for a fire, prescribed controlled burns to reduce dangerous accumulations of combustible fuel, and
                    creating defensible regions in the forest where fires can stall or slow down significantly. Wildfires that burn into
                    areas where fuels have been reduced by prescribed burning cause less damage and are much easier to control
                    <b>(Georgia Forestry Commission 1989) (Southern Group of State Foresters 2019)</b>.
                  </p>
                  <p>
                    With controlled burns, there is the potential of a large fire starting if the controlled burn gets out of control.
                    Controlled burns contribute to unhealthy air quality so that the public is not generally in favor of these (<b>Schultz et al., 2018</b>). 
                    However, a trade off of slightly unhealthy air quality prior to and following the core fire season to
                    prevent very unhealthy air and destructive wildfires during the fire season may be more acceptable to the public,
                    particularly when prescribed burns are well-communicated in advance (<b>Blades et al., 2014</b>).
                  </p>
                  <p>
                    Selective logging of small-diameter trees, such as for use as Cross-Laminated-Timber (CLT) products, may reduce
                    fuel for wildfires. Sensible approaches to forest thinning may reduce wildfire risk while offering opportunities for
                    regional economic development.
                  </p>
                  <p>
                    <b>City Management:</b> Nationally, human development has increasingly encroached into the wildland-urban interface,
                    (<b>Pierre-Louis and White 2018</b>) where Spokane is no exception. The wildland-urban interface is a place where
                    humans and their development meet or intermix with wildland fuel (i.e. forests and dead forest litter). As climate
                    change drives an increase in large wildland fires, fire protection costs of property have soared (<b>Cleetus and Mulik 2014</b>). 
                    Spokane city management can help to decrease the risk of large fire protection costs during a fire to county
                    residential homes, businesses and infrastructure by zoning to restrict the number of homes that are built in the
                    wildland-urban interface or by requiring that property owners maintain a defensible space around their structures
                    and that neighborhoods built on this interface create many regions within and on the borders of their neighborhood
                    that are defensible and would slow down the spread of wildfire through the neighborhoods. Key city infrastructure,
                    such as electricity lines, could be moved to more safe locations, such as underground, to help prevent infrastructure
                    damage in fires.
                  </p>
                  <p>
                    <b>Civic Management:</b> We can work together to make a difference in the life of our communities by developing a
                    heightened awareness on the local risk of fire. This can be accomplished through roadside signs indicating fire
                    danger, such as is already present in the national forests, or reminders to clear a sufficient area of land around homes
                    (<b>Ready for Wildfire 2019</b>). Local news stations can also do their part in making such monitoring part of their local
                    weather segments so that there is a heightened awareness when conditions are ripe for fire.
                  </p>
                </div>
              </div>
              <div id="future-work" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Future Work</h4>
                </div>
                <div className="card-body">
                  <p>
                    As noted above, several impacts related to wildfires were beyond the scope of this inquiry. These includes: impacts
                    to agricultural production (through reduced crop photosynthesis due to smoke cover); impacts to energy (through
                    reduced effectiveness of ash covered solar panels); impacts to local ecosystem services (such as potential changes to
                    local watersheds); impacts to local wildlife; and impacts to outdoor tourism (hiking, boating, etc.), which is likely to
                    be reduced on smoky days due to air quality issues and safety concerns. This analysis also did not attempt to
                    calculate loss of property or loss of human and animal life due to fire. All of these impacts are important and could
                    be explored at length in a larger analysis. However, an examination of potential impacts to the Spokane Coeur
                    d’Alene watershed should be prioritized.
                  </p>
                  <p>
                    <b>Future Smoke Tool</b>: Also as noted above, this analysis did not look at wind directions during the different seasons
                    of the year. A future analysis (perhaps built in the form of an online tool) could be developed to determine how
                    smoke from fires might blow into Spokane, affecting the city’s air quality in the future. This could perhaps be used
                    to inform future resiliency actions. For instance, if an analysis were to determine that a fire in a particular forest of
                    set of forests might be more impactful to Spokane than others, the city could participate in fuel reduction in those
                    forests in order to avoid poor air quality due to smoke from fires in that forest or set of forests.
                  </p>
                  <p>
                    <b>Changes to the Spokane Watershed</b>: There was particular concern from amongst our group that very large
                    wildfires from within the upland forests that form the headwaters of the Spokane River will see many more Extreme
                    Fire Danger Days, and extended higher temperatures. Since these conditions led to the 1910 fires in northern Idaho
                    and northeast Washington (<b>USDA Forest Service 2019) (Cohen and Miller 1978</b>) there is concern that there might
                    be a large wildfire soon in this region and that such a large fire would significantly impact watershed conditions.
                  </p>
                  <p>
                    Forests burned by very large fires can take a very long time to regenerate, especially if there are loss of species,
                    conversion to non-forest cover and flooding events on the burned areas that cause extreme erosion. As a result,
                    burned forests 50 years from now may not store as much water, posing a threat to summer flows and groundwater in
                    the Spokane Coeur d’Alene watershed. Our group would like to do future work in exploring these changes to the
                    watershed.
                  </p>
                </div>
              </div>
              <div id="citations" className="card border-dark bg-light mb-3">
                <div className="card-header"><h4>Citations For this Chapter</h4></div>
                <div className="card-body">
                  <p className="card-text">
                    Mike Petersen, Levi Keesecker, Wei Li, Terry Lawhead, Katherine
                    Rowden, and Joel Breems, Jerusha Hampson, and Katherine Hegewisch. “Fire and Smoke Impact Study for
                    Spokane, Washington” In <i>The Spokane Climate Vulnerability and Resilience Assessment</i>,” edited by Katherine
                    Hegewisch, Nathan Gilles, John Abatzoglou, Ann Mooney, and Meghan Dalton. Corvallis, Oregon: 
                    <i>The Pacific Northwest Climate Impacts Research Consortium (CIRC), College of Earth, Ocean, and Atmospheric Sciences, Oregon State University</i>, 2020.
                </p>
                  <h5>CIRC-Related Data Sources:</h5>
                  <ol className="list-group">
                    <li className="list-group-item">
                      Abatzoglou, John T. "Development of gridded surface meteorological data for ecological applications and
                      modelling." <i>International Journal of Climatology</i> 33, no. 1 (2013): 121-131. <a href="https://doi.org/10.1002/joc.3413">https://doi.org/10.1002/joc.3413</a>.
                    </li>
                    <li className="list-group-item">
                      Abatzoglou, John T., and Timothy J. Brown. &quot;A comparison of statistical downscaling methods suited for wildfire
                      applications.&quot; <i>International Journal of Climatology</i> 32, no. 5 (2012): 772-780.
                      <a href="https://doi.org/10.1002/joc.2312">https://doi.org/10.1002/joc.2312</a>.
                    </li>
                    <li className="list-group-item">
                      Hegewisch, Katherine C., and John T. Abatzoglou. "An improved Multivariate Adaptive Constructed Analogs
                      (MACA) Statistical Downscaling Method.” (In Preparation as of 2019).
                      <a href="https://www.sciencebase.gov/catalog/item/5752f2d9e4b053f0edd15628">https://www.sciencebase.gov/catalog/item/5752f2d9e4b053f0edd15628</a>.
                    </li>
                    <li className="list-group-item">
                      Hegewisch, K.C., Abatzoglou, J.T.. 2014. MACAv2-LIVNEH: Climate Model Outputs from CMIP5 using
                      Multivariate Adaptive Constructed Analogs (MACA) v2 statistical downscaling method with Livneh Observational
                      Training Dataset. USGS ScienceBase.
                    </li>
                    <li className="list-group-item">
                      Hegewisch, Katherine C., John T. Abatzoglou, Oriana Chegwidden, and Bart Nijssen. “Climate Mapper Tool.” The
                      Climate Toolbox. Accessed May 25, 2019. <a href="https://climatetoolbox.org/tool/Climate-Mapper">https://climatetoolbox.org/tool/Climate-Mapper</a>.
                    </li>
                    <li className="list-group-item">
                      Hegewisch, Katherine C., and John T. Abatzoglou. “Climate Boxplots Tool.” The Climate Toolbox. Accessed May
                      25, 2019. <a href="https://climatetoolbox.org/tool/future-climate-boxplots">https://climatetoolbox.org/tool/future-climate-boxplots</a>.
                    </li>
                    <li className="list-group-item">
                      Hegewisch, Katherine C., and John T. Abatzoglou. “Historical Climate Tracker Tool.” The Climate Toolbox.
                      Accessed May 25, 2019. <a href="https://climatetoolbox.org/tool/Historical-Climate-Tracker">https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>.
                    </li>
                  </ol>
                  <h5 className="m-2">Additional References:</h5>
                  <ol className="list-group">
                    <li className="list-group-item">
                      Abatzoglou, John T., and Crystal A. Kolden. "Relationships between climate and macroscale area burned in the
                      western United States." <i>International Journal of Wildland Fire</i> 22, no. 7 (2013): 1003-1020.
                      <a href="https://doi.org/10.1071/WF13019">https://doi.org/10.1071/WF13019</a>.
                    </li>
                    <li className="list-group-item">
                      Abatzoglou, John T., and A. Park Williams. "Impact of anthropogenic climate change on wildfire across western US
                      forests." <i>Proceedings of the National Academy of Sciences</i> 113, no. 42 (2016): 11770-11775.
                      <a href="https://doi.org/10.1073/pnas.1607171113">https://doi.org/10.1073/pnas.1607171113</a>.
                   </li>
                    <li className="list-group-item">
                      Abatzoglou, John. “Fire Season is Here!” <i>The Climate CIRCulator</i>, August 8,
                      2017. <a href='https://climatecirculatororg.wordpress.com/2017/08/08/a-look-at-this-years-fire-season-using-the-northwest-climate-toolbox/'>https://climatecirculatororg.wordpress.com/2017/08/08/a-look-at-this-years-fire-season-using-the-northwest-climate-toolbox/</a>.
                    </li>
                    <li className="list-group-item">
                      Air Now. “Air Quality Index (AQI) Basics.” Accessed November 14, 2019.
                      <a href="https://airnow.gov/index.cfm?action=aqibasics.aqi">https://airnow.gov/index.cfm?action=aqibasics.aqi</a>.
                    </li>
                    <li className="list-group-item">
                      Air Now. “Air Quality Index - A Guide to Air Quality and Your Health.” Accessed November 14, 2019.
                      <a href='https://airnow.gov/index.cfm?action=aqi_brochure.index'>https://airnow.gov/index.cfm?action=aqi_brochure.index</a>.
                    </li>
                    <li className="list-group-item">
                      Barbero, Renaud, John T. Abatzoglou, Narasimhan Larkin, Crystal A. Kolden, and B. J. Stocks. “Climate Change
                      Presents Increased Potential for Very Large Fires in the Contiguous United States.”
                      <i>International Journal of Wildland Fire</i> 24, no. 7 (2015): 892-899.
                      <a href='https://doi.org/10.1071/WF15083'>https://doi.org/10.1071/WF15083</a>.
                    </li>
                    <li className="list-group-item">
                      Blades, Jarod J., Steven R. Shook, and Troy E. Hall. "Smoke management of wildland and prescribed fire:
                      understanding public preferences and trade-offs." <i>Canadian Journal of Forest Research</i> 44, no. 11 (2014): 1344-
                      1355. <a href="https://doi.org/10.1139/cjfr-2014-0110">https://doi.org/10.1139/cjfr-2014-0110</a>.
                    </li>
                    <li className="list-group-item">
                      Brunt, Johnathan. “Spokane sets record for air pollution; smoke expected to remain until Friday.” <i>The Spokesman-Review</i>, 
                      September 7, 2017. <a href="https://www.spokesman.com/stories/2017/sep/06/air-on-wednesday-remains-hazardous-for-third-strai/">https://www.spokesman.com/stories/2017/sep/06/air-on-wednesday-remains-hazardous-for-third-strai/</a>.
                    </li>
                    <li className="list-group-item">
                      Burton, Carmen A., Todd M. Hoefen, Geoffrey S. Plumlee, Katherine L. Baumberger, Adam R. Backlin, Elizabeth
                      Gallegos, and Robert N. Fisher. "Trace elements in stormflow, ash, and burned soil following the 2009 station fire in
                      Southern California." <i>PLOS ONE</i> 11, no. 5 (2016): e0153372. <a href="https://doi.org/10.1371/journal.pone.0153372">https://doi.org/10.1371/journal.pone.0153372</a>.
                    </li>
                    <li className="list-group-item">
                      Campbell, Will. “Air quality worst in country; heat advisory issued for Spokane” <i>The Spokesman-Review</i>, August 6,
                      2019. <a href="https://www.spokesman.com/stories/2019/aug/06/air-quality-worst-in-country-heat-advisory-issued-/">https://www.spokesman.com/stories/2019/aug/06/air-quality-worst-in-country-heat-advisory-issued-/</a>.
                    </li>
                    <li className="list-group-item">
                      Cleetus, Rachel, and Kranti Mulik. Playing with fire: how climate change and development patterns are contributing
                      to the soaring costs of western wildfires. <i>Union of Concerned Scientists</i>, 2014.
                      <a href='https://www.ucsusa.org/sites/default/files/legacy/assets/documents/global_warming/playing-with-fire-report.pdf'>https://www.ucsusa.org/sites/default/files/legacy/assets/documents/global_warming/playing-with-fire-report.pdf</a>.
                    </li>
                    <li className="list-group-item">
                      Cohen, Jack D., and John E. Deeming. “The National Fire-Danger Forest Service Rating System: basic equations,
                      General Technical Report PSW–82” Pacific Southwest Forest and Range Experiment Station, Berkeley, California:
                      United States Department of Agriculture Forest Service, 1985.
                      <a href="https://www.fs.fed.us/psw/publications/documents/psw_gtr082/psw_gtr082.pdf">https://www.fs.fed.us/psw/publications/documents/psw_gtr082/psw_gtr082.pdf</a>
                    </li>
                    <li className="list-group-item">
                      Cohen, Stan, and Donald C. Miller. The big burn: The Northwest’s forest fire of 1910. Pictorial Histories Publishing
                      Company, 1978.
                    </li>
                    <li className="list-group-item">
                      Georgia Forestry Commission “Reasons For Prescribed Fire In Forest Resource Management.” USDA Forest
                      Service Southern Region, Technical Publication R8-TP 11. USDA Forest Service. Dry Branch, Georgia: 1989.
                      <a href="http://www.gfc.state.ga.us/forest-fire/prescribed-fire-guide/reasons.cfm">http://www.gfc.state.ga.us/forest-fire/prescribed-fire-guide/reasons.cfm</a>.
                    </li>
                    <li className="list-group-item">
                      Glover, Jonathan. “Here’s a list of major fires contributing to Spokane’s smoky air.” <i>The Spokesman-Review</i>,
                      September 7, 2017. <a href="https://www.spokesman.com/stories/2017/sep/05/heres-a-list-of-major-fires-contributing-to-spokan/">https://www.spokesman.com/stories/2017/sep/05/heres-a-list-of-major-fires-contributing-to-spokan/</a>.
                    </li>
                    <li className="list-group-item">
                      Cooper, Craig. “Influences of Climate Change on Coeur d’Alene Lake”. Coeur d’Alene, Idaho: Spokane River
                      Forum: Our Gem Conference, 2019 <a href="https://spokaneriver.net/wp-content/uploads/2019/11/OurGem-2019-Cooper.pdf">https://spokaneriver.net/wp-content/uploads/2019/11/OurGem-2019-Cooper.pdf</a>.
                    </li>
                    <li className="list-group-item">
                      Government Accountability Office (GAO; GAO-20-73). 2019. “EPA Should Take Additional Actions to Manage
                      Risks from Climate Change.” Washington, DC.: Government Accountability Office, 2019.
                      <a href="https://www.gao.gov/products/GAO-20-73">https://www.gao.gov/products/GAO-20-73</a>.
                    </li>
                    <li className="list-group-item">
                      Mooney, Ann, Nathan Gilles, Katherine C. Hegewisch, John T. Abatzoglou, and Meghan Dalton. “The Northwest
                      Climate Toolbox Workbook: Discovering and applying your climate data story for climate adaptation planning,”
                      Corvallis, Oregon: The Pacific Northwest Climate Impacts Research Consortium (CIRC), College of Earth, Ocean,
                      and Atmospheric Sciences, Oregon State University, 2019. <a href='https://pnwcirc.org/sites/pnwcirc.org/files/nwct.pdf'>https://pnwcirc.org/sites/pnwcirc.org/files/nwct.pdf</a>.
                    </li>
                    <li className="list-group-item">
                      Murphy, B. P., L. L. Yocom, and P. Belmont. 2018. Beyond the 1984 Perspective: Narrow Focus on Modern
                      Wildfire Trends Underestimates Future Risks to Water Security. Earth’s Future 6:1492–1497.
                    </li>
                    <li className="list-group-item">
                      National Research Council. Superfund and Mining Megasites: Lessons from the Coeur d'Alene River Basin.
                      Washington, DC: The National Academies Press, 2005. <a href='https://doi.org/10.17226/11359'>https://doi.org/10.17226/11359</a>.
                    </li>
                    <li className="list-group-item">
                      Pacific Northwest Climate Impacts Research Consortium (CIRC). “Human Choice, Warming, & Emissions.”
                      Accessed October 7, 2019. <a href="https://pnwcirc.org/science/pathways">https://pnwcirc.org/science/pathways</a>.
                    </li>
                    <li className="list-group-item">
                      Pacific Northwest Climate Impacts Research Consortium (CIRC). “Wildfires & Changing Vegetation.” Accessed
                      October 21, 2019. <a href="https://pnwcirc.org/science/wildfires">https://pnwcirc.org/science/wildfires</a>.
                    </li>
                    <li className="list-group-item">
                      Pierre-Louis, Kendra, and Jeremy White. “Americans Move Closer To Nature, In Fire Zones.” New York Times
                      November 16, 2018. <a href="https://www.nytimes.com/2018/11/15/climate/california-fires-wildland-urban-interface.html">https://www.nytimes.com/2018/11/15/climate/california-fires-wildland-urban-interface.html</a>.
                    </li>
                    <li className="list-group-item">
                      Ready for Wildfire. “Maintain Defensible Space.” Accessed December 16, 2019.
                      <a href="https://www.readyforwildfire.org/prepare-for-wildfire/get-ready/defensible-space/">https://www.readyforwildfire.org/prepare-for-wildfire/get-ready/defensible-space/</a>.
                    </li>
                    <li className="list-group-item">
                      Riley, Karin L., John T. Abatzoglou, Isaac C. Grenfell, Anna E. Klene, and Faith Ann Heinsch. "The relationship of
                      large fire occurrence with drought and fire danger indices in the western USA, 1984–2008: the role of temporal
                      scale." <i>International Journal of Wildland Fire</i> 22, no. 7 (2013): 894-909. <a href='https://doi.org/10.1071/WF12149'>https://doi.org/10.1071/WF12149</a>.
                    </li>
                    <li className="list-group-item">
                      Rhoades, Charles C., Alex T. Chow, Timothy P. Covino, Timothy S. Fegel, Derek N. Pierson, and Allison E. Rhea.
                      "The legacy of a severe wildfire on stream nitrogen and carbon in headwater catchments." <i>Ecosystems</i> 22, no. 3
                      (2019): 643-657. <a href="https://doi.org/10.1007/s10021-018-0293-6">https://doi.org/10.1007/s10021-018-0293-6</a>.
                    </li>
                    <li className="list-group-item">
                      Schlobohm, Paul, and Jim Brain. "Gaining an understanding of the National Fire Danger Rating System." <i>National Wildfire Coordinating Group</i>, PMS 932 (2002), p 72.
                      <a href="https://www.nwcg.gov/sites/default/files/products/pms932.pdf">https://www.nwcg.gov/sites/default/files/products/pms932.pdf</a>.
                    </li>
                    <li className="list-group-item">
                      Schultz, Courtney A., Heidi Huber-Stearns, Sarah McCaffrey, Douglas Quirke, Gwen Ricco, and Cassandra
                      Moseley. "Prescribed fire policy barriers and opportunities: a diversity of challenges and strategies across the west."
                      Eugene, Oregon: University of Oregon, 2018. <a href="https://ewp.uoregon.edu/sites/ewp.uoregon.edu/files/WP_86.pdf">https://ewp.uoregon.edu/sites/ewp.uoregon.edu/files/WP_86.pdf</a>.
                    </li>
                    <li className="list-group-item">
                      Sheehan, Timothy J., Dominique Bachelet, and Ken Ferschweiler.
                      “Projected Major Fire and Vegetation Changes in the Pacific Northwest of the Conterminous United States under
                      Selected CMIP5 Climate Futures.” Ecological Modeling 317 (2015): 16-29.
                      <a href="https://doi.org/10.1016/j.ecolmodel.2015.08.023">https://doi.org/10.1016/j.ecolmodel.2015.08.023</a>.
                    </li>
                    <li className="list-group-item">
                      Sokal, Chad, and and Rachel Sun. “Air quality ‘unhealthy’ in Spokane, North Idaho, Northeastern Washington” <i>The Spokesman-Review</i>, August 13, 2018. <a href="https://www.spokesman.com/stories/2018/aug/13/air-quality-unhealthy-in-spokane-north-idaho-north/">https://www.spokesman.com/stories/2018/aug/13/air-quality-unhealthy-in-spokane-north-idaho-north/</a>.
                    </li>
                    <li className="list-group-item">
                      Southern Group of State Foresters. “Benefits of Prescribed Fire.” Accessed December 16, 2019.
                      <a href='https://www.southernforests.org/fire/prescribed-fire/benefits-of-prescribed-fire'>https://www.southernforests.org/fire/prescribed-fire/benefits-of-prescribed-fire</a>
                    </li>
                    <li className="list-group-item">
                      United States Environmental Protection Agency (EPA). “Air Data: Air Quality Data Collected at Outdoor Monitors
                      Across the US.” Accessed April 15, 2019. <a href="https://www.epa.gov/airdata">https://www.epa.gov/airdata</a>.
                    </li>
                    <li className="list-group-item">
                      United States Environmental Protection Agency (EPA). “Air Quality System Data Mart.” Accessed April 15, 2019.
                      <a href="https://aqs.epa.gov/aqsweb/documents/data_mart_welcome.html">https://aqs.epa.gov/aqsweb/documents/data_mart_welcome.html</a>.
                    </li>
                    <li className="list-group-item">
                      United States Environmental Protection Agency (EPA). “Guidelines for the Reporting of Daily Air Quality – the Air
                      Quality Index (AQI).” Research Triangle Park, North Carolina: U.S. Environmental Protection Agency
                      Office of Air Quality Planning and Standards, 2006. <a href='https://archive.epa.gov/ttn/ozone/web/pdf/rg701.pdf'>https://archive.epa.gov/ttn/ozone/web/pdf/rg701.pdf</a>.
                    </li>
                    <li className="list-group-item">
                      United States Environmental Protection Agency (EPA). 2019. “Search for Superfund sites where you live.”
                      Accessed: December 15, 2019. <a href="https://www.epa.gov/superfund/search-superfund-sites-where-you-live">https://www.epa.gov/superfund/search-superfund-sites-where-you-live</a>.
                    </li>
                    <li className="list-group-item">
                      U.S. Department of Agriculture Forest Service (USDA Forest Service). “The Great Fire of 1910.” Accessed
                      December 16, 2019. <a href="https://www.fs.usda.gov/Internet/FSE_DOCUMENTS/stelprdb5444731.pdf">https://www.fs.usda.gov/Internet/FSE_DOCUMENTS/stelprdb5444731.pdf</a>.
                    </li>
                    <li className="list-group-item">
                      Woods, Paul F. “Historical Overview: Interplay of Metal Contamination and Eutrophication in Coeur d’Alene
                      Lake.” Coeur d’Alene, Idaho: Spokane River Forum: Our Gem Conference, 2019. <a href="https://spokaneriver.net/wp-content/uploads/2019/11/PAUL.WOODS_.pdf">https://spokaneriver.net/wp-content/uploads/2019/11/PAUL.WOODS_.pdf</a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* Beginning of Sidebar */}
            <div className="col-2 d-none d-md-block navbar">
              <div className="navbar-sticky-top sticky-top bg-white side-navbar">
                <h5 className="sidebar-title">Menu</h5>
                <ol className="list-group">
                  <li className="list-group-item">
                    <a href="#chapter-summary">Chapter Summary</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#climate-data-story-fires">CDS - Fires in the Forests</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#larger-context">Larger Context</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#local-context">Local Context</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#analysis">Analysis</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#temperature-precipitation">Temperature & Precipitation</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#historical-fires">Historical Fires</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#air-quality-index">Sensitive Groups</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#historical-smoke-wildfires">Historical Smoke Wildfires</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#historical-smoke-air-quality">Historical Smoke, Air Quality</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#future-climate-temperature">Future Climate</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#conclusion">Conclusion</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#future-work">Future Work</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#citations">Citations</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Wildfires;