import React from 'react';

function Snow() {
    return (
      <div>
        <div className="card bg-dark text-white">
          <img src="/media/snow/snow-header_edited.jpg" className="card-img" alt="Spokane Falls" />
          <div className="h-40 header-image-portion-overlay header-title d-none d-lg-flex">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text">Chapter 3: Snowfall Impact Study</h1>
              <h4 className="card-text header-title">
                <i>
                  <p>
                    “In mountain towns across the United States that rely on winter tourism, snow is currency. For snow
                    lovers and the winter sports industry, predictions of a future with warmer winters, reduced snowfall, and
                    shorter snow seasons is inspiring them to innovate, increase their own efforts to address emissions, and
                    speak publicly on the urgent need for action.”
                  </p>
                  <p>
                    —Elizabeth Burakowski, “The Economic Contributions of Winter Sports in a Changing Climate”
                  </p>
                </i>
              </h4>
            </div>
          </div>
          <div className="h-80 header-image-portion-overlay header-title d-none d-md-flex d-lg-none">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text">Chapter 3: Snowfall Impact Study</h1>
              <i>
                <p>
                  “In mountain towns across the United States that rely on winter tourism, snow is currency. For snow
                  lovers and the winter sports industry, predictions of a future with warmer winters, reduced snowfall, and
                  shorter snow seasons is inspiring them to innovate, increase their own efforts to address emissions, and
                  speak publicly on the urgent need for action.”
                </p>
                <p>
                  —Elizabeth Burakowski, “The Economic Contributions of Winter Sports in a Changing Climate”
                </p>
              </i>
            </div>
          </div>
          <div className="h-80 header-image-portion-overlay header-title d-none d-sm-flex d-md-none">
            <div className="my-auto">
              <h3 className="card-title image-overlay-text">Chapter 3: Snowfall Impact Study</h3>
              <i>
                <p>
                  “In mountain towns across the United States that rely on winter tourism, snow is currency. For snow
                  lovers and the winter sports industry, predictions of a future with warmer winters, reduced snowfall, and
                  shorter snow seasons is inspiring them to innovate, increase their own efforts to address emissions, and
                  speak publicly on the urgent need for action.”
                </p>
                <p>
                  —Elizabeth Burakowski, “The Economic Contributions of Winter Sports in a Changing Climate”
                </p>
              </i>
            </div>
          </div>
          <div className="h-80 header-image-portion-overlay header-title d-sm-none">
            <div className="my-auto">
              <h3 className="card-title image-overlay-text center-vertical">Chapter 3: Snowfall Impact Study</h3>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="row">
            <div className="col-md-10 content">
              <ul id="chapter-summary" className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Chapter Summary: </b>Examines historical trends in snowfall for the Spokane region, future climate projections and
                    mountain snow forecasts, and determines what these data suggests about the future of winter recreation at Spokane’s
                    five-area ski resorts (Mt. Spokane Ski & Snowboard Park, 49 Degrees North Mountain Resort, Silver Mountain
                    Resort, Schweitzer Mountain Resort, and Lookout Pass Ski Area).</li>
                <li className="list-group-item">
                  <b>Lead Authors: </b>Brian Henning (Gonzaga University), Levi Keesecker (E2 Environmental), David Camp (Northwest
                  Renewables), and Erik Budsberg (Eastern Washington University)</li>
                <li className="list-group-item">
                  <b>Review Editors: </b>Nathan Gilles (CIRC/Oregon State University), Katherine Hegewisch (CIRC/University of Idaho),
                  John Abatzoglou (CIRC/UI), Ann Mooney (CIRC/OSU), and Meghan Dalton (CIRC/OSU).</li>
              </ul>
              <div className="row m-1">
                <div className="col p-0">
                  <div className="card border-dark m-3">
                    <div className="card-header p-2 card-header-display">
                      <img className="col-1 icon-image p-0" src="/media/OSU_icon_key_01.png" alt="key"></img>
                      <h4 className="col-11 card-header-title p-0">Key Findings</h4>
                    </div>
                    <div className="card-body p-0">
                      <ol className="list-group" >
                        <li className="list-group-item">
                          All five Spokane-area ski resorts (Mt. Spokane Ski & Snowboard Park, 49 Degrees North Mountain Resort,
                          Silver Mountain Resort, Schweitzer Mountain Resort, and Lookout Pass Ski Area) are likely to be impacted
                          by rising temperatures.
                        </li>
                        <li className="list-group-item">
                          Projected climate changes at Spokane’s five ski resorts by the middle of this century include:
                          <ol>
                            <li >
                              An increase in mean temperatures
                            </li>
                            <li >
                              An overall decline in snow on the ground (as measured as snow water equivalent)
                            </li>
                            <li >
                              A decrease in the total number of winter days below 32 degrees Fahrenheit, potentially hindering
                              opportunities to make snow
                            </li>
                          </ol>
                        </li>
                        <li className="list-group-item">
                          Taken together, these projected climate changes pose a continuing threat to the vitality of winter sports
                          recreation in the Spokane region.
                        </li>
                        <li className="list-group-item">
                          Reducing greenhouse gas emissions—moving from our current path on the high emissions scenario (RCP
                          8.5) to the lower emissions scenario (RCP 4.5)—could mean the difference between a degraded but viable
                          ski industry (RCP 4.5) and a nonviable ski industry (RCP 8.5) for five Spokane-area ski resorts, according
                          to our analysis.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                {/* End of Key Findings */}
                <div className="col p-0">
                  <div className="card border-dark m-3">
                    <div className="card-header text-white bg-success p-2 card-header-display">
                      <img className="col-2 icon-image" src="/media/OSU_icon_shield_01.png" alt="shield"></img>
                      <h4 className="col-10 card-header-title p-0">Resiliency Actions</h4>
                    </div>
                    <div className="card-body p-0">
                      <ol className="list-group">
                        <li className="list-group-item list-group-item-success">
                          <b>Reduce Emissions:</b> The primary action for lessening the impacts to Spokane’s winter sports industry
                          associated with projected higher temperatures is to reduce greenhouse gas emissions, specifically to move
                          away from the high emissions scenario (RCP 8.5) to the lower emissions scenario (RCP 4.5).
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Prepare for Shorter Seasons:</b> As temperatures in the Spokane region continue to rise, Spokane’s
                          regional snow-dependent recreation industries—including skiing, snowboarding, snowshoeing, and
                          snowmobiling—need to prepare for snow seasons that start later, are shorter, have less snow, and
                          potentially provide fewer days cold enough to make snow.
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Diversify: </b> Winter recreation industries in the Spokane region should consider diversifying their business
                          models, including emphasizing warmer weather recreation activities.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div id="climate-data-story-projected-future-snow" className="card border-dark mb-3">
                <div className="card-header p-2 card-header-display">
                  <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data-story"></img>
                  <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Projected Future Snow at Mt. Spokane Ski & Snowboard Park</i></h4>
                </div>
                <div className="card-body">
                  <p>
                    Over the course of this century, projected temperature increases and the increasing likelihood that precipitation will
                    fall as rain rather than snow during the cold months of the year will likely shrink the length of the ski season and
                    lead to adverse economic impacts for all five Spokane-area ski resorts: Mt. Spokane Ski & Snowboard Park, 49
                    Degrees North Mountain Resort, Silver Mountain Resort, Schweitzer Mountain Resort, and Lookout Pass Ski Area.
                  </p>
                  <p>
                    For the purposes of this summary climate data story, the authors of this chapter focused on future climate impacts at
                    one of these five resorts: Mt. Spokane Ski & Snowboard Park (Mt. Spokane).
                  </p>
                  <p>
                    By the middle of this century (2040–2069) during the prime ski season (December–February), the average mean
                    temperature at Mt. Spokane is expected to rise from a historical (1971–2000) mean of 25.9 degrees Fahrenheit to
                    30.6°F under the lower emissions scenario (RCP 4.5) or 31.8°F under high emissions scenario (RCP 8.5).
                  </p>
                  <p>
                    The rise in mean temperatures at Mt. Spokane is expected to correspond to a decline in snow at the ski park. Simply
                    put, as temperatures rise, it becomes far more likely that precipitation will fall as rain rather than as snow. To
                    determine how much snow Mt. Spokane might have by mid-century, our team used the variable snow water
                    equivalent (SWE), which is a measure of how much liquid water is available in a given amount of snow on the
                    ground. By mid-century, our analysis found, SWE accumulated on Mt. Spokane by the date January 1st—a key date
                    for the local ski industry—is expected to decline from a historical mean of 10.7′′ to 8.8′′ under RCP 4.5 and to 7.5′′
                    under RCP 8.5 (<b>Figures 8 and 9</b>). 
                  </p>
                  <p>
                    Moreover, if we examine precipitation projections, we can make the reasonable
                    inference that this loss of snow is due to rising temperatures and not declines in precipitation. By mid-century,
                    winter (December to February) precipitation at Mt. Spokane is projected to move from a historical mean of 14.6′′ to
                    15.8′′ under RCP 4.5 and to 16.0′′ under RCP 8.5 (<b>Figures 7 and 8</b>). (See <b>Analysis: Inferences & Limitations</b> below
                    for details on our analysis.)
                  </p>
                  <p>
                    During this same mid-century period, the annual number of days below freezing (32 °F) at Mt. Spokane is projected
                    to drop from a historical average of 169 days to 127 days under RCP 4.5 and to 111 days under RCP 8.5 (<b>Table 6</b>).
                    In other words, there is expected to be 42–58 fewer freezing days per year at Mt. Spokane by mid-century compared
                    to what was observed during the last three decades of the 20th century. 
                  </p>
                  <p>  
                    If we consider this trend of fewer freezing
                    days, we can make the reasonable interference that by mid-century Mt. Spokane will see fewer days cold enough for
                    snow to form in the atmosphere and remain frozen on the ground. Fewer days below freezing also means fewer days
                    cold enough to make snow with equipment, which generally requires temperatures below freezing (specifically 30
                    °F or lower).
                  </p>
                </div>
              </div>
              <div id="larger-context" className="card border-dark mb-3 bg-light">
                <div className="card-header p-2 card-header-display">
                  <h4 className="col-11 card-header-title p-0">Larger Context — Climate Change and Snow-based Recreation in the Pacific Northwest</h4>
                </div>
                <div className="card-body">
                  <h6 className="card-subtitle mb-2 text-muted">
                    <p><i>
                      “Climate change could decrease snow-based recreation revenue by more than 70% annually in the
                      Northwest under a higher scenario (RCP8.5).”
                      – Fourth National Climate Assessment
                    </i></p>
                  </h6>
                  <p>
                    The projected rise in temperatures throughout this century is expected to lead to more rain and less snow across
                    much of the American Mountain West (<b>Lute et al., 2015; Klos et al., 2014</b>). Such changes in mountain snowpack
                    resulting from a warming climate are already evident. From 1955 to 2016, declines in snow were found at over 90%
                    of snow monitoring sites (<b>Mote et al., 2018</b>) in the American West. The economic effects of this loss of snow on the
                    United States ski industry have already being felt. From November 1999 to April 2010, the U.S. downhill ski
                    industry lost $1.07 billion in revenue due to low snow years (<b>Burakowski and Magnusson 2012</b>). Economic losses
                    are expected to continue as mountain snow levels decline under projected climate changes. According to an analysis
                    published in the Fourth National Climate Assessment released in 2018, snow-based recreation in the U.S. Pacific
                    Northwest could decrease by as much as 70% annually under the high emissions scenario (RCP 8.5) (<b>NCA 4, “Chapter 24 Northwest” 2018</b>).
                  </p>
                </div>
              </div>
              <div id="local-context" className="card border-dark bg-light mb-3">
                <div className="card-header"><h4>Local Context — Climate Change and Spokane’s Snow-Based Recreation</h4></div>
                <div className="card-body">
                  <p>
                    <i>
                      “Spokane has all the hallmarks of a great ski town: killer shops dedicated to supporting and growing the
                      local ski community (shout out to Brian at the Alpine House), easy access to a wide variety of skiing
                      ranging from mom-and-pop areas to regional destination resorts – all with amazing terrain, and reliably
                      good snow. ... Plus, it’s a place where whole families can ski religiously and live affordably while mom
                      and dad maintain steady jobs. In my mind that almost makes it better than many of the more glamorous
                      ski towns out there.”
                      —Kevin Luby, former Skiing Magazine senior editor and Spokane native
                    </i>
                  </p>
                  <p>
                    During the winter season of 2015–2016, snowboarders and skiers added an estimated $20.3 billion to the U.S.
                    economy, spending money at resorts and hotels, as well as restaurants, bars, and grocery stores. In total, more than
                    20 million people downhill-skied, snowboarded, and snowmobiled during this period (<b>Hagenstad et al., 2018</b>). For
                    decades, Spokane-area residents have enjoyed easy access to winter snow sports. Winter recreation, including skiing
                    and snowboarding, are an important part of Spokane’s identity and a significant source of winter revenue for our
                    region. Spokane has five ski resorts within a two-and-a-half-hour drive from the city: Mt. Spokane Ski &
                    Snowboard Park (Mt. Spokane), 49 Degrees North Mountain Resort (49 Degrees North), Silver Mountain Resort
                    (Silver Mountain), Schweitzer Mountain Resort (Schweitzer Mountain), and Lookout Pass Ski Area (Lookout Pass).
                    In a 2018 story, the Spokesman-Review reported that Mt. Spokane alone attracts about 100,000 visitors annually,
                    creating almost $5 million in revenue for the local economy (<b>Kramer 2018</b>) or $50 in revenue per visitor.
                  </p>
                  <p>
                    It is important to note that much of the economic value of snow-based sports is indirect. For instance, nationally
                    during the 2015–2016 snow season, direct spending at ski resorts, local restaurants, and the like, accounted for 41%
                    of winter sports revenues nation-wide, or roughly $4.7 billion. The other 59% of revenues ($6.7 billion) came from
                    indirect spending, including retail purchases and spending by resort employees (<b>Hagenstad et al., 2018</b>).
                  </p>
                  <p>
                    The economic value of winter snow sports for Spokane’s five-area ski resorts is directly related to the number of
                    visitors to the resorts. A 2006 economic analysis conducted by SE Group on behalf of Washington State Parks
                    provides the visitor averages for the years 1996–2006 and is displayed in <b>Table 1 (SE Group 2007)</b>.
                  </p>
                  <p>
                    <b>Table 1:</b> <i> Number of average annual visitors to Spokane’s five ski resorts (Mt. Spokane, 49 Degrees North, Silver Mountain,
                    Schweitzer Mountain, and Lookout Pass) for the ten-year period 1996–2006. Source: SE Group 2007
                    (<a href="http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.550.1332&rep=rep1&type=pdf">http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.550.1332&rep=rep1&type=pdf</a>).</i>
                  </p>
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Resort</th>
                        <th scope="col">Average Annual Visitors over the years 1996–2006</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Mt. Spokane</th>
                        <td>67,747</td>
                      </tr>
                      <tr>
                        <th scope="row">49 Degrees North</th>
                        <td>59,866</td>
                      </tr>
                      <tr>
                        <th scope="row">Silver Mountain</th>
                        <td>88,246</td>
                      </tr>
                      <tr>
                        <th scope="row">Schweitzer Mountain</th>
                        <td>180,285</td>
                      </tr>
                      <tr>
                        <th scope="row">Lookout Pass</th>
                        <td>31,677</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    At all five Spokane-area ski resorts, winter temperatures are projected to increase over the course of this century
                    under both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5), according to our
                    analysis using the Climate Toolbox. Projected rising temperatures are expected to lead to a decline in total snow
                    levels and fewer days below freezing (potentially hindering opportunities to make snow) at all five Spokane-area ski
                    resorts.
                  </p>
                  <p>
                    Taken together, less snow and less opportunities to make snow will likely lead to fewer visitors at all five Spokane-
                    area ski resorts. If the five resorts see fewer visitors, we can expect that this will lead to adverse economic impacts to
                    Spokane’s five ski resorts as well as local businesses in Spokane and the surrounding region that rely on indirect
                    spending connected to snow-based recreation.
                  </p>
                </div>
              </div>
              <div id="analysis" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Analysis — Geography, Data Tools, Inferences & Limitations, Emissions Scenarios,
                  Timeframes, Multi-model Means, Variables, and Climate Data Story</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Geography:</b> To investigate the effects of climate change on Spokane’s snow recreation, we chose to look at the five large ski
                    resorts within a 2.5-hour drive from Spokane: Mt. Spokane Ski & Snowboard Park (Mt. Spokane), 49 Degrees North
                    Mountain Resort (49 Degrees North), Silver Mountain Resort (Silver Mountain), Schweitzer Mountain Resort
                    (Schweitzer Mountain), and Lookout Pass Ski Area (Lookout Pass).
                  </p>
                  <p>
                    <b>Data Tools:</b> Our team started by examining snow depth measurements recorded at Spokane’s five resorts and data available from
                    the website On the Snow (www.onthesnow.com) for the years 2009–2017. We then proceeded to examine simulated
                    historical snow data as well as projected future snow data for the five resorts by using the following tools in CIRC’s
                    Climate Toolbox:
                  </p>
                  <ul>
                    <li>
                      Historical Climate Tracker Tool (<a href="https://climatetoolbox.org/tool/Historical-Climate-Tracker">https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>)
                    </li>
                    <li>
                      The Future Boxplots Tool (<a href='https://climatetoolbox.org/tool/Future-Boxplots'>https://climatetoolbox.org/tool/Future-Boxplots</a>)
                    </li>
                    <li>
                      The Climate Mapper Tool (<a href="https://climatetoolbox.org/tool/Climate-Mapper">https://climatetoolbox.org/tool/Climate-Mapper</a>)
                    </li>
                  </ul>
                  <p>
                    <b>Inferences & Limitations:</b> The mapping software available as part of each Toolbox tool was used to locate each resort by putting in the name
                    of the resort as the location, as opposed to entering precise latitude and longitude coordinates for the base and
                    summit of the five ski resorts. The reason this was done has to do with the resolution of the data available. Toolbox
                    data for snow has been downscaled to a grid cell resolution of 2.5 square miles. This 2.5-square mile resolution
                    limited the degree of detail our team could obtain. The grid cell resolution allowed us to determine only general
                    trends across the grid cells containing the resorts’ locations rather than pinpoint precise impacts at the bases and
                    summits of the five resorts. A more precise effort to pinpoint the exact elevations of the resorts’ bases and summits
                    would have been instructive. However, such an effort would have been unlikely to have changed the overall trends
                    revealed in each of the tools. In other words, a more precise determination of the climate impacts to our region’s ski
                    resorts would have required an additional analysis beyond what was available using the Toolbox. Historical snow
                    data available for Spokane’s five ski resorts was also limited in scope and difficult to draw conclusions from. Data
                    from the resorts was only available from 2009–2017 and was averaged for a full year. Our analysis also examined
                    the projected first calendar date with freezing conditions in the fall and the projected last calendar date with freezing
                    conditions in the spring. This was done to determine the broadest possible period of time with freezing temperatures
                    on Mt. Spokane. However, this analysis did not give us a clear picture of the projected length of the snow season or
                    the projected length of the ski season, which would have required a larger analysis beyond the scope performed
                    here. Because the total length of the ski season is critical for the future viability of snow-based recreation at all five
                    Spokane-area ski resorts, it is our hope that this analysis will be carried out at a later date (see <b>Discussion</b> below).
                  </p>
                  <p>
                    Our team made a number of inferences throughout our analysis. For instance, because climate scientists have
                    determined that the high temperature/low snow winter of 2014–2015 resembles the high temperature/low snow
                    conditions projected for Washington state for the middle of this century (2040–2069) (<b>Marlier et al., 2017</b>), we
                    inferred that how Spokane’s five ski resorts fared during the 2014–2015 winter could tell us something about how
                    they might fare under similar conditions projected for the middle of this century. Our team also used multiple
                    proxies throughout our analysis in order to make reasonable inferences (see <b>Variables</b> below).
                  </p>
                  <p>
                    <b>Emissions Scenarios:</b> For this analysis, we utilized both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP
                    8.5) from phase 5 of the Coupled Model Intercomparison Project (<b>Climate Toolbox 2019</b>). The RCP 8.5 scenario
                    simulates in a computer what is likely to happen if greenhouse gases continue to be released into the atmosphere at
                    their current rate, and, as result, warming is allowed to continue at its current upward trajectory throughout this
                    century and beyond. The RCP 4.5 scenario simulates a dramatic reduction in greenhouse gas emissions, so that
                    while warming continues throughout this century, warming starts to level off after 2100. In general, the two
                    scenarios start to diverge around mid-century (2040–2069). At mid-century, warming under RCP 4.5 slows while
                    warming under RCP 8.5 continues at its current rate (<b>CIRC “Human Choice, Warming, & Emissions” 2019</b>). The
                    two scenarios were used side by side in this analysis to evaluate whether the climate impacts under RCP 4.5 and
                    RCP 8.5 are meaningfully different in their impact on snow recreation in the Spokane region. We found that they
                    were.
                  </p>
                  <p>
                    (Note: RCP 4.5 isn’t the lowest emissions scenario used by climate researchers. RCP 2.6 is the lowest emission
                    scenario considered in climate models. However, because the collective global emission pathway has very likely
                    veered off course from that modeled under RCP 2.6, RCP 2.6 is no longer used as the low emission scenario
                    pathway. Since RCP 4.5 is lower than RCP 8.5, this report has adopted the standard used by many in the climate
                    research community: lower to describe RCP 4.5 and high to describe RCP 8.5, rather than lower and higher to
                    describe the two scenarios.)
                  </p>
                  <p>
                    <b>Time Frames:</b> For our analysis, we examined projected future climate for the middle (2040–2069) and late (2070–2099) decades of
                    this century. We chose to focus our analysis on mid-century (2040–2069) because this time horizon seemed soon
                    enough to be meaningful to current policymakers and winter sports enthusiasts. When comparing impacts between
                    the RCP scenarios, we chose to look at late century (2070–2099) because the differences between RCP 4.5 and RCP
                    8.5 become more extreme by late century.
                  </p>
                  <p>
                    <b>Multi-model Means:</b> Many of the data and figures that make up this analysis employ the mean resulting from multiple climate models. In
                    general, the Toolbox uses 18–20 global climate models (GCMs) to visualize its climate projections (temperature,
                    precipitation, etc.) and 10 GCMs to visualize its hydrology projections (snow water equivalent, streamflow, etc.).
                    Using a multi-model mean, as opposed to the results of a single model, is accepted as best practice by the climate
                    science community. However, the multi-model mean does not show the full spread of results from all the GCMs
                    used to create a future projection. Instead, the multi-model mean shows average of that multi-model spread. In other
                    words, actual future climate conditions—when we get to them in the decades ahead—might lie either above or
                    below the multi-model mean of our current projections.
                  </p>
                  <p>
                    <b>Variables:</b> Our analysis began by examining snow measurements taken in the field at each of the five Spokane-area ski resorts
                    for the years 2009–2017 and recorded by the website On the Snow. The data for each resort was available at two
                    elevations: at each resort’s base and summit. This historical record provided us with the following variables:
                  </p>
                  <ul>
                    <li>
                      <i>Average Total Annual Snowfall in inches</i> (<b>On The Snow</b>)
                    </li>
                    <li>
                      <i>Average Annual Snowfall Days</i> (<b>On The Snow</b>)
                    </li>
                    <li>
                      <i>Average Snow Depth</i> in inches at the base and summit for each resort (<b>On The Snow</b>)
                    </li>
                  </ul>
                  <p>
                    Using the Toolbox, we employed simulated historical and projected future data for snow water equivalent (SWE)—
                    a common metric used to determine the amount of water contained in snow present on the ground—as a proxy for
                    the amount of snow on the ground. We compiled SWE data for January 1st . This date was chosen because many
                    resorts make as much as a third of their annual revenue before January 1st (Russell 2015). We gathered data for the
                    following variables:
                  </p>
                  <ul>
                    <li>
                      <i>Total January 1st SWE (in inches)</i> (Future Boxplots Tool)
                    </li>
                    <li>
                      <i>Percent Change in January 1st SWE</i> (Climate Mapper Tool)
                    </li>
                  </ul>
                  <p>
                    The Toolbox also gave us the ability to evaluate indirectly other aspects of snow by utilizing additional climate and
                    hydrology information. This included assessing a ski resorts projected potential to make snow using the following
                    variables:
                  </p>
                  <ul>
                    <li>
                      <i>Total Winter Precipitation</i> (Future Boxplots Tool)
                    </li>
                    <li>
                      <i>Mean Winter Temperature</i> (Future Boxplots Tool)
                    </li>
                    <li>
                      <i>Annual Number of Days Above Freezing</i> (Future Boxplots Tool)
                    </li>
                  </ul>
                  <p>
                    <b>Climate Data Story:</b> While our analysis examined all five Spokane-area ski resorts, our team focused on impacts at Mt. Spokane alone
                    for our climate data story (see <b>Climate Data Story—Mt. Spokane Ski & Snowboard Park</b> above). A climate data
                    story is defined by CIRC as “a narrative outlining climate facts and impacts specific to your community” (<b>Mooney et al., 2019</b>).
                  </p>
                </div>
              </div>
              <div id="historical-climate" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Historical Climate - The 2015 Snow Drought</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Variable:</b> <i>Average Total Annual Snowfall; Average Annual Snowfall Days; Average Snow Depth at Base; Average
                    Snow Depth at Summit; Total January–March Precipitation; Mean Maximum January–March Temperature</i>
                  </p>
                  <p>
                    <b>Finding:</b> All five Spokane-area ski resorts show responses to the larger climate trends that occurred across the
                    Western United States and the state of Washington in recent years. Specifically, all five resorts appear to have been
                    impacted by the snow drought that occurred during the winter of 2014–2015.
                  </p>
                  <p>
                    <b>Justification:</b> The historical climate data reported in the figures and tables below only capture a short period of time
                    (2009–2017). They therefore should not be used to draw far-reaching conclusions about larger trends regarding
                    impacts from climate change over longer and projected future periods of time. However, the metrics noted in
                    <b>Figures 1–4</b> show what appears to be a clear snow drought signal during the winter of 2014–2015. During the 2014-
                    –2015 winter (2014 in the figures), all five Spokane-area resorts saw declines in Average Total Annual Snowfall
                    (<b>Figure 1</b>), Average Annual Snowfall Days (<b>Figure 2</b>), and Average Snow Depth recorded at the resorts’ bases and
                    summits (<b>Figures 3, 4) (Table 2</b>).
                  </p>
                  <p>
                    During the period October 1st, 2014 to September 30th, 2015 (the 2015 water year), record low snow levels were
                    recorded at 80% of mountain recording sites across the Western United States. In spring 2015, snow levels in the
                    state of Washington (as measured as snow water equivalent recorded on April 1st) tied for lowest on record (<b>Mote et al., 2016</b>).
                  </p>
                  <p>
                    A snow drought occurs when a region receives a less-than-adequate amount of snow. Snow droughts can result from
                    insufficient precipitation leading to declines in snow or warm temperatures and near normal levels of precipitation
                    leading to declines in snow (<b>Harpold et al., 2017</b>). According to meteorological data collected at the time, the 2015
                    snow drought in Washington (<b>Marlier et al., 2017</b>) and the Western US as a whole (<b>Mote et al., 2016</b>) was the
                    result of warm temperatures rather than a lack of precipitation. Precipitation levels during the 2014–2015 winter
                    were at near normal levels for Washington and the Western US generally. However, temperatures during this period
                    were above average (<b>Mote et al., 2016; Marlier et al., 2017; Harpold et al., 2017</b>). In Washington, the 2014–2015
                    winter was 3.8 degrees Fahrenheit warmer on average than winters over the period 1950–2015 (<b>Marlier et al., 2017</b>). 
                    If we examine Mt. Spokane, we can see how the 2014–2015 snow drought played out at the local scale.
                  </p>
                  <p>
                    From January to March 2015, Mt. Spokane saw 12.3 inches of precipitation. This is near the average of 13.0′′ for the
                    period 1979–2019 (<b>Figure 5</b>). However, temperatures during this period were above normal. For instance, mean
                    maximum daily temperatures at Mt. Spokane during January–March 2015 was 41.3 °Fcompared to an average of
                    36.0 °F over the same three-month period for the years 1979–2019 (<b>Figure 6</b>).
                  </p>
                  <p>
                    The abnormally warm temperatures during the winter of 2014–2015 can be seen reflected in the record low snow
                    numbers recorded at all five Spokane-area ski resorts during the same period (<b>Figures, 1, 3, 4; Table 2</b>). For
                    instance, average annual snow accumulated and recorded at Mt. Spokane from 2009 to 2017 was 144′′, but during
                    the snow drought of 2014–2015, average accumulated snow fall recorded on the mountain was just 67′′ (<b>Table 2</b>).
                    Similarly snow depth at Mt. Spokane’s base averaged 43.2′′ from 2009 to 2017, but measured only 17′′ during the
                    2014–2015 winter (<b>Table 2</b>). This strongly suggests that the high January-to-March temperatures caused
                    precipitation to fall as rain rather than as snow. The data also suggest that warm temperatures likely led to snow
                    melting on the ground, leading to the observed reduction in average snow depths.
                  </p>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Annual snowfall" src="/media/snow/Fig-1-Snow.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 1:</b> Annual Snowfall, 2009–2017. Average Annual Snowfall (in accumulated inches) recorded at the five resorts (Mt.
                            Spokane; 49 Degrees North; Silver Mountain; Schweitzer; and Lookout) for the years 2009–2017. Snowfall in inches are
                            displayed on the y-axis (left vertical). Years are displayed on the x-axis (bottom horizontal). Due to the calendar change that
                            occurs over the winter months, the year designation includes both that year and the year that follows it. For instance, 2014
                            represents the winter that occurred over the calendar years 2014–2015. Note: Schweitzer Mountain is missing data for 2009.
                            Source: On the Snow (www.onthesnow.com).
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Annual snowfall days" src="/media/snow/Fig-2-Snow.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 2:</b> Average Annual Snowfall Days, 2009–2017. Snowfall days recorded at the five resorts for the years 2009–2017.
                            “Snowfall days” are defined as days where snowfall is greater than 1 inch. Days with snowfall are displayed on the y-axis (left
                            vertical). Years are displayed on the x-axis (bottom horizontal). Note: Schweitzer Mountain is missing data for the winter of
                            2009. Source: On the Snow (www.onthesnow.com).
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Average winter base depth" src="/media/snow/Fig-3-Snow.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 3:</b> Winter Base Snow Depth, 2009–2017. Average snow depth (in inches) recorded at the five resorts’ bases from
                            December to March for the years 2009–2017. Note: Schweitzer Mountain is missing data for the winter of 2009. Source: On the
                            Snow (www.onthesnow.com).
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Average winter summit depth" src="/media/snow/Fig-4-Snow.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 4:</b> Winter Summit Snow Depth, 2009–2017. Average snow depth (in inches) recorded at the five resorts’ summits from
                            December to March for the years 2009–2017. Note: Schweitzer Mountain is missing data for the winter of 2009. Source: On the
                            Snow (www.onthesnow.com).
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="precipitation-low" src="/media/snow/Fig-5-Snow.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <b>Figure 5:</b> Total precipitation (in inches) at Mt. Spokane for January–March 2015 (simulated historical) and January–March
                          1979–2019 (simulated historical). Inches of precipitation are displayed on the y-axis (left vertical). Years are displayed on the x-
                          axis (bottom horizontal). Source: Historical Climate Tracker (https://climatetoolbox.org/tool/Historical-Climate-Tracker), The
                          Climate Toolbox.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="precipitation-low" src="/media/snow/Fig-6-Snow.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <b>Figure 6:</b> Average maximum temperature in degrees Fahrenheit at Mt. Spokane for January–March 2015 (simulated historical)
                          and January–March 1979–2019 (simulated historical). Degrees Fahrenheit are displayed on the y-axis (left vertical). Years are
                          displayed on the x-axis (bottom horizontal). Source: Historical Climate Tracker (https://climatetoolbox.org/tool/Historical-
                          Climate-Tracker), The Climate Toolbox.
                        </p>
                      </div>
                    </div>
                  </div>
                  <p>
                    <b>Table 2:</b> <i> Average total snowfall (in inches) observed at the five Spokane-area ski resorts, and observed average snow depth (in
                    inches) at the five resorts’ bases and summits for the years 2009–2017 and for the “snow drought” winter of 2014–2015. Source:
                    On The Snow (www.onthesnow.com).</i>
                  </p>
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Resort</th>
                        <th scope="col">2009–2017 Average Total Annual Snowfall (inches)</th>
                        <th scope="col">2014–2015 (Snow Drought) Total Annual Snowfall (inches)</th>
                        <th scope="col">2009–2017 Average Snow Depth at Base(inches)</th>
                        <th scope="col">2014–2015 (Snow Drought) Average Snow Depth at Base (inches)</th>
                        <th scope="col">2009–2017 Average Snow Depth at Summit (inches)</th>
                        <th scope="col">2014–2015 (Snow Drought) Average Snow Depth at Summit (inches)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Mt. Spokane</th>
                        <td>144″</td>
                        <td>67″</td>
                        <td>43.2″</td>
                        <td>17″</td>
                        <td>67.6″</td>
                        <td>36″</td>
                      </tr>
                      <tr>
                        <th scope="row">49 Degrees North</th>
                        <td>215″</td>
                        <td>57″</td>
                        <td>50.2″</td>
                        <td>20″</td>
                        <td>89.1″</td>
                        <td>37″</td>
                      </tr>
                      <tr>
                        <th scope="row">Silver Mountain</th>
                        <td>256.8″</td>
                        <td>92″</td>
                        <td>49″</td>
                        <td>33″</td>
                        <td>67.6″</td>
                        <td>46″</td>
                      </tr>
                      <tr>
                        <th scope="row">Schweitzer Mountain</th>
                        <td>229″</td>
                        <td>113″</td>
                        <td>50.4″</td>
                        <td>12″</td>
                        <td>87.8″</td>
                        <td>48″</td>
                      </tr>
                      <tr>
                        <th scope="row">Lookout Pass</th>
                        <td>345.4″</td>
                        <td>167″</td>
                        <td>58″</td>
                        <td>29″</td>
                        <td>90″</td>
                        <td>53″</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    <b>Finding:</b> Not one of Spokane’s five ski resorts is likely to be insulated from future climate changes. Some resorts
                    are likely to fare better than others.
                  </p>
                  <p>
                    <b>Justification:</b> The available data recorded at all five Spokane-area ski resorts indicates that all five resorts have
                    responded to the climate events of recent years and that no one resort has been insulated from the declines in snow
                    associated with the snow drought of 2014–2015 (2014 in the figures) (<b>Figures 1–4</b>). If we consider the available ski
                    resort data recorded during the winter of 2014–2015 in conjunction with the finding that the climate conditions that
                    led to the recent snow drought look similar to projections for mid-century (2040–2069) (<b>Marlier et al., 2017</b>), we
                    can make the reasonable inference that snow conditions similar to those that occurred during the winter of 2014–
                    2015 may occur more commonly by mid-century. What’s more, by comparing the recent snow drought to the snow
                    drought conditions projected for mid-century, we can infer that all five resorts are very likely to see less snow under
                    a warming climate.
                  </p>
                  <p>
                    The resort-specific data demonstrates variability from resort-to-resort for observed annual snowfall (<b>Figure 1</b>),
                    snowfall days (<b>Figure 2</b>), and snow depth (<b>Figures 3 and 4</b>). These variations can likely be attributed to differences
                    in elevation and latitude at the respective resorts (<b>Table 3</b>). (Aspect, for instance, whether a resort faces north—away
                    from the sun—or south—toward the sun—was not explored in this analysis.) For example, of the five resorts
                    Lookout Pass has the highest base elevation (4,500 ft.) and consistently reported the highest snow depths, snowfall,
                    and days with snow for each year (<b>Figure 1–3</b>).
                  </p>
                  <p>
                    If we consider all our findings together, we may infer three things:
                  </p>
                  <ol>
                    <li>
                      Because all five resorts showed clear declines in snow that corresponded with the 2014–2015 winter’s
                      abnormally warm temperatures (<b>Figures 1–4</b>), all five resorts are therefore likely to see similar declines
                      under the similarly warm temperatures projected for mid-century under RCP 4.5.
                    </li>
                    <li>
                      Likewise, because some of the five resorts clearly fared better than others during the 2014–2015 winter’s
                      snow drought, some of the five resorts will likely fare better than others during similar snow drought
                      conditions projected for mid-century under RCP 4.5.
                    </li>
                    <li>
                      We might also reasonably infer that the reason some of the five resorts fared slightly better during 2014–
                      2015 had to do with difference in elevation and latitude (<b>Table 3</b>) at the various resorts; because of this,
                      latitude and elevation differences are likely to be key factors in the future success of the five resorts in a
                      warming climate.
                    </li>
                  </ol>
                  <p>
                    We should also note here that all five ski resorts in the Spokane region are at relatively low elevations. Elevation and
                    temperature are closely related. Temperatures go down with elevation gains and go up with elevation loss. The
                    elevation of a resort has direct implications to its sensitivity to rising temperatures projected under climate change.
                    Being at lower elevations makes Spokane’s ski resorts more sensitive to projected warming than higher (and
                    consequently colder) resorts in the U.S. West (<b>Abatzoglou 2019</b>).
                  </p>
                  <p>
                    <i>
                      <b>Table 3:</b> Geography of Ski Resort Locations. Latitude/Longitudes were assessed from geo-locating the name of the resort using
                      the Google search engine. Source: Google (www.google.com). The elevations of the base and summits for each resort were
                      located from the website On the Snow. Source: On the Snow (www.onthesnow.com).
                    </i>
                  </p>
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Resort</th>
                        <th scope="col">Latitude (degrees N)</th>
                        <th scope="col">Longitude (degrees W)</th>
                        <th scope="col">Elevation (Base)</th>
                        <th scope="col">Elevation (Summit)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Mt. Spokane</th>
                        <td>47.9214 °N</td>
                        <td>117.0964 °W</td>
                        <td>4,200 ft.</td>
                        <td>5,886 ft.</td>
                      </tr>
                      <tr>
                        <th scope="row">49 Degrees North</th>
                        <td>48.3011 °N</td>
                        <td>117.5629 °W</td>
                        <td>3,923 ft.</td>
                        <td>5,774 ft.</td>
                      </tr>
                      <tr>
                        <th scope="row">Silver Mountain</th>
                        <td>47.5407 °N</td>
                        <td>116.1332 °W</td>
                        <td>4,100 ft.</td>
                        <td>6,300 ft.</td>
                      </tr>
                      <tr>
                        <th scope="row">Schweitzer Mountain</th>
                        <td>48.368 °N</td>
                        <td>116.6227 °W</td>
                        <td>4,000 ft.</td>
                        <td>6,400 ft.</td>
                      </tr>
                      <tr>
                        <th scope="row">Lookout Pass</th>
                        <td>47.456 °N</td>
                        <td>115.6973 °W</td>
                        <td>4,500 ft.</td>
                        <td>5,650 ft.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="projected-future-climate-precipitation" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Projected Future Climate — Precipitation Projected to Increase, Snow Projected to Decrease</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Variables:</b> <i>Mean Winter (December–February) Precipitation; Mean Winter (December–February) Temperature;
                    January 1st Snow Water Equivalent (SWE); Change in January 1st Snow Water Equivalent (SWE)</i>
                  </p>
                  <p>
                    <b>Finding:</b> All five Spokane-area ski resorts see declines in snow under both the lower and high emission scenarios
                    (RCP 4.5 and RCP 8.5).
                  </p>
                  <p>
                    <b>Justification:</b> Our investigation considered projected future changes in mean winter (December–February)
                    precipitation and temperature for all five Spokane-area ski resorts. We also considered projected future changes in
                    snow water equivalent (SWE)—the amount of liquid water in a given amount of snow—for all five resorts. Our
                    analysis focused in particular on the difference between the lower emissions (RCP 4.5) and high emissions (RCP
                    8.5) scenarios at mid-century (2040–2069) and late century (2070–2099).
                  </p>
                  <p>
                    As noted in the <b>Precipitation</b> chapter of this report, the timing and volume of precipitation in the Spokane region is
                    not expected to change dramatically over the course of this century. The Spokane region is projected to see a slight
                    increase in annual precipitation, a slight decrease in precipitation during the summer months, and a slight increase in
                    precipitation during the fall, winter, and spring months. However, despite projected future increases in annual
                    precipitation, the primary concern for snow-based businesses and recreation is not the amount of precipitation that
                    will fall but the form that precipitation will take. Throughout this century, temperatures are projected to rise in the
                    Spokane region. This will lead to precipitation falling more as rain, rather than as snow.
                  </p>
                  <p>
                    For the purposes of our analysis, we looked at precipitation during the height of the winter ski season (December–
                    February) at Mt. Spokane. By mid-century under RCP 4.5, mean winter precipitation at Mt. Spokane is projected to
                    increase from a historical (1971–2000) mean of 14.6 inches to 15.8′′ (<b>Figure 7</b>), according to the Toolbox’s Future
                    Boxplots Tool. By mid-century under RCP 8.5, mean winter precipitation at Mt. Spokane is projected to reach 16.0′′
                    (<b>Figure 8</b>). By late century (2070–2099), mean winter precipitation at Mt. Spokane is projected to reach 15.9′′
                    under RCP 4.5 (<b>Figure 7</b>) and 17.0′′ under RCP 8.5 (<b>Figure 8</b>). However, more precipitation does not mean more
                    snow. The projected rise in precipitation corresponds with a projected rise in mean temperature.
                  </p>
                  <p>
                    By mid-century, mean winter temperatures at Mt. Spokane are projected to rise from the historical (1971–2000)
                    mean of 25.9 °F to 30.6 °F under RCP 4.5 and to 31.8 °F under RCP 8.5 (<b>Table 5</b>). As temperatures warm,
                    precipitation becomes far more likely to fall as rain, rather than as snow, and snow on the ground becomes far more
                    likely to melt earlier in the winter season, both of which result in reduced snow on the ground. By mid-century the
                    amount of snow water equivalent (SWE) expected by January 1st is projected to decline at Mt. Spokane from a
                    historical (1971–2000) mean of 10.7′′ to 8.8′′ under RCP 4.5 (<b>Figure 9</b>) and to 7.5′′ under RCP 8.5 (<b>Figure 10</b>).
                  </p>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="dec-jan-feb-precipitation" src="/media/snow/Fig-7-Snow.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 7:</b> Mean winter (December to February) precipitation (in inches) at Mt. Spokane for the simulated historical period
                            1971–2000 and the projected future periods 2010–2039, 2040–2069, and 2070–2099 under the lower emissions scenario (RCP
                            4.5). Inches of precipitation are displayed on the y-axis (left vertical). Years are displayed on the x-axis (bottom horizontal). The
                            results of each of the 20 models used in the analysis are represented by individual points. The multi-model mean is indicated by a
                            solid bar. Source: Future Boxplots Tool (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="dec-jan-feb precipitation 8.5" src="/media/snow/Fig-8-Snow.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 8:</b> Mean winter (December to February) precipitation (in inches) at Mt. Spokane for the simulated historical period
                            1971–2000 and the projected future periods 2010–2039, 2040–2069, and 2070–2099 under the high emissions scenario (RCP
                            8.5). Inches of precipitation are displayed on the y-axis (left vertical). Years are displayed on the x-axis (bottom horizontal). The
                            results of each of the 20 models used in the analysis are represented by individual points. The multi-model mean is indicated by a
                            solid bar. Source: Future Boxplots Tool (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="dec-jan-feb 8.5" src="/media/snow/Fig-9-Snow.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 9:</b> January 1st snow water equivalent (SWE) (in inches) at Mt. Spokane for simulated historical years 1971–2000 and
                            projected future years 2010–2039, 2040–2069, and 2070–2099 under the lower emissions scenario (RCP 4.5). Inches of SWE are
                            displayed on the y-axis (left vertical). Years are displayed on the x-axis (bottom horizontal). The results of each of the 10 models
                            used in the analysis are represented by individual points. The multi-model mean is indicated by a solid bar. Source Future
                            Bloxplots Tool (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="jan 1st Snow water equivalent 8.5" src="/media/snow/Fig-10-Snow.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 10:</b> January 1st snow water equivalent (SWE) (in inches) at Mt. Spokane for simulated historical years 1971–2000 and
                            projected future years 2010–2039, 2040–2069, and 2070–2099 under the high emissions scenario (RCP 8.5). Inches of SWE are
                            displayed on the y-axis (left vertical). Years are displayed on the x-axis (bottom horizontal). The results of each of the 10 models
                            used in the analysis are represented by individual points. The multi-model mean is indicated by a solid bar. Source Future
                            Bloxplots Tool (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p>
                    <b>Finding:</b> The difference between the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5)
                    could mean the difference between a degraded but viable ski industry (RCP 4.5) and a nonviable ski industry (RCP
                    8.5) for all five Spokane-area ski resorts.
                  </p>
                  <p>
                    <b>Justification:</b> January 1st snow water equivalent (SWE) projections for mid-century (2040–2069) at all five
                    Spokane-area ski resorts tell a similar story. By mid-century, declines in January 1st SWE are projected to occur at
                    all five resorts under both the lower emissions scenario (RCP 4.5) (<b>Figure 11</b>) and the high emissions scenario (RCP
                    8.5) (<b>Figure 12</b>). In all cases, the projected declines in SWE are notably different between RCP 4.5 and RCP 8.5
                    (<b>Table 4</b>). To take just one example, Mt. Spokane is projected to see a decline of 9% under RCP 4.5 and a decline of
                    30% under RCP 8.5. In other words, by mid-century the difference between the lower emissions scenario (RCP 4.5)
                    and the high emissions scenario (RCP 8.5) could mean the difference between a degraded but viable ski season
                    (RCP 4.5) and a nonviable ski industry (RCP 8.5) for all five resorts.
                  </p>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="jan 1st Snow water equivalent 8.5" src="/media/snow/Fig-11-Snow.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 11:</b> Projected future January 1st snow water equivalent (SWE) (in inches and as percent change from simulated historical
                            period 1971–2000) for all five Spokane-area ski resorts for the middle of this century (2040–2069) under the lower emissions
                            scenario (RCP 4.5). The percent changes shown here represent the multi-model mean resulting from 10 climate models. Source:
                            The Climate Mapper Tool (<a href="https://climatetoolbox.org/tool/Climate-Mapper">https://climatetoolbox.org/tool/Climate-Mapper</a>), The Climate Toolbox. 
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="jan 1st Snow water equivalent 8.5" src="/media/snow/Fig-12-Snow.png"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 12:</b> Projected future January 1st snow water equivalent (SWE) (in inches and as percentage change from the simulated
                            historical period 1971–2000) for all five Spokane-area ski resorts for the middle of this century (2040–2069) under the high
                            emissions scenario (RCP 8.5). The percent changes shown here represent the multi-model mean resulting from 10 climate
                            models. Source: The Climate Mapper Tool (<a href='https://climatetoolbox.org/tool/Climate-Mapper'>https://climatetoolbox.org/tool/Climate-Mapper</a>),The Climate Toolbox
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p>
                    <i>
                      <b>Table 4:</b> Projected future January 1st snow water equivalent (SWE) at all five Spokane-area resorts expressed as percentage from
                      the historical period 1971–2000 for the years 2040–2069 under both the lower emissions scenario (RCP 4.5) and the high
                      emissions scenario (RCP 8.5) Source: The Climate Mapper Tool (https://climatetoolbox.org/tool/Climate-Mapper), The Climate
                      Toolbox.
                    </i>
                  </p>
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Resort</th>
                        <th scope="col">RCP 4.5 2040–2069 Projected Future January 1st Snow Water Equivalent (SWE) Percent Change (%)</th>
                        <th scope="col">RCP 8.5 2040–2069 Projected Future January 1st Snow Water Equivalent (SWE) Percent Change (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Mt. Spokane</th>
                        <td>-9%</td>
                        <td>-30%</td>
                      </tr>
                      <tr>
                        <th scope="row">49 Degrees North</th>
                        <td>–5%</td>
                        <td>-16%</td>
                      </tr>
                      <tr>
                        <th scope="row">Silver Mountain</th>
                        <td>-25%</td>
                        <td>-59%</td>
                      </tr>
                      <tr>
                        <th scope="row">Schweitzer Mountain</th>
                        <td>-13%</td>
                        <td>-40%</td>
                      </tr>
                      <tr>
                        <th scope="row">Lookout Pass</th>
                        <td>-9%</td>
                        <td>-31%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="projected-future-climate-rise" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Projected Future Climate — Rise in Mean Temperature and Fewer Freezing Days Limits
                  Snowfall and Snowmaking Opportunities</h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Variables:</b> <i>Mean Winter (December–February) Temperature; Days with a Minimum Temperature Above 32°F</i>
                  </p>
                  <p>
                    <b>Finding:</b> By mid-century (2040–2069), mean winter (December–February) temperatures at Mt. Spokane are
                    projected to rise from a historical (1971–2000) mean of 25.9 degrees Fahrenheit to 30.6 °F under the lower
                    emissions scenario (RCP 4.5) and 31.8 °F under the high emissions scenario (RCP 8.5).
                  </p>
                  <p>
                    <b>Finding:</b> Mt. Spokane is projected to see 41 to 58 fewer freezing days by mid-century (2040–2069), compared to
                    the last third of the twentieth century (1971–2000).
                  </p>
                  <p>
                    <b>Finding:</b> Precipitation projections for Spokane show a slight increase in annual precipitation, with a slight increase
                    in precipitation during the fall, winter, and spring months, and a slight decrease in precipitation during the summer
                    months.
                  </p>
                  <p>
                    <b>Finding:</b> Given what we know about both temperature and precipitation projections for this century, investments in
                    snowmaking equipment might be ill-advised for all five Spokane-area ski resorts.
                  </p>
                  <p>
                    <b>Justification:</b> To have more control over the start of the ski season, many ski resorts in the Pacific Northwest have
                    chosen to purchase costly snowmaking equipment. According to a 2015 report in the Spokesman-Review, a
                    feasibility study commissioned by the Bogus Basin ski resort in southern Idaho concluded that the resort would need
                    “extensive snowmaking to be a sustainable business” (<b>Russell 2015</b>). Snowmaking equipment has already been
                    deployed on runs at Schweitzer Mountain, Silver Mountain, and 49 Degrees North (<b>Russell 2015</b>). As of October
                    2019, both Mt. Spokane (<b>Copeland 2019</b>) and Lookout Pass (<b>Sawyer 2019</b>) do not use snowmaking equipment in
                    their operations. Advances in snowmaking technology have brought down prices, but the systems still cost millions
                    of dollars. For instance, at the time of the 2015 reporting by the Spokesman-Review, “first phase of its snowmaking
                    system” at Bogus Basin was estimated to cost $4 million to install (<b>Russell 2015</b>). However, given what we know
                    about both temperature and precipitation projections for this century, investments in snowmaking equipment might
                    be ill-advised for many ski resorts in the Pacific Northwest. Projected changes in temperature could create
                    conditions where, even with expensive snowmaking equipment, it may not be possible to create snow, especially
                    early in the season.
                  </p>
                  <p>
                    When considering whether to buy costly snowmaking equipment, ski resorts in the Spokane area would be well-
                    advised to consider two climate factors: precipitation and temperature.
                  </p>
                  <p>
                    Snowmaking is best employed when winter season precipitation is low and temperatures remain low enough to
                    make snow. Over the course of this century, Spokane and the Pacific Northwest generally are projected to have both
                    adequate amounts of precipitation during the winter months and temperatures that will likely be too warm for
                    snowmaking.
                  </p>
                  <p>
                    The <b>Precipitation</b> chapter of this report found that precipitation projections for Spokane show a slight increase in
                    annual precipitation, with a slight increase in precipitation during the fall, winter, and spring months, and a slight
                    decrease in precipitation during the summer months. However, these projections do not preclude the existence of
                    periodic future droughts due to low precipitation. Snowmaking is best employed when precipitation levels are low
                    and temperatures also remain low. If we consider just precipitation on its own, snowmaking would only be advisable
                    during periods when precipitation during the winter months was lower than the projected average, in other words,
                    during the less-frequent occurrences of periodic precipitation droughts. However, when we consider precipitation
                    projections in conjunction with temperature projections, snowmaking becomes less advisable.
                  </p>
                  <p>
                    Standard snowmaking equipment requires temperatures below 30 °F, ideally in the 20s or teens Fahrenheit (<b>Wobus et al., 2017</b>). 
                    Working from this knowledge, our team addressed the question of snowmaking by analyzing mean
                    temperature (<b>Table 5</b>) and days above freezing (<b>Table 6</b>). By mid-century (2040–2069), the mean temperature
                    during the prime ski season (December–February) at all five Spokane-area ski resorts is projected to rise under both
                    the lower (RCP 4.5) and high emissions scenario (RCP 8.5). At Mt. Spokane, the mean temperature is expected to
                    rise from a historical mean of 25.9 °F to 30.6 °F under RCP 4.5 and to 31.8 °F under RCP 8.5. By late century
                    (2070–2099), the difference between the two scenarios becomes more notable with 31.5 °F projected for RCP 4.5
                    and 35.4 °F for RCP 8.5. The pattern observed for Mt. Spokane was true for all five resorts. All five resorts see more
                    warming under RCP 8.5 than they do under RCP 4.5 (<b>Table 5</b>).
                  </p>
                  <p>
                    <i>
                      <b>Table 5:</b> Simulated historical mean winter (December–February) temperature (in degrees Fahrenheit) at all five Spokane-area
                      ski resorts for the years 1971–2000; and projected future mean temperature at all five Spokane-area ski resorts for both the
                      lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5) for the years 2040–2069 and 2070–2099. Source:
                      Future Boxplots Tool (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                    </i>
                  </p>
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Resort</th>
                        <th scope="col">1971-2000 Simulated Historical December–February Mean Temperature Degrees Fahrenheit (°F)</th>
                        <th scope="col">RCP 4.5 2040–2069 Projected Future December–February Mean Temperature (°F)</th>
                        <th scope="col">RCP 8.5 2040-2069 Projected Future (RCP 8.5) December–February Mean Temperature (°F)</th>
                        <th scope="col">RCP 8.5 2040-2069 Projected Future (RCP 8.5) December–February MeanTemperature (°F)</th>
                        <th scope="col">RCP 8.5 2070–2099 Projected Future December–February Mean Temperature (°F)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Mt. Spokane</th>
                        <td>25.9 °F</td>
                        <td>30.6 °F</td>
                        <td>31.8 °F</td>
                        <td>31.5 °F</td>
                        <td>35.4 °F</td>
                      </tr>
                      <tr>
                        <th scope="row">49 Degrees North</th>
                        <td>23.8 °F</td>
                        <td>28.5 °F</td>
                        <td>29.7 °F</td>
                        <td>29.5 °F</td>
                        <td>33.4 °F</td>
                      </tr>
                      <tr>
                        <th scope="row">Silver Mountain</th>
                        <td>28.2 °F</td>
                        <td>32.9 °F</td>
                        <td>34.1 °F</td>
                        <td>33.8 °F</td>
                        <td>37.6 °F</td>
                      </tr>
                      <tr>
                        <th scope="row">Schweitzer Mountain</th>
                        <td>24.9 °F</td>
                        <td>29.6 °F</td>
                        <td>30.8 °F</td>
                        <td>30.5 °F</td>
                        <td>34.5 °F</td>
                      </tr>
                      <tr>
                        <th scope="row">Lookout Pass</th>
                        <td>24.4 °F</td>
                        <td>20.0 °F</td>
                        <td>30.2 °F</td>
                        <td>30.0 °F</td>
                        <td>33.9 °F</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    Another way to examine the projected rise in temperatures at the five Spokane-area ski resorts is to examine the
                    number of days with freezing conditions. To do this, our team used the Toolbox variable Days with a Minimum
                    Temperature Above 32 °F (<b>Table 6</b>). All five Spokane-area ski resorts are projected to see an increase in the number
                    of days above freezing under both RCP 4.5 and RCP 8.5 when compared to the historical average, according to our
                    analysis. Consider just Mt. Spokane, which is projected to see an increase in the number of days above freezing
                    from a historical (1971–2000) mean of 196 days to 238 days by mid-century (2040–2069) under RCP 4.5 and 254
                    days by mid-century under RCP 8.5. For further illustration these numbers can be seen in <b>Figure 13</b> (RCP 4.5) and
                    <b>Figure 14</b> (RCP 8.5). These numbers can be inverted (assuming a 365-day year) to infer projections of the number
                    of days below freezing at Mt. Spokane at mid-century from a historical average of 169 days a year to 127 days under
                    RCP 4.5 and 111 freezing days under RCP 8.5. In other words, Mt. Spokane is projected to see 42 (RCP 4.5) to 58
                    (RCP 8.5) fewer freezing days by mid-century compared to 1971–2000.
                  </p>
                  <p>
                    The projected rise in temperature (as illustrated by both the rise in mean temperature and the increase in the number
                    of days above freezing/fewer days below freezing), suggests that there will be fewer days in the future that will be
                    cold enough to make snow. Moreover, there are expected to be more winter days when snow is melting at Mt.
                    Spokane and the other four Spokane-area resorts. This will likely result in less commerce around winter sports (lift
                    tickets, lodging, food, equipment, etc.) and is expected to negatively impact local businesses. Due to increased
                    temperatures, it is likely to be increasingly difficult to make snow for much of the season. Considering the projected
                    increase in temperatures alongside the projected increase in winter precipitation, snowmaking is unlikely to be
                    advisable even if a resort goes to the time and expense of installing the infrastructure to do so.
                  </p>
                  <p>
                    <i>
                      <b>Table 6:</b> Simulated historical number of days annually above 32°F or the years 1971–2000 and projected future number of days
                      annually above 32°F for all five Spokane-area ski resorts for both the lower emissions scenario (RCP 4.5) and the high emissions
                      scenario (RCP 8.5) for the years 2040–2069 and 2070–2099. Source: Future Boxplots Tool
                      (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                    </i>
                  </p>
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Resort</th>
                        <th scope="col">1971–2000 Simulated Historical Number of days with Min Temp above 32°F (days)</th>
                        <th scope="col">RCP 4.5 2040–2069 Projected Number of Future Days with Min Temp above 32°F (days)</th>
                        <th scope="col">RCP 8.5 2040–2069 Projected Number of Future Days with Min Temp above 32°F (days)</th>
                        <th scope="col">RCP 4.5 2070–2099 Projected Number of Future Days with Min Temp above 32°F (days)</th>
                        <th scope="col">RCP 8.5 2070–2099 Projected Number of Future Days with Min Temp above 32°F (days)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Mt. Spokane</th>
                        <td>196 days</td>
                        <td>238 days</td>
                        <td>254 days</td>
                        <td>251 days</td>
                        <td>293 days</td>
                      </tr>
                      <tr>
                        <th scope="row">49 Degrees North</th>
                        <td>166 days</td>
                        <td>201 days</td>
                        <td>247 days</td>
                        <td>213 days</td>
                        <td>255 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Silver Mountain</th>
                        <td>204 days</td>
                        <td>257 days</td>
                        <td>273 days</td>
                        <td>270 days</td>
                        <td>308 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Schweitzer Mountain</th>
                        <td>182 days</td>
                        <td>261 days</td>
                        <td>278 days</td>
                        <td>238 days</td>
                        <td>282 days</td>
                      </tr>
                      <tr>
                        <th scope="row">Lookout Pass</th>
                        <td>185 days</td>
                        <td>227 days</td>
                        <td>242 days</td>
                        <td>240 days</td>
                        <td>282 days</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Days with min temp above 32 rcp 4.5" src="/media/snow/Fig-13-Snow.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 13:</b> Number of days above freezing at Mt. Spokane simulated for historical years 1971–2000 and projected future years
                            2010–2039, 2040–2069, and 2070–2099 under the lower emissions scenario (RCP 4.5). The number of days is displayed on the
                            y-axis (left vertical). Years are displayed on the x-axis (bottom horizontal). The results of each of the 20 models used in the
                            analysis are represented by individual points. The multi-model mean is indicated by a solid bar. Source: Future Boxplots Tool
                            (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="card-body">
                        <img className="img-fluid border border-dark" alt="Days with min temp above 32 rcp 8.5" src="/media/snow/Fig-14-Snow.jpeg"></img>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-body">
                        <p>
                          <i>
                            <b>Figure 14:</b> Number of days above freezing at Mt. Spokane simulated for historical years 1971–2000 and projected future years
                            2010–2039, 2040–2069, and 2070–2099 under the high emissions scenario (RCP 8.5). The number of days is displayed on the y-
                            axis (left vertical). Years are displayed on the x-axis (bottom horizontal). The results of each of the 20 models used in the analysis
                            are represented by individual points. The multi-model mean is indicated by a solid bar. The results of each of the 20 models used
                            in the analysis are represented by individual points. The multi-model mean is indicated by a solid bar. Source: Future Boxplots
                            Tool (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="conclusion" className="card border-dark bg-light mb-3">
                <div className="card-header"><h4>Conclusion - Impacts to Snow-Based Recreation </h4></div>
                <div className="card-body">
                  <h6 className="card-subtitle mb-2 text-muted">
                    <p><i>
                      “Future risks from climate change depend primarily on decisions made today.”
                      – Fourth National Climate Assessment
                    </i></p>
                  </h6>
                  <p>
                    Planning on the part of winter recreation industries in the Spokane-region will be necessary to accommodate warmer
                    winters with less snow and more rain. It would be wise for Spokane’s ski resorts to begin to diversify their business
                    models to emphasize warmer weather recreation activities. In the next 30–60 years, it would also be prudent for
                    resorts to reconsider expanding operations and especially costly investments in snowmaking equipment that may not
                    be viable later in the century.
                  </p>
                  <p>
                    The future viability of winter recreation in the Spokane area is dependent on future emissions levels, namely
                    whether we stay on the high emissions path of RCP 8.5 or reduce emissions and move to the lower emissions path of
                    RCP 4.5. Our research suggests that reducing greenhouse gas emissions—moving from our current path on the high
                    emissions scenario (RCP 8.5) to the lower emissions scenario (RCP 4.5)—could mean the difference between a
                    degraded but viable ski industry (RCP 4.5) and a nonviable ski industry (RCP 8.5) for all five resorts. It is also clear
                    that without considerable reductions in emissions, snow-dependent recreation will not be viable within a few
                    decades.
                  </p>
                  <p>
                    This would suggest that ski industries, skiers, snowboarders, and others who enjoy winter recreation should be
                    aware that systemic societal transformations are necessary to achieve a future closer to the lower emission scenario
                    (RCP 4.5), and thus a climate that supports viable winter recreation activities, industries, and jobs.
                  </p>
                  <p>
                    The “business-as-usual” high emission scenario (RCP 8.5) is possible, but not inevitable. The negative outcomes of
                    human-caused climate change can be mitigated by swift and significant reductions in greenhouse gas emissions.
                    Individual choices, business practices and government policy are all necessary components of an effective strategy
                    to prevent the climate impacts associated with the high emissions scenario. Many solutions to prevent dangerous
                    climate conditions are currently available, but these solutions require ongoing commitment, financial support, and
                    political will to ensure effective implementation.
                  </p>
                  <div className="card border-dark m-3">
                    <div className="card-header text-white bg-success p-2 card-header-display">
                      <h4 className="col-10 card-header-title ">Recommended Resiliency Actions</h4>
                    </div>
                    <div className="card-body p-0">
                      <ol className="list-group">
                        <li className="list-group-item list-group-item-success">
                          <b>Reduce Emissions:</b> The primary action for lessening the impacts to Spokane’s winter sports industry
                          associated with projected higher temperatures is to reduce greenhouse gas emissions, specifically to move
                          away from the high emissions scenario (RCP 8.5) to the lower emissions scenario (RCP 4.5).
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Prepare for Shorter Seasons</b> As temperatures in the Spokane region continue to rise, Spokane’s
                          regional snow-dependent recreation industries—including skiing, snowboarding, snowshoeing, and
                          snowmobiling—need to prepare for snow seasons that start later, are shorter, have less snow, and
                          potentially provide fewer days cold enough to make snow.
                        </li>
                        <li className="list-group-item list-group-item-success">
                          <b>Diversify:</b> Winter recreation industries in the Spokane region should consider diversifying their business
                          model, including emphasizing warmer weather recreation activities.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div id="discussion-more-research-needed" className="card border-dark bg-light mb-3">
                <div className="card-header">
                  <h4>Discussion—More Research Needed to Determine the Projected Future Length of the Ski
                  Season</h4>
                </div>
                <div className="card-body">
                  <p>
                    As part of our initial analysis our team worked to establish the projected length of the ski season. To do this, we
                    examined the date of the first freeze of the snow season in the fall (first fall freeze) and the date of the last freeze of
                    the snow season in the spring (last spring freeze) and then calculated the number of days between the two dates to
                    determine the number of days with freezing conditions. Not surprisingly, the projected length of time during which
                    freezing conditions are likely to occur in the future is expected to shrink under projected warming. <b>Table 7</b>
                    illustrates how freezing conditions at Mt. Spokane are likely to shrink under projected future warming. During the
                    last third of the twentieth century (1971–2000), the date of the first fall freeze at Mt. Spokane was around October
                    1st (the 274th day of the year) while the date of the last spring freeze was around May 16th (136th day of the year)
                    (<b>Table 7</b>). By mid-century (2040–2069) under the lower emissions scenario (RCP 4.5), the first fall freeze at Mt.
                    Spokane is projected to be October 12th (11 days later than historical). Under the high emissions scenario (RCP 8.5),
                    the date of the first fall freeze at mid-century moves to October 16th (15 days later than historical). During the same
                    mid-century timeframe, the last spring freeze is projected to be 18 (RCP 4.5) to 24 (RCP 8.5) days earlier (<b>Table 7</b>).
                    If we take the first fall freeze and last spring freeze data together, by the mid-century freezing conditions on Mt.
                    Spokane could shrink by 29 (RCP 4.5) to 38 (RCP 8.5) days. In other words, within just a few decades, the number
                    of days with freezing conditions at Mt. Spokane could be a month (or more) shorter than they were during the last
                    three decades of the 20th century. We caution that this measure is not directly translatable into seasonal windows or
                    days conducive for precipitation to fall as snow or for snowmaking. This analysis also did not give us a clear picture
                    of how the length of the ski season might change under warming conditions. However, the dates of the first and last
                    freeze do demonstrate that the length of the Spokane-area snow season—which can only start when conditions are
                    cold enough—is likely to shrink at Mt. Spokane under projected temperature increases.
                  </p>
                  <p>
                    Because the total length of the ski season is critical for the future viability of snow-based recreation at all five
                    Spokane-area ski resorts, we are recommending a further analysis that would link the projected decline in freezing
                    conditions to the projected length of the ski season.
                  </p>
                  <p>
                    <i>
                      <b>Table 7:</b> First fall freeze (as calendar date and number of days after January 1st ) and last spring freeze (as calendar date and
                      number of days after January 1st ) at Mt. Spokane the simulated historical period 1971–2000, and projected future years 2010–
                      2039, 2040–2069, and 2070–2099 for both emissions scenarios (RCP 4.5 and RCP 8.5). Calendar dates are based on non-leap
                      years. Source: Future Boxplots Tool (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                    </i>
                  </p>
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Time Period</th>
                        <th scope="col">First Fall Freeze Calendar Date & Number of Days after Jan 1st</th>
                        <th scope="col">Last Spring Freeze Calendar Date & Number of |Days after Jan 1st</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1971–2000</th>
                        <td>Oct. 1st | 274 days</td>
                        <td>May 16th | 136 days</td>
                      </tr>
                      <tr>
                        <th scope="row">RCP 4.5 2010–2039</th>
                        <td>Oct. 6th |279 days</td>
                        <td>May 8th | 128 days</td>
                      </tr>
                      <tr>
                        <th scope="row">RCP 4.5 2040-–2069</th>
                        <td>Oct. 12th | 285 days</td>
                        <td>Apr. 28th | 118 days</td>
                      </tr>
                      <tr>
                        <th scope="row">RCP 4.5 2070–2099</th>
                        <td>Oct. 5th | 278 days</td>
                        <td>Apr. 21st | 111 days</td>
                      </tr>
                      <tr>
                        <th scope="row">RCP 8.5 2010–2039</th>
                        <td>Oct. 9th | 282 days</td>
                        <td>May 7th | 127 days</td>
                      </tr>
                      <tr>
                        <th scope="row">RCP 8.5 2040–2069</th>
                        <td>Oct. 16th | 289 days</td>
                        <td>Apr. 22nd | 112 days</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="citations" className="card border-dark bg-light mb-3">
                <div className="card-header"><h4>Citations For this Chapter</h4></div>
                <div className="card-body">
                  <p className="card-text">
                    Henning, Brian, Levi Keesecker, David Camp, and Erik Budsberg.
                    “Snowfall Impact Study for Spokane, Washington.” In <i>The Spokane Climate Vulnerability and Resilience Assessment</i>, 
                    edited by Nathan Gilles, Katherine Hegewisch, John Abatzoglou, Ann Mooney, and Meghan Dalton.
                    Corvallis, Oregon: <i>The Pacific Northwest Climate Impacts Research Consortium (CIRC), College of Earth, Ocean, and Atmospheric Sciences, Oregon State University</i>, 2020.
                </p>
                  <h5>CIRC-Related Data Sources:</h5>
                  <ol className="list-group">
                    <li className="list-group-item">
                      Abatzoglou, John T. &quot;Development of gridded surface meteorological data for ecological applications and
                      modelling.&quot; <i>International Journal of Climatology</i> 33, no. 1 (2013): 121-131.
                      <a href="https://doi.org/10.1002/joc.3413">https://doi.org/10.1002/joc.3413</a>.
                    </li>
                    <li className="list-group-item">
                      Abatzoglou, John T., and Timothy J. Brown. &quot;A comparison of statistical downscaling methods suited for wildfire
                      applications.&quot; <i>International Journal of Climatology</i> 32, no. 5 (2012): 772-780.
                      <a href="https://doi.org/10.1002/joc.2312">https://doi.org/10.1002/joc.2312</a>.
                    </li>
                    <li className="list-group-item">
                      Hamman, Joseph J., Bart Nijssen, Theodore J. Bohn, Diana R. Gergel, and Yixin Mao. "The Variable Infiltration
                      Capacity model version 5 (VIC-5): infrastructure improvements for new applications and reproducibility."
                      <i>Geoscientific Model Development (Online)</i> 11, no. 8 (2018). <a href="https://doi.org/10.5194/gmd-11-3481-2018">https://doi.org/10.5194/gmd-11-3481-2018</a>.
                    </li>
                    <li className="list-group-item">
                      Hegewisch, Katherine C., John T. Abatzoglou, Oriana Chegwidden, and Bart Nijssen. “The Climate Mapper Tool.”
                      The Climate Toolbox. Accessed September 19, 2019. <a href="https://climatetoolbox.org/tool/Climate-Mapper">https://climatetoolbox.org/tool/Climate-Mapper</a>.
                    </li>
                    <li className="list-group-item">
                      Hegewisch, Katherine C., and John T. Abatzoglou. “Future Climate Boxplots Tool.” The Climate Toolbox. Accessed
                      February 6, 2020. <a href="https://climatetoolbox.org/tool/future-climate-boxplots">https://climatetoolbox.org/tool/future-climate-boxplots</a>.
                    </li>
                    <li className="list-group-item">
                      Liang, Xu, Dennis P. Lettenmaier, Eric F. Wood, and Stephen J. Burges. "A simple hydrologically based model of
                      land surface water and energy fluxes for general circulation models." <i>Journal of Geophysical Research: Atmospheres</i> 99, no. D7 (1994): 14415-14428. 
                      <a href="https://doi.org/10.1029/94JD00483">https://doi.org/10.1029/94JD00483</a>.
                    </li>
                  </ol>
                  <h5 className="m-2">Additional References:</h5>
                  <ol className="list-group">
                    <li className="list-group-item">
                      Abatzoglou, John T., (CIRC Researcher, Associate Professor University of Idaho). Email message to Nathan Gilles.
                      December 14, 2019.
                    </li>
                    <li className="list-group-item">
                      Burakowski, Elizabeth, and Matthew Magnusson. "Climate impacts on the winter tourism economy in the United
                      States." (2012). <i>National Resources Defense Council (NRDC)/Protect Our Winters (POW)</i>.
                      <a href="https://scholars.unh.edu/cgi/viewcontent.cgi?article=1020&context=sustainability">https://scholars.unh.edu/cgi/viewcontent.cgi?article=1020&context=sustainability</a>.
                   </li>
                    <li className="list-group-item">
                      Copeland, Alexandra (Snow Sports Center Supervisor for Mt. Spokane Ski & Snowboard Park). Email message to
                      Nathan Gilles. October 3rd, 2019.
                    </li>
                    <li className="list-group-item">
                      Fourth National Climate Assessment. “Summary Findings.” Accessed October 7, 2019.
                      <a href="https://nca2018.globalchange.gov/">https://nca2018.globalchange.gov/</a>.
                    </li>
                    <li className="list-group-item">
                      Hagenstad, Marca, Elizabeth Burakowski, and Rebecca Hill. “ Economic Contributions of Winter Sports in a
                      Changing Climate.” <i>Protect Our Winters/REI Co-op</i>, 2018. <a href="https://protectourwinters.org/2018-economic-report/">https://protectourwinters.org/2018-economic-report/</a>.
                    </li>
                    <li className="list-group-item">
                      Harpold, Adrian, Michael Dettinger, and Seshadri Rajagopal. "Defining snow drought and why it matters." Eos,
                      <i>Earth and Space Science News</i> 98 (2017). <a href="https://eos.org/opinions/defining-snow-drought-and-why-it-matters">https://eos.org/opinions/defining-snow-drought-and-why-it-matters</a>.
                    </li>
                    <li className="list-group-item">
                      Klos, P. Zion, Timothy E. Link, and John T. Abatzoglou. "Extent of the rain-snow transition zone in the western US
                      under historic and projected climate." <i>Geophysical Research Letters</i> 41, no. 13 (2014): 4560-4568.
                      <a href="https://doi.org/10.1002/2014GL060500">https://doi.org/10.1002/2014GL060500</a>.
                    </li>
                    <li className="list-group-item">
                      Kramer, Becky. “Warming trends: Climate change to shorten Mt. Spokane’s season,” <i>Spokesman-Review</i>, March 4,
                      2018. <a href="http://www.spokesman.com/stories/2018/mar/04/warming-trends-climate-change-to-shorten-mt-spokan/">http://www.spokesman.com/stories/2018/mar/04/warming-trends-climate-change-to-shorten-mt-spokan/</a>.
                    </li>
                    <li className="list-group-item">
                      Luby, Kevin quoted in, “Is Spokane a Ski Town? Hometown Ski Industry Icons Weigh In.” Brad Naccarato.
                      <i>Out There Outdoors</i>. November 15, 2014. <a href="https://outthereoutdoors.com/is-spokane-a-ski-town/">https://outthereoutdoors.com/is-spokane-a-ski-town/</a>.
                    </li>
                    <li className="list-group-item">
                      Lute, A. C., John T. Abatzoglou, and Katherine C. Hegewisch. “Projected changes in snowfall extremes and
                      interannual variability of snowfall in the western United States.”
                      <i>Water Resources Research</i> 51, no. 2 (2015): 960-972.
                      <a href="https://doi.org/10.1002/2014WR016267">https://doi.org/10.1002/2014WR016267</a>.
                    </li>
                    <li className="list-group-item">
                      Marlier, Miriam E., Mu Xiao, Ruth Engel, Ben Livneh, John T. Abatzoglou, and Dennis P. Lettenmaier. “The 2015
                      drought in Washington State: a harbinger of things to come?.” <i>Environmental Research Letters</i> 12, no. 11 (2017):
                      114008. <a href="https://doi.org/10.1088/1748-9326/aa8fde">https://doi.org/10.1088/1748-9326/aa8fde</a>.
                    </li>
                    <li className="list-group-item">
                      May, Christine, Charles Luce, Joe Casola, Michael Chang, Jennifer Cuhaciyan, Meghan Dalton, Scott Lowe, Gary
                      Morishima, Philip Mote, Alexander Petersen, Gabrielle Roesch-McNally, and Emily York, 2018: “Northwest.” In
                      Impacts, Risks, and Adaptation in the United States: Fourth National Climate Assessment, Volume II [Reidmiller,
                      D.R., C.W. Avery, D.R. Easterling, K.E. Kunkel, K.L.M. Lewis, T.K. Maycock, and B.C. Stewart (eds.)]. U.S.
                      Global Change Research Program, Washington, DC, USA, pp. 1036–1100.
                      <a href="https://doi.org/10.7930/NCA4.2018.CH24">https://doi.org/10.7930/NCA4.2018.CH24</a>.
                      <a href="https://nca2018.globalchange.gov/chapter/24/">https://nca2018.globalchange.gov/chapter/24/</a>.
                    </li>
                    <li className="list-group-item">
                      Mooney, Ann, Nathan Gilles, Katherine Hegewisch, John Abatzoglou, and Meghan Dalton. “The Northwest Climate
                      Toolbox Workbook: Discovering and applying your climate data story for climate adaptation planning,” Corvallis,
                      Oregon: The Pacific Northwest Climate Impacts Research Consortium (CIRC), College of Earth, Ocean, and
                      Atmospheric Sciences, Oregon State University, 2019. <a href="https://pnwcirc.org/sites/pnwcirc.org/files/nwct.pdf">https://pnwcirc.org/sites/pnwcirc.org/files/nwct.pdf</a>.
                    </li>
                    <li className="list-group-item">
                      Mote, Philip W., Sihan Li, Dennis P. Lettenmaier, Mu Xiao, and Ruth Engel. “Dramatic declines in snowpack in the
                      western US.” <i>Nature Partner Journals: Climate and Atmospheric Science</i> volume 1, 2. (2018).
                      <a href="https://doi.org/10.1038/s41612-018-0012-1">https://doi.org/10.1038/s41612-018-0012-1</a>.
                    </li>
                    <li className="list-group-item">
                      Mote, Philip W., David E. Rupp, Sihan Li, Darrin J. Sharp, Friederike Otto, Peter F. Uhe, Mu Xiao, Dennis P.
                      Lettenmaier, Heidi Cullen, and Myles R. Allen. “Perspectives on the Causes of Exceptionally Low 2015 Snowpack
                      in the Western United States.” <i>Geophysical Research Letters</i> 43, no. 20 (2016).
                      <a href="https://doi.org/10.1002/2016GL069965">https://doi.org/10.1002/2016GL069965</a>.
                    </li>
                    <li className="list-group-item">
                      Climate Toolbox. “Future Climate Projection Data.” Accessed October 7, 2019.
                      <a href="https://climatetoolbox.org/data/future-climate-projection-data">https://climatetoolbox.org/data/future-climate-projection-data</a>.
                    </li>
                    <li className="list-group-item">
                      Pacific Northwest Climate Impacts Research Consortium (CIRC). “Climate Tools.” Accessed October 4, 2019.
                      <a href="https://pnwcirc.org/science/hydrology">https://pnwcirc.org/science/hydrology</a>.
                    </li>
                    <li className="list-group-item">
                      Pacific Northwest Climate Impacts Research Consortium (CIRC). “Human Choice, Warming, & Emissions.”
                      Accessed October 7, 2019. <a href="https://pnwcirc.org/science/pathways">https://pnwcirc.org/science/pathways</a>.
                    </li>
                    <li className="list-group-item">
                      On The Snow. “49 Degrees North.” Accessed October 3, 2019. <a href="https://www.onthesnow.com/washington/49-degrees-north/ski-resort.html">https://www.onthesnow.com/washington/49-degrees-north/ski-resort.html</a>.
                    </li>
                    <li className="list-group-item">
                      On The Snow. “49 DEGREES NORTH SNOW HISTORY.” Accessed March 3, 2019.
                      <a href="https://www.onthesnow.com/washington/49-degrees-north/historical-snowfall.html">https://www.onthesnow.com/washington/49-degrees-north/historical-snowfall.html</a>.
                    </li>
                    <li className="list-group-item">
                      On The Snow. “Lookout Pass Ski Area.” Accessed October 3, 2019. <a href="https://www.onthesnow.com/idaho/lookout-pass-ski-area/ski-resort.html">https://www.onthesnow.com/idaho/lookout-pass-ski-area/ski-resort.html</a>.
                    </li>
                    <li className="list-group-item">
                      On The Snow. “LOOKOUT PASS SKI AREA SNOW HISTORY.” Accessed March 3, 2019.
                      <a href='https://www.onthesnow.com/idaho/lookout-pass-ski-area/historical-snowfall.html'>https://www.onthesnow.com/idaho/lookout-pass-ski-area/historical-snowfall.html</a>.
                    </li>
                    <li className="list-group-item">
                      On the Snow. “Mt. Spokane Ski and Snowboard Park.” Accessed October 3, 2019.
                      <a href="https://www.onthesnow.com/washington/mt-spokane-ski-and-snowboard-park/ski-resort.html">https://www.onthesnow.com/washington/mt-spokane-ski-and-snowboard-park/ski-resort.html</a>.
                    </li>
                    <li className="list-group-item">
                      On The Snow. “MT. SPOKANE SKI AND SNOWBOARD PARK SNOW HISTORY.” Accessed March 3, 2019.
                      <a href="https://www.onthesnow.com/washington/mt-spokane-ski-and-snowboard-park/historical-snowfall.html">https://www.onthesnow.com/washington/mt-spokane-ski-and-snowboard-park/historical-snowfall.html</a>.
                    </li>
                    <li className="list-group-item">
                      On The Snow. “Schweitzer.” Accessed October 3, 2019. <a href='https://www.onthesnow.com/idaho/schweitzer/ski-resort.html'>https://www.onthesnow.com/idaho/schweitzer/ski-resort.html</a>.
                    </li>
                    <li className="list-group-item">
                      On The Snow. “SCHWEITZER SNOW HISTORY.” Accessed March 3, 2019.
                      <a href='https://www.onthesnow.com/idaho/schweitzer/historical-snowfall.html'>https://www.onthesnow.com/idaho/schweitzer/historical-snowfall.html</a>.
                    </li>
                    <li className="list-group-item">
                      On The Snow. “Silver Mountain.” Accessed October 3, 2019. <a href="https://www.onthesnow.com/idaho/silver-mountain/ski-resort.html">https://www.onthesnow.com/idaho/silver-mountain/ski-resort.html</a>.
                    </li>
                    <li className="list-group-item">
                      On The Snow. “SILVER MOUNTAIN SNOW HISTORY.” Accessed March 3, 2019.
                      <a href="https://www.onthesnow.com/idaho/silver-mountain/historical-snowfall.html">https://www.onthesnow.com/idaho/silver-mountain/historical-snowfall.html</a>.
                    </li>
                    <li className="list-group-item">
                      Russell, Betsy Z. “Snowmaking becoming a game-changer for area ski resorts,” <i>Spokesman-Review</i>, December 20,
                      2015. <a href='http://www.spokesman.com/stories/2015/dec/20/snowmaking-becoming-a-game-changer-for-area-ski-re/'>http://www.spokesman.com/stories/2015/dec/20/snowmaking-becoming-a-game-changer-for-area-ski-re/</a>.
                    </li>
                    <li className="list-group-item">
                      Sawyer, Matthew (Director of Marketing & Sales for Lookout Pass). Email message to Nathan Gilles. October 3rd,
                      2019.
                    </li>
                    <li className="list-group-item">
                      SE Group. Market and Economics Analysis for the Mt. Spokane Ski and Snowboard Park Master Facilities Plan.
                      Prepared for. Bellevue, Washington: Washington State Parks, 2007.
                      <a href="http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.550.1332&rep=rep1&type=pdf">http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.550.1332&rep=rep1&type=pdf</a>.
                    </li>
                    <li className="list-group-item">
                      University of Washington. “University of Washington Variable Infiltration Capacity (VIC) Hydrological Model
                      retrospective data for 1950-2000.” Accessed October 2, 2019. <a href="http://research.jisao.washington.edu/data/vic/">http://research.jisao.washington.edu/data/vic/</a>.
                    </li>
                    <li className="list-group-item">
                      Wobus, Cameron, Eric E. Small, Heather Hosterman, David Mills, Justin Stein, Matthew Rissing, Russell Jones et
                      al., "Projected climate change impacts on skiing and snowmobiling: A case study of the United States." Global
                      Environmental Change 45 (2017): 1-14. <a href="https://doi.org/10.1016/j.gloenvcha.2017.04.006">https://doi.org/10.1016/j.gloenvcha.2017.04.006</a>.
                    </li>
                    <li className="list-group-item">
                      Zarzycki, Colin M. "Finding Snowmageddon: Detecting and quantifying northeastern US snowstorms in a multi-
                      decadal global climate ensemble." In <i>AGU Fall Meeting Abstracts</i>. 2017.
                      <a href='http://adsabs.harvard.edu/abs/2017AGUFM.A51K..07Z'>http://adsabs.harvard.edu/abs/2017AGUFM.A51K..07Z</a>.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* Beginning of Sidebar */}
            <div className="col-2 d-none d-md-block navbar">
              <div className="navbar-sticky-top sticky-top bg-white side-navbar">
                <h5 className="sidebar-title">Menu</h5>
                <ol className="list-group">
                  <li className="list-group-item">
                    <a href="#chapter-summary">Chapter Summary</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#climate-data-story-projected-future-snow">CDS - Projected Future Snow</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#larger-context">Larger Context</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#local-context">Local Context</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#analysis">Analysis</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#historical-climate">Historical Climate</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#projected-future-climate-precipitation">Projected Future Climate</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#conclusion">Conclusion</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#discussion-more-research-needed">Discussion</a>
                  </li>
                  <li className="list-group-item">
                    <a href="#citations">Citations</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Snow;