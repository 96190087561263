import React from 'react';

function Resources() {
    return (
      <div>
        <div className="card bg-dark text-white">
          <img src="/media/summary-page-header-edited.jpg" className="card-img" alt="Spokane Falls" />
          <div className="h-40 header-image-portion-overlay header-title d-none d-lg-flex">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text display-3">Resources</h1>
            </div>
          </div>
          <div className="h-40 header-image-portion-overlay header-title d-none d-md-flex d-lg-none">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text display-4">Resources</h1>
            </div>
          </div>
          <div className="h-40 header-image-portion-overlay header-title d-none d-sm-flex d-md-none">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text">Resources</h1>
            </div>
          </div>
          <div className="h-40 header-image-portion-overlay header-title d-sm-none">
            <div className="my-auto">
              <h3 className="card-title image-overlay-text center-vertical">Resources</h3>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div id="about-this-report" className="card bg-light mb-3 border-dark">
            <div className="card-header"><h4>Useful Tools and Resources</h4></div>
            <div className="card-body">
              <p className="card-text">
                Below we have included links to various tools that you can use to recreate some of the results of this study, 
                as well as carry out some of the same climate analysis on other regions. 
              </p>
            </div>
          </div>
          <div id="resources" className="row">
            <div id="northwest-climate-toolbox" className="col-lg-4 align-center">
              <img src="/media/resources/northwest-climate-toolbox-small.png" className="rounded border border-dark" alt="Northwest Climate Toolbox" />
              <h2 className="pt-2"><a href="https://pnwcirc.org/sites/pnwcirc.org/files/nwct.pdf">The Northwest Climate Toolbox Workbook</a></h2>
              <p>
                The Northwest Climate Toolbox Workbook provides step-by-step instructions for using the Climate Toolbox. 
                The Toolbox and the workbook are meant to aid and empower you to discover and craft what we are calling climate data stories, 
                narratives outlining the climate impacts and trends relevant to your community. This is the first volume in the Climate Resilience Workbook series.
              </p>       
            </div>
            <div id="northwest-climate-toolbox" className="col-lg-4 align-center">
              <img src="/media/resources/vulnerability-assesssment-workbook-edited.png" className="rounded border border-dark" alt="Northwest Climate Toolbox" />
              <h2 className="pt-2"><a href="https://pnwcirc.org/sites/pnwcirc.org/files/vulnerability_assessment_workbook.pdf">The Vulnerability Assessment Workbook </a> </h2>
              <p>
                The Vulnerability Assessment Workbook provides step-by-step instructions for assessing current and projected future climate impacts faced by your community. 
                Use this workbook to build on the climate data analysis and climate data stories you previously created using the Climate Toolbox and The Northwest Climate Toolbox Workbook. 
                This is the second volume in the Climate Resilience Workbook series.
              </p>
            </div>
            <div id="northwest-climate-toolbox" className="col-lg-4 align-center">
              <img src="/media/resources/resilience-actions-workbook-edited.png" className="rounded border border-dark" alt="Northwest Climate Toolbox" />
              <h2 className="pt-2"><a href="https://pnwcirc.org/sites/pnwcirc.org/files/resilience_actions_workbook.pdf">The Resilience Actions Workbook</a> </h2>
              <p>
                The Resilience Actions Workbook provides tools to help you understand the human landscape of your community and to plan resilience actions that integrate the best 
                available scientific research and tools with your local experience and judgement. Finally, this workbook provides guidance on how to communicate effectively about the process, decisions, 
                and findings. This is the third volume in the Climate Resilience Workbook series.
              </p>
            </div>
          </div>
          <div id="other-tools" className="row w-100 my-md-3 pl-md-3">
            <div class="col-lg-5 bg-dark text-center text-white overflow-hidden rounded">
              <div class="bg-dark mx-auto pt-3">
                <img src="/media/resources/climate-toolbox.png" className="rounded border border-white" alt="Northwest Climate Toolbox" />
              </div>
              <div class="p-3">
                <h2 class="display-5"><a href="https://climatetoolbox.org/">The Climate Toolbox</a></h2>
              </div>
              <div class="pb-3">
                  CIRC's Climate Toolbox (formerly The Northwest Climate Toolbox) provides timely climate and weather information to Pacific Northwest farmers, businesses, and resource managers.
                  Filled with tools that map and visualize climate and weather conditions, the Toolbox helps its users better manage their financial and natural resources by giving them the information they need to respond to and plan for weather and climate impacts.
              </div>
            </div>
            <div class="col-lg-2 text-center text-white overflow-hidden">
            </div>
            <div class="col-lg-5 bg-dark text-center text-white overflow-hidden rounded">
              <div class="bg-dark mx-auto pt-3" >
                <img src="/media/resources/aquifer-atlas-edited.png" className="rounded border border-white" alt="Northwest Climate Toolbox" />
              </div>
              <div class="p-3">
                <h2 class="display-5"><a href="http://www.spokaneaquifer.org/2015-aquifer-atlas/mobile/index.html#p=3">Rathdrum Prairie Aquifer Atlas</a></h2>
              </div>
              <div class="pb-3">
                  The Spokane Falls Rathdrum Prairie Aquifer Atlas is intended to provide a basic reference of the geographic, geologic, and hydrologic characteristics of this aquifer.
                  It is intended for regional use in education, in planning, and as a source for general technical information.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Resources;