import React from 'react';
import YoutubeEmbed from "./YoutubeEmbed";


function Temperature() {
  return (
    <div>
      <div className="card bg-dark text-white">
        <img src="/media/temperature/temperature-header-edited.jpg" className="card-img" alt="Spokane Falls"/>
        <div className="h-40 header-image-portion-overlay header-title">
          <div className="my-auto">
            <h1 className="card-title image-overlay-text">Chapter 1: Temperature Impact Study</h1>
            <h4 className="card-text header-title"><i>
              <p>
                “Climate change will increase the frequency and severity of future extreme heat events while also resulting in
                generally warmer summers and milder winters, with implications for human health.”
              </p>
                
              <p>- Marcus Sarofim et al., “Temperature-Related Death and Illness. The Impacts of Climate Change on Human Health in the
                United States: A Scientific Assessment. U.S. Global Change Research Program”</p>
            </i></h4>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="row">
          <div className="col-md-10 content">
            <ul id="chapter-summary" className="list-group list-group-flush">
              <li className="list-group-item">
                <b>Chapter Summary: </b>This chapter examines the effects that projected future temperature increases are likely to have on
                  human health and safety in Spokane. Spokane’s annual Lilac Bloomsday Run (Bloomsday) is used as a case study to
                  illustrate potential impacts to human health.</li>
              <li className="list-group-item">
                <b>Lead Authors: </b>Rebecca MacMullan (350 Spokane), Kara Odegard (Measure Meant), Jim Simon (Gonzaga University), and
                  David Camp (Northwest Renewables)</li>
              <li className="list-group-item">
                <b>Review Editors: </b>Nathan Gilles (CIRC/Oregon State University), Katherine Hegewisch (CIRC/University of Idaho), John
                  Abatzoglou (CIRC/UI), Ann Mooney (CIRC/OSU), and Meghan Dalton (CIRC/OSU).</li>
            </ul>
            <div className="row m-1">
              <div className="col p-0">
                <div className="card border-dark m-3">
                  <div className="card-header p-2 card-header-display">
                    <img className="col-1 icon-image p-0" src="/media/OSU_icon_key_01.png" alt="key"></img>
                    <h4 className="col-11 card-header-title p-0">Key Findings</h4>
                  </div>
                  <div className="card-body p-0">
                    <ol className="list-group" >
                      <li className="list-group-item">
                        Strong evidence suggests that human-caused climate change is leading to rising temperatures in Spokane that will
                        likely correspond to a rise in heat-related illnesses.
                      </li>
                      <li className="list-group-item">
                        By the middle of this century (2040–2069), mean annual temperatures in Spokane are projected to be 4.4 degrees
                        Fahrenheit warmer under the lower emissions scenario (RCP 4.5) and 5.9 °F warmer under the high emissions
                        scenario (RCP 8.5) than they were during the historical years 1971–2000. Temperature increases in Spokane become
                        more extreme by late century (2070–2099), rising by 5.5 °F under RCP 4.5 and by 9.5 °F under RCP 8.5.
                      </li>
                      <li className="list-group-item">
                        Throughout this century, temperatures in the Spokane region are projected to rise across all months of the year.
                      </li>
                      <li className="list-group-item">
                        Under the high emissions scenario (RCP 8.5), Spokane’s hottest summer day is projected to reach 106 °F by mid-
                        century (2040–2069) and 111°F by late-century (2070–2099) compared to the mean of 99 °F for the years 1971–2000.
                      </li>
                      <li className="list-group-item">
                        Under both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5), Spokane is projected
                        to see an increase in the number of days annually where the heat index reaches or exceeds 90 °F, 100 °F, and 105 °F.
                      </li>
                      <li className="list-group-item">
                        Rising temperatures create future challenges in the Spokane region that will require planning and preparation to
                        protect the health and safety of all Spokane residents, as well groups identified as being vulnerable to heat-related
                        illnesses.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              {/* End of Key Findings */}
              <div className="col p-0">
                <div className="card border-dark m-3">
                  <div className="card-header text-white bg-success p-2 card-header-display">
                    <img className="col-2 icon-image" src="/media/OSU_icon_shield_01.png" alt="shield"></img>
                    <h4 className="col-10 card-header-title p-0">Resiliency Actions</h4>
                  </div>
                  <div className="card-body p-0">
                    <ol className="list-group">
                      <li className="list-group-item list-group-item-success">
                        <b>Reduce Emissions:</b> The negative outcomes of the high emissions scenario (RCP 8.5) can be mitigated by swift and
                          significant reductions in greenhouse gas emissions.
                      </li>
                      <li className="list-group-item list-group-item-success">
                        <b>Plan for High Temperatures:</b> Over the next 30–60 years, high heat dangers should be considered when scheduling outdoor
                        public events. 
                      </li>
                      <li className="list-group-item list-group-item-success">
                        <b>Safety Protocols:</b> Health and safety officials should create and promote safety protocols for extreme heat events
                        with particular concern for outdoor workers, outdoor recreation/athletes, and vulnerable
                        populations.                         
                      </li>
                      <li className="list-group-item list-group-item-success">
                        <b>Safety Protocols:</b> First responders should be adequately trained and equipped to treat heat-related illnesses.
                      </li>
                      <li className="list-group-item list-group-item-success">
                        <b>Urban Forest:</b>The City should take immediate action to protect and expand the urban tree cover to mitigate the effects of rising temperatures on human health.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div id="climate-data-story-beat-the-heat" className="card border-dark mb-3">
              <div className="card-header p-2 card-header-display">
                <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data-story"></img>
                <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Beat The Heat</i></h4>
              </div>
              <div className="card-body">
                
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      In Summer 2021, historic temperature records were shattered throughout the Pacific Northwest in
                      an extreme weather event known as a Heat Dome. The Washington State Department of Health (DOH)
                      officially reported 119 heat-related deaths from June 26 to July 2, making it the deadliest
                      weather event in Washington history to date.
                    </p>
                    <p>
                      The next year, in the Summer 2022, the Gonzaga Center for Climate, Society & the Environment
                      set out to learn more about urban heat islands in Spokane. Through partnership with National Oceanic
                      and Atmospheric Administration (NOAA) and CAPA Strategies, community volunteers from around Spokane
                      took to the streets on an 87-degree July day to collect air temperature data and facilitate the
                      citywide mapping of urban heat islands.
                    </p>
                    <p>
                      Learn more about their findings, and the ongoing research being carried out by the Gonzaga Climate Center
                      for the Beat the Heat program by visiting the <a href="https://storymaps.arcgis.com/stories/e989c85c20494cac8056476552f4d9b7/">Spokane Beat the Heat Story Map.</a>
                    </p>
                  </div>
                  <div className="col-md-6">
                    <YoutubeEmbed embedId="kqAgtUPsBGc" />
                  </div>
                </div>
              </div>
            </div>
            <div id="climate-data-story-bloomsday" className="card border-dark mb-3">
              <div className="card-header p-2 card-header-display">
                <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data-story"></img>
                <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Bloomsday</i></h4>
              </div>
              <div className="card-body">
                <p>
                  Held every year in May to coincide with the seasonal
                  blooming of lilacs, Spokane’s Lilac Bloomsday Run
                  (Bloomsday) is a seven-and-a-half mile run that draws
                  roughly 50,000 participants and raises money for
                  charities (<b>Bloomsday Run 2019</b>). From 1979 to 2019,
                  Spokane has seen above average temperatures for
                  May (<b>Figure 1</b>). As a consequence of these rising temperatures,
                  Bloomsday is already experiencing an increase in
                  heat-related health issues.
                </p>
                <p>
                  During the May 2018 Bloomsday race, warmer-thannormal temperatures likely led to an increase in
                  heat-related illnesses and an increase in the dropout
                  rate for the race (<b>Lutz 2018</b>). Heat-related illnesses
                  will likely be a factor during future Bloomsdays. A
                  comparison between recorded historical temperatures
                  and projected future temperatures shows how.
                </p>
                <p>
                  The first Bloomsday run was held in 1977 (<b>Bloomsday Run 2019</b>).
                  Historically temperatures during
                  Bloomsday races have varied considerably, ranging
                  from an historical low of 42 degrees Fahrenheit
                  (recorded at 2:00 pm during the 1984 race) to a high
                  of 78 °F (recorded at 2:00 pm during the 1977 and
                  1980 races) (see <b>Table 1</b>). The mean maximum daily temperature
                  for the entire month of May for the race’s early
                  years, defined here as 1971–2000, was roughly 68
                  °F (<b>Bloomsday Run 2019</b>). However, under the high
                  emissions scenario (RCP 8.5), mean maximum daily
                  temperatures for May are projected to be 74 °F by the
                  middle decades of this century (2051–2080) and 76
                  °F by the late decades of this century (2081–2099) (see <b>Table 3</b> below), a
                  projected 8 °F increase. The projected future increase
                  in May temperatures strongly suggests the likelihood
                  of a corresponding increase in heat-related illnesses
                  occurring during future Bloomsdays.
                </p>
              </div>
            </div>
            <div id="may-max-temp" className="card border-dark mb-3 bg-light">
              <div className="row no-gutters">
                <div className="col-md-9">
                  <div className="card-body">
                    <img className="img-fluid border border-dark" alt="may-max-temp" src="/media/temperature/Fig-1-Temp.jpeg"></img>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-body">
                    <p>
                      <i>
                        <b>Figure 1:</b> Mean maximum daily temperatures for the month of May in degrees Fahrenheit with trend line for Spokane, Washington for the
                        years 1979–2019. Blue bars represent temperature values below the historical mean. Red bars represent temperature values above the
                        historical mean.
                      </i>
                    </p>
                    <p>
                      <i>
                        <b>Source:</b> Historical Climate Tracker Tool (https://climatetoolbox.org/tool/Historical-Climate-Tracker), The Climate
                        Toolbox.
                      </i>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <p>
                  <i>
                    <b>Table 1:</b> Historical Bloomsday Temperatures (in degrees Fahrenheit) recorded at 2:00 pm for the years 1977–2018, includes warmest and
                    coolest Bloomsdays. Data Source: Blooms Day Run. “History & Stats—Race Day Weather.” (<a href="https://www.bloomsdayrun.org/history-stats/race-day-weather">https://www.bloomsdayrun.org/history-stats/race-day-weather</a>).
                  </i>
                </p>
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Bloomsday Date</th>
                      <th scope="col">Temperature at 2 PM (in Fahrenheit)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">May 1st, 1977 (historical high)</th>
                      <td className="table-danger">78 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">May 4th, 1980 (historical high)</th>
                      <td className="table-danger" >78 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">May 6th, 1984 (historical low)</th>
                      <td className="table-info">42 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">May 6, 2018</th>
                      <td className="table-warning">75 °F</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div id="larger-context" className="card border-dark bg-light mb-3">
              <div className="card-header"><h4>Larger Context—Rising Temperatures & Human Health</h4></div>
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  <p>
                    “Exposure to extreme heat can lead to a complex cascade of dysfunction in multiple organ systems
                    resulting from the direct effects of heat and the body’s inflammatory response, causing altered
                    mental status, renal failure, coagulopathy, and cardiovascular collapse.”
                    – <i>Jennifer F. Bobb, et al.,</i>
                  </p>
                  <p>
                    "Cause-Specific Risk of Hospital Admission Related to Extreme Heat in Older Adults" 
                    - <u>Journal of the American Medical Association</u>
                  </p>
                </h6>
                <p>
                  Since the start of 20th century, average annual temperatures across the United States have increased by about 1.8 degrees
                  Fahrenheit when compared to the historical baseline established by the time period 1901–1960. The majority of this rise (1.2
                  °F) has occurred from 1986 to 2015 (<b>Fourth National Climate Assessment, Chapter 2, 2018</b>). The overwhelming scientific
                  consensus following from multiple independent lines of inquiry is that the burning of oil, coal, and gas as well as
                  deforestation has led to an increase in concentrations of atmospheric carbon dioxide that is warming our planet’s climate
                  (<b>Skeptical Science 2019; NCA 4, Chapter 2, 2018</b>).
                </p>
                <p>To make projections about future climate change, climate researchers use emissions scenarios. The scientific community
                  generally uses two emissions scenarios in tandem:
                </p>
                <ul>
                  <li>
                    The high emissions scenario (RCP 8.5)—simulates the warming that is likely to occur throughout this century if
                    greenhouse gases continue to be released into the atmosphere at their current rate.
                  </li>
                  <li>
                    The lower emissions scenario (RCP 4.5)—simulates the warming that is likely to occur throughout this century if
                    moderate reductions in greenhouse gas emissions are made at the global scale.
                  </li>
                </ul>
                <p>
                  By the end of this century, average annual temperatures across the contiguous United States are expected to increase by 2.3–
                  6.7 °F under the lower emissions scenario (RCP 4.5) and by as much as 5.4–11.0 °F under the high emissions scenario (RCP
                  8.5) compared to the historical baseline 1986–2015 (<b>NCA 4, Chapter 1, 2018</b>). Unless emissions are cut, temperatures in the
                  United States and globally are expected to continue rising throughout this century and beyond (<b>Clark et al., 2016</b>).
                </p>
                <p>
                  The projected rise in temperatures forecasted for this century is expected to lead to more people being exposed to extremely
                  warm temperatures (<b>Dahl et al., 2019</b>). There is strong evidence to suggest that exposure to this warming poses a continuing
                  threat to human health, and that impacts to human health due to rising temperatures are likely to worsen throughout this
                  century (<b>NCA 4, Chapter 14 2018</b>). Along with impacts to human health, human labor in agriculture and construction
                  have already seen declines in productivity during daylight hours due to extreme temperatures (<b>Watts et al., 2019</b>), suggesting
                  this trend will continue as temperatures continue to rise.
                </p>
              </div>
            </div>
            <div id="local-context" className="card border-dark bg-light mb-3">
              <div className="card-header"><h4>Local Context—Rising Temperatures in Spokane & Human Health</h4></div>
              <div className="card-body">
                <p>
                  By the end of this century, the United States Pacific Northwest is projected to be 2–15 degrees Fahrenheit warmer than it was
                  during the second half of the twentieth century, depending on model and emissions scenario used (<b>Rupp et al., 2013</b>). Our
                  analysis in this report employed both the high (RCP 8.5) and lower (RCP 4.5) emissions scenarios to determine the risk(s) to
                  human health posed by projected rising temperatures in the Spokane region.
                </p>
                <p>
                  By the middle of this century (2040–2069), mean annual temperatures in Spokane are projected to be 4.4 °F warmer under
                  RCP 4.5 and 5.9 °F warmer under RCP 8.5 than they were during the years 1971–2000, according to numbers obtained from
                  the Climate Toolbox (<b>Table 2</b>). By the end of this century (2070–2099), temperatures are projected to increase by 5.5 °F
                  under RCP 4.5 and by 9.5 °F under RCP 8.5. The change in mean annual temperatures projected for the middle and late
                  decades of this century encapsulates a larger trend that includes an increase in temperatures across all months of the year
                  (Table 3), and an increase in the temperature of the hottest day of the summer (<b>Figures 6 and 7</b>).
                </p>
                <p>
                  As temperatures rise in Spokane and the surrounding Inland Pacific Northwest, heat-related illnesses are likely to increase.
                  Heat vulnerable populations include the young, the elderly, individuals with certain chronic diseases, low-income
                  households, and outdoor workers. Other groups affected by extreme heat include, children, infants, older adults, outdoor
                  athletes, and emergency responders, including police, firefighters, and paramedics. Domestic pets are also vulnerable to heat
                  stress (<b>Centers for Disease Control and Prevention 2019</b>) (<b>National Integrated Heat Health Information System 2019</b>).
                </p>
                <p>
                  From 1988 to 2018, extreme heat caused more deaths than any other weather-related event across the United States (<b>National
                  Weather Service 2018</b>). The findings of this chapter indicate that Spokane needs to prepare as a community for the increased
                  risk of extreme heat to human health.
                </p>
                <p>
                  As temperatures rise, we can reasonably assume that extreme heat will not only continue to lead to more deaths than any
                  other weather-related event, we can also reasonably assume, as outside research has concluded, that the number deaths
                  related to extreme heat will also increase in Spokane even with moderate increases in temperature (<b>USGCRP 2016</b>). What’s
                  more, even though climate models do not project Spokane and the Pacific Northwest to experience the same extreme
                  temperatures projected for other parts of the United States (<b>Dahl et al., 2019</b>), Spokane is still likely to see an increase in
                  heat-related illnesses. The temperature at which hospitalizations for heat-related illnesses are likely to occur vary greatly from
                  region to region. For instance, 80 °F currently marks the point of peak hospitalization for heat-related illnesses in the Pacific
                  Northwest and U.S. West generally, compared to 105 °F for the Southern US (<b>Lancet 2019</b>). According to our analysis, by
                  mid-century (2040–2069) under RCP 4.5, Spokane is projected to see 31 days annually with a heat index greater than or
                  equal to (≥) 90°F. Under RCP 8.5, the number reaches 39 days annually with a heat index of ≥ 90 °F (<b>Table 4</b>).
                </p>
                <p>
                  The projected increases in temperatures in Spokane under both RCP 4.5 and RCP 8.5 can reasonably be expected to impact
                  human health, safety, and productivity in Spokane and its surrounding region. However, RCP 4.5 will likely be less
                  detrimental than RCP 8.5 due to the more extreme warming expected under RCP 8.5.
                </p>
                <p>
                  RCP 8.5, sometimes called the “business-as-usual” emissions scenario, is understood by the climate research community to
                  be the scenario that most closely resembles a high-end scenario that follows the energy and development trajectories over the
                  last several decades. Yet, it’s worth noting that RCP 8.5 is not inevitable. The negative outcomes of human-caused climate
                  change can be mitigated by swift and significant reductions in greenhouse gas emissions. Individual choices, business
                  practices, and government policy are all necessary components of an effective strategy to prevent the worst-possible climate
                  impacts scenarios. Many solutions to prevent dangerous climate conditions are currently available, but these solutions require
                  ongoing commitment, financial support, and political will to ensure effective implementation.
                </p>
              </div>
            </div>
            <div id="analysis" className="card border-dark bg-light mb-3">
              <div className="card-header">
                <h4>Analysis — Data Tools, Assumptions & Limitations, Multi-Model Means, Emissions Scenarios, Variables,
                  Time Frames, and Climate Data Stories</h4>
              </div>
              <div className="card-body">
                <p>
                  <b>Data Tools:</b> For this report, we included data visualizations from the climate web tools made available online as part of <a href="https://climatetoolbox.org/">The Climate
                  Toolbox</a>, a product of The Pacific Northwest Climate Impacts Research Consortium (CIRC). Specifically, our team downloaded
                  figures from the following tools in the Toolbox:
                </p>
                <ul>
                  <li>
                    Historical Climate Tracker Tool (<a href="https://climatetoolbox.org/tool/Historical-Climate-Tracker">https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>)
                  </li>
                  <li>
                    Future Time Series Tool (<a href="https://climatetoolbox.org/tool/Future-Time-Series">https://climatetoolbox.org/tool/Future-Time-Series</a>)
                  </li>
                  <li>
                    The Climate Mapper Tool (<a href="https://climatetoolbox.org/tool/Climate-Mapper">https://climatetoolbox.org/tool/Climate-Mapper</a>)
                  </li>
                  <li>
                    The Future Boxplots Tool (<a href='https://climatetoolbox.org/tool/Future-Boxplots'>https://climatetoolbox.org/tool/Future-Boxplots</a>)
                  </li>
                  <li>
                    The Future Cold Hardiness Zones Tool (<a href="https://climatetoolbox.org/tool/Future-Cold-Hardiness-Zones">https://climatetoolbox.org/tool/Future-Cold-Hardiness-Zones</a>)
                  </li>
                </ul>
                <p>
                  We also performed data analyses using climate data downloaded from the <a href="https://climate.northwestknowledge.net/IntegratedScenarios/data_csv.php">Integrated Scenarios Point Data download tool</a>,
                  part of the <a href="https://climate.northwestknowledge.net/IntegratedScenarios/">Integrated Scenarios website</a>, another CIRC-associated effort. Specifically, we downloaded average maximum
                  daily temperatures data for each month for 20 global climate models from the MACAv2-LIVNEH dataset (<b>Hegewisch and
                  Abatzoglou 2014</b>).
                </p>
                <p>
                  <b>Assumptions & Limitations:</b> Limitations within the various online tools required us to use a specific coordinate while
                  requesting temperature data. For this report, we used 47.66 o N, 117.43 o W, which are the specific coordinates for Spokane,
                  Washington. Therefore, the temperature data reported does not account for variations that may be present in the micro-
                  climates throughout the city and region. For the purpose of this analysis, we did not evaluate temperature increases across the
                  larger Inland Pacific Northwest region, including eastern Washington and northern Idaho. However, multiple peer-reviewed
                  studies have found that the Pacific Northwest generally will see temperature increases under both emissions scenarios used in
                  this report. Because our analysis was interested in Spokane, we did not examine these larger region-wide increases in detail.
                </p>
                <p>
                  <b>Multi-Model Means:</b> The data that make up the key findings of this analysis represent the mean resulting from the 18–20
                  global climate models (GCMs) used by the Climate Toolbox and the MACAv2-LIVNEH dataset to create their future climate
                  projections. The number of GCMs that make up a multi-model ensemble depend on the dataset used. The number of GCMs
                  used are noted in the text and captions of this document. Using a multi-model mean as opposed to the results of single model
                  is generally accepted as best practice by climate researchers. However, the mean does not show the full spread of results from
                  the 18–20 GCMs used. In other words, actual future climate conditions, when we get to them, might lie either above or below
                  the multi-model mean due to the fact that some GCMs might better model local climate conditions compared to others.
                </p>
                <p>
                  <b>Emissions Scenarios:</b> For this analysis, we employed both the lower emissions scenario (RCP 4.5) and the high emissions
                  scenario (RCP 8.5) from phase 5 of the Coupled Model Intercomparison Project CMIP5 (<b>Climate Toolbox 2019</b>). The two
                  scenarios were used side by side in some instances to evaluate whether the climate impacts of the lower emissions scenario
                  (RCP 4.5) and high emissions scenarios (RCP 8.5) are meaningfully different in their impact on temperature projections for
                  our region. We found that they were.
                </p>
                <p>
                  However, to streamline our discussion of impacts, our team employed the high emissions scenario (RCP 8.5) on its own for
                  some of our analysis. This was done because our teamed deemed that RCP 8.5 is currently more plausible than RCP 4.5
                  given humanity’s current collective carbon footprint.
                </p>
                <p>
                  (Note: RCP 4.5 isn’t the lowest emissions scenario used by climate researchers. RCP 2.6 is the lowest emission scenario
                  considered in climate models. However, because the collective global emissions pathway has very likely veered off course
                  from that modeled under RCP 2.6, RCP 2.6 is no longer used as the low emission scenario pathway. Since RCP 4.5 is lower
                  than RCP 8.5, this report has adopted the standard used by many in the climate research community: lower to describe RCP
                  4.5 and high to describe RCP 8.5, rather than lower and higher to describe the two scenarios.)
                </p>
                <p>
                  <b>Variables:</b> Our use of variables guided our analysis. Our team queried the Climate Toolbox and MACAv2-LIVNEH datasets
                  for temperature data and figures related to the following variables:
                </p>
                <ul>
                  <li>
                    <i>Mean Annual Temperature</i> (Future Time Series Tool)
                  </li>
                  <li>
                    <i>Change in Mean Annual Temperature</i> (Climate Mapper Tool)
                  </li>
                  <li>
                    <i>Mean Maximum Daily Temperature</i> (MACAv2-LIVNEH Dataset and Historical Climate Tracker Tool)
                  </li>
                  <li>
                    <i>The Hottest Summer Day</i> (Future Boxplots Tool)
                  </li>
                  <li>
                    <i>Heat Index</i> (Climate Mapper Tool)
                  </li>
                  <li>
                    <i>USDA Cold Hardiness Zones</i> (The Future Cold Hardiness Zones Tool)
                  </li>
                </ul>
                <p>
                  <b>Time Frames:</b> When using the Climate Toolbox, our analysis used the dates 2010–2039, 2040–2069, and 2070–2099 to
                  represent early, middle, and late century times periods. When using the MACAv2-LIVNEH dataset, we used the dates
                  2020–2050, 2050–2080, and 2081–2099 to represent <i>early, middle, and late century</i>. Time frames used for baselines
                  sometimes varied from tool to tool and from dataset to dataset. Timeframes for historical baselines are listed in the body text
                  and captions of this document.
                </p>
                <p>
                  <b>Climate Data Stories:</b> For this analysis our team wrote two climate data stories. A <i>climate data story</i> is defined by CIRC as
                  “a narrative outlining climate facts and impacts specific to your community” (<b>Mooney et al., 2019</b>). Our first climate data
                  story (listed above) details the effects of projected rising temperatures on Spokane’s annual Lilac Bloomsday Run. Here
                  Bloomsday was used as a case study to examine how rising temperatures could affect Spokane’s outdoor activities in spring
                  and summer generally. Our team’s second climate data story concerns the trees that make up the city’s urban forest. The
                  health of Spokane’s urban forest is expected to be essential for helping cool the city during our projected warm summer
                  months.
                </p>
              </div>
            </div>
            <div id="projected-future-climate" className="card border-dark bg-light mb-3">
              <div className="card-header">
                <h4>Projected Future Climate — Temperatures Projected to Rise Under Both Emissions Scenarios</h4>
              </div>
              <div className="card-body">
                <p>
                  <b>Variable:</b> <i>Mean Annual Temperature</i>
                </p>
                <p>
                  <b>Finding:</b> Spokane is expected to see an increase in its mean annual temperature under both the lower emissions scenario
                  (RCP 4.5) and the high emissions scenario (RCP 8.5).
                </p>
                <p>
                  <b>Finding:</b> Warming is projected to be more intense under RCP 8.5 when compared to RCP 4.5.
                </p>
                <p>
                  <b>Justification:</b> If we make a side-by-side comparison of future temperature projections for both the lower emissions scenario
                  (RCP 4.5) and the high emissions scenario (RCP 8.5), two things become clear:
                </p>
                <ol>
                  <li>
                    Throughout this century, Spokane is projected to see warming under both RCP 4.5 and RCP 8.5.
                  </li>
                  <li>
                    Temperature increases are projected to be more intense under RCP 8.5 when compared to RCP 4.5, particularly after
                    the middle decades of this century (2040–2069).
                  </li>
                </ol>
                <p>
                  <b>Figure 2</b> employs the variable Mean Annual Temperature (in degrees Fahrenheit) to illustrate how the two emissions
                  scenarios are projected to produce different levels of warming and how the two scenarios diverge. RCP 4.5 is shown in blue.
                  RCP 8.5 is shown in red. The solid colored lines represent the multi-model mean of the 20 downscaled global climate models
                  (GCMs) used for this figure. The shaded bands of red and blue colors represent the spread of the results from the 20 GCMs.
                  Here the blue band represents RCP 4.5 and the red band represents RCP 8.5. The gray band represents a historical simulation
                  of the years 1950–2005. The historical simulation is based on in-the-field historical measurements. The solid black line
                  represents the multi-model mean from the historic simulation.
                </p>
                <p>
                  Because RCP 4.5 simulates a world in which greenhouse gas emissions are curtailed in this century, projected warming under
                  RCP 4.5 is less intense by late century (2080–2099) than projections for RCP 8.5, which simulates a world in which
                  greenhouse gases remain on their current “business-as-usual” upward trajectory. Due to the assumed reduction in emissions
                  under RCP 4.5, RCP 4.5 and RCP 8.5 start to diverge around the middle decades of this century (2040–2069). The split
                  becomes more apparent by the late decades of this century when the two scenarios start following two very different paths.
                </p>
              </div>
            </div>
            <div id="mean-temp" className="card border-dark mb-3 bg-light">
              <div className="row no-gutters">
                <div className="col-md-9">
                  <div className="card-body">
                    <img className="img-fluid border border-dark" alt="may-max-temp" src="/media/temperature/Fig-2-Temp.jpeg"></img>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-body">
                    <i>
                      <p>
                        <b>Figure 2:</b> Projected mean annual (January–December) temperature in degrees Fahrenheit for Spokane, Washington from 1950 to the year
                        2100 for the lower emission scenario (RCP 4.5) and the high emissions scenario (RCP 8.5). Degrees Fahrenheit are displayed on the y-
                        axis (left vertical). Years are displayed on the x-axis (bottom horizontal). The RCP 4.5 is shown in blue. RCP 8.5 is shown in red. The solid
                        lines represent the multi-model mean of 20 downscaled global climate models (GCMs). The shaded bands of red and blue colors represent
                        the spread of results from the 20 GCMs. The blue band represents RCP 4.5. The red band represents RCP 8.5. The gray band represents a
                        historical simulation of the years 1950–2005. The solid black line represents the multi-model mean from the historic simulation. 
                      </p>
                      <p>
                        <b>Source:</b> Future Time Series Tool (<a href="https://climatetoolbox.org/tool/Future-Time-Series">https://climatetoolbox.org/tool/Future-Time-Series</a>), The Climate Toolbox.
                      </p>
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <div className="card border-dark bg-light mb-3">
              <div className="card-header">
                <h4>Projected Future Climate—Temperature Projections Above What Was Normal Historically</h4>
              </div>
              <div className="card-body">
                <p>
                  <b>Variable:</b> <i>Change in Mean Annual Temperature</i>
                </p>
                <p>
                  <b>Finding:</b> By the middle of this century (2040–2069), mean annual temperatures in Spokane are projected to be 4.4 degrees
                    Fahrenheit warmer under the lower emissions scenario (RCP 4.5) and 5.9 °F warmer under the high emissions scenario (RCP
                    8.5) than they were during the historical baseline years 1971–2000. Temperature increases in Spokane become more extreme
                    by late century (2070–2099). Under RCP 4.5, mean annual temperatures at late century increase by 5.5 °F under RCP 4.5 and
                    by 9.5 °F under RCP 8.5.
                </p>
                <p>
                  <b>Justification:</b> In the section above we examined mean annual temperature under both the lower emissions scenario (RCP
                  4.5) and the high emissions scenario (RCP 8.5). While it is informative to examine the increase as raw numbers, it is equally
                  informative to examine the increase as measured as a change from what was normal historically. Using the Climate Mapper
                  Tool, we examined this using the variable Change in Mean Annual Temperature.
                </p>
                <p>
                  Throughout the course of this century, climate models project that mean annual temperatures in Spokane will rise under both
                  RCP 4.5 and RCP 8.5 when compared to the historical period 1971–2000 (Table 2). As discussed above, due to the
                  difference in the release of emissions modeled in the two scenarios, warming is projected to be notably higher under RCP 8.5
                  than under RCP 4.5 for both the middle and late decades of this century.
                </p>
                <p>
                  By the middle of this century (2040–2069), mean annual temperatures in Spokane are projected to be 4.4 °F warmer under
                  RCP 4.5 and 5.9 °F warmer under RCP 8.5 compared to the historical baseline (1971–2000). By the late decades of this
                  century (2070–2099), mean annual temperatures in Spokane are projected to rise by 5.5 °F under RCP 4.5 and 9.5 °F under
                  RCP 8.5 compared to 1971–2000 (<b>Table 2</b>).
                </p>
                <p>
                  <i>
                    <b>Table 2:</b> Change in mean annual temperatures projected for Spokane, Washington for the middle (2040–2069) and late (2070–2099)
                    decades of this century under both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5) compared to the
                    historical baseline 1971–2000. Source: The Climate Mapper Tool (https://climatetoolbox.org/tool/Climate-Mapper), The Climate Toolbox.
                  </i>
                </p>
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Emissions Scenario</th>
                      <th scope="col">Mid-Century (2040–2069) Change in Mean Annual Temperature</th>
                      <th scope="col">Late Century (2070-2099) Change in Mean Annual Temperature</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Lower Emissions Scenario (RCP 4.5)</th>
                      <td>4.4 °F</td>
                      <td>5.5 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">High Emissions Scenario (RCP 8.5)</th>
                      <td>5.9 °F</td>
                      <td>9.5 °F</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card border-dark bg-light mb-3">
              <div className="card-header">
                <h4>Projected Future Climate—Rise in Monthly Temperatures</h4>
              </div>
              <div className="card-body">
                <p>
                  <b>Variable:</b> <i>Mean Maximum Daily Temperature</i>
                </p>
                <p>
                  <b>Finding:</b> Throughout this century, temperatures in Spokane are projected to rise steadily across all months of the year.
                </p>
                <p>
                  <b>Justification:</b> A key piece of how warming will impact our region has to do with when during the calendar year that
                  warming is expected to occur. To examine this, our team employed the variable Mean Maximum Daily Temperature. In our
                  analysis, Mean Maximum Daily Temperature is defined as a measurement of the average highest daily temperature reached
                  for each month of the year. For this analysis we chose to employ just the high emissions scenario (RCP 8.5). This was done
                  both to streamline our analysis and because RCP 8.5 more closely resembles the current emissions (and hence warming) path
                  our climate is currently on. Using data from 20 downscaled global climate models collected in the MACAv2-LIVNEH
                  dataset, a CIRC-related effort, our team examined projected Mean Maximum Daily Temperatures for Spokane for the years
                  2020–2050 (early century), 2051–2080 (middle century), and 2081–2099 (late century) for all months of the calendar year
                  (Table 3). Our team then compared these projections against temperatures for the same months for historical baseline
                  1971–2000. Our analysis revealed that temperatures are projected to increase in Spokane across all months of the year
                  throughout this century. The warming most relevant to human health occurs during the summer months. For instance, August
                  temperatures are projected to go from a mean maximum daily temperature of 84 °F historically to 94 °F by 2051–2080 and
                  98 °F by 2081–2099.
                </p>
                <i>
                  <p>
                    <b>Table 3:</b> Simulated mean maximum daily temperature and change from historical (in degrees Fahrenheit) for Spokane, Washington for
                    each month of the year under the high emissions future (RCP 8.5) for 2020–2050, 2051–2080, and 2081–2099. The numbers displayed
                    here represent the multi-model mean from 20 global climate models downscaled for Spokane. 
                  </p>
                  <p> 
                    <b>Source:</b> MACAv2-LIVNEH, (https://climate.northwestknowledge.net/MACA/).
                  </p>
                </i>
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th></th>
                      <th scope="col"><b>Historical 1971–2000</b></th>
                      <th scope="col" colSpan="2"><b>Projected Future RCP 8.5 2020–2050</b> (Early Century)</th>
                      <th scope="col" colSpan="2"><b>Projected Future RCP 8.5 2051–2080</b> (Middle Century)</th>
                      <th scope="col" colSpan="2"><b>Projected Future RCP 8.5 2081–2099</b> (Late Century)</th>
                    </tr>
                  </thead>
                  <thead className="thead-secondary">
                    <tr>
                      <th>Month</th>
                      <th scope="col">Mean Max Daily Temp (°F)</th>
                      <th scope="col">Mean Max Daily Temp (°F)</th>
                      <th scope="col">Change from Historical (°F)</th>
                      <th scope="col">Mean Max Daily Temp (°F)</th>
                      <th scope="col">Change from Historical (°F)</th>
                      <th scope="col">Mean Max Daily Temp (°F)</th>
                      <th scope="col">Change from Historical (°F)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Dec</th>
                      <td className="table-info" >34.8 °F</td>
                      <td className="table-success">38.2 °F</td>
                      <td className="table-success">+ 3.4 °F</td>
                      <td className="table-warning">41.1 °F</td>
                      <td className="table-warning">+ 6.3 °F</td>
                      <td className="table-danger">43.6 °F</td>
                      <td className="table-danger">+ 8.8 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">Jan</th>
                      <td className="table-info" >34.4 °F</td>
                      <td className="table-success">37.9 °F</td>
                      <td className="table-success">+ 3.5 °F</td>
                      <td className="table-warning">40.8 °F</td>
                      <td className="table-warning">+ 6.4 °F</td>
                      <td className="table-danger">43.4 °F</td>
                      <td className="table-danger">+ 9.0 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">Feb</th>
                      <td className="table-info" >40.9 °F</td>
                      <td className="table-success">43.9 °F</td>
                      <td className="table-success">+ 3.0 °F</td>
                      <td className="table-warning">47.3 °F</td>
                      <td className="table-warning">+ 6.4 °F</td>
                      <td className="table-danger">50.2 °F</td>
                      <td className="table-danger">+ 9.3 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">Mar</th>
                      <td className="table-info" >49.2 °F</td>
                      <td className="table-success">52.9 °F</td>
                      <td className="table-success">+ 3.7 °F</td>
                      <td className="table-warning">56.0 °F</td>
                      <td className="table-warning">+ 6.8 °F</td>
                      <td className="table-danger">58.7 °F</td>
                      <td className="table-danger">+ 9.5 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">Apr</th>
                      <td className="table-info" >58.5 °F</td>
                      <td className="table-success">61.8 °F</td>
                      <td className="table-success">+ 3.3 °F</td>
                      <td className="table-warning">64.6 °F</td>
                      <td className="table-warning">+ 6.1 °F</td>
                      <td className="table-danger">66.7 °F</td>
                      <td className="table-danger">+ 8.2 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">May</th>
                      <td className="table-info" >67.8 °F</td>
                      <td className="table-success">70.9 °F</td>
                      <td className="table-success">+ 3.1 °F</td>
                      <td className="table-warning">73.9 °F</td>
                      <td className="table-warning">+ 6.1 °F</td>
                      <td className="table-danger">76.1 °F</td>
                      <td className="table-danger">+ 8.3 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">Jun</th>
                      <td className="table-info" >75.4 °F</td>
                      <td className="table-success">79.4 °F</td>
                      <td className="table-success">+ 4.0 °F</td>
                      <td className="table-warning">83.2 °F</td>
                      <td className="table-warning">+ 7.8 °F</td>
                      <td className="table-danger">86.7 °F</td>
                      <td className="table-danger">+ 11.3 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">Jul</th>
                      <td className="table-info" >85.0 °F</td>
                      <td className="table-success">89.4 °F</td>
                      <td className="table-success">+ 4.4 °F</td>
                      <td className="table-warning">94.5 °F</td>
                      <td className="table-warning">+ 9.5 °F</td>
                      <td className="table-danger">98.9 °F</td>
                      <td className="table-danger">+ 13.9 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">Aug</th>
                      <td className="table-info" >84.2 °F</td>
                      <td className="table-success">88.8 °F</td>
                      <td className="table-success">+ 4.6 °F</td>
                      <td className="table-warning">94.0 °F</td>
                      <td className="table-warning">+ 9.8 °F</td>
                      <td className="table-danger">98.4 °F</td>
                      <td className="table-danger">+ 14.2 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">Sept</th>
                      <td className="table-info" >74.2 °F</td>
                      <td className="table-success">78.1 °F</td>
                      <td className="table-success">+ 3.9 °F</td>
                      <td className="table-warning">82.8 °F</td>
                      <td className="table-warning">+ 8.6 °F</td>
                      <td className="table-danger">86.6 °F</td>
                      <td className="table-danger">+ 12.4 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">Oct</th>
                      <td className="table-info" >59.8 °F</td>
                      <td className="table-success">63.0 °F</td>
                      <td className="table-success">+ 3.2 °F</td>
                      <td className="table-warning">66.4 °F</td>
                      <td className="table-warning">+ 6.6 °F</td>
                      <td className="table-danger">68.9 °F</td>
                      <td className="table-danger">+ 9.1 °F</td>
                    </tr>
                    <tr>
                      <th scope="row">November</th>
                      <td className="table-info" >43.3 °F</td>
                      <td className="table-success">46.2 °F</td>
                      <td className="table-success">+ 2.9 °F</td>
                      <td className="table-warning">49.5 °F</td>
                      <td className="table-warning">+ 6.2 °F</td>
                      <td className="table-danger">51.6 °F</td>
                      <td className="table-danger">+ 8.3 °F</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  <b>Finding:</b> The largest projected summer temperature increase is expected to occur during the months of July and August.
                </p>
                <p>
                  <b>Justification:</b> Because the warming that is projected to occur during the summer months can reasonably be expected to have
                  an impact on human health in Spokane, we chose to examine it further using the MACAv2-LIVNEH dataset. If we plot the
                  temperature data from the MACAv2-LIVNEH dataset for the summer months June, July, and August, a clear pattern
                  emerges: the warmest temperatures are projected to occur during the months of July and August (<b>Figure 3</b>).
                </p>
              </div>
            </div>
            <div id='climate-data-story-urban-tree' className="card border-dark bg-light mb-3">
              <div className="card-header"><h4>Climate Data Story—Urban Tree Canopy</h4></div>
              <div className="card-body">
                <p>
                  Protecting and expanding urban tree cover nationwide is increasingly understood as important to minimizing the effects of
                  heat and drought. Supporting coverage of trees that are Fire-resistant, drought-resistant, and deciduous hardwood species will
                  be especially important. Deciduous, leafy trees cool streets and structures in summer while allowing winter sun to provide
                  heat and light. As summers grow hotter, trees can absorb 90% of incoming solar radiation (<b>Heisler 1986</b>) while
                  evapotranspiration from their leaves cools the air and their shade cuts the solar heating of buildings and parking lots—effects
                  that can reduce air temperatures by as much as 5.8 °Fahrenheit (<b>Akbari and Taha 1992</b>). As side benefits, trees boost
                  property values (<b>Nowak et al., 2002</b>), increase tax revenue (<b>Dwyer et al.,1992</b>), and increase general feelings of wellbeing
                  (<b>Kardan et al., 2015; Donovan et al., 2013</b>).
                </p>
                <p>
                  Urban forestry will become more challenging as temperatures increase, both for maintaining trees planted in cooler times and
                  for establishing species better suited to hotter summers. Urban foresters will need to adapt to new conditions and anticipate
                  still warmer conditions to come. Insect pests, including bark beetles, will likely continue to infest conifers as warmer winters
                  expand insect ranges, especially as drought stress makes more trees susceptible to these insects (<b>NCA 4, Chapter 6 2018</b>). At
                  the same time, horticulturists in Spokane, currently in U.S. Department of Agriculture zone 6a or 6b (<b>USDA Plant Hardiness Zone Map 2018</b>) 
                  will have a broader palette of plant species to work with as Spokane continues to warm. The
                  Climate Toolbox’s Future Cold Hardiness Zones Tool reports Spokane as being in zone 7a for the years 1971–2010. The tool
                  projects the city will be in zones 7b, 8a, and 8b by 2010–2039, 2040–2069, and 2070–2099, respectively under RCP8.5
                  (<b>Future Cold Hardiness Zones Tool 2018</b>).
                </p>
                <p>
                  For reference, among Intermountain Western United States cities, Portland, Oregon is currently in zone 8b (<b>Future Cold Hardiness Zones Tool 2018</b>) 
                  (<b>USDA 2018</b>). Because frequent watering is critical when establishing newly planted trees
                  (<b>University of Minnesota Extension 2019</b>), increased summer and spring irrigation could be more important when
                  establishing trees under a warmer climate.
                </p>
                <p>
                  While planting more trees is desirable and wise, planting on the south sides of homes and buildings could interfere with
                  rooftop solar power, which will be a fast-growing requirement in a decarbonizing world. Policymakers might make
                  allowances for this by allowing or even assisting in tree removal where needed to accommodate solar, even as they tighten
                  protections for trees overall. Further analysis is required to determine the net impact on carbon emissions if policymakers are
                  going to prioritize tree removal for solar installation.
                </p>
              </div>
            </div>
            <div className="card border-dark bg-light mb-3">
              <div className="card-header">
                <h4>Projected Future Climate—Rise in Summer Temperatures</h4>
              </div>
              <div className="card-body">
                <p>
                  <b>Variable:</b> <i>Mean Maximum Daily Temperature and Hottest Summer Day</i>
                </p>
                <p>
                  <b>Finding:</b> Projected summer temperatures for Spokane rise under both the lower emissions scenario (RCP 4.5) and the high
                  emissions scenario (RCP 8.5). However, warming during the summer months is projected to be several degrees warmer under
                  RCP 8.5 when compared to RCP 4.5.
                </p>
                <p>
                  <b>Finding:</b> Under RCP 8.5, Spokane’s hottest summer day is projected to reach 106 degrees Fahrenheit by mid-century
                  (2040–2069) and 111 °F by late-century (2070–2099) compared to the historical mean of 99 °F for the years 1971–2000.
                </p>
                <p>
                  <b>Justification:</b> Using the Climate Toolbox’s Future Boxplots Tool, our team examined projected summer temperature
                  increases for Spokane using the variables Mean Maximum Daily Temperature and Hottest Summer Day. This analysis
                  employed both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5) for comparison. Mean
                  Maximum Daily Temperature is the maximum mean temperature reached daily from June to August. The variable Hottest
                  Summer Day estimates the hottest temperature that a summer day could reach by calculating the mean highest maximum
                  daily temperature.
                </p>
                <p>
                  Under RCP 4.5, Toolbox data shows mean maximum daily temperatures during summer (June–August will reach 87 °F by
                  mid-century (2040–2069) and 88 °F by late century (2070–2099) compared to the historical mean of 82 °F for the historical
                  period 1971–2000 (<b>Figure 4</b>). By comparison, under RCP 8.5, Toolbox data shows that summer mean maximum daily
                  temperatures are projected to reach a multi-model mean of 89 °F by mid-century and 94 °F by late-century (<b>Figure 5</b>).
                </p>
                <p>
                  The variable <i>Hottest Summer Day</i> tells a similar story. Under RCP 8.5, Spokane’s hottest summer day is projected to get
                  even hotter than the historical hottest summer day that occurred during the late 20 th century (1971–2000), reaching a multi-
                  model mean of 106 °F by mid-century and 111 °F by late-century compared to the historical (1971–2000) mean of 99 °F
                  (<b>Figure 7</b>). Spokane’s hottest summer day also rises under RCP 4.5, but the warming is less extreme when compared to RCP
                  8.5. Under RCP 4.5, Spokane’s hottest summer day is projected to reach a multi-model mean of 104 °F by mid-century and
                  106 °F by the late-century (<b>Figure 6</b>).
                </p>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                      <img className="img-fluid border border-dark" alt="jun-july-aug max temp 4.5" src="/media/temperature/Fig-5-Temp.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 4:</b> Mean Maximum daily temperatures for Spokane’s summer months (June, July, and August) for the historical years 1971–2000,
                          and the projected future years 2010–2039, 2040–2069, and 2070–2099 under the lower emissions future (RCP 4.5). Degrees Fahrenheit
                          are displayed on the y-axis (left vertical). Years are displayed on the x-axis (bottom horizontal). The results of each of the 20 models used
                          in the analysis are represented by individual points. The multi-model mean is indicated by a solid bar. Source: Future Boxplots Tool
                          (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                      <img className="img-fluid border border-dark" alt="jun-july-aug max temp 8.5" src="/media/temperature/Fig-4-Temp.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p className="card-text">
                        <i>
                          <b>Figure 5:</b> Mean Maximum daily temperatures for Spokane’s summer months (June, July, and August) for the historical years 1971–2000,
                          and the projected future years 2010–2039, 2040–2069, and 2070–2099 under the high emissions future (RCP 8.5). Degrees Fahrenheit are
                          displayed on the y-axis (left vertical). Years are displayed on the x-axis (bottom horizontal). The results of each of the 20 models used in
                          the analysis are represented by individual points. The multi-model mean is indicated by a solid bar. Source: Future Boxplots Tool
                          (<a href='https://climatetoolbox.org/tool/Future-Boxplots'>https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                      <img className="img-fluid border border-dark" alt="jun-july-aug max temp 8.5" src="/media/temperature/Fig-7-Temp.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p className="card-text">
                        <i>
                          <b>Figure 6:</b> The hottest summer (June, July, and August) day (as measured as the mean highest maximum daily temperature for each year)
                          for the historical years 1971–2000, and the projected future years 2010–2039, 2040–2069, and 2070–2099 under the high emissions
                          scenario (RCP 8.5). Degrees Fahrenheit are displayed on the y-axis (left vertical). Years are displayed on the x-axis (bottom horizontal).
                          The results of each of the 20 models used in the analysis are represented by individual points. The multi-model mean is indicated by a solid
                          bar. Source: Future Boxplots Tool (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                      <img className="img-fluid border border-dark" alt="hottest-summer-day temp 4.5" src="/media/temperature/Fig-6-Temp.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p className="card-text">
                        <i>
                          <b>Figure 7:</b> The hottest summer (June, July, and August) day (as measured as the mean highest maximum daily temperature for each year)
                          for the historical years 1971–2000, and the projected future years 2010–2039, 2040–2069, and 2070–2099 under the lower emissions
                          scenario (RCP 4.5). Degrees Fahrenheit are displayed on the y-axis (left vertical). Years are displayed on the x-axis (bottom horizontal).
                          The results of each of the 20 models used in the analysis are represented by individual points. The multi-model mean is indicated by a solid
                          bar. Source: Future Boxplots Tool (<a href="https://climatetoolbox.org/tool/Future-Boxplots">https://climatetoolbox.org/tool/Future-Boxplots</a>), The Climate Toolbox.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card border-dark bg-light mb-3">
              <div className="card-header"><h4>Projected Future Climate—Rise in High Heat Index Days</h4></div>
              <div className="card-body">
                <p>
                  <b>Variable:</b> <i>Heat Index</i>
                </p>
              <p>
                <b>Finding:</b> Under both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5), Spokane is
                projected to see an increase in the number of days annually where the heat index reaches or exceeds 90 degrees Fahrenheit,
                100 °F, and 105 °F.
              </p>
              <p>
                <b>Finding:</b> By far the largest increase in the number of high heat index days will occur under the high emissions scenario (RCP
                8.5) when compared to the lower emissions scenario (RCP 4.5).
              </p>
              <p>
                <b>Justification:</b> When it’s especially hot and especially humid, the human body has trouble cooling itself by sweating. This
                combined impact of heat and humidity on human health is represented in the heat index. When the heat index has reached or
                exceeds 95–104 ° F, the National Weather Service will generally issue a Heat Advisory for affected regions (<b>“Heat” NWS 2019</b>). 
                For this analysis we used a heat index feature built into the Climate Toolbox’s Climate Mapper Tool. Because
                hospitalizations for heat-related illnesses currently peak in the Pacific Northwest at around 80 ° F (<b>Lancet 2019</b>), for our
                analysis we chose to examine future projections for heat index values greater than or equal to (≥) 90 °F, ≥ 100 °F, and ≥
                105 °F. (Heat index values ≥ 80 °F were not available.)
              </p>
              <p>
                <b>Table 4:</b> shows heat index values ≥ 90 °F, ≥ 100 ° F, and ≥ 105 ° F for Spokane, Washington for mid-century (2040–2069)
                and late century (2070–2099) for both the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5).
                From 1971–2000 (the historical baselines used), Spokane saw an average of 11 days per year when the heat index was ≥ 90 °
                F. By mid-century (2040–2069), Spokane is projected to see 30 days per year with a heat index ≥ 90 °F under RCP 4.5, and
                39 days with a heat index ≥ 90 °F under RCP 8.5. By late century (2070-2099), Spokane is projected to experience 37 days
                annually with a heat index ≥ 90 °F under RCP 4.5 and 61 days annually with a heat index ≥ 90 °F under RCP 8.5. From
                1971–2000, Spokane experienced virtually zero days (0.2) on average during which the heat index was ≥ 100 ° F and zero
                days when the heat index was ≥ 105 ° F. The number of heat index days is expected to grow annually under both RCP 4.5
                and RCP 8.5 throughout this century. However, by far the largest increase in heat index days occurs under RCP 8.5 and
                occurs at late century. For instance, by late century, the high warming scenario is expected to lead to 61.4 days annually with
                a heat index ≥ 90 °F (compared to 11 days historically), 23 days annually with a heat index ≥ 100 °F (compared to 0.2 days
                historically), and 10 days annually with a heat index ≥ 105 °F (compared to zero days historically).
              </p>
              <p>
                <b>Table 4:</b> <i> Annual number days for Spokane, Washington when the heat index is ≥ 90 °F, ≥ 100 °F, ≥ 105 °F for the historical period
                  1971–2000 and the projected future periods mid-century (2040–2069) and late century (2070–2099) under both the lower emissions
                  scenario (RCP 4.5) and the high emissions scenario (RCP 8.5). ). The number of days represents the result of the multi-model mean from
                  18 downscaled global climate models. Source: Climate Mapper Tool (<a href="https://climatetoolbox.org/tool/Climate-Mapper">https://climatetoolbox.org/tool/Climate-Mapper</a>), The Climate
                  Toolbox.</i>
              </p>
              <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Heat Index Value</th>
                      <th scope="col">Historical (1971–2000) Average annual number of days</th>
                      <th scope="col">Mid-Century (2040–2069) RCP 4.5 Average annual number of days</th>
                      <th scope="col">Mid-Century (2040–2069) RCP 8.5 Average annual number of days</th>
                      <th scope="col">Late Century (2070–2099) RCP 4.5 Average annual number of days</th>
                      <th scope="col">Late Century (2070–2099) RCP 8.5 Average annual number of days</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">≥ 90 °F</th>
                      <td>11 days</td>
                      <td>30.6 days</td>
                      <td>38.8 days</td>
                      <td>37.2 days</td>
                      <td>61.4 days</td>
                    </tr>
                    <tr>
                      <th scope="row">≥ 100 °F</th>
                      <td>0.2 days</td>
                      <td>3.5 days</td>
                      <td>7.7 days</td>
                      <td>6.1 days</td>
                      <td>22.7 days</td>
                    </tr>
                    <tr>
                      <th scope="row">≥ 105 °F</th>
                      <td>0.0 days</td>
                      <td>0.6 days</td>
                      <td>1.3 days</td>
                      <td>2 days</td>
                      <td>10.1 days</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div id="about-heat-vulnerable-pop" className="card border-dark bg-light mb-3">
              <div className="card-header"><h4>About Heat-Vulnerable Populations</h4></div>
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  <p>
                    “Climate conditions such as high temperatures create a heat hazard. Exposure to this hazard, particularly if a
                    person is sensitive and not well adapted, creates risk, and increases the likelihood of negative health outcomes.”
                    – <i>National Integrated Heat Health Information System, U.S. Climate Resilience Toolkit,</i>
                  </p>
                </h6>
                <p>
                  While everyone can be affected by extreme heat events, some groups are more at risk of heat-related illnesses during extreme
                  heat events than others. Groups vulnerable to heat-related illnesses include the young, older adults, and pregnant women.
                  Other groups affected by heat stress include outdoor athletes, outdoor workers, low-incomes households, individuals with
                  certain chronic medical diseases, and first responders (including police, firefighters, and paramedics) and domestic pets
                  (<b>Centers for Disease Control and Prevention 2019; National Integrated Heat Health Information System 2018</b>).
                  Individuals experiencing homelessness are also vulnerable to temperature extremes (<b>National Health Care for the Homeless Council 2019</b>).
                </p>
                <p>
                  All of the vulnerable populations listed above are at increased risk of serious heat-related illnesses and even death from
                  extreme heat events if not adequately protected. School activities and youth athletic programs are likely to be affected by
                  rising temperatures as high heat poses a threat to children’s health. Public safety at summer outdoor events from festivals to
                  athletic events will be impacted by rising temperatures. Under projected future climate conditions, event organizers will be
                  required to provide increased medical services, increased access to hydration, or to consider changing the scheduled event
                  dates to favor cooler months. Regional health districts are advised to conduct an assessment to determine their unique
                  vulnerability to climate change and the populations under their jurisdictions that are at greatest risk (<b>U.S. Climate Resilience Toolkit 2018</b>).
                </p>
                <p>
                  Throughout the United States, unsheltered and low-income populations are also at greater risk of heat-related distress and
                  disease as a result of climate change. Extreme heat poses a particular threat to these populations due to their lack of access to
                  air conditioning and their lack of resources to escape extreme weather. Low-income populations tend to live in urban areas
                  with high levels of heat-trapping concrete with little tree cover to reduce heat (<b>Morello-Frosch et al., 2009</b>). As Spokane
                  prepares for a hotter future climate, it becomes necessary to focus our planning and adaptations strategies in lower-income
                  areas first since they will be hit the hardest. To close the income gap of climate impacts, our community will need to redirect
                  resources to assist our low-income and homeless populations. This is arguably a matter of environmental justice. According
                  to the Environmental Protection Agency, environmental justice will only be achieved “when everyone enjoys the same
                  degree of protection from environmental and health hazards, and [everyone has] equal access to the decision-making process
                  to have a healthy environment in which to live, learn, and work” (<b>EPA 2018</b>).
                </p>
                <p>
                  First-responders and emergency services personnel nation-wide will need information, resources, and additional training to
                  deal with extreme heat events and the possibility of increased casualties during extreme heat waves. Police and first-
                  responders are already operating to their maximum capacity in most communities. Extreme weather brought on by climate
                  change will only exacerbate the issues of resource-stressed emergency responders (<b>Friese 2018</b>). Public areas where indoor
                  cooling is available, such as libraries, malls, and grocery stores will face greater demand and may need to be expanded to
                  offer emergency public cooling during extreme heat events.
                </p>
                <p>
                  Outdoor workers are at increasing risk of heat-impacts. Increasing temperatures will result in an increase in liability for
                  employers to provide air-conditioned break areas, access to water, clothing to mitigate the effects of heat, sunscreen, and
                  additional time for breaks (<b>Centers for Disease Control and Prevention 2018</b>). Employers and decision-makers at all levels
                  will need clear guidelines for safeguarding and protecting the health of populations under their jurisdictions during extreme
                  heat events. Depending on the costs associated with this increase in required adaptation techniques for employers, the
                  potential for negative economic consequences could be considerable. Like many communities, Spokane will need to adopt
                  policies to safeguard their most vulnerable populations during the hot summer months.
                </p>
              </div>
            </div>
            <div id="conclusion" className="card border-dark bg-light mb-3">
              <div className="card-header"><h4>Conclusion</h4></div>
              <div className="card-body">
                <p>
                  The findings of this chapter indicate that Spokane needs to prepare as a community for the increased risk of extreme heat to
                  human health. Specifically, our findings indicate a need for new policies and procedures in the public and private sectors to
                  address increasing heat-related risks that will help safeguard human health, safety, and productivity. Because the projected
                  rise in temperatures creates a future challenge in the Spokane region that will require planning and preparation, we strongly
                  recommend that city planners, school districts, employers, healthcare workers, elected officials, and other community
                  decision-makers begin discussing today the sorts of policies and adaptation measures that are needed to lessen the adverse
                  health effects of heat increases on our most vulnerable populations in the not too distant future. For example, in the next
                  30–60 years, the increasing potential for heat-related illness should be considered when scheduling outdoor public events.
                  Health and safety officials could, for example, create and promote safety protocols for extreme heat events with particular
                  concern for outdoor workers, outdoor recreation/athletes, and other heat-vulnerable populations. First responders could be
                  trained and equipped to treat heat stress in individuals and in multiple-casualty events.
                </p>
                <p>
                  Although the largest increase in temperatures are projected to occur during the summer, increasing temperatures will also be
                  experienced throughout the year. Outdoor events, including Bloomsday and SpokeFest, which are scheduled during the
                  spring and fall months, are already experiencing an increase in heat-related health issues.
                </p>
                <p>
                  As heat events become more likely and more common, communities will need to increase public awareness of heat dangers
                  and how to be safe during heat events. Health officials and other jurisdictions can enact programs to increase public
                  awareness around specific risks, such as leaving children or pets in cars, how to protect yourself from heat illness, and how to
                  recognize the signs and symptoms of heat stress, heat exhaustion, and heat stroke.
                </p>
                <p>
                  The primary action for mitigating the risk of increased temperatures in the Spokane region is to reduce greenhouse gas
                  emissions in order to avoid an RCP 8.5 scenario. “Impacts on natural and human systems from global warming have already
                  been observed,” and the intensity of future climate-related risks depend on our action and ability to drawdown emissions of
                  carbon dioxide in our atmosphere (<b>Allen et al., 2018</b>). As noted above, RCP 8.5 is plausible, but not inevitable. The negative
                  outcomes of human-caused climate change can be mitigated by swift and significant reductions in greenhouse gas emissions.
                  Individual choices, business practices, and government policy are all necessary components of an effective strategy to
                  prevent the worst-possible climate impacts scenarios. Many solutions to prevent dangerous climate conditions are currently
                  available, but these solutions require ongoing commitment, financial support, and political will to ensure effective
                  implementation.
                </p>
                <div className="card border-dark m-3">
                  <div className="card-header text-white bg-success p-2 card-header-display">
                    <h4 className="col-10 card-header-title ">Resiliency Actions</h4>
                  </div>
                  <div className="card-body p-0">
                    <ol className="list-group">
                      <li className="list-group-item list-group-item-success">
                        <b>Reduce Emissions:</b> The negative outcomes of the high emissions scenario (RCP 8.5) can be mitigated by swift and
                          significant reductions in greenhouse gas emissions.
                      </li>
                      <li className="list-group-item list-group-item-success">
                        <b>Plan for High Temperatures:</b> Over the next 30–60 years, high heat dangers should be considered when scheduling outdoor
                        public events. 
                      </li>
                      <li className="list-group-item list-group-item-success">
                        <b>Safety Protocols:</b> Health and safety officials should create and promote safety protocols for extreme heat events
                        with particular concern for outdoor workers, outdoor recreation/athletes, and vulnerable
                        populations.                         
                      </li>
                      <li className="list-group-item list-group-item-success">
                        <b>Train and Equip First Responders:</b> First responders should be adequately trained and equipped to treat heat-related illnesses.
                      </li>
                      <li className="list-group-item list-group-item-success">
                        <b>Urban Forest:</b>The City should take immediate action to protect and expand the urban tree cover to mitigate the effects of rising temperatures on human health.
                      </li>
                    </ol>
                  </div>
                </div>
                <h4>Potential Future Work—Investigating Increased Energy & Water Demand</h4>
                <p>
                  Evaluating specific changes in energy and water demand for the Spokane region falls outside of this report. However, it is
                  important to note, that the impact of increasing temperatures will no doubt require a significant increase in energy
                  consumption to meet increased cooling demands. Water consumption for humans, animals, and plants is also likely to
                  increase with rising temperatures. Further research on this topic is required and could be explored in a future analysis.
                </p>
              </div>
            </div>
            <div id="citations" className="card border-dark bg-light mb-3">
              <div className="card-header"><h4>Citations For this Chapter</h4></div>
              <div className="card-body">
                <p className="card-text">
                  Rebecca MacMullan, Kara Odegard, Jim Simon, and David Camp.
                  "Temperature Impact Study for Spokane, Washington.” In <i>The Spokane Climate Vulnerability and Resilience Assessment</i>,
                  edited by Nathan Gilles, Katherine Hegewisch, John Abatzoglou, Ann Mooney, and Meghan Dalton. Corvallis, Oregon: 
                  <i>The Pacific Northwest Climate Impacts Research Consortium (CIRC), College of Earth, Ocean, and Atmospheric Sciences, Oregon State University</i>, 2020.
                </p>
                <h5>CIRC-Related Data Sources:</h5>
                <ol className="list-group">
                  <li className="list-group-item">
                    Abatzoglou, John T. "Development of gridded surface meteorological data for ecological applications and
                    modelling." <i>International Journal of Climatology</i> 33, no. 1 (2013): 121-131.  
                    <a href="https://doi.org/10.1002/joc.3413">https://doi.org/10.1002/joc.3413</a>.
                  </li>
                  <li className="list-group-item">
                    Abatzoglou, John T., and Timothy J. Brown. "A comparison of statistical downscaling methods suited for wildfire
                    applications." <i>International Journal of Climatology</i> 32, no. 5 (2012): 772-780.  
                    <a href='https://doi.org/10.1002/joc.2312'>https://doi.org/10.1002/joc.2312</a>.
                  </li>
                  <li className="list-group-item">
                    Hegewisch, Katherine C., and John T. Abatzoglou. “Future Climate Boxplots Tool.” The Climate Toolbox. Accessed Nov
                    21, 2018. <a href="https://climatetoolbox.org/tool/future-climate-boxplots">https://climatetoolbox.org/tool/future-climate-boxplots</a>.
                  </li>
                  <li className="list-group-item">
                    Hegewisch, Katherine C., and John T. Abatzoglou. “Future Climate Dashboard Tool.” The Climate Toolbox. Accessed Nov
                    21, 2018. <a href='https://climatetoolbox.org/tool/future-climate-dashboard'>https://climatetoolbox.org/tool/future-climate-dashboard</a>.
                  </li>
                  <li className="list-group-item">
                    Hegewisch, Katherine C., and John T. Abatzoglou. “Future Cold Hardiness Zones Tool.” The Climate Toolbox. Accessed
                    November 21, 2018. <a href="https://climatetoolbox.org/tool/Cold-Hardiness-Zones">https://climatetoolbox.org/tool/Cold-Hardiness-Zones</a>.
                  </li>
                  <li className="list-group-item">
                    Hegewisch, Katherine C., and John T. Abatzoglou. “Future Time Series Tool.” The Climate Toolbox. Accessed November
                    1st, 2019. <a href='https://climatetoolbox.org/tool/Future-Time-Series'>https://climatetoolbox.org/tool/Future-Time-Series</a>
                  </li>
                  <li className="list-group-item">
                    Hegewisch, Katherine C., and John T. Abatzoglou. “Historical Climate Tracker Tool.” The Climate Toolbox. Accessed
                    September 19, 2019. <a href="https://climatetoolbox.org/tool/Historical-Climate-Tracker">https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>.
                  </li>
                  <li className="list-group-item">
                    Hegewisch, Katherine C., John T. Abatzoglou, Oriana Chegwidden, and Bart Nijssen. “The Climate Mapper Tool.” The
                    Climate Toolbox. Accessed September 19, 2019. <a href="https://climatetoolbox.org/tool/Climate-Mapper">https://climatetoolbox.org/tool/Climate-Mapper</a>.
                  </li>
                  <li className="list-group-item">
                    Hegewisch, Katherine C., and John T. Abatzoglou. Northwest Knowledge Network. “The Multivariate Adaptive Constructed
                    Analogs (MACA) Datasets.” Accessed Nov 21, 2018. <a href='https://climate.northwestknowledge.net/MACA/'>https://climate.northwestknowledge.net/MACA/</a>.
                  </li>
                </ol>
                <h5 className="m-2">Additional References:</h5>
                <ol className="list-group">
                  <li className="list-group-item">
                    Akbari, Hashem, and Haider Taha. "The impact of trees and white surfaces on residential heating and cooling energy use in
                    four Canadian cities." Energy 17, no. 2 (1992): 141-149. <a href="https://doi.org/10.1016/0360-5442(92)90063-6">https://doi.org/10.1016/0360-5442(92)90063-6</a>.
                  </li>
                  <li className="list-group-item">
                    Allen, Myles, Mustafa Babiker, Yang Chen, Heleen de Coninck, Sarah Connors, Renee van Diemen, Opha Pauline Dube, et
                    al. 2018. “Global Warming of 1.5 C: Summary For Policymakers.” Climate, <i>Global: Intergovernmental Panel On Climate Change</i>. 
                    <a href="https://report.ipcc.ch/sr15/pdf/sr15_spm_final.pdf">https://report.ipcc.ch/sr15/pdf/sr15_spm_final.pdf</a>.
                  </li>
                  <li className="list-group-item">
                    Bloomsday Run. “History & Stats—Race Day Weather.” Accessed May 30, 2019. <a href="https://www.bloomsdayrun.org/history-stats/race-day-weather">https://www.bloomsdayrun.org/history-stats/race-day-weather</a>.
                  </li>
                  <li className="list-group-item">
                    Bobb, Jennifer F., Ziad Obermeyer, Yun Wang, and Francesca Dominici. "Cause-Specific Risk of Hospital Admission
                    Related to Extreme Heat in Older Adults." <i>Journal of the American Medical Association</i> 312, no. 24 (2014): 2659-2667.
                    <a href="https://doi.org/10.1001/jama.2014.15715">https://doi.org/10.1001/jama.2014.15715</a>.
                  </li>
                  <li className="list-group-item">
                    Centers for Disease Control and Prevention. “Extreme Heat". Accessed November 20, 2019.
                    <a href="https://www.cdc.gov/disasters/extremeheat/index.html">https://www.cdc.gov/disasters/extremeheat/index.html</a>.
                  </li>
                  <li className="list-group-item">
                    Centers for Disease Control and Prevention. “About Extreme Heat.” Accessed November 21, 2018.
                    <a href="https://www.cdc.gov/disasters/extremeheat/heat_guide.html">https://www.cdc.gov/disasters/extremeheat/heat_guide.html</a>.
                  </li>
                  <li className="list-group-item">
                    Centers for Disease Control and Prevention. “Heat and Outdoor Workers.” Accessed November 21, 2018.
                    <a href='https://www.cdc.gov/disasters/extremeheat/workers.html'>https://www.cdc.gov/disasters/extremeheat/workers.html</a>.
                  </li>
                  <li className="list-group-item">
                    Clark, Peter U., Jeremy D. Shakun, Shaun A. Marcott, Alan C. Mix, Michael Eby, Scott Kulp, Anders Levermann et al.
                    "Consequences of twenty-first-century policy for multi-millennial climate and sea-level change." <i>Nature Climate Change</i> 6,
                    no. 4 (2016): 360. <a href="https://doi.org/10.1038/NCLIMATE2923">https://doi.org/10.1038/NCLIMATE2923</a>.
                  </li>
                  <li className="list-group-item">
                    Dahl, Kristina, Rachel Licker, John T. Abatzoglou, and Juan Declet-Barreto. “Increased frequency of and populations
                    exposure to extreme heat index days in the United States during the 21st century.” <i>Environmental Research Communications</i>.
                    (2019). <a href="https://doi.org/10.1088/2515-7620/ab27cf">https://doi.org/10.1088/2515-7620/ab27cf</a>.
                  </li>
                  <li className="list-group-item">
                    Donovan, Geoffrey H., David T. Butry, Yvonne L. Michael, Jeffrey P. Prestemon, Andrew M. Liebhold, Demetrios
                    Gatziolis, and Megan Y. Mao. "The relationship between trees and human health: evidence from the spread of the emerald
                    ash borer." <i>American Journal of Preventive Medicine</i> 44, no. 2 (2013): 139-145.
                    <a href="https://doi.org/10.1016/j.amepre.2012.09.066">https://doi.org/10.1016/j.amepre.2012.09.066</a>.
                  </li>
                  <li className="list-group-item">
                    Dwyer, McPherson, Gregory McPherson, Herbert W. Schroeder, and Rowan A. Rowntree. "Assessing the Benefits and Costs
                    of the Urban Forest." <i>Journal of Arboriculture</i> 227-234 (1992).
                    <a href="https://pdfs.semanticscholar.org/e371/cb0b22db7286b27ae826abdd29dfe44bf613.pdf">https://pdfs.semanticscholar.org/e371/cb0b22db7286b27ae826abdd29dfe44bf613.pdf</a>.
                  </li>
                  <li className="list-group-item">
                    Ebi, Kristie L., John M. Balbus, George Luber, Aparna Bole, Allison Crimmins, Gregory Glass,
                    Shubhayu Saha, Mark M. Shimamoto, J. Trtanj, and Jalonne .L.
                    White-Newsome, 2018: Human Health. In <i>Impacts, Risks, and Adaptation in the United States</i>: Fourth National
                    Climate Assessment, Volume II [Reidmiller, D.R., C.W. Avery, D.R. Easterling, K.E. Kunkel, K.L.M. Lewis, T.K. Maycock,
                    and B.C. Stewart (eds.)]. U.S. Global Change Research Program, Washington, DC, USA, pp. 539–571.
                    <a href="https://doi.org/10.7930/NCA4.2018.CH14">https://doi.org/10.7930/NCA4.2018.CH14</a>.
                  </li>
                  <li className="list-group-item">
                    Ebi, Kristie L., John M. Balbus, George Luber, Aparna Bole, Allison Crimmins, Gregory Glass,
                    Shubhayu Saha, Mark M. Shimamoto, J. Trtanj, and Jalonne .L.
                    White-Newsome, 2018: Human Health. In <i>Impacts, Risks, and Adaptation in the United States</i>: Fourth National
                    Climate Assessment, Volume II [Reidmiller, D.R., C.W. Avery, D.R. Easterling, K.E. Kunkel, K.L.M. Lewis, T.K. Maycock,
                    and B.C. Stewart (eds.)]. U.S. Global Change Research Program, Washington, DC, USA, pp. 572–603.
                    <a href="https://doi.org/10.7930/NCA4.2018.CH14">https://doi.org/10.7930/NCA4.2018.CH14</a>.
                  </li>
                  <li className="list-group-item">
                    Environmental Protection Agency. “Environmental Justice.” Accessed November 21, 2018.
                    <a href="https://www.epa.gov/environmentaljustice">https://www.epa.gov/environmentaljustice</a>.
                  </li>
                  <li className="list-group-item">
                    Friese, Greg, “For first responders, climate change could add even more stress,” interview with by Daisy Simmons, Yale
                    <i>Climate Connections</i>, July 5, 2018. <a href="https://www.yaleclimateconnections.org/2018/07/climate-change-adds-stress-for-first-responders/">https://www.yaleclimateconnections.org/2018/07/climate-change-adds-stress-for-first-responders/</a>.
                  </li>
                  <li className="list-group-item">
                    Hawkins, S.C. Herring, L. Jantarasami, D.M. Mills, S. Saha, M.C. Saro m, J. Trtanj, and L. Ziska, Eds. U.S. Global Change
                    Research Program, Washington, DC, 2016. P 44. <a href="http://dx.doi.org/10.7930/J0R49NQX">http://dx.doi.org/10.7930/J0R49NQX</a>.
                  </li>
                  <li className="list-group-item">
                    Hegewisch, Katherine C., and John T. Abatzoglou. 2014. “MACAv2-LIVNEH: Climate Model Outputs from CMIP5 using
                    Multivariate Adaptive Constructed Analogs (MACA) v2 statistical downscaling method with Livneh Observational Training
                    Datasets.” <i>USGS ScienceBase</i>.
                  </li>
                  <li className="list-group-item">
                    Heisler, Gordon M. “Effects of individual trees on the solar radiation climate of small buildings.” <i>Urban Ecology</i> 9, no. 3-4
                    (1986): 337-359. <a href="https://doi.org/10.1016/0304-4009(86)90008-2">https://doi.org/10.1016/0304-4009(86)90008-2</a>.
                  </li>
                  <li className="list-group-item">
                    Jay, Alexa, D.R. Reidmiller, Christopher W. Avery, Daniel Barrie, Benjamin J. DeAngelo, Apurva Dave, Matthew Dzaugis,
                    Michael Kolian, Kristin Lew is, Katie Reeves, and Darrell Winner, 2018: “Overview. In Impacts, Risks, and Adaptation” in
                    the United States: Fourth National Climate Assessment, Volume II [Reidmiller, D.R., C.W. Avery, D.R. Easterling, K.E.
                    Kun- kel, K.L.M. Lewis, T.K. Maycock, and B.C. Stewart (eds.)]. U.S. Global Change Research Program, Washington, DC,
                    USA, p 42. <a href="https://doi.org/10.7930/NCA4.2018.CH1">https://doi.org/10.7930/NCA4.2018.CH1</a>.
                  </li>
                  <li className="list-group-item">
                    Kardan, Omid, Peter Gozdyra, Bratislav Misic, Faisal Moola, Lyle J. Palmer, Tomáš Paus, and Marc G. Berman.
                    "Neighborhood greenspace and health in a large urban center." <i>Scientific Reports</i> 5 (2015): 11610.
                    <a href='https://doi.org/10.1038/srep11610'>https://doi.org/10.1038/srep11610</a>.
                  </li>
                  <li className="list-group-item">
                    Kim, Ella J. "The impacts of climate change on human health in the United States: A scientific assessment, by us global
                    change research program." <i>Journal of the American Planning Association</i> 82, no. 4 (2016): 418-419.
                    <a href='https://dialnet.unirioja.es/servlet/articulo?codigo=5695615'>https://dialnet.unirioja.es/servlet/articulo?codigo=5695615</a>.
                  </li>
                  <li className="list-group-item">
                    Lancet. 2019 Lancet Countdown on Health and Climate Change: Policy Brief for the U.S. Accessed December 5, 2019.
                    <a href="https://www.lancetcountdownus.org/2019-lancet-countdown-us-brief">https://www.lancetcountdownus.org/2019-lancet-countdown-us-brief</a>.
                  </li>
                  <li className="list-group-item">
                    Lutz, Bob, MD, MPH, Health Officer at Spokane Regional Health District & Medical Director for Bloomsday, interviewed
                    by Kara Odegard, October 31, 2018.
                  </li>
                  <li className="list-group-item">
                    Mooney, Ann, Nathan Gilles, Katherine Hegewisch, John Abatzoglou, and Meghan Dalton. “The Northwest Climate
                    Toolbox Workbook: Discovering and applying your climate data story for climate adaptation planning,” Corvallis, Oregon:
                    The Pacific Northwest Climate Impacts Research Consortium (CIRC), College of Earth, Ocean, and Atmospheric Sciences,
                    Oregon State University, 2019. <a href='https://pnwcirc.org/sites/pnwcirc.org/files/nwct.pdf'>https://pnwcirc.org/sites/pnwcirc.org/files/nwct.pdf</a>.
                  </li>
                  <li className="list-group-item">
                    Morello-Frosch, Rachel, Manuel Pastor, James Sadd, and Seth Shonkoff. 2009. " The Climate Gap: Inequalities in How
                    Climate Change Hurts Americans & How to Close the Gap.” University of Southern California Dornsife College or Letters,
                    Arts and Sciences 7-14. <a href="https://dornsife.usc.edu/assets/sites/242/docs/The_Climate_Gap_Full_Report_FINAL.pdf">https://dornsife.usc.edu/assets/sites/242/docs/The_Climate_Gap_Full_Report_FINAL.pdf</a>.
                  </li>
                  <li className="list-group-item">
                    National Health Care for the Homeless Council. “Heat-Related Injuries.” Accessed November 20, 2019.
                    <a href="https://nhchc.org/clinical-practice/diseases-and-conditions/heat-related-injuries/">https://nhchc.org/clinical-practice/diseases-and-conditions/heat-related-injuries/</a>.
                  </li>
                  <li className="list-group-item">
                    National Weather Service, National Oceanic and Atmospheric Administration. “Weather Fatalities 2018.” Accessed 2018.
                    <a href='http://www.nws.noaa.gov/om/hazstats/resources/weather_fatalities.pdf'>http://www.nws.noaa.gov/om/hazstats/resources/weather_fatalities.pdf</a>.
                  </li>
                  <li className="list-group-item">
                    National Weather Service, National Oceanic and Atmospheric Administration, “Heat Index.” Accessed 2018.
                    <a href="https://www.weather.gov/safety/heat-index">https://www.weather.gov/safety/heat-index</a>.
                  </li>
                  <li className="list-group-item">
                    National Weather Service, National Oceanic and Atmospheric Administration. “79-Year List of Severe Weather Fatalities.”
                    Accessed 2018. <a href="https://www.weather.gov/media/hazstat/79years.pdf">https://www.weather.gov/media/hazstat/79years.pdf</a>.
                  </li>
                  <li className="list-group-item">
                    National Weather Service, National Oceanic and Atmospheric Administration. “Heat” Accessed December 11, 2019.
                    <a href="https://www.weather.gov/bgm/heat">https://www.weather.gov/bgm/heat</a>.
                  </li>
                  <li className="list-group-item">
                    National Integrated Heat Health Information System. “Understanding the Health Risks of Extreme Heat.” Accessed February
                    6, 2020. <a href="https://toolkit.climate.gov/nihhis/">https://toolkit.climate.gov/nihhis/</a>.
                  </li>
                  <li className="list-group-item">
                    National Integrated Heat Health Information System. “AT-RISK GROUPS.” Accessed November 20, 2019.
                    <a href='https://toolkit.climate.gov/nihhis/#atrisk'>https://toolkit.climate.gov/nihhis/#atrisk</a>.
                  </li>
                  <li className="list-group-item">
                    National Weather Service, National Oceanic and Atmospheric Administration. “Relative Humidity (RH).”
                    <a href="https://graphical.weather.gov/definitions/defineRH.html">https://graphical.weather.gov/definitions/defineRH.html</a>.
                  </li>
                  <li className="list-group-item">
                    Nowak, David J., Daniel E. Crane, and John F. Dwyer . "Compensatory Value of Urban Trees in the United States." <i>Journal of Arboriculture</i> 28, no. 40 (2002): 194-199.
                  </li>
                  <li className="list-group-item">
                    The Pacific Northwest Climate Impacts Research Consortium (CIRC), Oregon State University. “Climate Tools.” Accessed
                    November 21, 2018. The Climate Toolbox. <a href="https://pnwcirc.org/climatetools">https://pnwcirc.org/climatetools</a>.
                  </li>
                  <li className="list-group-item">
                    Pacific Northwest Climate Impacts Research Consortium (CIRC). “Human Choice, Warming, & Emissions.” Accessed
                    September 23, 2019. <a href="https://pnwcirc.org/science/pathways">https://pnwcirc.org/science/pathways</a>.
                  </li>
                  <li className="list-group-item">
                    Pacific Northwest Climate Impacts Research Consortium (CIRC). “Temperature.” Accessed September 23, 2019.
                    <a href="https://pnwcirc.org/science/temperature">https://pnwcirc.org/science/temperature</a>.
                  </li>
                  <li className="list-group-item">
                    Rupp, David E., John T. Abatzoglou, Katherine C. Hegewisch, and Philip W. Mote. “Evaluation of CMIP5 20th Century
                    Climate Simulations for the Pacific Northwest USA.”
                    <i>Journal of Geophysical Research: Atmospheres</i> 118, no. 19 (2013).
                    <a href="https://doi.org/10.1002/jgrd.50843">https://doi.org/10.1002/jgrd.50843</a>.
                  </li>
                  <li className="list-group-item">
                    Sarofim, Marcus C., S Shubhayu Saha, Michelle D. Hawkins, David M. Mills , Jeremy Hess , Radley Horton, Patrick
                    Kinney, Joel Schwartz , and Alexis St. Juliana, 2016: “Ch. 2: Temperature-Related Death and Illness. The Impacts of Climate
                    Change on Human Health in the United States: A Scientific Assessment.” U.S. Global Change Research Program,
                    Washington, DC, 43–68. <a href='http://dx.doi.org/10.7930/J0MG7MDX'>http://dx.doi.org/10.7930/J0MG7MDX</a>.
                  </li>
                  <li className="list-group-item">
                    Skeptical Science. “The 97% consensus on global warming.” Accessed September 23, 2019.
                    <a href="https://skepticalscience.com/print.php?r=442">https://skepticalscience.com/print.php?r=442</a>.
                  </li>
                  <li className="list-group-item">
                    U.S. Global Change Research Program (USGCRP). The Impacts of Climate Change on Human Health in the United States:
                    A Scientific Assessment. Crimmins, A., J. Balbus, J.L. Gamble, C.B. Beard, J.E. Bell, D. Dodgen, R.J. Eisen, N. Fann, M.D.
                  </li>
                  <li className="list-group-item">
                    U.S. Climate Resilience Toolkit. “Assessing Health Vulnerability to Climate Change: A Guide for Health Departments.”
                    Accessed November 21, 2018. <a href="https://toolkit.climate.gov/tool/assessing-health-vulnerability-climate-change-guide-health-departments">https://toolkit.climate.gov/tool/assessing-health-vulnerability-climate-change-guide-health-departments</a>.
                  </li>
                  <li className="list-group-item">
                    U.S. Climate Resilience Toolkit. “Excessive Heat Events Guidebook.” Accessed February 8, 2019.
                    <a href="https://toolkit.climate.gov/tool/excessive-heat-events-guidebook">https://toolkit.climate.gov/tool/excessive-heat-events-guidebook</a>.
                  </li>
                  <li className="list-group-item">
                    U.S. Department of Agriculture. “USDA Plant Hardiness Zone Map.” Accessed Dec 7, 2018.
                    <a href='https://planthardiness.ars.usda.gov/PHZMWeb/'>https://planthardiness.ars.usda.gov/PHZMWeb/</a>.
                  </li>
                  <li className="list-group-item">
                    University of Minnesota Extension 2019. “Watering newly planted trees and shrubs.” Accessed November 20, 2019.
                    <a href="https://extension.umn.edu/planting-and-growing-guides/watering-newly-planted-trees-and-shrubs">https://extension.umn.edu/planting-and-growing-guides/watering-newly-planted-trees-and-shrubs</a>.
                  </li>
                  <li className="list-group-item">
                    Vose, James M., David L. Peterson, Grant M. Domke, Christopher J. Fettig, Linda A. Joyce, Robert E. Keane, Charles H.
                    Luce, Jeffrey P. Prestemon, Lawrence E. Band, James S. Clark, Nicolette E. Cooley, Anthony D'Amato, and Jessica E.
                    Halofsky. “Chapter 6: Forests.” In Impacts, Risks, and Adaptation in the United States: Fourth National Climate Assessment,
                    Volume II [Reidmiller, D.R., C.W. Avery, D.R. Easterling, K.E. Kunkel, K.L.M. Lewis, T.K. Maycock, and B.C. Stewart
                    (eds.)]. U.S. Global Change Research Program, Washington, DC, USA, pp. 232–267.
                    <a href='https://doi.org/10.7930/NCA4.2018.CH6'>https://doi.org/10.7930/NCA4.2018.CH6</a>.
                  </li>
                  <li className="list-group-item">
                    Watts, Nick, Markus Amann, Nigel Arnell, Sonja Ayeb-Karlsson, Kristine Belesova, Maxwell Boykoff, Peter Byass et al.
                    "The 2019 report of The Lancet Countdown on health and climate change: ensuring that the health of a child born today is
                    not defined by a changing climate." <i>The Lancet</i> 394, no. 10211 (2019): 1836-1878.
                    <a href="https://doi.org/10.1016/S0140-6736(19)32596-6">https://doi.org/10.1016/S0140-6736(19)32596-6</a>.
                  </li>
                  <li className="list-group-item">
                    <i>Weather Underground</i>. “Historical Weather, for May 6.” Accessed November 21, 2019.
                    <a href="https://www.wunderground.com/history/">https://www.wunderground.com/history/</a>.
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* Beginning of Sidebar */}
          <div className="col-2 d-none d-md-block navbar">
            <div className="navbar-sticky-top sticky-top bg-white side-navbar">
              <h5 className="sidebar-title">Menu</h5>
              <ol className="list-group">
                <li className="list-group-item">
                  <a href="#chapter-summary">Chapter Summary</a>
                </li>
                <li className="list-group-item">
                  <a href="#climate-data-story-beat-the-heat">CDS - Beat The Heat</a>
                </li>
                
                <li className="list-group-item">
                  <a href="#climate-data-story-bloomsday">CDS - Bloomsday</a>
                </li>
                <li className="list-group-item">
                  <a href="#may-max-temp">May Max Temperature</a>
                </li>
                <li className="list-group-item">
                    <a href="#larger-context">Larger Context</a>
                </li>
                <li className="list-group-item">
                    <a href="#local-context">Local Context</a>
                </li>
                <li className="list-group-item">
                    <a href="#analysis">Analysis</a>
                </li>
                <li className="list-group-item">
                    <a href="#projected-future-climate">Projected Future Climate</a>
                </li>
                <li className="list-group-item">
                    <a href="#mean-temp">Mean Temperature</a>
                </li>
                <li className="list-group-item">
                    <a href="#climate-data-story-urban-tree">CDS - Urban Tree Canopy</a>
                </li>
                <li className="list-group-item">
                    <a href="#about-heat-vulnerable-pop">About Heat-Vulnerable Populations</a>
                </li>
                <li className="list-group-item">
                    <a href="#conclusion">Conclusion</a>
                </li>
                <li className="list-group-item">
                    <a href="#citations">Citations</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Temperature;