import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Summary from './Summary.js'
import Temperature from './Temperature.js'
import Snow from './Snow.js'
import Streamflow from './Streamflow.js'
import Wildfires from './Wildfires.js'
import Precipitation from './Precipitation.js'
import Resources from './Resources.js'
import Footer from './Footer.js'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <Router>
      <ul className="navbar navbar-expand-lg navbar-dark sticky-top navbar-top m-0">
        <nav className="nav nav-pills m-1">
          <Link to="/"><button className="btn btn-primary">Summary</button></Link>          
        </nav>
        <nav className="nav nav-pills m-1">
          <Link to="/temperature"><button className="btn btn-primary">Temperature</button></Link> 
        </nav>
        <nav className="nav nav-pills m-1">
          <Link to="/precipitation"><button className="btn btn-primary">Precipitation</button></Link> 
        </nav>
        <nav className="nav nav-pills m-1">
          <Link to="/snow"><button className="btn btn-primary">Snowfall</button></Link> 
        </nav>
        <nav className="nav nav-pills m-1">
          <Link to="/streamflow"><button className="btn btn-primary">Streamflow</button></Link> 
        </nav>
        <nav className="nav nav-pills m-1">
          <Link  to="/wildfires"><button className="btn btn-primary">Wildfires</button></Link> 
        </nav>
        <nav className="nav nav-pills m-1">
          <Link to="/resources"><button className="btn btn-primary">Resources</button></Link>
        </nav>
      </ul>
      {/*
        A <Switch> looks through all its children <Route>
        elements and renders the first one whose path
        matches the current URL. Use a <Switch> any time
        you have multiple routes, but you want only one
        of them to render at a time
      */}
      <Switch>
        <Route exact path="/">
          <Summary />
        </Route>
        <Route path="/temperature">
          <Temperature />
        </Route>
        <Route path="/precipitation">
          <Precipitation />
        </Route>
        <Route path="/snow">
          <Snow />
        </Route>
        <Route path="/streamflow">
          <Streamflow />
        </Route>
        <Route path="/wildfires">
          <Wildfires />
        </Route>
        <Route path="/resources">
          <Resources />
        </Route>
      </Switch>
      <Footer/>
    </Router>
  );
}

export default App;