import React from 'react';

function Precipitation() {
    return (
      <div>
        <div className="card bg-dark text-white">
          <img src="/media/precipitation/precipitation-pic-edited.jpg" className="card-img" alt="Spokane Falls"/>
          <div className="h-40 header-image-portion-overlay header-title d-none d-lg-flex">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text">Chapter 2: Precipitation Impact Study</h1>
              <h4 className="card-text header-title">
                <i>
                  <p>
                    “Agricultural systems in the inland Pacific Northwest (PNW) have evolved under a Mediterranean-type 
                    climate characterized by warm, dry summers and cool, wet winters. Precipitation is the primary limiting 
                    factor of production for most of the dryland wheat-growing region.”
                  </p>
                  <p>
                    – “Advancements of Dryland Farming in the Pacific Northwest”
                  </p>
                </i>
              </h4>
            </div>
          </div>
          <div className="h-80 header-image-portion-overlay header-title d-none d-md-flex d-lg-none">
            <div className="my-auto">
              <h1 className="card-title image-overlay-text">Chapter 2: Precipitation Impact Study</h1>
              <i>
                <p>
                  “Agricultural systems in the inland Pacific Northwest (PNW) have evolved under a Mediterranean-type 
                  climate characterized by warm, dry summers and cool, wet winters. Precipitation is the primary limiting 
                  factor of production for most of the dryland wheat-growing region.”
                </p>
                <p>
                  – “Advancements of Dryland Farming in the Pacific Northwest”
                </p>
              </i>
            </div>
          </div>
          <div className="h-80 header-image-portion-overlay header-title d-none d-sm-flex d-md-none">
            <div className="my-auto">
              <h3 className="card-title image-overlay-text">Chapter 2: Precipitation Impact Study</h3>
              <i>
                <p>
                  “Agricultural systems in the inland Pacific Northwest (PNW) have evolved under a Mediterranean-type 
                  climate characterized by warm, dry summers and cool, wet winters. Precipitation is the primary limiting 
                  factor of production for most of the dryland wheat-growing region.”
                </p>
                <p>
                  – “Advancements of Dryland Farming in the Pacific Northwest”
                </p>
              </i>
            </div>
          </div>
          <div className="h-80 header-image-portion-overlay header-title d-sm-none">
            <div className="my-auto">
              <h3 className="card-title image-overlay-text center-vertical">Chapter 2: Precipitation Impact Study</h3>
            </div>
          </div>
        </div>
      <div className="p-4">
        <div className="row">
          <div className="col-md-10 content">
            <ul id="chapter-sumamry" className="list-group list-group-flush">
              <li className="list-group-item">
                <b>Chapter Summary: </b>This chapter examines precipitation under projected future climate change in the Spokane area.
                  The chapter focuses on potential climate impacts on dryland wheat farming due to the crop’s direct reliance on
                  precipitation and economic importance to the region.</li>
              <li className="list-group-item">
                <b>Lead Authors: </b>Joel Breems (Avista) and Kevin Booth (Avista)</li>
              <li className="list-group-item">
                <b>Additional Analysis Author: </b>Erich Seamon (CIRC/University of Idaho)</li>
              <li className="list-group-item">
                <b>Review Editors: </b>Nathan Gilles (CIRC/Oregon State University), Katherine Hegewisch (CIRC/University of Idaho),
                John Abatzoglou (CIRC/UI), Erich Seamon (CIRC/UI), Ann Mooney (CIRC/OSU), and Meghan Dalton
                (CIRC/OSU).</li>
            </ul>
            <div className="row m-1">
              <div className="col p-0">
                <div className="card border-dark m-3">
                  <div className="card-header p-2 card-header-display">
                    <img className="col-1 icon-image p-0" src="/media/OSU_icon_key_01.png" alt="key"></img>
                    <h4 className="col-11 card-header-title p-0">Key Findings</h4>
                  </div>
                  <div className="card-body p-0">
                    <ol className="list-group" >
                      <li className="list-group-item">
                        In recent decades, the Spokane region has observed a slight increase in precipitation during the fall, winter,
                        and spring months, and a slight decrease in precipitation during the summer months.
                      </li>
                      <li className="list-group-item">
                        The timing and volume of precipitation in the Spokane region is not projected to dramatically change over
                        this century.
                      </li>
                      <li className="list-group-item">
                        Precipitation projections for Spokane for this century show a slight increase in annual precipitation, with a
                        slight increase in precipitation during the fall, winter, and spring months, and a slight decrease in
                        precipitation over the summer months. However, these projections do not preclude the existence of periodic
                        future droughts due to low precipitation levels.
                      </li>
                      <li className="list-group-item">
                        The Spokane region will continue to meet the precipitation timing and volume requirements for dryland
                        wheat production.
                      </li>
                      <li className="list-group-item">
                        During the 2015 drought, drought loss claims filed for wheat in Spokane, Adams, Whitman, and Lincoln
                        Counties totaled a combined $22 million.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              {/* End of Key Findings */}
              <div className="col p-0">
                <div className="card border-dark m-3">
                  <div className="card-header text-white bg-success p-2 card-header-display">
                    <img className="col-2 icon-image" src="/media/OSU_icon_shield_01.png" alt="shield"></img>
                    <h4 className="col-10 card-header-title p-0">Resiliency Actions</h4>
                  </div>
                  <div className="card-body p-0">
                    <ol className="list-group">
                      <li className="list-group-item list-group-item-success">
                        <b>Planting Techniques:</b> There are several steps farmers can take to minimize the compounding effects of
                        climate change to our agricultural community. Current efforts to minimize erosion in our region, including
                        no-till and direct seeding planting techniques as well as re-establishing stream and field buffers, will
                        become even more important in the future.
                      </li>
                      <li className="list-group-item list-group-item-success">
                        <b>Plan for High Temperatures:</b> Over the next 30–60 years, high heat dangers should be considered when scheduling outdoor
                        public events. 
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div id="climate-data-story-bloomsday" className="card border-dark mb-3">
              <div className="card-header p-2 card-header-display">
                <img className="col-1 icon-image" src="/media/OSU_icon_gears_01.png" alt="data-story"></img>
                <h4 className="col-11 card-header-title p-0">Climate Data Story - <i>Dryland Wheat Farming</i></h4>
              </div>
              <div className="card-body">
                <p>
                  In the Inland Pacific Northwest, dryland farming dominates much of the landscape. In 2018 alone, Washington
                  dryland farmers produced 153 million bushels of wheat, making the state the fourth largest wheat-producing state in
                  the nation, with the second highest average yield per acre, according to the Washington Grain Commission. All told,
                  dryland wheat is Washington’s third largest commodity and accounts for nearly $691 million in production value for
                  the state (<b>Washington Grain Commission 2019</b>). Dryland wheat farming is also dependent solely on precipitation
                  for all of the moisture required for crop growth, maturation, and productivity.
                </p>
                <p>
                  This relationship to precipitation makes dryland wheat ideal for studying how projected future changes to
                  precipitation could impact dryland farming in the Spokane region. Dryland wheat production requires 8–25 inches
                  of precipitation during the fall, winter, and spring, and benefits from relatively dry summer months (<b>Schillinger et al., 2012</b>).
                  Historically, the majority of Spokane’s annual precipitation has fallen during the winter and spring
                  months. This trend is expected to continue under both the lower emissions scenario (RCP 4.5) and the high
                  emissions scenario (RCP 8.5). Under both scenarios, precipitation projections for Spokane show a slight increase in
                  annual precipitation, a slight increase in precipitation during the fall, winter, and spring months, and a slight
                  decrease in precipitation over the summer months. 
                </p>
                <p> 
                  According to future climate projections tracked in the Climate
                  Toolbox, the Spokane region will continue to meet the precipitation timing and volume requirements for dryland
                  wheat production. Independent research has also concluded that dryland winter wheat production in the Pacific
                  Northwest could see increased yields under both the rising temperatures and the rising atmospheric carbon dioxide
                  levels expected under both RCP 4.5 and RCP 8.5 (<b>Stöckle et al., 2014; Fourth National Climate Assessment, Chapter 24 2018</b>).
                </p>
                <p>
                  Historically, however, dryland wheat farming has not been immune to the effects of drought. To better understand how
                  drought might impact dryland wheat farming in the Spokane area, CIRC examined drought-associated insurance loss claims
                  for wheat filed from 2001 to 2015 across the 24-county region of the Inland Pacific Northwest, a region that includes
                  Spokane County and nearby Adams, Whitman, and Lincoln Counties (<b>Seamon et al., 2019a</b>).
                </p>
                <p>
                  In 2015, wheat insurance loss claims filed across the Inland Pacific Northwest totaled $240 million for all damage causes, of
                  which losses attributed to drought accounted for 56%. In Spokane, Adams, Whitman, and Lincoln Counties in 2015, drought
                  loss claims filed for wheat totaled a combined $22 million (<b>Seamon et al., 2019b</b>).
                </p>
                <p>
                  The drought conditions during 2015 provide a potential analog for future droughts in terms of both precipitation and
                  temperature. The year 2015 is an ideal analog in this sense because the year saw near-normal precipitation levels but with
                  temperatures similar to those projected for the middle decades of this century (<b>Marlier et al., 2017</b>). While the 2015 drought
                  and its effect on wheat and insurance loss claims is not a clear guide to how dryland wheat might be affected in the future, it
                  does raise the possibility that dryland wheat farming might be more susceptible to certain climate conditions than previously
                  estimated.
                </p>
                <p>
                  <b>Discussion — Why Precipitation Should Not Be Considered Alone</b>
                </p>
                <p>
                  When precipitation is considered with other climate variables, such as temperature, several other potential
                  impacts to agriculture become apparent. While there is evidence to suggest that dryland wheat farming in
                  Washington State may see higher yields due both to rising temperatures and rising CO2
                  levels at least through
                  the middle of this century (<b>Stöckle et al., 2014</b>) (<b>Fourth National Climate Assessment, “Chapter 24: Northwest” 2018</b>),
                  other crops might not benefit from rising temperatures. For instance, warmer winter temperatures are
                  expected to lead to precipitation falling more as rain and less as snow, particularly at the lower elevations. This
                  is important for some crops because winter snow cover can provide an insulating effect, protecting dormant
                  crops from freezing temperatures (<b>Aase and Siddoway 1979</b>).
                </p>
                <p>
                  Additionally, as the Snowfall and Streamflow chapters of this report describe, the shift in precipitation from
                  snow to rain during the fall, winter, and spring months is expected to alter the timing of streamflow in the
                  region and is likely to impact the amount of stored water available for irrigated agriculture. Without large
                  increases in storage or conservation, the decline in spring snowpack will tax summer irrigation and water
                  resources. However, this is likely to be a larger problem in other parts of the Pacific Northwest rather than
                  Spokane (<b>Fourth National Climate Assessment, “Chapter 24: Northwest” 2018</b>). In the farmlands surrounding
                  Spokane, direct irrigation from the Spokane River is limited. Most agriculture in the region is dryland farming
                  and is not irrigated.
                </p>
              </div>
            </div>
            <div id="larger-context" className="card border-dark mb-3 bg-light">
              <div className="card-header p-2 card-header-display">
                <h4 className="col-11 card-header-title p-0">Larger Context—About Precipitation Projections</h4>
              </div>
              <div className="card-body">
                <p>
                  Precipitation projections have less confidence to them when compared to temperature projections. As such,
                  precipitation projections experience greater levels of uncertainty associated with them <b>(Rupp et al., 2017; CIRC “Precipitation” 2019). Figure 1</b> shows 
                  the projected annual precipitation simulations for the Pacific Northwest
                  United States to the year 2100 for both the lower emissions scenario (RCP 4.5) and the high emissions scenario
                  (RCP 8.5). Note: there is little difference between the two scenarios as far as projected precipitation is concerned.
                </p>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="larger-context" src="/media/precipitation/Fig-1-Precip.jpg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <p>
                      <i>
                        <b>Figure 1:</b> Annual precipitation projections for the Pacific Northwest to the year 2100 for both the lower emissions scenario (RCP
                        4.5) and the high emissions scenario (RCP 8.5). The dashed zero line represents average annual precipitation for the Pacific
                        Northwest United States for the historical baseline period 1950–2008. The gray section represents simulations of the historical
                        period; the black line represents the multi-model mean from those simulations. The light blue band represents the spread of
                        results from RCP 4.5; the gray line represents the multi-model mean from those results. The dark blue represents the spread of
                        results from RCP 8.5; the dark blue line represents the multi-model mean from those results. Source: Rupp et al., 2017/CIRC
                        “Precipitation” 2019 (https://pnwcirc.org/science/precipitation).
                      </i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div id="local-context" className="card border-dark bg-light mb-3">
              <div className="card-header"><h4>Local Context - Precipitation Not Projected to Change Dramatically</h4></div>
              <div className="card-body">
                <p>
                  While the uncertainty associated with precipitation projections remains high, the model results used for this report
                  can lead us to some reasonable conclusions. The first is that the timing and volume of projected precipitation in the
                  Spokane region is not projected to dramatically change over this century. However, precipitation should not be
                  considered alone (see <b>Discussion—Why Precipitation Should Not Be Considered Alone</b>). When precipitation is
                  considered along with temperature increases, several climate impacts come to light, including decreased mountain
                  snowpack (see the Snow chapter of this report), changes in the timing and flows of local streams and rivers (see <b>Streamflow</b>), 
                  and increased likelihood of wildfires (see <b>Fire</b>), which is expected to increase due to projected
                  temperatures gains as well as projected declines in summer precipitation.
                </p>
              </div>
            </div>
            <div id="analysis" className="card border-dark bg-light mb-3">
              <div className="card-header">
                <h4>Analysis — Geography, Data Tools, Inferences & Limitations, Emissions Scenarios, Multi-
                model Means, Variables, Time Frames, Climate Data Story, and Additional Analysis</h4>
              </div>
              <div className="card-body">
                <p>
                  <b>Geography:</b> Precipitation trends for the Spokane Region were evaluated using tools available in the Climate Toolbox
                  (Toolbox), a product of The Pacific Northwest Climate Impacts Research Consortium (CIRC). For all Toolbox tools used, the
                  location of Spokane, Washington was set at 47.66 o North, 117.43o West. Toolbox data has been downscaled to a grid cell
                  resolution of 2.5 miles. This 2.5-mile resolution limited the degree of detail our team could obtain.
                </p>
                <p>
                  <b>Data Tools:</b> Our team downloaded figures from the following tools in the Toolbox:
                </p>
                <ul>
                  <li>
                    Future Time Series Tool (<a href="https://climatetoolbox.org/tool/Future-Time-Series">https://climatetoolbox.org/tool/Future-Time-Series</a>)
                  </li>
                  <li>
                    Historical-Climograph Tool (<a href="https://climatetoolbox.org/tool/Historical-Climograph">https://climatetoolbox.org/tool/Historical-Climograph</a>)
                  </li>
                  <li>
                    Historical Climate Tracker Tool (<a href="https://climatetoolbox.org/tool/Historical-Climate-Tracker">https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>)
                  </li>
                  <li>
                    The Future Boxplots Tool (<a href='https://climatetoolbox.org/tool/Future-Boxplots'>https://climatetoolbox.org/tool/Future-Boxplots</a>)
                  </li>
                </ul>
                <p>
                  <b>Inferences & Limitations:</b> The greatest limitation to our analysis was the uncertainty associated with precipitation
                  projections. (See <b>Larger Context and Projected Future Precipitation—Overall Trends</b> above).
                </p>
                <p>
                  <b>Emissions Scenarios:</b> Our team chose to focus on results from the high emissions scenario (RCP 8.5) for our
                  analysis of projected seasonal differences in precipitation. This was done to streamline our analysis and in order to
                  be consistent with the other chapters of this report. We also chose to use RCP 8.5 on its own because, as the authors
                  of the <b>Temperature</b> chapter of this report put it, RCP 8.5 is “understood by the climate community to be the scenario
                  that most closely resembles our current emissions trajectory.” However, we did perform an analysis to see if there
                  were any notable difference between precipitation projections under the lower emissions scenario (RCP 4.5) and the
                  high emissions scenario (RCP 8.5). We found no discernable difference between the two scenarios as far as annual
                  and seasonal precipitation projections were concerned.
                </p>
                <p>
                  (Note: RCP 4.5 isn’t the lowest emissions scenario used by climate researchers. RCP 2.6 is the lowest emission
                  scenario considered in climate models. However, because the collective global emissions pathway has very likely
                  veered off course from that modeled under RCP 2.6, RCP 2.6 is no longer used as the low emission scenario
                  pathway. Since RCP 4.5 is lower than RCP 8.5, this report has adopted the standard used by many in the climate
                  research community: lower to describe RCP 4.5 and high to describe RCP 8.5, rather than lower and higher to
                  describe the two scenarios.)
                </p>
                <p>
                  <b>Multi-model Means:</b> The data and figures that make up this analysis represent the mean resulting from the 20
                  global climate models (GCMs) used by the Toolbox to create its future climate projections. Using a multi-model
                  mean as opposed to the results of single model is generally accepted as best practice by climate researchers.
                  However, the mean does not show the full spread of results from the 20 GCMs used. In other words, actual future
                  climate conditions, when we get to them, might lie either above or below the multi-model mean.
                </p>
                <p>
                    <b>Variables:</b> Our analysis examined the following variables using the following tools.
                </p>
                <ul>
                  <li>
                    <i>Mean monthly precipitation and minimum/maximum temperatures</i> (Historical-Climograph Tool)
                  </li>
                  <li>
                    <i>Summer (June–August) precipitation</i> (Historical Climate Tracker Tool)
                  </li>
                  <li>
                    <i>Annual precipitation</i> (Historical Climate Tracker Tool; Future Time Series Tool)
                  </li>
                  <li>
                    <i>Seasonal precipitation</i> (Future Boxplots Tool)
                  </li>
                </ul>
                <p>
                  <b>Time Frames:</b> This analysis examined recent historical precipitation for the years 1981–2010 and 1979–2018.
                  Projected annual changes were examined to year 2100 and compared to the historical baseline period 1950–2008.
                  Projected annual and seasonal changes were further examined using the following time frames: early century (2010–
                  2039), mid-century (2040–2069), and late century (2070–2099). Future projections were compared to the historical
                  baseline 1971–2000.
                </p>
                <p>
                  <b>Climate Data Story:</b> Our climate data story for this section focused on potential climate impacts on dryland wheat
                  farming due to its economic importance to Spokane’s regional economy and its direct reliance on precipitation. A
                  climate data story is defined by CIRC as “a narrative outlining climate facts and impacts specific to your
                  community” (<b>Mooney et al., 2019</b>).
                </p>
                <p>
                  <b>Additional Analysis:</b> This chapter includes an additional analysis provided by CIRC that examines the relationship
                  between the 2015 drought and insurance loss claims for wheat filed by farmers in the Inland Pacific Northwest
                  during 2015.
                </p>
              </div>
            </div>
            <div id="historical-climate-precipitation" className="card border-dark bg-light mb-3">
              <div className="card-header">
                <h4>Historical Climate—Seasonal Precipitation Trends</h4>
              </div>
              <div className="card-body">
                <p>
                  <b>Variable:</b> <i>Mean Monthly Precipitation; Minimum/Maximum Temperature</i>
                </p>
                <p>
                  <b>Finding:</b> Historically, the majority of Spokane’s annual precipitation has fallen during the fall, winter, and spring
                  months.
                </p>
                <p>
                  <b>Justification:</b> From 1981 to 2010, the average annual precipitation in Spokane, Washington was 17.6 inches,
                  according to the Historical-Climograph Tool (<b>Figure 2</b>). This data represents a simulated historical climate that was
                  created using historical data. To check this data against location data, our team examined data collected at the
                  Spokane International Airport. The National Weather Service station at the Spokane International Airport recorded
                  an average of 16.6 inches for the same time period (<b>NOWData 2019</b>). Inclusion of more recent years’ data raises
                  the average annual precipitation to 18.4 inches for 1979–2018 in Spokane (<b>Figure 3</b>).
                </p>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                      <img className="img-fluid border border-dark" alt="temp-precip" src="/media/precipitation/Fig-2-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 2:</b> Mean Monthly precipitation measured in inches and minimum/maximum temperatures measured in degrees Fahrenheit
                          for the years 1981–2010. Source: Historical-Climograph Tool (<a href='https://climatetoolbox.org/tool/Historical-Climograph'>https://climatetoolbox.org/tool/Historical-Climograph</a>), The
                          Climate Toolbox.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="historical-climate-recent-seasonal" className="card border-dark bg-light mb-3">
              <div className="card-header">
                <h4>Historical Climate—Recent Seasonal Precipitation Trends</h4>
              </div>
              <div className="card-body">
                <p>
                  <b>Variable:</b> <i>Annual (January–December) Precipitation; Fall (October–December) Precipitation; Winter (January–
                  March) Precipitation; Spring (March–May) Precipitation; Summer (June–August) Precipitation</i>
                </p>
                <p>
                  <b>Finding:</b> In recent decades, the Spokane region has experienced a slight increase in annual precipitation, a slight
                  increase in precipitation during the fall, winter, and spring months, and a slight decrease in precipitation during the
                  summer months.
                </p>
                <p>
                  <b>Justification:</b> If we further examine historical precipitation data for Spokane, several trends can be seen in the
                  available data. The Historical Climate Tracker Tool shows a prevailing trend in recent years (1979–2018) of
                  increasing annual (January–December) precipitation (+1 inch per decade) (<b>Figure 3</b>).
                </p>
                <p>
                  Examined seasonally, the Historical Climate Tracker Tool shows a trend of increasing precipitation in the fall
                  (October–December) (+0.5 inch per decade) (<b>Figure 4</b>), winter (January–March) (+0.6 inch per decade) (<b>Figure 5</b>),
                  and spring (March–May) (+0.4 inch per decade) (<b>Figure 6</b>). From 1979 to 2019, Spokane has also seen a trend of
                  decreasing summer precipitation (June–August) (- 0.1 inch per decade) (<b>Figure 7</b>).
                </p>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="jan-dec-precip" src="/media/precipitation/Fig-3-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 3:</b> Annual (January–December) precipitation (in inches) for Spokane, Washington for the years 1979–2018 with trend
                          line. Source: Historical Climate Tracker Tool (<a href="https://climatetoolbox.org/tool/Historical-Climate-Tracker">https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>), The Climate
                          Toolbox.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="oct-dec-precip" src="/media/precipitation/Fig-4-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 4:</b> Fall (October–December) precipitation (in inches) for Spokane, Washington for the years 1979–2018 with trend line.
                          Source: Historical Climate Tracker Tool (<a href='https://climatetoolbox.org/tool/Historical-Climate-Tracker'>https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>), The Climate Toolbox.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                      <img className="img-fluid border border-dark" alt="jan-mar-precip" src="/media/precipitation/Fig-5-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 5:</b> Winter (January–March) precipitation (in inches) for Spokane, Washington for the years 1979–2019 with trend line.
                          Source: Historical Climate Tracker Tool (<a href='https://climatetoolbox.org/tool/Historical-Climate-Tracker'>https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>), The Climate Toolbox.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="mar-may-precip" src="/media/precipitation/Fig-6-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 6:</b> Spring (March–May) precipitation for Spokane, Washington for the years 1979–2019 with trend line. Source:
                          Historical Climate Tracker Tool (<a href='https://climatetoolbox.org/tool/Historical-Climate-Tracker'>https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>), The Climate Toolbox.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                      <img className="img-fluid border border-dark" alt="jun-aug-precip" src="/media/precipitation/Fig-7-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 7:</b> Summer (June–August) precipitation for the years 1979–2019 with trend line. Source: Historical Climate Tracker Tool
                          (<a href="https://climatetoolbox.org/tool/Historical-Climate-Tracker">https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>), The Climate Toolbox.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="projected-future-climate" className="card border-dark bg-light mb-3">
              <div className="card-header">
                <h4>Projected Future Climate—Annual Precipitation Trends</h4>
              </div>
              <div className="card-body">
                <p>
                  <b>Variable:</b> <i>Annual (January–December) Precipitation</i>
                </p>
                <p>
                  <b>Finding:</b> There is little difference between the lower emissions scenario (RCP 4.5) and the high emissions scenario
                  (RCP 8.5) in terms of annual precipitation projections for Spokane.
                </p>
                <p>
                  <b>Finding:</b> Projected future annual precipitation for Spokane under both the lower emission scenario (RCP 4.5) and
                  the high emissions scenario (RCP 8.5) is not expected to deviate significantly from historical levels. In other words,
                  the annual volume of precipitation is not projected to change dramatically under future climate change.
                </p>
                <p>
                  <b>Justification:</b> Precipitation projections generally have less confidence to them than temperature projections. An
                  analysis by the Pacific Northwest Climate Impact Research Consortium (CIRC) suggests that the higher variability
                  in precipitation projections for the Pacific Northwest United States is due to several factors (<b>Rupp et al. 2017</b>). The
                  impact of greenhouse gas emissions is less direct for precipitation than for temperature. For instance, the higher
                  emissions scenario (RCP 8.5) produces more warming than the lower emissions scenario (RCP 4.5) yet the
                  precipitation projections are similar for both scenarios (<b>Figures 8 and 9</b>). Interannual precipitation variability is also
                  rather high and generally larger than the magnitude of projected changes (<b>Rupp et al. 2017</b>). Precipitation
                  projections for the Pacific Northwest show disagreement between global climate models (GCMs) about whether
                  precipitation will increase or decrease under a warming climate. At the same time, the multi-model mean from these
                  projections tends not to deviate significantly from what was normal historically. <b>Figure 8</b> shows projected average
                  annual (January–December) precipitation for Spokane (measured in inches) to year the 2100 for the lower emissions
                  scenario (RCP 4.5). <b>Figure 9</b> shows projected average annual (January—December) precipitation for Spokane
                  (measured in inches) to the year 2100 for the high emissions scenario (RCP 8.5). The gray and blue lines in the
                  figures represent the multi-model mean of 20 GCMs used to create the projections. Projected future precipitation is
                  shown in blue. The bands of blue represent the spread of results from the GCMs. Three things can be understood
                  from these figures:
                </p>
                <ol className="list-group">
                  <li className="list-group-item">
                    There is little to no difference in projected precipitation levels between the two scenarios. For instance, the
                    projected average annual precipitation for the year 2099 is 19.6 inches under RCP 8.5 and 19.0 inches
                    under RCP 4.5.
                  </li>
                  <li className="list-group-item">
                    The projected future multi-model mean for both scenarios shows slight increases above the historical
                    values. For instance, the historical mean is 17.6 ′′ while the end of century projections for both scenarios
                    are only slightly above that mean (19.6 ′′ for RCP 8.5; 19.0 ′′ for RCP 4.5). This means the region might
                    be trending slightly wetter under climate change.
                  </li>
                  <li className="list-group-item ">
                    The spread of the climate projections (represented as the blue band) can be found both above and below
                    both the historical multi-model mean and projected future multi-model mean. This result is harder to
                    interpret. However, if you consider CIRC’s above-mentioned analysis, this likely implies two things:
                    <ol className="list-group">
                      <li className="list-group-item">
                        There is disagreement among the models about whether Spokane will get wetter or drier under
                        climate change;
                      </li>
                      <li className="list-group-item">
                        Natural variability, as annual and interannual changes in precipitation (i.e. abnormally wet and
                        abnormally dry years or seasons) will continue to be key
                        factors in the region’s future.
                      </li>
                    </ol>
                  </li> 
                </ol>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="precipitation-low" src="/media/precipitation/Fig-8-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 8:</b> Projected annual (January–December) precipitation in inches for Spokane, Washington to the year 2100 for the lower
                          emissions scenario (RCP 4.5) shown in blue. The lines represent the multi-model mean of 20 downscaled global climate models.
                          The solid bands (blue and gray) represent the spread of results from the 20 models used to create this projection. The gray band
                          represents a historical simulation of the years 1950–2005. The black line represents the multi-model mean for the historical
                          simulation. Projected future precipitation is represented in the blue line (the multi-model mean) and the blue band (the spread of
                          results from the 20 models). Source: Future Time Series Tool (<a href="https://climatetoolbox.org/tool/Future-Time-Series">https://climatetoolbox.org/tool/Future-Time-Series</a>), The Climate
                          Toolbox.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="precipitation-high" src="/media/precipitation/Fig-9-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 9:</b> Projected annual (January–December) precipitation in inches for Spokane, Washington to the year 2100 for the high
                          emissions scenario (RCP 8.5) shown in blue. The lines represent the multi-model mean of 20 downscaled global climate models.
                          The solid bands (blue and gray) represent the spread of results from the 20 models used to create this projection. The gray band
                          represents a historical simulation of the years 1950–2005. The black line represents the multi-model mean for the historical
                          simulation. Projected future precipitation is represented in the blue line (the multi-model mean) and the blue band (the spread of
                          results from the 20 models). Source: Future Time Series Tool (<a href="https://climatetoolbox.org/tool/Future-Time-Series">https://climatetoolbox.org/tool/Future-Time-Series</a>), The Climate
                          Toolbox.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="projected-future-climate-seasonal" className="card border-dark bg-light mb-3">
              <div className="card-header">
                <h4>Projected Future Climate—Seasonal Precipitation Trends</h4>
              </div>
              <div className="card-body">
                <p>
                  <b>Variable:</b> <i>Annual (January–December) Precipitation; Winter (December—February) Precipitation; Spring
                  (March—May) Precipitation; Summer (June–August) Precipitation</i>
                </p>
                <p>
                  <b>Finding:</b> Precipitation projections for Spokane show a slight increase in annual precipitation, with a slight increase
                  in precipitation during the fall, winter, and spring months, and a slight decrease in precipitation over the summer
                  months.
                </p>
                <p>
                  <b>Justification:</b> This section of our analysis looks at projected seasonal precipitation. Because we found no
                  substantive difference between the lower emissions scenario (RCP 4.5) and the high emissions scenario (RCP 8.5)
                  as far as annual precipitation projections were concerned, our seasonal analysis examines RCP 8.5 alone. To
                  examine projected seasonal precipitation, we used the Future Boxplots Tool available in the Climate Toolbox.
                </p>
                <p>
                  The boxplots shown in <b>Figures 10–14</b> display the results of 20 global climate models statistically downscaled to the
                  Spokane region. The boxplots each contain 90% of the variance or values within the box. The results of each of the
                  20 global climate models are represented by the individual points. The multi-model mean is indicated by a solid bar.
                  Over all, precipitation projections for Spokane show a slight increase in annual (January–December) precipitation
                  (<b>Figure 10</b>), a slight increase in fall (September–November) precipitation (<b>Figure 11</b>), winter (December–February)
                  (<b>Figure 12</b>), and spring (March—May) (<b>Figure 13</b>), and a slight decrease in summer (June–August) precipitation
                  (<b>Figure 14</b>).
                </p>
                <p>
                  Projected 20-model mean annual precipitation (<b>Figure 10</b>) for the 30-year time periods early century (2010–2039),
                  mid-century (2040–2069), and late century (2070–2099) are 18.2 inches, 19′′, and 19.6′′, respectively, compared to
                  17.7′′ for the historical baseline (1971–2000). Projected 20-model mean summer precipitation (<b>Figure 14</b>) are 2.7′′
                  early century, 2.6′′ mid-century, and 2.4′′ late century, compared to 2.8′′ for the historical baseline (1971–2000).
                </p>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="precipitation-low" src="/media/precipitation/Fig-10-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 10:</b> Projected Future annual (January–December) precipitation (in inches) for Spokane, Washington for the simulated
                          historical period (1971–2000) and projection future periods (2010–2039, 2040–2069, and 2070–2099) under the high emissions
                          scenario (RCP 8.5). The results of each of the 20 models used in the analysis are represented by individual points. The multi-
                          model mean is indicated by solid bars. Source: Future Climate Boxplots Tool (https://climatetoolbox.org/tool/Future-Boxplots),
                          The Climate Toolbox.
                        </i>
                    </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="precipitation-high" src="/media/precipitation/Fig-11-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 11:</b> Projected future Fall (September—November) precipitation (in inches) for Spokane, Washington for the simulated
                          historical period (1971–2000) and projection future periods (2010–2039, 2040–2069, and 2070–2099) under the high emissions
                          scenario (RCP 8.5). The results of each of the 20 models used in the analysis are represented by individual points. The multi-
                          model mean is indicated by solid bars. Source: Future Climate Boxplots Tool (https://climatetoolbox.org/tool/Future-Boxplots),
                          The Climate Toolbox.
                        </i>
                    </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="precipitation-high" src="/media/precipitation/Fig-12-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 12:</b> Projected Future winter (December—February) precipitation (in inches) for Spokane, Washington for the simulated
                          historical period (1971–2000) and projection future periods (2010–2039, 2040–2069, and 2070–2099) under the high emissions
                          scenario (RCP 8.5). The results of each of the 20 models used in the analysis are represented by individual points. The multi-
                          model mean is indicated by solid bars. Source: Future Climate Boxplots Tool (https://climatetoolbox.org/tool/Future-Boxplots),
                          The Climate Toolbox.
                        </i>
                    </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="precipitation-high" src="/media/precipitation/Fig-13-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 13:</b> Projected Future spring (March—May) precipitation (in inches) for Spokane, Washington for the simulated historical
                          period (1971–2000) and projection future periods (2010–2039, 2040–2069, and 2070–2099) under the high emissions scenario
                          (RCP 8.5). The results of each of the 20 models used in the analysis are represented by individual points. The multi-model mean
                          is indicated by solid bars. Source: Future Climate Boxplots Tool (https://climatetoolbox.org/tool/Future-Boxplots), The Climate
                          Toolbox.
                        </i>
                    </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="precipitation-high" src="/media/precipitation/Fig-14-Precip.jpeg"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 14:</b> Projected future summer (June–August) precipitation (in inches) for Spokane, Washington for the simulated historical
                          period (1971–2000) and projection future periods (2010–2039, 2040–2069, and 2070–2099) under the high emissions scenario
                          (RCP 8.5). The results of each of the 20 models used in the analysis are represented by individual points. The multi-model mean
                          is indicated by solid bars. Source: Future Climate Boxplots Tool (https://climatetoolbox.org/tool/Future-Boxplots), The Climate
                          Toolbox.
                        </i>
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="conclusion" className="card border-dark bg-light mb-3">
                <div className="card-header"><h4>Conclusion — Dryland Wheat Farming is Viable Under Future Climate Change</h4></div>
              <div className="card-body">
                <p>
                  The seasonal timing as well as the annual and seasonal volume of precipitation in the Spokane region is not expected
                  to dramatically change over this century, according to our analysis. This means that the Spokane region will
                  continue to meet the precipitation timing and volume requirements for dryland wheat production as outlined by the
                  Washington Grain Commission. However, as discussed above, compared to other climate variables, such as
                  temperature, the precipitation projections have much greater levels of uncertainty associated with them. Natural
                  variability in the form of especially wet and especially dry years and seasons is also likely to continue in the future.
                  Put simply, our analysis does not preclude the existence of periodic future droughts due to low precipitation levels.
                </p>
                <h4>Discussion — Why Precipitation Should Not Be Considered Alone</h4>
                <p>
                  When precipitation is considered with other climate variables, such as temperature, several other potential impacts to
                  agriculture become apparent. While there is evidence to suggest that dryland wheat farming in Washington State
                  may see higher yields due both to rising temperatures and rising CO2 levels at least through the middle of this
                  century (<b>Stöckle et al., 2014</b>) (<b>Fourth National Climate Assessment, “Chapter 24: Northwest,” 2018</b>), other crops
                  might not benefit from rising temperatures. For instance, warmer winter temperatures are expected to lead to
                  precipitation falling more as rain and less as snow, particularly at the lower elevations. This is important for some
                  crops because winter snow cover can provide an insulating effect protecting dormant crops from freezing
                  temperatures (<b>Aase and Siddoway 1979</b>).
                </p>
                <p>
                    Additionally, as the Snow and Streamflow chapters of this report describe, the shift in precipitation from snow to
                    rain during the fall, winter, and spring months is expected to alter the timing of streamflow in the region and is likely
                    to impact the amount of stored water available for irrigated agriculture. Without large increases in storage or
                    conservation, the decline in spring snowpack will tax summer irrigation and water resources. However, this is likely
                    to be a larger problem in other parts of the Pacific Northwest rather than Spokane (<b>Fourth National Climate Assessment, “Chapter 24: Northwest,” 2018</b>). 
                    In the farmlands surrounding Spokane, direct irrigation from the
                    Spokane River is limited. Most agriculture in the region is dryland farming and is not irrigated.
                </p>
                <div className="card border-dark m-3">
                  <div className="card-header text-white bg-success p-2 card-header-display">
                    <h4 className="col-10 card-header-title ">Resiliency Actions</h4>
                  </div> 
                  <div className="card-body p-0">
                    <ol className="list-group">
                      <li className="list-group-item list-group-item-success">
                        <b>Planting Techniques:</b> Current efforts to minimize erosion in our region, including no-till and direct
                        seeding planting techniques as well as re-establishing stream and field buffers, will become even more
                        important in the future.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div id="projected-future-climate-seasonal" className="card border-dark bg-light mb-3">
              <div className="card-header">
                <h4>Additional Analysis: Insurance Loss Claims for Wheat and the 2015 Drought</h4>
              </div>
              <div className="card-body">
                <p>
                  <b>Author:</b> Erich Seamon, University of Idaho, Pacific Northwest Climate Impacts Research Consortium (CIRC)
                </p>
                <p>
                  <b>Finding:</b> During the 2015 drought, wheat insurance loss claims across the Inland Pacific Northwest totaled $240
                  ion for all damage causes, of which drought loss accounted for 56% of these claims.
                </p>
                <p>
                  <b>Finding:</b>During the 2015 drought, drought loss claims filed for wheat in Spokane, Adams, Whitman, and Lincoln
                  Counties totaled a combined $22 million.
                </p>
                <p>
                  <b>Justification:</b> As noted above, several studies suggest that dryland wheat farming in the Pacific Northwest may see
                  higher yields due both to rising temperatures and rising CO2 levels (<b>Stöckle et al., 2014</b>) (<b>Fourth National Climate Assessment, Chapter 24 2018</b>). 
                  Precipitation projections for Spokane also suggest that dryland wheat farming will
                  receive adequate amounts of precipitation in the future, according to the above analysis. However, these projections
                  do not preclude the occurrence of periodic droughts.
                </p>
                <p>
                  To better understand how drought might impact dryland wheat farming in the Spokane area, CIRC examined
                  drought-associated insurance loss claims for wheat filed from 2001 to 2015 across the 24-county region of the
                  Inland Pacific Northwest portion of the United States (<b>Figure 15</b>), a region that includes Spokane County and
                  nearby Adams, Whitman, and Lincoln Counties (<b>Seamon et al., 2019a</b>).
                </p>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="ag-regions" src="/media/precipitation/Fig-15-Precip.png"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <b>Figure 15:</b> Pacific Northwest (PNW) agricultural regions, which include the Willamette Valley (green), the Southern Idaho
                        Valley (red), and the study area for this analysis, the Inland Pacific Northwest (yellow).
                    </p>
                    </div>
                  </div>
                </div>
                <p>
                  CIRC’s analysis highlights the drought conditions that occurred across the Inland Pacific Northwest in 2015.
                  Because climate conditions during the 2015 drought look similar to projected future climate conditions for the
                  Pacific Northwest generally (<b>Marlier et al., 2017</b>), drought conditions during 2015 are used in this analysis as an
                  analog for understanding potential future drought impacts on dryland wheat in the Spokane region. In particular,
                  CIRC found a clear relationship between drought conditions during 2015 and drought-associated insurance loss
                  claims filed during 2015.
                </p>
                <p>
                  Over 90% of US farmers have agricultural crop insurance. Nationwide, over 2.8 million crop insurance claims were
                  filed from 2001 to 2015. Crop insurance is especially important when climate-related events, such as droughts,
                  extreme heat, or cold weather, affect crops and, as a result, impact a farmer’s livelihood (<b>Christiansen et al., 1975; Diskin 1997; Miranda and Glauber 1997</b>). 
                  During the 2001–2015 period examined by CIRC, insurance losses associated with wheat accounted for over $1.4
                  billion in claims across the 15-county region of the Inland Pacific Northwest, including over $700 million in claims
                  due to drought. During the 15-year period, the years 2009 and 2015 standout (<b>Figure 16</b>).
                </p>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="wheat-loss-damage" src="/media/precipitation/Fig-16-Precip.png"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 16:</b> Comparisons of wheat insurance loss from 2001 to 2015 for the Inland Pacific Northwest, comparing causes of loss
                          (excessive moisture, decline in price, heat, and drought). Note the peak years of 2009 and 2015.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <p>
                  Both 2009 and 2015 were peak years for filed insurance loss claims for wheat, and both years saw a large number of
                  claims for drought. However, 2015 was the only year of the two that experienced regional drought conditions
                  (<b>Seamon et al., 2019a</b>). This suggests that the Great Economic Recession of 2008/2009 and the associated drop in
                  wheat prices may have played a role in farmers’ decisions to file insurance claims for losses due to drought
                  (<b>Seamon et al., 2019a</b>).
                </p>
                <p>
                  The year 2015 was clearly a drought year. Precipitation levels during 2015 were slightly below normal. However,
                  temperatures during 2015 were warmer than normal (<b>Mote et al., 2015</b>). By late June 2015, much of the Inland
                  Pacific Northwest was categorized as being <i>Abnormally Dry</i> or experiencing <i>Moderate to Severe Drought</i>,
                  according to data collected at the time by the U.S. Drought Monitor (<b>Figure 17</b>) (<b>U.S. Drought Monitor 2019</b>).
                </p>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="us-drought-monitor" src="/media/precipitation/Fig-17-Precip.png"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 17:</b> Drought conditions for June 23, 2015 across the western region of the United States. Much of the Inland Pacific
                          Northwest was placed under the categories Abnormally Dry, Moderate Drought, and Severe Drought, according to the US
                          Drought Monitor.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <p>
                  Wheat insurance loss claims across the Inland Pacific Northwest for 2015 totaled $240 million for all damage
                  causes, of which losses attributed to drought accounted for 56% of that total (<b>Seamon et al., 2019b</b>). In Spokane,
                  Adams, Whitman, and Lincoln Counties, drought loss claims for wheat filed in 2015 totaled a combined $22
                  million. <b>Figure 18</b> provides a comparison of drought and heat loss claims filed in the four counties for the years
                  2001–2015.
                </p>
                <div className="row no-gutters">
                  <div className="col-md-9">
                    <div className="card-body">
                        <img className="img-fluid border border-dark" alt="drought-heat-loss-claims" src="/media/precipitation/Fig-18-Precip.png"></img>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <p>
                        <i>
                          <b>Figure 18:</b> A comparison of drought and heat loss claims filed in Spokane, Adams, Whitman, and Lincoln Counties for the years
                          2001–2015.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <p>
                  The drought conditions during 2015 provide an analog for future droughts in terms of both precipitation and
                  temperature. Future precipitation projections for Spokane and the Inland Pacific Northwest generally are not
                  expected to vary significantly from current patterns in terms of the timing and volume of precipitation. The year
                  2015 is an ideal analog in this sense because the year saw near-normal precipitation levels across the region.
                  Temperatures during 2015 also look quite similar to temperatures projected for the middle decades of this century
                  (<b>Marlier et al., 2017</b>). Third, is the relationship between wheat drought claims made by farmers and drought
                  conditions at the time of those claims.
                </p>
                <p>
                  A statistical analysis performed by CIRC examined the relationship between insurance loss claims and multiple
                  variables, including commodity prices, temperature, precipitation, evapotranspiration, and the Palmer Drought
                  Severity Index (PDSI). CIRC’s analysis revealed a clear relationship between drought claims and PDSI, a climate
                  variable that is used as a proxy for soil moisture. PDSI is commonly used in agriculture and incorporated into the US
                  Drought Monitor. CIRC’s analysis indicated that PDSI was the most important factor in predicting total seasonal
                  wheat/drought insurance loss claims. This pattern was especially clear in the drought-prone counties of eastern
                  Washington (<b>Seamon et al., 2019b</b>). While the 2015 drought and its effect on wheat and insurance loss claims is not
                  a clear guide to how dryland wheat might be affected in the future, it does raise the possibility that dryland wheat
                  farming might be more susceptible to certain climate conditions than previously estimated.
                </p>
              </div>
            </div>
            <div id="citations" className="card border-dark bg-light mb-3">
              <div className="card-header"><h4>Citations For this Chapter</h4></div>
              <div className="card-body">
                <p className="card-text">
                  Breems, Joel, and Kevin Booth. “Precipitation Study for Spokane,
                  Washington.” In <i>The Spokane Climate Vulnerability and Resilience Assessment</i>, edited by Nathan Gilles, Katherine
                  Hegewisch, John Abatzoglou, Erich Seamon, Ann Mooney, and Meghan Dalton. Corvallis, Oregon: 
                  <i>The Pacific Northwest Climate Impacts Research Consortium (CIRC), College of Earth, Ocean, and Atmospheric Sciences, Oregon State University</i>, 2020.
                </p>
                <h5>CIRC-Related Data Sources:</h5>
                <ol className="list-group">
                  <li className="list-group-item">
                    Abatzoglou, John T. "Development of gridded surface meteorological data for ecological applications and
                    modelling." <i>International Journal of Climatology</i> 33, no. 1 (2013): 121-131.
                    <a href="https://doi.org/10.1002/joc.3413">https://doi.org/10.1002/joc.3413</a>.
                  </li>
                  <li className="list-group-item">
                    Abatzoglou, John T., and Timothy J. Brown. "A comparison of statistical downscaling methods suited for wildfire
                    applications." <i>International Journal of Climatology</i> 32, no. 5 (2012): 772-780.  
                    <a href="https://doi.org/10.1002/joc.2312">https://doi.org/10.1002/joc.2312</a>.
                  </li>
                  <li className="list-group-item">
                      Hegewisch, Katherine C., and John T. Abatzoglou. “Future Climate Boxplots Tool.” Climate Toolbox. Accessed
                    April 2, 2019. <a href="https://climatetoolbox.org/tool/future-climate-boxplots">https://climatetoolbox.org/tool/future-climate-boxplots</a>.
                  </li>
                  <li className="list-group-item">
                    Hegewisch, Katherine C., and John T. Abatzoglou. “Future Time Series.” Climate Toolbox. Accessed May 15,
                    2019. <a href="https://climatetoolbox.org/tool/Future-Time-Series">https://climatetoolbox.org/tool/Future-Time-Series</a>.
                  </li>
                  <li className="list-group-item">
                      Hegewisch, Katherine C., and John T. Abatzoglou. “Future Cold Hardiness Zones Tool.” The Climate Toolbox. Accessed
                    November 21, 2018. <a href="https://climatetoolbox.org/tool/Cold-Hardiness-Zones">https://climatetoolbox.org/tool/Cold-Hardiness-Zones</a>.
                  </li>
                  <li className="list-group-item">
                      Hegewisch, Katherine C., and John T. Abatzoglou. “Future Time Series Tool.” The Climate Toolbox. Accessed November
                    1st, 2019. <a href="https://climatetoolbox.org/tool/Future-Time-Series">https://climatetoolbox.org/tool/Future-Time-Series</a>
                  </li>
                  <li className="list-group-item">
                    Hegewisch, Katherine C., and John T. Abatzoglou. “Historical-Climograph Tool.” Climate Toolbox. Accessed April
                    2, 2019. <a href="https://climatetoolbox.org/tool/Historical-Climograph">https://climatetoolbox.org/tool/Historical-Climograph</a>
                  </li>
                  <li className="list-group-item">
                    Hegewisch, Katherine C., and John T. Abatzoglou. “Historical Climate Tracker.” Climate Toolbox. Accessed April
                    2, 2019. <a href="https://climatetoolbox.org/tool/Historical-Climate-Tracker">https://climatetoolbox.org/tool/Historical-Climate-Tracker</a>.
                  </li>
                </ol>
                <h5 className="m-2">Additional References:</h5>
                <ol className="list-group">
                  <li className="list-group-item">
                      Aase, J. K., and F. H. Siddoway. “Crown-Depth Soil Temperatures and Winter Protection for Winter Wheat
                      Survival 1.” <i>Soil Science Society of America Journal</i> 43, no. 6 (1979): 1229–33.
                      <a href="https://doi.org/10.2136/sssaj1979.03615995004300060036x">https://doi.org/10.2136/sssaj1979.03615995004300060036x</a>.
                  </li>
                  <li className="list-group-item">
                    Christensen, Laurits R. 1975. “Concepts and Measurement of Agricultural Productivity.” <i>American Journal of Agricultural Economics</i> 57 (5): 910. 
                    <a href="https://doi.org/10.2307/1239102">https://doi.org/10.2307/1239102</a>.
                  </li>
                  <li className="list-group-item">
                    Diskin, Patrick. “Agricultural Productivity Indicators Measurement Guide.” <i>Food and Nutrition Technical Assistance Project</i> (FANTA, 1997).
                  </li>
                  <li className="list-group-item">
                    Marlier, Miriam E., Mu Xiao, Ruth Engel, Ben Livneh, John T. Abatzoglou, and Dennis P. Lettenmaier. 2017. “The
                    2015 Drought in Washington State: A Harbinger of Things to Come?” <i>Environmental Research Letters</i> 12 (11):
                    114008. <a href="https://doi.org/10.1088/1748-9326/aa8fde">https://doi.org/10.1088/1748-9326/aa8fde</a>.
                  </li>
                  <li className="list-group-item">
                    Miranda, Mario J, and Joseph W. Glauber. 1997. “Systemic Risk, Reinsurance, and the Failure of Crop Insurance
                    Markets.” <i>American Journal of Agricultural Economics</i> 79 (1): 206–15. <a href="https://doi.org/10.2307/1243954">https://doi.org/10.2307/1243954</a>.
                  </li>
                  <li className="list-group-item">
                    Mooney, Ann, Nathan Gilles, Katherine Hegewisch, John Abatzoglou, and Meghan Dalton. “The Northwest Climate
                    Toolbox Workbook: Discovering and applying your climate data story for climate adaptation planning,” Corvallis,
                    Oregon: The Pacific Northwest Climate Impacts Research Consortium (CIRC), College of Earth, Ocean, and
                    Atmospheric Sciences, Oregon State University, 2019. <a href="https://pnwcirc.org/sites/pnwcirc.org/files/nwct.pdf">https://pnwcirc.org/sites/pnwcirc.org/files/nwct.pdf</a>.
                  </li>
                  <li className="list-group-item">
                    Mote, Philip W., David E. Rupp, Sihan Li, Darrin J. Sharp, Friederike Otto, Peter F. Uhe, Mu Xiao, Dennis P.
                    Lettenmaier, Heidi Cullen, and Myles R. Allen. "Perspectives on the causes of exceptionally low 2015 snowpack in
                    the western United States." <i>Geophysical Research Letters</i> 43, no. 20 (2016): 10-980.
                    <a href="https://doi.org/10.1002/2016GL069965">https://doi.org/10.1002/2016GL069965</a>.
                  </li>
                  <li className="list-group-item">
                    National Weather Service Forecast Office. “NOWData—NOAA Online Weather Data.” <i>Spokane International Airport Daily/Monthly Normals</i>. Accessed November 13, 2019.
                    <a href="https://w2.weather.gov/climate/xmacis.php?wfo=otx">https://w2.weather.gov/climate/xmacis.php?wfo=otx</a>.
                  </li>
                  <li className="list-group-item">
                    The Pacific Northwest Climate Impacts Research Consortium (CIRC), Oregon State University. “Climate Tools.”
                    Accessed September 24, 2019. <a href="https://pnwcirc.org/science/precipitation">https://pnwcirc.org/science/precipitation</a>.
                  </li>
                  <li className="list-group-item">
                    Rupp, David E., John T. Abatzoglou, and Philip W. Mote. "Projections of 21st century climate of the Columbia
                    River Basin." <i>Climate Dynamics</i> 49, no. 5-6 (2017): 1783-1799.
                    <a href="https://doi.org/10.1002/jgrd.50843">https://doi.org/10.1002/jgrd.50843</a>.
                  </li>
                  <li className="list-group-item">
                    Schillinger, William F., Steven E. Schofstoll, and J. Richard Alldredge. 2008. “Available Water and Wheat Grain
                    Yield Relations in a Mediterranean Climate.” <i>Field Crops Researc</i>h 109, no. 1–3 (2008): 45–49.
                    <a href='https://doi.org/10.1016/j.fcr.2008.06.008'>https://doi.org/10.1016/j.fcr.2008.06.008</a>.
                  </li>
                  <li className="list-group-item">
                    Schillinger, William F., Steven E. Schofstoll, and J. Richard Alldredge. “Predicting Wheat Grain Yields Based on
                    Available Water.” <i>Washington State University Extension</i>, 2012.
                    <a href="https://s3.wp.wsu.edu/uploads/sites/2200/2012/04/Available-water-and-wheat-yield-EM049E.pdf">https://s3.wp.wsu.edu/uploads/sites/2200/2012/04/Available-water-and-wheat-yield-EM049E.pdf</a>.
                  </li>
                  <li className="list-group-item">
                    Seamon, Erich, Paul E. Gessler, John T. Abatzogou, Philip W. Mote, and Stephen S. Lee. PhD diss., University of
                    Idaho, 2019 (2019a). “Agricultural insurance loss analysis of the Pacific Northwest, USA: 2001–2015.”
                    <a href="https://github.com/erichseamon/seamon_dissertation">https://github.com/erichseamon/seamon_dissertation</a>;
                    <a href="https://dmine.io/waf/dissertation/appendices/seamon_PHD_appendix_A.html">https://dmine.io/waf/dissertation/appendices/seamon_PHD_appendix_A.html</a>.
                  </li>
                  <li className="list-group-item">
                    Seamon, Erich, Paul E. Gessler, John T. Abatzogou, Philip W. Mote, Stephen S. Lee. PhD diss., University of
                    Idaho, 2019 (2019b). “Regression based random forest modeling of inland pacific northwestern drought-related
                    wheat insurance loss using time-lagged climate correlation matrix association.”
                    <a href='https://github.com/erichseamon/seamon_dissertation'>https://github.com/erichseamon/seamon_dissertation</a>;
                    <a href='https://dmine.io/waf/dissertation/appendices/seamon_PHD_appendix_B.html'>https://dmine.io/waf/dissertation/appendices/seamon_PHD_appendix_B.html</a>.
                  </li>
                  <li className="list-group-item">
                    Stöckle, Claudio O., Stewart Higgins, Roger Nelson, John Abatzoglou, Dave Huggins, William Pan, Tina Karimi,
                    John Antle, Sanford D. Eigenbrode, and Erin Brooks. "Evaluating opportunities for an increased role of winter crops
                    as adaptation to climate change in dryland cropping systems of the US Inland Pacific Northwest." <i>Climatic Change</i>
                    146, no. 1-2 (2018): 247-261. <a href="https://doi.org/10.1007/s10584-017-1950-z">https://doi.org/10.1007/s10584-017-1950-z</a>.
                  </li>
                  <li className="list-group-item">
                    United States Drought Monitor. “Map Archive, August 25, 2015” Accessed January 14, 2020.
                    <a href='https://droughtmonitor.unl.edu/Maps/MapArchive.aspx'>https://droughtmonitor.unl.edu/Maps/MapArchive.aspx</a>.
                  </li>
                  <li className="list-group-item">
                    <i>Washington Grain Commission</i>. “Where Wheat Grows.” Accessed March 28, 2019. <a href='http://wagrains.org/all-about-wheat/varieties-of-wheat/where-wheat-grows/'>http://wagrains.org/all-about-wheat/varieties-of-wheat/where-wheat-grows/</a>.
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* Beginning of Sidebar */}
          <div className="col-2 d-none d-md-block navbar">
            <div className="navbar-sticky-top sticky-top bg-white side-navbar">
              <h5 className="sidebar-title">Menu</h5>
              <ol className="list-group">
                <li className="list-group-item">
                  <a href="#chapter-sumamry">Chapter Summary</a>
                </li>
                <li className="list-group-item">
                  <a href="#climate-data-story-bloomsday">CDS - Dryland Wheat Farming</a>
                </li>
                <li className="list-group-item">
                  <a href="#larger-context">Larger Context</a>
                </li>               
                <li className="list-group-item">
                    <a href="#local-context">Local Context</a>
                </li>
                <li className="list-group-item">
                    <a href="#analysis">Analysis</a>
                </li>
                <li className="list-group-item">
                  <a href="#historical-climate-precipitation">Historical Climate</a>
                </li>
                <li className="list-group-item">
                    <a href="#projected-future-climate">Projected Future Climate</a>
                </li>
                <li className="list-group-item">
                    <a href="#conclusion">Conclusion</a>
                </li>
                <li className="list-group-item">
                    <a href="#citations">Citations</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

export default Precipitation;