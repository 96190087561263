import React from 'react';

function Footer() {
    return (
        <footer className="page-footer container-fluid pt-4">
          {/* Footer Links */}
          <div className="text-center">
            {/* Grid column */}
            <p>
              Questions on the study results or the website? 
            </p>
            <p>
              Contact us at <a href="mailto:spokaneclimateproject@gmail.com">spokaneclimateproject@gmail.com</a>
            </p>
            <div className="row">
              <div className="col-md-2 col-1">
              </div>
              <div className="col-md-3 col-4">
                <img className="img-fluid" alt="circ logo" src="/media/circ_logo.png"></img>
              </div>
              <div className="col-md-2 col-2">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-8">
                  <img className="img-fluid" alt="Spokane Climate Project icon" src="/media/blue-leaf-favicon.png"></img>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>
              <div className="col-md-3 col-4">
                <img className="img-fluid" alt="risa logo" src="/media/RISA_logo.png"></img>
              </div>
              <div className="col-md-2 col-1">
              </div>
            </div>
            <div className="mt-md-0 mt-3">
              <p>Icons courtesy of Oregon State University and the College of Earth, Ocean, and Atmospheric Sciences, all rights reserved.</p>
            </div>
            {/* Grid column */}
          </div>
        {/* Footer */}
        </footer>
    );
  }

export default Footer;